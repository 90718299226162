import { MenuItem, Select } from '@mui/material';
import IntlMessages from 'components/util/IntlMessages';
import { OFFER_DETAILS_INPUT_NAMES } from 'constants/businessModelsPro';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront, UpFrontEvents } from 'interfaces/businessModels/upfront';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import validate from 'services/util/validate';

const InverterWarantyUpfront = () => {
    const {
        state: {
            selected: { elements },
        },
        isRequestingBM,
        setBMEventHandler,
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const {
        control,
        formState: { errors },
    } = useFormContext();

    if (!elements[OFFER_DETAILS_INPUT_NAMES.INVERTERS_WARATY]) return <></>;
    const options = elements[OFFER_DETAILS_INPUT_NAMES.INVERTERS_WARATY]!.options.options[0];

    return (
        <div className="d-flex justify-content-between mb-3">
            <div className="label-container">
                <label>
                    <IntlMessages id="page.spv.summary.inverters-warranty-years" />
                </label>
            </div>
            <div className="input-container">
                <Controller
                    name={OFFER_DETAILS_INPUT_NAMES.INVERTERS_WARATY}
                    control={control}
                    rules={validate(['required', 'number', 'positive'])}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    render={({ field: { ref, ...field } }) => (
                        <Select
                            {...field}
                            inputProps={{ className: 'd-flex align-items-center' }}
                            disabled={options.om_durations.length === 1 || isRequestingBM}
                            error={!!errors[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]}
                            onChange={(e) => {
                                field.onChange(e.target.value);
                                setBMEventHandler(UpFrontEvents.SET_INVERTER_WARRANTY, Number(e.target.value));
                            }}
                        >
                            <MenuItem value={-1} disabled={true}>
                                <IntlMessages id={'label.selectOption'} />
                            </MenuItem>
                            {options.om_durations.map((duration) => {
                                return (
                                    <MenuItem key={`om_duration_${duration}`} value={`${duration}`}>
                                        <IntlMessages
                                            id={`label.nYear${duration / 12 === 1 ? '' : 's'}`}
                                            values={{ number: duration / 12 }}
                                        />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
                />
            </div>
        </div>
    );
};

export default InverterWarantyUpfront;
