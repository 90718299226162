import clone from 'fast-copy';
import { orderByLocale } from 'services/dropdown';
import { INPUT_NAMES_FDATA as INPUT_NAMES, QUESTIONS_FDATA_B2B, QUESTIONS_FDATA_B2C } from 'constants/facilityPro/facilityData';
import validate from 'services/util/validate';
import { getCompanyProfileIds } from 'services/user';
import { NUMERO_FASES_ID_DEFAULT_VALUES_B2C } from 'constants/facilityPro/loadProfileData';
import { isDefined, isFieldDefined } from 'services/util/auxiliaryUtils';

//#region /** Common B2B & B2C **/
export function getOptions(responses, info) {
    let { userLocale } = info;
    let RQST_IDX = {
        facilityTypes: 0,
        voltageLevels: 1,
        connectionVoltages: 2,
        nuts: 3,
        classes: 4,
        counties: 5,
    };
    let facilityTypes =
        responses?.[RQST_IDX.facilityTypes]?.data?.data ?
            orderByLocale(responses?.[RQST_IDX.facilityTypes]?.data?.data, 'facilityType', userLocale)
        :   [];
    let voltageLevels = responses?.[RQST_IDX.voltageLevels]?.data?.data ?? [];
    let connectionVoltages = responses?.[RQST_IDX.connectionVoltages]?.data?.data ?? [];
    let nuts = responses?.[RQST_IDX.nuts]?.data?.data ?? [];
    let classes =
        responses?.[RQST_IDX.classes]?.data?.data ? orderByLocale(responses?.[RQST_IDX.classes]?.data?.data, 'classes', userLocale) : [];
    let counties = responses?.[RQST_IDX.counties]?.data?.data ?? [];

    return {
        facilityTypes,
        voltageLevels,
        connectionVoltages,
        nuts,
        classes,
        counties,
    };
}

export function getCountyFallback(companyProfileId, counties) {
    switch (companyProfileId) {
        case getCompanyProfileIds().PPC:
            return counties.find((el) => el.id === 'GR03') ?? ''; // Central Athens
        case getCompanyProfileIds().PROFILE_EDP_FR:
            return counties.find((el) => el.id === 'FRMUN1') ?? ''; // France
        default:
            return '';
    }
}

export function getCountyAbrevFallback(companyProfileId) {
    switch (companyProfileId) {
        case getCompanyProfileIds().PPC:
            return 'Central Athens'; // Central Athens
        default:
            return '';
    }
}
export function getPostalCodeFallback(companyProfileId) {
    switch (companyProfileId) {
        case getCompanyProfileIds().PPC:
            return '104 46'; // Central Athens /* inQC #1665 */
        default:
            return '';
    }
}

export function getCityFallback(companyProfileId) {
    switch (companyProfileId) {
        case getCompanyProfileIds().PPC:
            return 'Αττική'; // Central Athens /* inQC #1665 */
        default:
            return '';
    }
}

export function getIsRegionValid(companyProfileId, countryCode) {
    switch (companyProfileId) {
        case getCompanyProfileIds().MONS:
        case getCompanyProfileIds().ROMANDE:
        case getCompanyProfileIds().PROFILE_ROMANDE:
        case getCompanyProfileIds().PROFILE_BASE_SWISS:
            return countryCode === 'CH';
        case getCompanyProfileIds().PPC:
        case getCompanyProfileIds().PROFILE_PPC:
            return countryCode === 'GR';
        case getCompanyProfileIds().DYNAMIC:
        case getCompanyProfileIds().EDEN:
        case getCompanyProfileIds().PROFILE_BASE_UK:
        case getCompanyProfileIds().EVOLO:
            return countryCode === 'GB';
        case getCompanyProfileIds().PROFILE_BASE_USA:
            return countryCode === 'US';
        case getCompanyProfileIds().ESB:
        case getCompanyProfileIds().NRG:
        case getCompanyProfileIds().PROFILE_BASE_IRELAND:
            return countryCode === 'IE';
        case getCompanyProfileIds().EDP_IT:
        case getCompanyProfileIds().PROFILE_BASE_IT:
            return countryCode === 'IT';
        case getCompanyProfileIds().SUNSEAP:
            return countryCode === 'SG';
        case getCompanyProfileIds().PROFILE_BASE_DE:
            return countryCode === 'DE';
        case getCompanyProfileIds().EDP_ES:
            return countryCode === 'ES';
        case getCompanyProfileIds().EDP_PL:
            return countryCode === 'PL';
        case getCompanyProfileIds().EDP_PT:
            return countryCode === 'PT';
        case getCompanyProfileIds().PROFILE_EDP_FR:
            return countryCode === 'FR';
        default:
            return true;
    }
}

//#endregion /* Methods Public */

//#region /** B2B **/

//#region /* Methods Private */

//#endregion /* Methods Private */

//#region /* Methods Public */

export function initialFDataInputsB2B() {
    return {
        cpe: '',
        descricao: '',
        localidade: '',
        morada: '',
        municipio_descricao: '',
        municipio_id: '',
        potencia_contratada: '',
        potencia_requisitada: '',
        nivel_tensao_id: '',
        tipo_instalacao_id: '',
        location: {
            //map+autocomplete
            address: '',
            street: '',
            number: '',
            complement: '',
            postal_code: '',
            district: '',
            city: '',
            nuts_i_id: '',
            nuts_ii_id: '',
            nuts_iii_id: '',
            county_id: '',
            county_description: '',
        },
        has_manual_address: false,
        manual_address: '',
        num_fases: '',
        mcb_rating: '',
    };
}
export function getFacilityDataB2B(inputs, options) {
    let { counties, isAddFacility, companyProfileId, setMarkerLocation, configs } = options;

    let defaultInputs = null;
    let countySelected = null;
    let address = null;

    // common Edit
    if (isAddFacility) {
        //edit defaults
        inputs[INPUT_NAMES.POTENCIA_CONTRATADA] =
            isDefined(configs?.new_facility_default_data) ? configs?.new_facility_default_data?.contracted_power_kw
            : (
                ![
                    getCompanyProfileIds().ROMANDE,
                    getCompanyProfileIds().PROFILE_BASE_SWISS,
                    getCompanyProfileIds().PROFILE_BASE_USA,
                ].includes(companyProfileId)
            ) ?
                50
            :   null; //default value
        inputs[INPUT_NAMES.NUM_FASES] = ![getCompanyProfileIds().PPC].includes(companyProfileId) ? '' : 3; //default value threephasic
    } else {
        // COUNTY_ID
        countySelected = counties?.find((item) => item.id === inputs?.municipio_id) ?? '';

        //ADDRESS
        address = {
            //autocomplete Gmap-Place
            description: inputs?.[INPUT_NAMES.MORADA] ?? '',
            address: inputs?.[INPUT_NAMES.MORADA] ?? '',
            lat: inputs?.[INPUT_NAMES.LATITUDE] ?? null,
            lng: inputs?.[INPUT_NAMES.LONGITUDE] ?? null,
            county_abrev: inputs?.[INPUT_NAMES.MUNICIPIO_DESCRICAO] ?? null,
        };
        setMarkerLocation({
            ...address,
            postal_code: inputs?.[INPUT_NAMES.CODIGO_POSTAL],
            [INPUT_NAMES.CITY]: inputs?.[INPUT_NAMES.LOCALIDADE] ?? null,
            [INPUT_NAMES.COUNTY_ID]: countySelected ?? '',
        });
    }

    // COMMON
    defaultInputs = clone({
        facilityId: inputs?.[INPUT_NAMES.ID] ?? null,
        [INPUT_NAMES.CPE]: inputs?.[INPUT_NAMES.CPE] ?? '',
        [INPUT_NAMES.DESCRICAO]: inputs?.[INPUT_NAMES.DESCRICAO] ?? '',
        [INPUT_NAMES.LOCATION]: {
            [INPUT_NAMES.ADDRESS]: address,
            [INPUT_NAMES.POSTAL_CODE]: inputs?.[INPUT_NAMES.CODIGO_POSTAL] ?? '',
            [INPUT_NAMES.CITY]: inputs?.[INPUT_NAMES.LOCALIDADE] ?? '',
            [INPUT_NAMES.COUNTY_ID]: countySelected ?? '',
        },
        [INPUT_NAMES.HAS_MANUAL_ADDRESS]: isFieldDefined(inputs?.[INPUT_NAMES.MANUAL_ADDRESS]) ?? false,
        [INPUT_NAMES.MANUAL_ADDRESS]: inputs?.[INPUT_NAMES.MANUAL_ADDRESS] ?? '',
        [INPUT_NAMES.POTENCIA_CONTRATADA]: `${inputs?.[INPUT_NAMES.POTENCIA_CONTRATADA] ?? ''}`,
        [INPUT_NAMES.POTENCIA_REQUISITADA]: `${inputs?.[INPUT_NAMES.POTENCIA_REQUISITADA] ?? ''}`,
        [INPUT_NAMES.NUM_FASES]: `${inputs[INPUT_NAMES.NUM_FASES] ?? ''}`,
        [INPUT_NAMES.NIVEL_TENSAO_ID]: `${inputs?.[INPUT_NAMES.NIVEL_TENSAO_ID] ?? ''}`,
        [INPUT_NAMES.MCB_RATING]: `${inputs?.[INPUT_NAMES.MCB_RATING] ?? ''}`,
        [INPUT_NAMES.TIPO_INSTALACAO_ID]: `${inputs?.[INPUT_NAMES.TIPO_INSTALACAO_ID] ?? ''}`,
    });

    return defaultInputs;
}

export function getQuestionsFacilityDataB2B(options) {
    let { isAddFacility, companyProfileId } = options;

    let questions = clone(QUESTIONS_FDATA_B2B);

    //#region COMMON
    //CPE
    questions[INPUT_NAMES.CPE].disabled =
        isAddFacility ? false : (
            [
                //Edit
                getCompanyProfileIds().SUNSEAP,
                getCompanyProfileIds().NRG,
                getCompanyProfileIds().ESB,
                getCompanyProfileIds().EDP_ES,
                getCompanyProfileIds().EDP_PT,
            ].includes(companyProfileId)
        );
    //#endregion  COMMON

    switch (companyProfileId) {
        case getCompanyProfileIds().SUNSEAP: {
            // NIVEL_TENSAO_ID
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = true;
            questions[INPUT_NAMES.MCB_RATING].visible = true;

            break;
        }
        case getCompanyProfileIds().EVOLO: {
            // COUNTY_ID
            questions[INPUT_NAMES.COUNTY_ID].visible = ![getCompanyProfileIds().PROFILE_BASE_UK, getCompanyProfileIds().EVOLO].includes(
                companyProfileId
            );

            questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = true;
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].validation = null;

            // DESCRICAO
            questions[INPUT_NAMES.DESCRICAO].validation = validate(['maxLength'], { maxLength: 256 });
            questions[INPUT_NAMES.DESCRICAO].isRequired = false;

            break;
        }
        case getCompanyProfileIds().PROFILE_BASE_UK: {
            //CPE
            if (isAddFacility) {
                questions[INPUT_NAMES.CPE].validation = validate(['maxLength'], { maxLength: 30 });
                questions[INPUT_NAMES.CPE].isRequired = false;
            }
            // COUNTY_ID
            questions[INPUT_NAMES.COUNTY_ID].visible = ![getCompanyProfileIds().PROFILE_BASE_UK, getCompanyProfileIds().EVOLO].includes(
                companyProfileId
            );

            questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = true;
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].validation = null;

            // DESCRICAO
            questions[INPUT_NAMES.DESCRICAO].validation = validate(['maxLength'], { maxLength: 256 });
            questions[INPUT_NAMES.DESCRICAO].isRequired = false;

            break;
        }
        case getCompanyProfileIds().PROFILE_BASE_USA: {
            // NIVEL_TENSAO_ID
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = true;
            // CPE
            if (isAddFacility) {
                questions[INPUT_NAMES.CPE].validation = validate(['maxLength'], { maxLength: 30 });
                questions[INPUT_NAMES.CPE].isRequired = false;
            }

            // DESCRICAO
            questions[INPUT_NAMES.DESCRICAO].validation = validate(['maxLength'], { maxLength: 256 });
            questions[INPUT_NAMES.DESCRICAO].isRequired = false;

            // ADDRESS
            questions[INPUT_NAMES.ADDRESS].validation = validate(['maxLength'], { maxLength: 256 });
            questions[INPUT_NAMES.ADDRESS].isRequired = false;

            // POSTAL_CODE
            questions[INPUT_NAMES.POSTAL_CODE].validation = validate(['required', 'minLength', 'maxLength'], {
                minLength: 5,
                maxLength: 8,
            });
            questions[INPUT_NAMES.POSTAL_CODE].optValidation = { minLength: 5, maxLength: 8 };
            questions[INPUT_NAMES.POSTAL_CODE].isRequired = true;

            // COUNTY_ID
            delete questions[INPUT_NAMES.COUNTY_ID].validation;
            questions[INPUT_NAMES.COUNTY_ID].isRequired = false;
            // CITY
            questions[INPUT_NAMES.CITY].visible = false;
            questions[INPUT_NAMES.CITY].validation = validate(['maxLength'], { maxLength: 64 });
            questions[INPUT_NAMES.CITY].isRequired = false;

            // CONTRACTED_POWER
            questions[INPUT_NAMES.POTENCIA_CONTRATADA].visible = true;

            // technicalInformation section
            questions.technicalInformationSection.visible = true;

            break;
        }
        case getCompanyProfileIds().PROFILE_BASE_DE: {
            if (isAddFacility) {
                questions[INPUT_NAMES.CPE].validation = validate(['maxLength'], { maxLength: 30 });
                questions[INPUT_NAMES.CPE].isRequired = false;
            }

            // COUNTY_ID
            questions[INPUT_NAMES.COUNTY_ID].visible = false;

            break;
        }
        case getCompanyProfileIds().ROMANDE:
        case getCompanyProfileIds().PROFILE_BASE_SWISS: {
            // CPE
            if (isAddFacility) {
                questions[INPUT_NAMES.CPE].validation = validate(['maxLength'], { maxLength: 30 });
                questions[INPUT_NAMES.CPE].isRequired = false;
            }

            // DESCRICAO
            questions[INPUT_NAMES.DESCRICAO].validation = validate(['maxLength'], { maxLength: 256 });
            questions[INPUT_NAMES.DESCRICAO].isRequired = false;

            // ADDRESS
            questions[INPUT_NAMES.ADDRESS].validation = validate(['maxLength'], { maxLength: 256 });
            questions[INPUT_NAMES.ADDRESS].isRequired = false;

            // POSTAL_CODE
            questions[INPUT_NAMES.POSTAL_CODE].validation = validate(['maxLength'], { maxLength: 8 });
            questions[INPUT_NAMES.POSTAL_CODE].isRequired = false;

            // CITY
            questions[INPUT_NAMES.CITY].visible = false;
            questions[INPUT_NAMES.CITY].validation = validate(['maxLength'], { maxLength: 64 });
            questions[INPUT_NAMES.CITY].isRequired = false;

            // CONTRACTED_POWER
            questions[INPUT_NAMES.POTENCIA_CONTRATADA].visible = false;

            // technicalInformation section
            questions.technicalInformationSection.visible = false;
            break;
        }
        case getCompanyProfileIds().EFZ: {
            // CPE
            questions[INPUT_NAMES.CPE].disabled = true;

            // DESCRICAO
            questions[INPUT_NAMES.DESCRICAO].disabled = true;

            // INSTALLATION_TYPE_ID
            questions[INPUT_NAMES.TIPO_INSTALACAO_ID].visible = true;

            // NIVEL_TENSAO_ID
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = true;

            break;
        }
        case getCompanyProfileIds().PPC: {
            // NUM_FASES
            questions[INPUT_NAMES.NUM_FASES].visible = true;
            break;
        }
        case getCompanyProfileIds().PROFILE_BASE_IT: {
            //CPE
            if (isAddFacility) {
                questions[INPUT_NAMES.CPE].validation = validate(['maxLength'], { maxLength: 30 });
                questions[INPUT_NAMES.CPE].isRequired = false;
            }
            // INSTALLATION_TYPE_ID
            questions[INPUT_NAMES.TIPO_INSTALACAO_ID].visible = true;

            // NIVEL_TENSAO_ID
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = true;
            break;
        }
        case getCompanyProfileIds().ESB:
        case getCompanyProfileIds().NRG: {
            // DESCRICAO
            questions[INPUT_NAMES.DESCRICAO].disabled = !isAddFacility;
            // INSTALLATION_TYPE_ID
            questions[INPUT_NAMES.TIPO_INSTALACAO_ID].visible = true;

            // NIVEL_TENSAO_ID
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = true;
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].disabled = !isAddFacility;
            break;
        }
        case getCompanyProfileIds().EDP_ES: {
            // DESCRICAO
            questions[INPUT_NAMES.DESCRICAO].disabled = !isAddFacility;
            // INSTALLATION_TYPE_ID
            questions[INPUT_NAMES.TIPO_INSTALACAO_ID].visible = true;

            // NIVEL_TENSAO_ID
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = true;
            break;
        }
        case getCompanyProfileIds().EDP_PL:
        case getCompanyProfileIds().EDP_IT: {
            // DESCRICAO
            questions[INPUT_NAMES.DESCRICAO].disabled = !isAddFacility;
            // INSTALLATION_TYPE_ID
            questions[INPUT_NAMES.TIPO_INSTALACAO_ID].visible = true;

            // NIVEL_TENSAO_ID
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = true;
            break;
        }
        case getCompanyProfileIds().PROFILE_EDP_FR: {
            // COUNTY_ID
            questions[INPUT_NAMES.COUNTY_ID].visible = false;
            break;
        }
        case getCompanyProfileIds().EDP_PT: {
            // DESCRICAO
            questions[INPUT_NAMES.DESCRICAO].disabled = !isAddFacility;
            // INSTALLATION_TYPE_ID
            questions[INPUT_NAMES.TIPO_INSTALACAO_ID].visible = true;

            // NIVEL_TENSAO_ID
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].visible = true;
            questions[INPUT_NAMES.NIVEL_TENSAO_ID].disabled = !isAddFacility;

            // POTENCIA_REQUISITADA
            questions[INPUT_NAMES.POTENCIA_REQUISITADA].visible = true;

            // NUM_FASES
            questions[INPUT_NAMES.NUM_FASES].visible = true;
            break;
        }
        default:
            break;
    }

    return { questions, isVisible: true };
}

/**
 * subscribedPowerUnit
 *
 * @param {*} companyProfileId
 * @returns
 */
export function subscribedPowerUnit(companyProfileId) {
    let unit = 'kVA';

    switch (companyProfileId) {
        case getCompanyProfileIds().PROFILE_BASE_SWISS:
        case getCompanyProfileIds().PPC:
        case getCompanyProfileIds().PROFILE_BASE_UK:
        case getCompanyProfileIds().EVOLO:
        case getCompanyProfileIds().PROFILE_BASE_USA:
        case getCompanyProfileIds().EDP_IT:
        case getCompanyProfileIds().NRG:
        case getCompanyProfileIds().ESB:
        case getCompanyProfileIds().EDP_PL:
        case getCompanyProfileIds().PROFILE_BASE_IT:
            unit = 'kW';
            break;
        default:
            break;
    }
    return { unitByCompanyID: unit };
}

//#endregion /* Methods Public */

//#endregion /** B2B **/

//#region /** B2C **/
//#region /* Methods Private */
//#endregion /* Methods Private */

//#region /* Methods Public */
export function initialFDataInputsB2C() {
    return {
        cpe: '',
        location: {
            //map+autocomplete
            address: '',
            manual_address: '',
            street: '',
            number: '',
            complement: '',
            postal_code: '',
            district: '',
            city: '',
            nuts_i_id: '',
            nuts_ii_id: '',
            nuts_iii_id: '',
            county_id: '',
            county_description: '',
        },
        has_manual_address: false,
        potencia_contratada: '',
        numero_fases_id: '',
    };
}

export function getFacilityDataB2C(inputs, options) {
    let { location } = inputs;
    let { counties, isAddFacility, companyProfileId, setMarkerLocation, configs } = options;

    let defaultInputs = null;
    let countySelected = null;
    let address = null;

    // common
    if (isAddFacility) {
        //defaults
        inputs[INPUT_NAMES.POTENCIA_CONTRATADA] =
            isDefined(configs?.new_facility_default_data) ? configs?.new_facility_default_data?.contracted_power_kw : 50;
        inputs[INPUT_NAMES.NUMERO_FASES_ID] =
            NUMERO_FASES_ID_DEFAULT_VALUES_B2C?.[companyProfileId] ?? NUMERO_FASES_ID_DEFAULT_VALUES_B2C.DEFAULT; //default value threephasic
    } else {
        //edit

        // COUNTY_ID
        countySelected = counties?.find((item) => item.id === location?.county_id) ?? '';

        //ADDRESS
        address = {
            //autocomplete Gmap-Place
            description: location?.[INPUT_NAMES.ADDRESS] ?? '',
            address: location?.[INPUT_NAMES.ADDRESS] ?? '',
            lat: location?.[INPUT_NAMES.LATITUDE] ?? null,
            lng: location?.[INPUT_NAMES.LONGITUDE] ?? null,
        };
        setMarkerLocation({
            ...address,
            postal_code: location?.[INPUT_NAMES.POSTAL_CODE],
            [INPUT_NAMES.CITY]: location?.[INPUT_NAMES.CITY],
            [INPUT_NAMES.COUNTY_ID]: countySelected ?? '',
        });
    }

    // defaults
    defaultInputs = clone({
        facilityId: inputs?.[INPUT_NAMES.ID] ?? null,
        [INPUT_NAMES.CPE]: inputs?.[INPUT_NAMES.CPE] ?? '',
        [INPUT_NAMES.LOCATION]: {
            [INPUT_NAMES.ADDRESS]: address ?? null,
            [INPUT_NAMES.MANUAL_ADDRESS]: location?.[INPUT_NAMES.MANUAL_ADDRESS] ?? '',
            [INPUT_NAMES.POSTAL_CODE]: location?.[INPUT_NAMES.POSTAL_CODE] ?? '',
            [INPUT_NAMES.CITY]: location?.[INPUT_NAMES.CITY] ?? '',
            [INPUT_NAMES.COUNTY_ID]: countySelected ?? '',
        },
        [INPUT_NAMES.HAS_MANUAL_ADDRESS]: isFieldDefined(inputs?.[INPUT_NAMES.MANUAL_ADDRESS]) ?? false,
        [INPUT_NAMES.MANUAL_ADDRESS]: inputs?.[INPUT_NAMES.MANUAL_ADDRESS] ?? '',
        [INPUT_NAMES.POTENCIA_CONTRATADA]: `${inputs?.[INPUT_NAMES.POTENCIA_CONTRATADA] ?? ''}`,
        [INPUT_NAMES.NUMERO_FASES_ID]: `${inputs?.[INPUT_NAMES.NUMERO_FASES_ID] ?? ''}`,
    });
    return defaultInputs;
}

export function getQuestionsFacilityDataB2C(options) {
    let { isAddFacility, companyProfileId } = options;

    let questions = clone(QUESTIONS_FDATA_B2C);

    switch (companyProfileId) {
        case getCompanyProfileIds().PROFILE_BASE_IT:
        case getCompanyProfileIds().PROFILE_BASE_SWISS: {
            if (isAddFacility) {
                questions[INPUT_NAMES.CPE].disabled = false;
                questions[INPUT_NAMES.CPE].validation = validate(['maxLength'], { maxLength: 30 });
                questions[INPUT_NAMES.CPE].isRequired = false;
            }

            // Show
            questions[INPUT_NAMES.CITY].visible = true;
            questions[INPUT_NAMES.COUNTY_ID].visible = true;

            // POSTAL_CODE
            questions[INPUT_NAMES.POSTAL_CODE].visible = true;
            questions[INPUT_NAMES.POSTAL_CODE].validation = null;
            questions[INPUT_NAMES.POSTAL_CODE].isRequired = false;
            break;
        }
        case getCompanyProfileIds().PROFILE_BASE_DE: {
            if (isAddFacility) {
                questions[INPUT_NAMES.CPE].disabled = false;
                questions[INPUT_NAMES.CPE].validation = validate(['maxLength'], { maxLength: 30 });
                questions[INPUT_NAMES.CPE].isRequired = false;
            }

            // Show
            questions[INPUT_NAMES.CITY].visible = true;
            questions[INPUT_NAMES.COUNTY_ID].visible = false;

            // POSTAL_CODE
            questions[INPUT_NAMES.POSTAL_CODE].visible = true;
            questions[INPUT_NAMES.POSTAL_CODE].validation = null;
            questions[INPUT_NAMES.POSTAL_CODE].isRequired = false;
            break;
        }
        case getCompanyProfileIds().PROFILE_EDP_FR: {
            if (isAddFacility) {
                questions[INPUT_NAMES.CPE].disabled = false;
            }

            // Show
            questions[INPUT_NAMES.CITY].visible = true;
            questions[INPUT_NAMES.COUNTY_ID].visible = true;

            // POSTAL_CODE
            questions[INPUT_NAMES.POSTAL_CODE].visible = true;
            questions[INPUT_NAMES.POSTAL_CODE].validation = null;
            questions[INPUT_NAMES.POSTAL_CODE].isRequired = false;
            break;
        }
        case getCompanyProfileIds().PROFILE_BASE_USA: {
            if (isAddFacility) {
                questions[INPUT_NAMES.CPE].disabled = false;
            }

            // Show
            questions[INPUT_NAMES.CITY].visible = true;
            questions[INPUT_NAMES.COUNTY_ID].visible = true;

            // POSTAL_CODE
            questions[INPUT_NAMES.POSTAL_CODE].visible = true;
            questions[INPUT_NAMES.POSTAL_CODE].validation = null;
            questions[INPUT_NAMES.POSTAL_CODE].isRequired = false;
            break;
        }
        case getCompanyProfileIds().PPC: {
            if (isAddFacility) {
                questions[INPUT_NAMES.CPE].disabled = false;
            }

            // Show
            questions[INPUT_NAMES.CITY].visible = true;
            questions[INPUT_NAMES.COUNTY_ID].visible = true;

            // POSTAL_CODE
            questions[INPUT_NAMES.POSTAL_CODE].visible = true;
            questions[INPUT_NAMES.POSTAL_CODE].validation = null;
            questions[INPUT_NAMES.POSTAL_CODE].isRequired = false;

            // NUMERO_FASES_ID
            questions[INPUT_NAMES.NUMERO_FASES_ID].visible = true;
            let options = questions[INPUT_NAMES.NUMERO_FASES_ID].options?.[companyProfileId] ?? [];
            questions[INPUT_NAMES.NUMERO_FASES_ID].options = options;
            break;
        }
        case getCompanyProfileIds().PROFILE_BASE_UK:
        case getCompanyProfileIds().EVOLO: {
            if (isAddFacility) {
                questions[INPUT_NAMES.CPE].disabled = false;
                questions[INPUT_NAMES.CPE].validation = validate(['maxLength'], { maxLength: 50 });
                questions[INPUT_NAMES.CPE].isRequired = false;
            }

            // Hide
            questions[INPUT_NAMES.COUNTY_ID].visible = false;
            // Show
            questions[INPUT_NAMES.CITY].visible = true;
            questions[INPUT_NAMES.CITY].validation = validate(['required', 'maxLength'], { maxLength: 64 });
            questions[INPUT_NAMES.CITY].isRequired = true;

            questions[INPUT_NAMES.COUNTY_ID].isRequired = false;

            questions[INPUT_NAMES.CPE].validation = validate(['maxLength'], { maxLength: 50 });
            questions[INPUT_NAMES.CPE].isRequired = false;

            // POSTAL_CODE
            questions[INPUT_NAMES.POSTAL_CODE].visible = true;
            questions[INPUT_NAMES.POSTAL_CODE].isRequired = true;

            questions[INPUT_NAMES.POTENCIA_CONTRATADA].isRequired = null;
            questions[INPUT_NAMES.POTENCIA_CONTRATADA].validation = null;
            questions[INPUT_NAMES.POTENCIA_CONTRATADA].visible = false;
            break;
        }
        default:
            break;
    }

    return { questions, isVisible: true };
}
//#endregion /* Methods Public */
//#endregion /** B2C **/
