import MUIAlert, { AlertColor } from '@mui/material/Alert';
import { themeBase } from 'constants/styles/global';
import { css, styled } from 'styled-components';

const buttonColor = (severity: AlertColor | undefined) => {
    switch (severity) {
        case 'error':
            return themeBase.color.error;
        case 'success':
            return themeBase.color.success;
        case 'warning':
            return themeBase.color.warning;
        case 'info':
            return themeBase.color.info600;
        default:
            return 'inherit';
    }
};

const alertBaseCSS = css`
    border-radius: 3px !important;
    font-size: 14px;
    font-weight: 400;
    /* padding: 0.875rem 1rem; */
    align-items: center;
    font-size: 0.875rem;

    &.full-screen {
        border-radius: 0px !important;
    }

    .MuiAlert {
        &-message {
            color: ${themeBase.color.neutral800};
            font-style: ${themeBase.fontStyle.normal};
            font-weight: ${themeBase.fontWeight.normal};
            font-size: 0.875rem;
            line-height: 1.188rem;

            &::first-letter {
                text-transform: capitalize;
            }
        }

        &-action {
            padding-top: 0;
            margin-right: 0;
        }
    }
`;
export const StyledAlert = styled(MUIAlert)`
    ${alertBaseCSS};

    &.default {
        ${alertBaseCSS};

        .MuiAlert-icon {
            color: ${themeBase.color.neutral800} !important;
        }

        border: none !important;
        background-color: #ffffff !important;
    }

    &.error {
        ${alertBaseCSS};

        .MuiAlert-icon {
            color: ${themeBase.color.edpRed400} !important;
        }

        border: 1px solid ${themeBase.color.edpRed400} !important;
        background-color: ${themeBase.color.edpRed50} !important;
    }

    &.info {
        ${alertBaseCSS};

        .MuiAlert-icon {
            color: ${themeBase.color.info600} !important;
        }

        border: 1px solid ${themeBase.color.info600} !important;
        background-color: ${themeBase.color.info50} !important;
    }

    &.success {
        ${alertBaseCSS};

        .MuiAlert-icon {
            color: ${themeBase.color.success600} !important;
        }

        border: 1px solid ${themeBase.color.success600} !important;
        background-color: ${themeBase.color.success50} !important;
    }

    &.warning {
        ${alertBaseCSS};

        .MuiAlert-icon {
            color: ${themeBase.color.warning600} !important;
        }

        border: 1px solid ${themeBase.color.warning600} !important;
        background-color: ${themeBase.color.warning50} !important;
    }

    .close-alert-btn {
        svg > path {
            fill: ${(props) => buttonColor(props.severity)} !important;
            stroke: ${(props) => buttonColor(props.severity)} !important;
        }
    }
`;
