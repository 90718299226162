import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { z } from 'zod';

const BusinessModelSchema = z.object({
    facility_id: z.nullable(z.string()),
    module_type: z.nullable(z.string()),
    annual_saving: z.nullable(z.number()),
    target_investment: z.nullable(z.number()),
    productInputs: z.nullable(z.any()),
    reformulation: z.nullable(z.any()).optional(),
});

type TBusinessModel = z.infer<typeof BusinessModelSchema>;

export const initialBusinessModel: TBusinessModel = {
    facility_id: null,
    module_type: null,
    annual_saving: null,
    target_investment: null,
    productInputs: null,
};
interface IBusinessModelsStore {
    businessModels: TBusinessModel;
    actions: {
        setBusinessModelsStore: (businessModels: any) => void;
        resetBusinessModelsStore: () => void;
    };
}

export const useBusinessModelsStore = create<IBusinessModelsStore>()(
    persist(
        (set) => ({
            businessModels: initialBusinessModel,
            actions: {
                setBusinessModelsStore: (businessModels: any) => set({ businessModels }),
                resetBusinessModelsStore: () => set({ businessModels: initialBusinessModel }),
            },
        }),
        {
            name: 'bm',
            partialize: (state) => Object.fromEntries(Object.entries(state).filter(([key]) => !['actions'].includes(key))),
        }
    )
);
