import { memo, useMemo } from 'react';

import LanguageItem from './LanguageItem';
import { AVAILABLE_LANGUAGES } from 'constants/settings';
import { isDevelopment } from 'services/settings';
import { AVAILABLE_USER_LOCALES } from 'constants/user';
import { isDefined } from 'services/util/auxiliaryUtils';
import { useUserStore } from 'store/user';

const LanguageSwitcher = ({ handleRequestClose }) => {
    const { settings: userStoreSettings } = useUserStore();

    const languageConfigs = userStoreSettings?.language_configs;

    const languages = useMemo(() => {
        const lngRes: any[] = [];
        // add pt-PT on DEV
        isDevelopment() &&
            !isDefined(languageConfigs?.find((lng) => lng.language_code === AVAILABLE_USER_LOCALES.ptPT)) &&
            lngRes.push({ ...AVAILABLE_LANGUAGES.find((lng) => lng.appLocale === AVAILABLE_USER_LOCALES.ptPT) });
        if (isDefined(languageConfigs)) {
            languageConfigs!
                // 'language_default' should come first; so, we perform this sort()
                .sort((a, b) => Number(b.language_default) - Number(a.language_default))
                .forEach((lang) => {
                    const language = AVAILABLE_LANGUAGES.find((lng) => lng.appLocale === lang.language_code);
                    isDefined(language) && lngRes.push(language);
                });
        } else {
            // default locales (if language_configs not defined)
            !isDefined(lngRes.find((lng) => lng.appLocale === AVAILABLE_USER_LOCALES.ptPT)) &&
                lngRes.push({ ...AVAILABLE_LANGUAGES.find((lng) => lng.appLocale === AVAILABLE_USER_LOCALES.ptPT) });
            lngRes.push({ ...AVAILABLE_LANGUAGES.find((lng) => lng.appLocale === AVAILABLE_USER_LOCALES.enGB) });
        }
        return lngRes;
    }, [languageConfigs]);

    return (
        <div className="language-list">
            <ul className="list-unstyled px-0 m-0">
                {languages.map((lang, ind) => (
                    <LanguageItem key={`language_list_${ind + 1}`} language={lang} handleRequestClose={handleRequestClose} />
                ))}
            </ul>
        </div>
    );
};

export default memo(LanguageSwitcher);
