import React, { memo, useState } from 'react';
import PhoneInput from 'components/@efz/PhoneInput';
import { SPV_SIMPLE_QUESTIONS as questions, SPV_SIMPLE_INPUTS as INPUT_NAMES } from 'constants/products/solarpvSimple';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorMessage from 'components/errors/ErrorMessage';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import { intlMessages, isFieldDefined } from 'services/util/auxiliaryUtils';
import { ReactComponent as CheckboxIcon } from 'assets/images/icons/svg/checkbox-outlined.svg';
import { ReactComponent as CheckboxCheckedIcon } from 'assets/images/icons/svg/checkbox-ticked-outlined.svg';
import IntlMessages from 'components/util/IntlMessages';
import Alert from 'components/@efz/Alert';
import TEIPDPPDialog from '../../../common/dialogs/TEIPDPPDialog';
import { Button } from '@save2compete/efz-design-system';
import NewPhoneInput from 'components/@efz/PhoneInput/PhoneInput';
import { isEnvDevFlag } from 'services/settings';
import { useFeatureFlags } from 'store/featureFlags';

const SimulationContactsCard = React.forwardRef(
    ({ sendRequestHandler, requestSent, isRequestingProposal, hasError, solutions, selectedCard }, ref) => {
        const {
            control,
            watch,
            setError,
            clearErrors,
            formState: { errors, isValid },
        } = useFormContext();

        const { featureFlags } = useFeatureFlags();

        const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
        const [showTEIPDPPDialog, setShowTEIPDPPDialog] = useState(false);

        const sendRequest = () => {
            sendRequestHandler({
                solution: solutions.find((el) => el.id === selectedCard),
                contacts: {
                    fullName: watch(INPUT_NAMES.CLIENT_NAME),
                    email: watch(INPUT_NAMES.CLIENT_EMAIL),
                    phone: watch(INPUT_NAMES.CLIENT_PHONE),
                },
            });
        };

        return (
            <div className="contacts-card" ref={ref}>
                <div className="contacts-card-title">
                    <IntlMessages id="page.spvSimple.simulation.card.contacts.title" />
                </div>
                <div className="contacts-card-form">
                    {/* Name */}
                    <div className="input-container">
                        <Controller
                            name={questions[INPUT_NAMES.CLIENT_NAME].name}
                            rules={questions[INPUT_NAMES.CLIENT_NAME]?.validation ?? {}}
                            control={control}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            render={({ field: { ref, ...field } }) => (
                                <OutlinedInput
                                    {...field}
                                    type={questions[INPUT_NAMES.CLIENT_NAME].inputType}
                                    disabled={questions[INPUT_NAMES.CLIENT_NAME]?.disabled || isRequestingProposal || requestSent}
                                    variant={'outlined'}
                                    placeholder={intlMessages(questions[INPUT_NAMES.CLIENT_NAME].placeholder)}
                                    error={!!errors?.[INPUT_NAMES.CLIENT_NAME]}
                                />
                            )}
                        />
                        {!!errors[INPUT_NAMES.CLIENT_NAME] && (
                            <ErrorMessage
                                error={errors[INPUT_NAMES.CLIENT_NAME]}
                                values={{
                                    max: questions?.[INPUT_NAMES.CLIENT_NAME]?.optValidation?.maxLength,
                                }}
                            />
                        )}
                    </div>
                    {/* Email */}
                    <div className="input-container">
                        <Controller
                            name={questions[INPUT_NAMES.CLIENT_EMAIL].name}
                            rules={questions[INPUT_NAMES.CLIENT_EMAIL]?.validation ?? {}}
                            control={control}
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            render={({ field: { ref, ...field } }) => (
                                <OutlinedInput
                                    {...field}
                                    type={questions[INPUT_NAMES.CLIENT_EMAIL].inputType}
                                    disabled={questions[INPUT_NAMES.CLIENT_EMAIL]?.disabled || isRequestingProposal || requestSent}
                                    variant={'outlined'}
                                    placeholder={intlMessages(questions[INPUT_NAMES.CLIENT_EMAIL].placeholder)}
                                    error={!!errors?.[INPUT_NAMES.CLIENT_EMAIL]}
                                />
                            )}
                        />
                        {!!errors[INPUT_NAMES.CLIENT_EMAIL] && <ErrorMessage error={errors[INPUT_NAMES.CLIENT_EMAIL]} />}
                    </div>
                    {/* Phone Number */}
                    <div className="input-container">
                        {isEnvDevFlag(featureFlags['fe-2531']) ?
                            <NewPhoneInput
                                name={questions[INPUT_NAMES.CLIENT_PHONE].name}
                                rules={questions[INPUT_NAMES.CLIENT_PHONE]?.validation ?? {}}
                                control={control}
                                disabled={questions[INPUT_NAMES.CLIENT_PHONE]?.disabled || isRequestingProposal || requestSent}
                                error={!!errors[INPUT_NAMES.CLIENT_PHONE]}
                            />
                        :   <PhoneInput
                                name={questions[INPUT_NAMES.CLIENT_PHONE].name}
                                rules={questions[INPUT_NAMES.CLIENT_PHONE]?.validation ?? {}}
                                control={control}
                                disabled={questions[INPUT_NAMES.CLIENT_PHONE]?.disabled || isRequestingProposal || requestSent}
                                error={errors[INPUT_NAMES.CLIENT_PHONE]}
                                setError={setError}
                                clearErrors={clearErrors}
                                variant="outlined"
                                defaultLocale={'el-GR'} // this prop needs to be sent because in OArea we do not have locale!
                            />
                        }
                        {!!errors[INPUT_NAMES.CLIENT_PHONE] && (
                            <ErrorMessage
                                error={errors[INPUT_NAMES.CLIENT_PHONE]}
                                values={{
                                    min: questions[INPUT_NAMES.CLIENT_PHONE]?.optValidation?.minLength,
                                    max: questions[INPUT_NAMES.CLIENT_PHONE]?.optValidation?.maxLength,
                                }}
                            />
                        )}
                    </div>
                    {requestSent && !isRequestingProposal && (
                        <Alert severity="success">
                            <IntlMessages id="page.spvSimple.simulation.card.contacts.success" />
                        </Alert>
                    )}
                    {hasError && !isRequestingProposal && (
                        <Alert severity="error">
                            <IntlMessages id="page.error.403.unexpected" />
                        </Alert>
                    )}
                    {/* Terms and Conditions */}
                    <div className="contacts-card-terms">
                        <Checkbox
                            disabled={isRequestingProposal || requestSent}
                            disableRipple
                            className="contacts-card-checkbox ps-0 pe-0"
                            onChange={(e) => setHasAcceptedTerms(e.target.checked)}
                            icon={<CheckboxIcon width="30" height="30" />}
                            checkedIcon={<CheckboxCheckedIcon className="checkedIcon" width="30" height="30" />}
                        />
                        <span>
                            <IntlMessages whitespaceEnd id="page.spvSimple.simulation.card.contacts.terms.1" />
                            <span className="link" onClick={() => setShowTEIPDPPDialog(true)}>
                                <IntlMessages id="page.spvSimple.simulation.card.contacts.terms.2" />
                            </span>
                        </span>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Button
                            type="submit"
                            disabled={!hasAcceptedTerms || !isValid || requestSent || isRequestingProposal || !isFieldDefined(selectedCard)}
                            onClick={sendRequest}
                            dataTestId="send-request"
                            loading={isRequestingProposal}
                        >
                            <IntlMessages id="page.spvSimple.simulation.card.contacts.button" />
                        </Button>
                    </div>
                </div>
                {showTEIPDPPDialog && (
                    <TEIPDPPDialog isOpen={showTEIPDPPDialog} handleClose={() => setShowTEIPDPPDialog(false)} isB2C={false} />
                )}
            </div>
        );
    }
);

export default memo(SimulationContactsCard);
