import copy from 'fast-copy';
import { Path } from 'react-hook-form';
import * as z from 'zod';
import { SafeParseError, SafeParseReturnType } from 'zod';

// API
import { postSimulation } from 'api/product';

// Constants
import {
    HEAT_LOSS_CALCULATION_METHOD,
    HP_FAST_BASE_API_PAYLOAD,
    HP_FAST_INPUT_NAMES,
    HP_FAST_STEPS,
    INPUT_NAMES_STEP_EXTRAS,
    INPUT_NAMES_STEP_HP_SELECTION,
    INPUT_NAMES_STEP_PROPERTY,
    INPUT_NAMES_STEP_PROPERTY_DEFINE_PROPERTY,
} from 'constants/products/hpFast';

// Interfaces
import { SelectOption } from 'interfaces/products/evcPro/inputs';
import { IHPFastStateOptions } from 'interfaces/products/hp/fast';
import {
    ALL_INPUT_NAMES,
    STEP_EXTRAS_SCHEMA,
    STEP_HP_SELECTION_SCHEMA,
    STEP_PROPERTY_DEFINE_PROPERTY_SCHEMA_WITH_VALIDATION_FOR_PAYLOAD,
    STEP_PROPERTY_EPC_DATA_SCHEMA_WITH_VALIDATION_FOR_PAYLOAD,
    STEP_PROPERTY_SCHEMA,
    TFormQuestions,
    THPFastForm,
    THPFastFormHPSelection,
    THPFastFormProperty,
    THPFastSteps,
} from 'interfaces/products/hp/fast/form';
import { TIntl } from 'types/utils';

// Services
import { accessObjectProp, isDefined, removeFieldEmptyInObj } from 'services/util/auxiliaryUtils';

// #region form utils

export const getFormInputName = <T extends THPFastForm>(name: ALL_INPUT_NAMES): Path<T> => {
    for (const [step, stepInputs] of Object.entries(HP_FAST_INPUT_NAMES)) {
        for (const inputName of Object.values(stepInputs)) {
            if (inputName === name) return `${step}.${inputName}` as Path<T>;
        }
    }
    return name as Path<T>;
};

export type TStepSchema =
    | z.infer<typeof STEP_PROPERTY_SCHEMA>
    | z.infer<typeof STEP_HP_SELECTION_SCHEMA>
    | z.infer<typeof STEP_EXTRAS_SCHEMA>;
export type TValidateStepResponse<K = TStepSchema> = SafeParseReturnType<Partial<K>, Partial<K>>;
export const validateStep = (values: THPFastForm, step: THPFastSteps): TValidateStepResponse => {
    const defaultError = { success: false } as SafeParseError<Partial<TStepSchema>>;
    if (!step) return defaultError;

    switch (step) {
        case HP_FAST_STEPS.PROPERTY: {
            if (!values?.property) return defaultError;
            return STEP_PROPERTY_SCHEMA.safeParse(values.property);
        }
        case HP_FAST_STEPS.HP_SELECTION: {
            if (!values?.hp_selection) return defaultError;
            return STEP_HP_SELECTION_SCHEMA.safeParse(values.hp_selection);
        }
        case HP_FAST_STEPS.BILL_OF_MATERIALS:
        case HP_FAST_STEPS.EXTRAS: {
            if (
                !validateStep(values, HP_FAST_STEPS.PROPERTY).success ||
                !validateStep(values, HP_FAST_STEPS.HP_SELECTION).success ||
                !values?.extras
            )
                return defaultError;
            return STEP_EXTRAS_SCHEMA.safeParse(values.extras);
        }
        case HP_FAST_STEPS.PROPOSAL:
        default:
            return defaultError;
    }
};

// #endregion form utils

export const addOptionsToContextQuestions = (questions: TFormQuestions, options: IHPFastStateOptions) => {
    if (!options || (!!options && !options?.propertyHeatSources)) return questions;
    if (!Array.isArray(options?.propertyHeatSources) || options?.propertyHeatSources?.length === 0) return questions;

    //#region heat_source_type
    if (questions?.[HP_FAST_STEPS.PROPERTY]?.[INPUT_NAMES_STEP_PROPERTY.heat_source_type]) {
        const heatSourceOptions: SelectOption[] = [];
        options.propertyHeatSources.forEach((option) => {
            heatSourceOptions.push({
                value: option.id,
                label: `page.hp.property.heatSource.type.${option.id}` as TIntl,
            });
        });
        questions[HP_FAST_STEPS.PROPERTY][INPUT_NAMES_STEP_PROPERTY.heat_source_type].options = heatSourceOptions;
    }
    //#endregion heat_source_type

    //#region heat_source_age
    if (questions?.[HP_FAST_STEPS.PROPERTY]?.[INPUT_NAMES_STEP_PROPERTY.heat_source_age]) {
        const heatingSystemAgeOptions: SelectOption[] = [];
        options.propertyHeatingSystemAge.forEach((option) => {
            heatingSystemAgeOptions.push({
                value: option.id,
                label: `page.hp.property.heatSource.age.${option.tag}` as TIntl,
            });
        });
        questions[HP_FAST_STEPS.PROPERTY][INPUT_NAMES_STEP_PROPERTY.heat_source_age].options = heatingSystemAgeOptions;
    }
    //#endregion heat_source_age

    //#region Define Property flow
    if (Array.isArray(options?.definePropertyActiveQuestions) && options.definePropertyActiveQuestions?.length > 0) {
        options.definePropertyActiveQuestions.forEach((question) => {
            if (Array.isArray(question?.answer_option_arr) && question?.answer_option_arr?.length > 0) {
                questions[HP_FAST_STEPS.PROPERTY][question.tag.toLowerCase()].visible = question?.is_active ?? false;
                questions[HP_FAST_STEPS.PROPERTY][question.tag.toLowerCase()].options = question?.answer_option_arr?.map((a) => ({
                    value: a?.id,
                    label: a?.description,
                }));
            } else {
                questions[HP_FAST_STEPS.PROPERTY][question.tag.toLowerCase()].visible = false;
            }
        });
    } else {
        // Flow NOT configured on BO
        // set every question as hidden so we know that we need to show the info card

        Object.values(INPUT_NAMES_STEP_PROPERTY_DEFINE_PROPERTY).forEach((name) => {
            if (isDefined(questions?.[HP_FAST_STEPS.PROPERTY]?.[name]?.visible)) questions[HP_FAST_STEPS.PROPERTY][name].visible = false;
        });
    }
    //#endregion Define Property flow

    return questions;
};

export const addRequiredValidationToQuestions = (questions: TFormQuestions, requiredFormNames: string[]) => {
    if (requiredFormNames?.length === 0) return questions;

    const _questions = copy(questions);
    for (const [stepName, stepQuestions] of Object.entries(questions)) {
        for (const [questionName, question] of Object.entries(stepQuestions)) {
            _questions[stepName][questionName] = {
                ...question,
                validation: {
                    ...(question?.validation ?? {}),
                    required: requiredFormNames.includes(getFormInputName(question.name)),
                },
            };
        }
    }

    return _questions;
};

/**
 * Adds a property to an Object. Works with nested properties
 * @param obj base object
 * @param keyPath string with the path of the property in the format 'level1.level2.etc..propName
 * @param value value of prop to add
 */
export function addToObject(obj: object, keyPath: string, value: any): void {
    const keys = keyPath.split('.');
    let currentObj = obj;

    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        if (!currentObj[key] || typeof currentObj[key] !== 'object') {
            currentObj[key] = {};
        }
        currentObj = currentObj[key];
    }

    currentObj[keys[keys.length - 1]] = value;
}

/**
 * Merges obj1 and obj2. Common properties will take their value from obj2
 * @param obj1
 * @param obj2
 * @returns
 */
export function mergeObjects(obj1: object, obj2: object): object {
    const merged = { ...obj1 };

    for (const key in obj2) {
        if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
            merged[key] = mergeObjects(obj1[key] || {}, obj2[key]);
        } else {
            merged[key] = obj2[key];
        }
    }

    return merged;
}

// #region payloads generation

const buildBasePayload = (facilityID: string | number) => {
    const base = copy(HP_FAST_BASE_API_PAYLOAD);
    addToObject(base, 'facility.id', facilityID);
    return base;
};

// #region step Property

export const getPayloadEPCDataEstimatedHeatLoss = (formValues: THPFastFormProperty, facilityID: string | number) => {
    const base = buildBasePayload(facilityID);

    const inputsPath = (input: string) => `inputs.${HP_FAST_STEPS.PROPERTY}.${input}`;

    const values = STEP_PROPERTY_EPC_DATA_SCHEMA_WITH_VALIDATION_FOR_PAYLOAD.parse(formValues);

    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method),
        accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method)
    );
    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_PROPERTY.space_heating_energy_consumption),
        accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.space_heating_energy_consumption)
    );
    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_PROPERTY.impact_loft_insulation),
        accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.impact_loft_insulation)
    );
    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_PROPERTY.impact_solid_wall_insulation),
        accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.impact_solid_wall_insulation)
    );
    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_PROPERTY.impact_cavity_wall_insulation),
        accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.impact_cavity_wall_insulation)
    );

    return removeFieldEmptyInObj(base);
};

const getPayloadManualHeatLoss = (formValues: THPFastFormProperty, facilityID: string | number) => {
    const base = buildBasePayload(facilityID);

    const inputsPath = (input: string) => `inputs.${HP_FAST_STEPS.PROPERTY}.${input}`;

    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method),
        accessObjectProp(formValues, INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method)
    );
    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_PROPERTY.manual_heat_loss),
        accessObjectProp(formValues, INPUT_NAMES_STEP_PROPERTY.manual_heat_loss)
    );

    return removeFieldEmptyInObj(base);
};

export const getPayloadDefinePropertyEstimatedHeatLoss = (formValues: THPFastFormProperty, facilityID: string | number) => {
    const base = buildBasePayload(facilityID);

    const inputsPath = (input: string) => `inputs.${HP_FAST_STEPS.PROPERTY}.${input}`;

    const values = STEP_PROPERTY_DEFINE_PROPERTY_SCHEMA_WITH_VALIDATION_FOR_PAYLOAD.parse(formValues);

    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method),
        accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method)
    );
    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_PROPERTY.combination_id),
        accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.combination_id)
    );
    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_PROPERTY.property_area),
        accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.property_area)
    );

    return removeFieldEmptyInObj(base);
};

export const getPayloadKPIsStepProperty = (formValues: THPFastFormProperty, facilityID: string | number) => {
    const heat_loss_calculation_method = accessObjectProp(
        formValues,
        INPUT_NAMES_STEP_PROPERTY.heat_loss_calculation_method
    ) as (typeof HEAT_LOSS_CALCULATION_METHOD)[keyof typeof HEAT_LOSS_CALCULATION_METHOD];

    let base;
    switch (heat_loss_calculation_method) {
        case HEAT_LOSS_CALCULATION_METHOD.EPC_DATA:
            base = getPayloadEPCDataEstimatedHeatLoss(formValues, facilityID);
            break;
        case HEAT_LOSS_CALCULATION_METHOD.INSERT_MANUALLY:
            base = getPayloadManualHeatLoss(formValues, facilityID);
            break;
        case HEAT_LOSS_CALCULATION_METHOD.DEFINE_PROPERTY:
            base = getPayloadDefinePropertyEstimatedHeatLoss(formValues, facilityID);
            break;
        default:
            base = buildBasePayload(facilityID);
            break;
    }

    if (accessObjectProp(formValues, INPUT_NAMES_STEP_PROPERTY.heat_source) === true) {
        try {
            const parse = STEP_PROPERTY_SCHEMA.safeParse(formValues);

            if (parse.success === true) {
                const values = parse.data;
                const inputsPath = (input: string) => `inputs.${HP_FAST_STEPS.PROPERTY}.${input}`;

                addToObject(
                    base,
                    inputsPath(INPUT_NAMES_STEP_PROPERTY.heat_source_type),
                    accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.heat_source_type)
                );
                addToObject(
                    base,
                    inputsPath(INPUT_NAMES_STEP_PROPERTY.heat_source_price_value),
                    accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.heat_source_price_value)
                );
                addToObject(
                    base,
                    inputsPath(INPUT_NAMES_STEP_PROPERTY.heat_source_price_unit),
                    accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.heat_source_price_unit)
                );
                addToObject(
                    base,
                    inputsPath(INPUT_NAMES_STEP_PROPERTY.heat_source_age),
                    accessObjectProp(values, INPUT_NAMES_STEP_PROPERTY.heat_source_age)
                );
            }
        } catch (e) {
            return removeFieldEmptyInObj(base);
        }
    }

    return removeFieldEmptyInObj(base);
};

// #endregion step Property

// #region step HP_Selection

const getPayloadKPIsStepHPSelection = (formValues: THPFastFormHPSelection, facilityID: string | number) => {
    const base = buildBasePayload(facilityID);

    const inputsPath = (input: string) => `inputs.${HP_FAST_STEPS.HP_SELECTION}.${input}`;

    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_HP_SELECTION.equipment_id),
        accessObjectProp(formValues, INPUT_NAMES_STEP_HP_SELECTION.equipment_id)
    );
    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_HP_SELECTION.equipment_cost),
        accessObjectProp(formValues, INPUT_NAMES_STEP_HP_SELECTION.equipment_cost)
    );
    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_HP_SELECTION.flow_temperature),
        accessObjectProp(formValues, INPUT_NAMES_STEP_HP_SELECTION.flow_temperature)
    );
    addToObject(
        base,
        inputsPath(INPUT_NAMES_STEP_HP_SELECTION.heatsource_dt),
        accessObjectProp(formValues, INPUT_NAMES_STEP_HP_SELECTION.heatsource_dt)
    );

    return removeFieldEmptyInObj(base);
};

// #endregion step HP_Selection

// #region step Extras

export const getPayloadKPIsStepExtras = (formValues: Partial<THPFastForm>, facilityID: string | number) => {
    if (!formValues?.[HP_FAST_STEPS.PROPERTY])
        throw new Error('Error on getPayloadKPIsStepEmitter: there is no values for step "property"');
    if (!formValues?.[HP_FAST_STEPS.HP_SELECTION])
        throw new Error('Error on getPayloadKPIsStepEmitter: there is no values for step "hp_selection"');

    const stepPropertyPayload = getPayloadKPIsStepProperty(formValues?.[HP_FAST_STEPS.PROPERTY], facilityID);
    const stepHPSelectionPayload = getPayloadKPIsStepHPSelection(formValues?.[HP_FAST_STEPS.HP_SELECTION], facilityID);

    const base = buildBasePayload(facilityID);
    const extras =
        accessObjectProp(formValues, INPUT_NAMES_STEP_EXTRAS.instrumentations) ??
        accessObjectProp(formValues, getFormInputName(INPUT_NAMES_STEP_EXTRAS.instrumentations));

    addToObject(base, `inputs.${HP_FAST_STEPS.EXTRAS}`, extras);

    const stepExtrasPayload = mergeObjects(mergeObjects(stepPropertyPayload, stepHPSelectionPayload), base);

    return removeFieldEmptyInObj(stepExtrasPayload);
};

// #endregion step Extras

export const fetchKPIs = (step: THPFastSteps, facilityID: string | number, values: THPFastForm) => {
    let payload;
    switch (step) {
        case HP_FAST_STEPS.PROPERTY:
        case HP_FAST_STEPS.HP_SELECTION:
            if (!values?.[HP_FAST_STEPS.PROPERTY]) throw new Error('Error on fetchKPIs: there is no values for step "property"');
            payload = getPayloadKPIsStepProperty(copy(values?.[HP_FAST_STEPS.PROPERTY]), facilityID);
            break;
        case HP_FAST_STEPS.EXTRAS:
        case HP_FAST_STEPS.BILL_OF_MATERIALS:
            payload = getPayloadKPIsStepExtras(copy(values), facilityID);
            break;

        default:
            return;
    }

    return postSimulation({ payload });
};

// #endregion payloads generation
