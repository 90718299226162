import { UserContext } from 'contexts/userContext';
import { TBMs } from 'interfaces/businessModels/reducer';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { DetailedOfferEditionEvents } from './../../interfaces/businessModels/detailed/index';
import { StatusCodes } from 'http-status-codes';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { resetProposal, setProposal } from 'redux/actions/proposal';

// Api
import { postAudit, postInstallmentsSimulation } from 'api/businessModels';

// Contexts
import { OfferEditionContext } from 'contexts/businessModels/businessModelsContext';

// Selectors
import { getBusinessModelsNegotiations } from 'redux/selectors/businessModels';

// Services
import { isDefined, isFieldDefined, isNumberDefined } from 'services/util/auxiliaryUtils';
import { businessModelProAccess, computeRubricCostsPayload } from 'services/businessModels/index';
import { BmDetailedActions, BmLoadings } from 'constants/businessModelsPro/index';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { getInputsSimulationPayload } from 'services/products/solarpv';

let abortfetchSimulation;
let abortgetDetailedKpis;
let abortpostAuditHandler;

const abortAllAPIsCalls = () => {
    abortfetchSimulation && abortfetchSimulation.abort();
    abortgetDetailedKpis && abortgetDetailedKpis.abort();
    abortpostAuditHandler && abortpostAuditHandler.abort();
};

const useOfferEdition = () => {
    const dispatch = useDispatch();
    // @ts-ignore
    const reduxSyncKpis = useSelector((state: any) => getBusinessModelsNegotiations(state.businessModels)?.syncKpis ?? []);

    const { bmState, setBMEventHandler, lowNegotiationProfile } = useContext(OfferEditionContext);

    const { productID, productPayload, state } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TBMs>;

    const { companyProfileId } = useContext(UserContext);

    const bModelSelected = bmState?.businessModelSelected;
    const monthly_payment_option_id = Number(bmState?.offerEditionValues?.monthly_payment_option_id);
    // productID for reformulation use case
    const tp_id = useSelector((state: any) => state?.product?.info?.id) ?? null;

    const fetchSimulation = async () => {
        try {
            setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_LOADINGS, [
                { name: BmLoadings.General, value: true },
                { name: BmLoadings.SimpleSimulation, value: true },
            ]);

            const bmBody = bmState?.businessModelBody;
            const inputs = bmBody?.productInputs?.inputs ?? bmBody?.inputs;

            let payload: any = {
                facility: {
                    id: bmBody?.facility_id,
                },
                inputs: {
                    ...getInputsSimulationPayload(inputs, { battery: inputs?.battery }),
                    monthly_payment_option: monthly_payment_option_id,
                    annual_evo_fee:
                        bmState?.elements?.annualUpdateMonthlyFee ?
                            Number(bmState?.offerEditionValues?.annualUpdateFee) / 100 ?? 0
                        :   undefined,
                    op_and_man_duration:
                        isNumberDefined(bmState?.offerEditionValues?.opAndManDuration) ?
                            bmState?.offerEditionValues?.opAndManDuration
                        :   undefined,
                    boolean_rec_ownership:
                        isFieldDefined(bmState?.offerEditionValues?.boolean_rec_ownership) ?
                            bmState?.offerEditionValues?.boolean_rec_ownership
                        :   undefined,
                    boolean_ownership_transfer:
                        isFieldDefined(bmState?.offerEditionValues?.boolean_ownership_transfer) ?
                            bmState?.offerEditionValues?.boolean_ownership_transfer
                        :   undefined,
                    itc_rate_prc:
                        isFieldDefined(parseFloat(`${bmState.offerEditionValues?.itc_rate_prc}`)) ?
                            Number(bmState?.offerEditionValues?.itc_rate_prc) / 100 ?? 0
                        :   undefined,
                    offer_edition: {
                        ...inputs?.offer_edition,
                        costs:
                            bmState?.offerEdition?.is_customize ?
                                bmState.offerEdition.system.costs
                            :   bmState?.businessModelBody?.productInputs?.syncKpis?.find(
                                    (kpi) => kpi?.tipo_modelo_negocio_id === bModelSelected?.business_model_id
                                )?.kpis?.system?.costs,
                        extra_cost: Number(bmState?.offerEditionValues?.extraCost),
                    },
                    allowance: bmState?.offerEditionValues?.allowance,
                },
                tipo_modelo_negocio_id: bModelSelected?.business_model_id,
                tipo_produto_id: typeof productID === 'number' ? productID : tp_id,
                register_simulation: false,
            };

            if (isFieldDefined(bmBody?.use_custom_fin_model)) {
                payload = {
                    ...payload,
                    use_custom_fin_model: bmBody?.use_custom_fin_model,
                };
            }

            if (bmState?.offerEditionValues?.rubrics?.length > 0) {
                payload.inputs.offer_edition.costs = computeRubricCostsPayload(
                    //@ts-ignore
                    {
                        // @ts-ignore
                        ...bmState?.kpis?.kpis?.system?.costs,
                        capex: bmState?.offerEdition.system.costs.capex,
                        opex: bmState?.offerEdition.system.costs.opex,
                    },
                    bmState?.offerEditionValues?.rubrics
                );
            }
            // @ts-ignore
            if (isDefined(state.selected?.values?.rubrics) && state.selected.values!.rubrics.values.length > 0) {
                payload.inputs.offer_edition.costs = computeRubricCostsPayload(
                    state?.simulation?.syncKpis?.find((kpis) => kpis?.tipo_modelo_negocio_id === bModelSelected?.business_model_id)?.kpis
                        ?.system?.costs,
                    //@ts-ignore
                    state.selected.values!.rubrics.values
                );
            }

            abortfetchSimulation && abortfetchSimulation.abort();
            abortfetchSimulation = new AbortController();
            const simulationRsp = await postInstallmentsSimulation(payload, abortfetchSimulation);
            if (simulationRsp?.status === StatusCodes.OK) {
                setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_KPIS, {
                    kpis: simulationRsp?.data?.data,
                    sliderReady: true,
                    reqsHash: simulationRsp?.data?.data?.req_id_arr,
                });
            } else throw new Error('Error Simulation');
        } catch (error) {
            console.log('ERROR fetchSimulation ->', error);
            setBMEventHandler(DetailedOfferEditionEvents.RESET_BUSINESS_MODEL_LOADINGS, null);
        }
    };

    const getDetailedKpis = async (showLoading = true) => {
        try {
            showLoading &&
                setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_LOADINGS, [
                    { name: BmLoadings.General, value: true },
                    {
                        name: !lowNegotiationProfile ? BmLoadings.DetailedSimulation : BmLoadings.Proposal,
                        value: true,
                    },
                ]);

            const bmBody = bmState?.businessModelBody;
            const inputs = bmBody?.productInputs?.inputs ?? bmBody?.inputs;
            let payload: any = {
                facility: {
                    id: bmBody?.facility_id,
                },
                inputs: {
                    ...getInputsSimulationPayload(inputs, { battery: inputs?.battery }),
                    monthly_payment_option: monthly_payment_option_id,
                    monthly_fee: bmState?.offerEditionValues?.monthlyPayment ?? bmState?.kpis?.negotiation?.pre_selected,
                    annual_evo_fee:
                        bmState?.elements?.annualUpdateMonthlyFee ?
                            Number(bmState?.offerEditionValues?.annualUpdateFee) / 100 ?? undefined
                        :   undefined,
                    op_and_man_duration:
                        isNumberDefined(bmState?.offerEditionValues?.opAndManDuration) ?
                            Number(bmState?.offerEditionValues?.opAndManDuration)
                        :   undefined,
                    boolean_rec_ownership:
                        isFieldDefined(bmState?.offerEditionValues?.boolean_rec_ownership) ?
                            bmState?.offerEditionValues?.boolean_rec_ownership
                        :   undefined,
                    boolean_ownership_transfer:
                        isFieldDefined(bmState?.offerEditionValues?.boolean_ownership_transfer) ?
                            bmState?.offerEditionValues?.boolean_ownership_transfer
                        :   undefined,
                    itc_rate_prc:
                        isFieldDefined(parseFloat(`${bmState.offerEditionValues?.itc_rate_prc}`)) ?
                            Number(bmState?.offerEditionValues?.itc_rate_prc) / 100 ?? 0
                        :   undefined,
                    negotiation: bmState?.kpis?.negotiation,
                    offer_edition: {
                        ...inputs?.offer_edition,
                        costs:
                            bmState?.offerEdition?.is_customize ?
                                bmState.offerEdition.system.costs
                            :   bmState?.businessModelBody?.productInputs?.syncKpis?.find(
                                    (kpi) => kpi?.tipo_modelo_negocio_id === bModelSelected?.business_model_id
                                )?.kpis?.system?.costs,
                        extra_cost: Number(bmState?.offerEditionValues?.extraCost) ?? 0,
                    },
                    allowance: bmState?.offerEditionValues?.allowance,
                },
                tipo_modelo_negocio_id: bModelSelected?.business_model_id,
                tipo_produto_id: typeof productID === 'number' ? productID : tp_id,
                register_simulation: false,
            };

            if (isFieldDefined(bmBody?.use_custom_fin_model)) {
                payload = {
                    ...payload,
                    use_custom_fin_model: bmBody?.use_custom_fin_model,
                };
            }

            if (bmState?.offerEditionValues?.rubrics?.length > 0) {
                payload.inputs.offer_edition.costs = computeRubricCostsPayload(
                    {
                        //@ts-ignore
                        ...bmState?.kpis?.kpis?.system?.costs,
                        capex: bmState?.offerEdition.system.costs.capex,
                        opex: bmState?.offerEdition.system.costs.opex,
                    },

                    bmState?.offerEditionValues?.rubrics
                );
            }
            // @ts-ignore
            if (isDefined(state.selected?.values?.rubrics) && state.selected.values!.rubrics.values.length > 0) {
                payload.inputs.offer_edition.costs = computeRubricCostsPayload(
                    state?.simulation?.syncKpis?.find((kpis) => kpis?.tipo_modelo_negocio_id === bModelSelected?.business_model_id)?.kpis
                        ?.system?.costs,
                    // @ts-ignore
                    state.selected.values!.rubrics.values
                );
            }

            /* Objects are not valid as a React child (found: object with keys {status, statusText, data, req_id_arr, description}).
             If you meant to render a collection of children, use an array instead. */
            abortgetDetailedKpis && abortgetDetailedKpis.abort();
            abortgetDetailedKpis = new AbortController();
            const simulationRsp = await postInstallmentsSimulation(payload, abortgetDetailedKpis);
            // if (simulationRsp?.status === StatusCodes.OK) {
            setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_FINAL_KPIS, {
                finalKpis: simulationRsp?.data?.data?.fin_kpis ?? null,
                reqHash: simulationRsp?.data?.data?.req_id_arr ?? [],
                inputs: payload.inputs,
                status: simulationRsp?.status,
                showLoading,
            });
            // } else throw new Error('Error Detailed Simulation')

            setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_LOADINGS, [
                { name: BmLoadings.GetProposalNoKpis, value: false },
            ]);

            return simulationRsp?.data?.data;
        } catch (error) {
            console.log('ERROR DETAILED KPIS', error);
            setBMEventHandler(DetailedOfferEditionEvents.RESET_BUSINESS_MODEL_LOADINGS, null);
            return Promise.reject({});
        }
    };

    const setProposalHandler = async () => {
        try {
            if (!isDefined(bmState?.kpis?.finalKpis)) {
                setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_LOADINGS, [
                    { name: BmLoadings.GetProposalNoKpis, value: true },
                ]);
                await getDetailedKpis();
            }
            setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_PROPOSAL_READY, true);
        } catch (err) {
            console.error(err);
        }
    };

    const postAuditHandler = async (isEligible = true, businessModelID = undefined) => {
        try {
            setBMEventHandler(DetailedOfferEditionEvents.SET_BUSINESS_MODEL_LOADINGS, [
                { name: BmLoadings.General, value: true },
                { name: BmLoadings.AuditFilesDownload, value: true },
            ]);
            const notEligibleReqHas =
                businessModelProAccess({ productID, companyProfileId }) ?
                    productPayload?.productInputs?.syncKpis?.find((bm) => bm.tipo_modelo_negocio_id === businessModelID)?.req_id_arr ?? []
                :   reduxSyncKpis?.find((bm) => bm.tipo_modelo_negocio_id === businessModelID)?.req_id_arr ?? [];
            let eligibleReqsHas = bmState?.reqsHash;
            if (isEligible && !isDefined(bmState?.kpis?.finalKpis) && isFieldDefined(eligibleReqsHas?.length)) {
                const detailedKpis = await getDetailedKpis(false);
                if (isFieldDefined(detailedKpis?.req_id_arr))
                    // @ts-ignore
                    eligibleReqsHas = [...eligibleReqsHas, ...detailedKpis.req_id_arr];
            }
            const auditPayload =
                isEligible ?
                    {
                        req_id_arr: eligibleReqsHas,
                        step_arr: [
                            'GET_SLIDER_MIN_INSTALLMENT',
                            'GET_SLIDER_RP',
                            'GET_IRR_CF',
                            'GET_IRR_RP',
                            'GET_IRR_IRR',
                            'GET_ADJUSTMENT_VALUE',
                        ],
                    }
                :   {
                        req_id_arr: notEligibleReqHas,
                        step_arr: ['GET_SLIDER_MIN_INSTALLMENT', 'GET_SLIDER_RP'],
                    };
            abortpostAuditHandler && abortpostAuditHandler.abort();
            abortpostAuditHandler = new AbortController();
            const rsp = await postAudit(auditPayload);

            if (rsp?.status === StatusCodes.OK) {
                const url = URL.createObjectURL(new Blob([rsp.data]));

                const a = document.createElement('a');

                a.href = url;
                a.download = `audit_files.zip`;
                document.body.appendChild(a);
                a.click();
                setBMEventHandler(DetailedOfferEditionEvents.RESET_BUSINESS_MODEL_LOADINGS, null);
            } else throw new Error('Audit Error');
        } catch (error) {
            console.log('ERROR -> postAuditHandler', error);
            setBMEventHandler(DetailedOfferEditionEvents.RESET_BUSINESS_MODEL_LOADINGS, null);
        }
    };

    const cancelRequestHandler = () => {
        abortAllAPIsCalls();
        // setBMEventHandler(DetailedOfferEditionEvents.RESET_BUSINESS_MODEL_LOADINGS, null)
    };

    useEffect(() => {
        dispatch(resetProposal());
        return () => {
            abortAllAPIsCalls();
            setBMEventHandler(DetailedOfferEditionEvents.RESET_BUSINESS_MODEL, null);
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        if (bmState?.proposalReady && isDefined(bmState?.kpis?.finalKpis)) {
            const bmBody = bmState?.businessModelBody;
            const inputs = bmBody?.productInputs?.inputs ?? bmBody?.inputs;

            let proposalInputs = {
                ...inputs,
                monthly_payment_option: monthly_payment_option_id,
                monthly_fee:
                    isFieldDefined(bmState?.offerEditionValues?.monthlyPayment) ? bmState?.offerEditionValues?.monthlyPayment : undefined,
                annual_evo_fee:
                    bmState?.elements?.annualUpdateMonthlyFee ? Number(bmState?.offerEditionValues?.annualUpdateFee) / 100 : undefined,
                op_and_man_duration:
                    isFieldDefined(bmState?.offerEditionValues?.opAndManDuration) ?
                        Number(bmState?.offerEditionValues?.opAndManDuration)
                    :   undefined,
                boolean_rec_ownership:
                    isFieldDefined(bmState?.offerEditionValues?.boolean_rec_ownership) ?
                        bmState?.offerEditionValues?.boolean_rec_ownership
                    :   undefined,
                boolean_ownership_transfer:
                    isFieldDefined(bmState?.offerEditionValues?.boolean_ownership_transfer) ?
                        bmState?.offerEditionValues?.boolean_ownership_transfer
                    :   undefined,
                itc_rate_prc:
                    isFieldDefined(parseFloat(`${bmState.offerEditionValues?.itc_rate_prc}`)) ?
                        Number(bmState?.offerEditionValues?.itc_rate_prc) / 100 ?? 0
                    :   undefined,
                negotiation: bmState?.kpis?.negotiation,
                fin_kpis: bmState?.kpis?.finalKpis,
                offer_edition: {
                    ...inputs?.offer_edition,
                    costs:
                        bmState?.offerEdition?.is_customize ?
                            bmState.offerEdition.system.costs
                        :   bmState?.businessModelBody?.productInputs?.syncKpis?.find(
                                (kpi) => kpi?.tipo_modelo_negocio_id === bModelSelected?.business_model_id
                            )?.kpis?.system?.costs,
                    extra_cost: Number(bmState?.offerEditionValues?.extraCost),
                },
                allowance: bmState?.offerEditionValues?.allowance,
                //@ts-ignore
                service_energy_price: state?.selected?.values?.marginSelected?.energy_price ?? 0,
                id_crm: state.selected.values?.id_crm ?? undefined,
            };

            if (bmState?.offerEditionValues?.rubrics?.length > 0) {
                proposalInputs.offer_edition.costs = computeRubricCostsPayload(
                    //@ts-ignore
                    bmState?.kpis?.kpis?.system?.costs,
                    bmState?.offerEditionValues?.rubrics
                );
            }
            // @ts-ignore
            if (isDefined(state.selected?.values?.rubrics) && state.selected.values!.rubrics.values.length > 0) {
                // @ts-ignore
                const tempVal = state?.simulation?.syncKpis?.find(
                    (kpis) => kpis?.tipo_modelo_negocio_id === bModelSelected?.business_model_id
                )?.kpis?.system?.costs;
                // @ts-ignore
                proposalInputs.offer_edition.costs = computeRubricCostsPayload(tempVal, state.selected.values!.rubrics.values);
            }
            // @ts-ignore
            if (state?.selected?.values?.grants?.totalGrants?.currency > 0) {
                proposalInputs = {
                    ...proposalInputs,
                    // @ts-ignore
                    grants: state?.selected?.values?.grants?.grants.map((grant) => {
                        return {
                            description: grant?.description,
                            prc: grant?.ammounts?.prc / 100,
                        };
                    }),
                };
            }
            //fe_version
            proposalInputs = {
                ...proposalInputs,
                fe_version: isDefined(bmState?.businessModelBody?.fromSpvV3) ? 'v3' : 'v2',
            };

            if (bmState?.offerEdition?.is_customize) {
                proposalInputs = {
                    ...proposalInputs,
                    is_customize: true,
                };
            }

            const proposalPayload = {
                facility: {
                    id: bmBody?.facility_id,
                },
                req_id_arr: bmState.reqsHash,
                inputs: proposalInputs,
                tipo_modelo_negocio_id: bModelSelected?.business_model_id,
                register_simulation: false,
            };

            dispatch(setProposal(proposalPayload));
        }
    }, [bmState?.proposalReady, bmState?.kpis?.finalKpis, monthly_payment_option_id]); // eslint-disable-line

    useEffect(() => {
        switch (bmState.action) {
            case BmDetailedActions.SIMPLE_SIMULATION: {
                fetchSimulation();
                break;
            }
            case BmDetailedActions.DETAILED_SIMULATION: {
                getDetailedKpis();
                break;
            }
            case BmDetailedActions.PROPOSAL: {
                setProposalHandler();
                break;
            }
            case BmDetailedActions.AUDIT: {
                postAuditHandler();
                break;
            }
            case BmDetailedActions.NOT_ELIGIBLE_AUDIT: {
                postAuditHandler(false);
            }
        }
    }, [bmState.action]); // eslint-disable-line

    return {
        fetchSimulation,
        getDetailedKpis,
        setProposalHandler,
        postAuditHandler,
        loadings: bmState.loadings,
        cancelRequestHandler,
    };
};

export default useOfferEdition;
