import { useContext } from 'react';
import UpFrontMargin from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront/CommercialMargin/MarginKpi';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront } from 'interfaces/businessModels/upfront';
import { UserContext } from 'contexts/userContext';
import { getCompanyProfileIds } from 'services/user';
import { GRUPO_CANAL_IDS, USERS_TYPE_ID } from 'constants/user';
import { isDefined } from 'services/util/auxiliaryUtils';
import { useFeatureFlags } from 'store/featureFlags';
import { isEnvDevFlag } from 'services/settings';

const UpFrontMarginKpis = () => {
    const { bmSelected, isRequestingBM } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    const { companyProfileId, userTypeID, userChannelIDGroup } = useContext(UserContext);

    const { featureFlags } = useFeatureFlags();

    const searchValue = bmSelected?.values?.margin_negotiation?.search;
    const marginSelected = bmSelected?.values?.marginSelected;
    const kpis_per_margin = bmSelected?.values?.kpis_per_margin;
    const kpisSelected = kpis_per_margin?.find((el) => el?.[searchValue] === marginSelected);
    const currentInvestment = kpisSelected?.investment ?? kpisSelected?.price ?? 0;

    const annualSaving = kpisSelected?.annual_saving ?? null;
    const payback = isDefined(annualSaving) ? currentInvestment / annualSaving! : null;
    const agencyComission =
        (isEnvDevFlag(featureFlags['fe-2553']) ? kpisSelected?.agency_commission?.[1] : kpisSelected?.agency_commission) ?? null;

    const agencyComissionVisibility =
        [getCompanyProfileIds().EDP_IT].includes(companyProfileId) &&
        ([USERS_TYPE_ID.MANAGER].includes(userTypeID) || [USERS_TYPE_ID.CHANNEL].includes(userTypeID)) &&
        ![GRUPO_CANAL_IDS.ENERTERL].includes(Number(userChannelIDGroup));

    return (
        <div className="bm-kpis-margin">
            <UpFrontMargin
                isLoading={isRequestingBM}
                label={'label.investment'}
                value={{ unit: 'currency', number: currentInvestment, numberOfDecimalPlaces: 0 }}
            />
            {
                <UpFrontMargin
                    isLoading={isRequestingBM}
                    label="label.annualSaving"
                    value={{ unit: 'currency', number: annualSaving, numberOfDecimalPlaces: 0 }}
                />
            }
            {/*@ts-ignore*/}
            {
                <UpFrontMargin
                    isLoading={isRequestingBM}
                    label="label.payback"
                    value={{ unit: 'year', number: payback, numberOfDecimalPlaces: 1 }}
                />
            }
            {agencyComissionVisibility && (
                <UpFrontMargin
                    isLoading={isRequestingBM}
                    label="label.agencyCommission"
                    value={{ unit: 'currency', number: agencyComission, numberOfDecimalPlaces: 0 }}
                />
            )}
        </div>
    );
};

export default UpFrontMarginKpis;
