import { useEffect, useMemo, useState, useRef } from 'react';

import { getSupportDocs, headSupportDocs, postProposalID } from 'api/proposal';
import { useSelector, useDispatch } from 'react-redux';
import { PRODUCT_IDS } from 'constants/products';
import { getParseDate, isDefined, removeFieldEmptyInObj } from 'services/util/auxiliaryUtils';
import { OK } from 'constants/statusCodeHttp';
import { setProductInputs } from 'redux/actions/product';
import { setNoAccessToProposal, requestHeadSupportDocsSuccess, requestGetProposalIDSuccess, resetProposal } from 'redux/actions/proposal';
import useStaticMap from 'hooks/utils/useStaticMap';
import { useUserStore } from 'store/user';
import { useProposalStore } from 'store/proposal';
import { getInputsSimulationPayload } from 'services/products/solarpv';
import { useFeatureFlags } from 'store/featureFlags';
import { isEnvDevFlag } from 'services/settings';
import { useProjects } from 'store/solutions/projects';
import { IArea } from 'interfaces/products/solarpv/state/sims';

const MAX_LIMIT_TO_HEAD_SUPPORT_DOCS = 180000; //3min

let createProjectAController;
let getProjectController;

const useProposal = () => {
    const {
        shadowProject,
        actions: { getProject, createProject },
    } = useProjects();
    const dispatch = useDispatch();
    const { fetchPostFileUploadImgContract } = useStaticMap();
    const { user } = useUserStore();
    const { featureFlags } = useFeatureFlags();

    const errorRef = useRef(false);
    const headRef = useRef(false);
    const contractRef = useRef(false);
    const fetchRef = useRef(0);

    const [isHeadingSupportDocs, setIsHeadingSupportDocs] = useState(true);
    const [hasStartedHead, setHasStartedHead] = useState(false);
    const [errorIdProposal, setErrorIdProposal] = useState(false);
    const [errorSupportDocs, setErrorSupportDocs] = useState(false);
    const [isGettingSupportDocs, setIsGettingSupportDocs] = useState(false);
    const [contractAvailable, setContractAvailable] = useState(false);
    const [fetchCounter, setFetchCounter] = useState(0);
    // @ts-ignore
    const proposal = useSelector((state) => state?.proposal);
    // @ts-ignore
    const productIDReducer = useSelector((state) => state?.product?.info?.id);
    const hasAccessToProposals = user?.acede_a_propostas;
    const imgContractFilename = useProposalStore.getState().img_contract_filename;
    // @ts-ignore
    const businessModelBody = useSelector((state) => state?.businessModels?.body);
    const proposalID = useMemo(() => proposal?.id, [proposal.id]);

    const isReformulation = useMemo(() => isDefined(businessModelBody?.reformulation), [businessModelBody]);
    const productID = useMemo(
        () => (isReformulation ? businessModelBody?.reformulation?.product?.info?.id : productIDReducer),
        [productIDReducer, businessModelBody, isReformulation]
    );

    // useRef -> useState
    useEffect(() => {
        errorRef.current = errorSupportDocs;
        headRef.current = hasStartedHead;
        contractRef.current = contractAvailable;
        fetchRef.current = fetchCounter;
    }, [errorSupportDocs, hasStartedHead, contractAvailable, fetchCounter]);

    const errorHeadingSupportDocsHandler = () => {
        if (!contractRef.current) {
            setErrorSupportDocs(!errorRef.current);
        }
    };

    const fetchProposalID = async () => {
        try {
            let payload;
            switch (productID) {
                case PRODUCT_IDS.EVC:
                case PRODUCT_IDS.EVCSB:
                    payload = {
                        facility: {
                            id: proposal.facility.id,
                        },
                        is_scaling: false,
                        sub_product_id: proposal.inputs.sub_product_id,
                        inputs: proposal.inputs,
                        tipo_modelo_negocio_id: proposal.tipo_modelo_negocio_id,
                        proposta_editada_id: proposal?.proposta_editada_id ?? null,
                    };
                    break;
                case PRODUCT_IDS.SPVSB:
                case PRODUCT_IDS.SPV: {
                    payload = {
                        facility: {
                            id: proposal.facility.id,
                        },
                        is_scaling: false,
                        inputs: getInputsSimulationPayload(proposal.inputs, { battery: proposal.inputs?.battery }),
                        img_contract_filename: imgContractFilename,
                        tipo_modelo_negocio_id: proposal.tipo_modelo_negocio_id,
                        tipo_produto_id: productID,
                        proposta_editada_id: proposal?.proposta_editada_id ?? null,
                    };

                    // fluxo Power Increase
                    if (isDefined(proposal?.inputs?.is_power_increase)) {
                        payload.is_power_increase = proposal?.inputs?.is_power_increase;
                        payload.old_proposal_id = proposal?.inputs?.old_proposal_id;
                        payload.production_over_six_month = proposal?.inputs?.is_power_increase;
                    }

                    if (isDefined(proposal?.req_id_arr)) {
                        payload.req_id_arr = proposal.req_id_arr;
                    }

                    //region remove areas without panels
                    const areasWithPanels: IArea[] = [];
                    payload.inputs.areas.forEach((area) => {
                        if (area.panels_number > 0) areasWithPanels.push(area);
                    });
                    payload.inputs.areas = areasWithPanels;

                    //endregion remove areas without panels
                    break;
                }
                case PRODUCT_IDS.SPV_SIMPLE: {
                    const img_contract_filename = await fetchPostFileUploadImgContract(
                        {
                            ...proposal?.mapData.center,
                            image_name: proposal?.mapData.image_name,
                        },
                        proposal?.mapData.roofCoordinates,
                        false
                    );

                    payload = {
                        facility: {
                            id: proposal.facility.id,
                        },
                        inputs: proposal.inputs,
                        img_contract_filename,
                        tipo_modelo_negocio_id: proposal.tipo_modelo_negocio_id,
                        is_open_area: false,
                    };
                    break;
                }
                case PRODUCT_IDS.HP: {
                    payload = {
                        facility: {
                            id: proposal.facility.id,
                        },
                        inputs: proposal.inputs,
                        tipo_modelo_negocio_id: proposal.tipo_modelo_negocio_id,
                    };
                    break;
                }
                default:
                    payload = {
                        facility: {
                            id: proposal.facility.id,
                        },
                        inputs: proposal.inputs,
                        tipo_modelo_negocio_id: proposal.tipo_modelo_negocio_id,
                        proposta_editada_id: proposal?.proposta_editada_id,
                        is_fast_module: proposal?.is_fast_module ?? null,
                    };
                    break;
            }

            const payloadFiltered = removeFieldEmptyInObj(payload);
            const fetchedProposal = await postProposalID(payloadFiltered);

            if (fetchedProposal?.status === OK) {
                if (isEnvDevFlag(featureFlags['fe-2508']) && proposal?.inputs?.is_solar_simple && !!shadowProject) {
                    createProjectAController && createProjectAController.abort();
                    createProjectAController = new AbortController();
                    getProjectController && getProjectController.abort();
                    getProjectController = new AbortController();
                    const rspProject = await getProject(shadowProject, getProjectController);

                    createProject({
                        description: `Project Solar Simple ${fetchedProposal?.data?.data?.sim_id}`,
                        facility_id: proposal.facility.id,
                        inputs: rspProject.inputs,
                        productID: productID,
                        simulation_id: fetchedProposal?.data?.id,
                        abortController: createProjectAController,
                        visible: true,
                    });
                }
                dispatch(requestGetProposalIDSuccess({ data: fetchedProposal.data }));
            } else {
                setErrorIdProposal(true);
            }
        } catch (error) {
            console.error(error);
            setErrorIdProposal(true);

            //TODO: handle error
        }
    };

    const fetchSupportDocs = async () => {
        !headRef.current && setTimeout(() => errorHeadingSupportDocsHandler(), MAX_LIMIT_TO_HEAD_SUPPORT_DOCS);
        const fetchedSupportDocs = await headSupportDocs(proposal.id);
        setIsHeadingSupportDocs(true);

        if (fetchedSupportDocs?.status === OK) {
            dispatch(requestHeadSupportDocsSuccess({ data: fetchedSupportDocs.data }));
            setIsHeadingSupportDocs(false);
            setContractAvailable(true);
        } else {
            !headRef.current && setHasStartedHead(true);
            setFetchCounter(fetchRef.current + 1);
        }
    };

    const getSupportDocsHandler = async () => {
        setIsGettingSupportDocs(true);

        const fileName = getParseDate() + '_support_docs_' + proposal.id + '.zip';

        const supportDocsFetched = await getSupportDocs({
            id: proposal.id,
            file_name: fileName,
        });

        if (supportDocsFetched?.status === OK) {
            const url = URL.createObjectURL(new Blob([supportDocsFetched.data]));
            const a = document.createElement('a');

            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
        }

        setIsGettingSupportDocs(false);
    };

    const fetchAgain = () => {
        setErrorSupportDocs(false);
        fetchSupportDocs();
    };

    const fetchProposalIdAgain = () => {
        setErrorIdProposal(false);
        fetchProposalID();
    };

    useEffect(() => {
        if (isDefined(proposal?.facility?.id) && !errorIdProposal) {
            dispatch(setProductInputs({ inputs: null }));
            if (!isDefined(proposalID)) {
                fetchProposalID();
            } else {
                if (hasAccessToProposals) {
                    fetchSupportDocs();
                } else {
                    setIsHeadingSupportDocs(false);
                    setContractAvailable(true);
                    dispatch(setNoAccessToProposal());
                }
            }
        }
    }, [proposalID, proposal?.facility?.id]); // eslint-disable-line

    useEffect(() => {
        let timeout;
        if (fetchCounter > 0) {
            if (!errorRef.current) {
                timeout = setTimeout(fetchSupportDocs, 2000);
            } else {
                setIsHeadingSupportDocs(false);
                setHasStartedHead(false);
                setFetchCounter(0);
                clearTimeout(timeout);
            }
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [fetchCounter]); // eslint-disable-line

    useEffect(() => {
        return () => {
            createProjectAController && createProjectAController.abort();
            dispatch(resetProposal());
        };
    }, []); // eslint-disable-line

    return {
        isHeadingSupportDocs,
        errorSupportDocs,
        proposalID,
        isGettingSupportDocs,
        getSupportDocsHandler,
        contractAvailable,
        canSendToCRM: proposal?.can_send_to_crm,
        has_proposal_description_feature: proposal?.has_proposal_description_feature,
        fetchSupportDocsHandler: fetchSupportDocs,
        hasAccessToProposals,
        fetchAgainHandler: fetchAgain,
        isReformulation,
        productTag: businessModelBody?.reformulation?.product?.info?.tp_tag,
        fetchProposalIdAgain,
        errorIdProposal,
    };
};

export default useProposal;
