/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @see https://reactjs.org/docs/hooks-custom.html
 *
 * @version 20210630
 * @since 20210630 Initial release
 *
 */

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { ALLOW_PATHNAME_REDICT_BY_TOKEN } from 'constants/settings';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { useAuthStore } from 'store/auth';

const useRedirectToken = (location) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const urlToken = () => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get('token');
    };
    const [isRedirectingByToken, setIsRedirectingByToken] = useState(
        isFieldDefined(urlToken()) && ALLOW_PATHNAME_REDICT_BY_TOKEN.includes(location.pathname)
    );
    const { loginOK } = useAuthStore();

    useEffect(() => {
        if (isRedirectingByToken) {
            urlTokenLogin();
        }
    }, []); //eslint-disable-line

    const urlTokenLogin = async () => {
        await loginOK({ token: JSON.stringify(urlToken()), history, dispatch });
        await setIsRedirectingByToken(false);
    };

    const isValidRedirectToken = (location) => {
        const searchParams = new URLSearchParams(location.search);
        const token = JSON.stringify(searchParams.get('token'));
        let isValid = false;
        // PV & IE
        if (isFieldDefined(token) && ALLOW_PATHNAME_REDICT_BY_TOKEN.includes(location.pathname)) {
            isValid = true;
            loginOK({ token, history, dispatch });
        }

        setIsRedirectingByToken(isValid);
        return isValid;
    };

    return {
        isRedirectingByToken,
        isValidRedirectToken,
    };
};

export default useRedirectToken;
