import { TLocale, TTheme, TUser, TUserSettings } from 'interfaces/user';
import { Nullable } from 'types/utils';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

// import { devtools } from 'zustand/middleware';

interface IUserStore {
    user: Nullable<TUser>;
    fieldUser: Nullable<number>;
    theme: Nullable<TTheme>;
    locale: TLocale;
    mapLocale: Nullable<string>;
    LOCALE_CODE: string;
    settings: Nullable<TUserSettings>;
    setUser: ({
        user,
        theme,
        locale,
        settings,
        fieldUser,
    }: {
        user: Nullable<TUser>;
        theme?: Nullable<TTheme>;
        locale: any;
        settings?: Nullable<TUserSettings>;
        fieldUser?: Nullable<number>;
    }) => void;
    setLocaleCode: (localeCode: string) => void;
    switchLanguage: (locale) => void;
    updateUserTerms: () => void;
    updateUserName: (name: string) => void;
    setMapLocale: (mapLocale: string) => void;
    setTheme: (theme: TTheme) => void;
    setSettings: (settings: TUserSettings) => void;
}

const initialLocale = {
    languageId: 'English (United Kingdom)',
    locale: 'en',
    userLocale: 'en-GB', //TODO: @deprecate Remover em breve
    appLocale: 'en-GB',
    isoLocale: 'en',
    localePickers: 'en-gb',
    name: 'label.english',
    nameTranslated: 'label.englishTranslated',
    icon: 'gb',
    flagIcon: `assets/images/icons/svg/flags/gb.svg`,
};

export const useUserStore = create<IUserStore>()(
    persist(
        devtools((set) => ({
            user: null,
            fieldUser: null,
            locale: initialLocale,
            theme: null,
            settings: null,
            LOCALE_CODE: 'en',
            mapLocale: null,
            setUser: ({ user, theme, locale, settings, fieldUser }) => {
                set((state) => {
                    return {
                        user,
                        theme: theme ?? state.theme,
                        locale,
                        settings,
                        LOCALE_CODE: locale?.locale ?? 'en',
                        fieldUser,
                    };
                });
            },
            setLocaleCode: (localeCode) => {
                set(() => {
                    return { LOCALE_CODE: localeCode };
                });
            },
            switchLanguage: (locale) => {
                set(() => {
                    return { LOCALE_CODE: locale?.locale ?? 'en', locale: locale };
                });
            },
            updateUserTerms: () => {
                set((state) => {
                    return { user: { ...state.user, shows_terms: false } } as IUserStore;
                });
            },
            updateUserName: (name) => {
                set((state) => {
                    return { user: { ...state.user, nome: name } } as IUserStore;
                });
            },
            setMapLocale: (mapLocale) => {
                set(() => {
                    return { mapLocale };
                });
            },
            setTheme: (theme) => {
                set(() => {
                    return { theme };
                });
            },
            setSettings: (settings) => {
                set(() => {
                    return { settings };
                });
            },
        })),
        // @ts-ignore
        {
            name: 'user',
        }
    )
);
