import { useEffect, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@save2compete/efz-design-system';
import { StyledNewUpdateDialog } from 'components/core/newUpdateDialog/StylesNewUpdateDialog';
import UAParser from 'ua-parser-js';

// Compoenents
import IntlMessages from 'components/util/IntlMessages';
import { SentryCaptureException } from 'services/@efz/sentry';
import { SENTRY_CODE_ERRO_TAGS } from 'constants/settings';

const BrowserCheckDialog = () => {
    const [isCompatibleBrowser, setIsCompatibleBrowser] = useState(false);

    useEffect(() => {
        let _isCompatibleBrowser = false;
        const parser = new UAParser();
        const browser = parser.getBrowser();
        if (['Chrome', 'Edge', 'Firefox', 'Brave'].includes(browser.name) && Number(browser.major) < 100) {
            _isCompatibleBrowser = true;
        }
        if (['Safari'].includes(browser.name) && Number(browser.major) < 16) {
            _isCompatibleBrowser = true;
        }
        if (['Opera'].includes(browser.name) && Number(browser.major) < 90) {
            _isCompatibleBrowser = true;
        }
        if (browser.name === 'IE') {
            _isCompatibleBrowser = true;
        }

        if (_isCompatibleBrowser) {
            setIsCompatibleBrowser(_isCompatibleBrowser);
            const message = `FE-INFO | ${SENTRY_CODE_ERRO_TAGS?.FE_BROWSER_CHECK}`;
            SentryCaptureException({
                level: 3,
                message,
                fingerprint: message,
                tags: {
                    CODE_TAG: SENTRY_CODE_ERRO_TAGS?.FE_BROWSER_CHECK,
                },
                extrasContext: {
                    browser,
                },
            });
        }
    }, []);

    return (
        <StyledNewUpdateDialog open={isCompatibleBrowser} maxWidth="md" onClose={() => {}}>
            <DialogTitle className=" text-center"></DialogTitle>

            <DialogContent className="justify-content-center text-center ws-break mb-4">
                {/* @ts-ignore */}
                <IntlMessages id={`dialog.browserCheck`} />
            </DialogContent>

            <DialogActions className="d-flex gap-4 justify-content-center align-items-center">
                <Button
                    variant="primary"
                    onClick={() => {
                        window.location.reload();
                    }}
                    size="bg"
                    dataTestId="new-update-reloadNow"
                >
                    {/* @ts-ignore */}
                    <IntlMessages id={`label.reloadNow`} />
                </Button>
            </DialogActions>
        </StyledNewUpdateDialog>
    );
};

export default BrowserCheckDialog;
