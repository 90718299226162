import { useContext, useState } from 'react';
import { Controller } from 'react-hook-form';
import { intlMessages } from 'services/util/auxiliaryUtils';

import { matchIsValidTel } from 'mui-tel-input';
import { useUserStore } from 'store/user';
import { AVAILABLE_USER_LOCALES } from 'constants/user';
import { UserContext } from 'contexts/userContext';
import { CountryCode, Metadata } from 'libphonenumber-js';

import { isDefined } from 'services/util/auxiliaryUtils';
import { getCompanyProfileIds } from 'services/user';
import { StyledMuiTelInput } from 'components/@efz/PhoneInput/StylesPhoneInput';
import { isOpenArea } from 'services/settings';

const PhoneInput = ({ name, rules, control, error, defaultValue }) => {
    const { user } = useUserStore();
    const {
        companyProfileId,
        //@ts-ignore - TODO: check UserContext type for locale.
        locale: { userLocale },
    } = useContext(UserContext);
    const isOArea = isOpenArea();
    const currentUserLocale = userLocale ?? AVAILABLE_USER_LOCALES.enGB;
    //Setting default to Greece in OArea because we can't use the user's locale.
    const PHONE_COUNTRY_CODE = isOArea ? 'GR' : user?.locale?.split('-')?.[1]?.toUpperCase() ?? 'GB';
    const [countryCode, setCountryCode] = useState(PHONE_COUNTRY_CODE);

    const metadata = new Metadata();
    metadata.selectNumberingPlan(countryCode as CountryCode);

    const maxPhoneLength = Math.max(...metadata.numberingPlan!.possibleLengths());

    return (
        <Controller
            name={name}
            rules={{
                ...rules,
                validate: (v) => {
                    if (!v) return true;
                    const isValid = matchIsValidTel(v, {
                        onlyCountries: [countryCode as CountryCode],
                    });
                    if (!isValid) {
                        return 'page.user.phone.error.invalidFormat';
                    }
                    return true;
                },
            }}
            control={control}
            defaultValue={defaultValue}
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            render={({ field: { ref, ...field } }) => {
                return (
                    <StyledMuiTelInput
                        {...field}
                        inputRef={ref}
                        forceCallingCode
                        focusOnSelectCountry
                        defaultCountry={countryCode as CountryCode}
                        langOfCountryName={currentUserLocale}
                        excludedCountries={[getCompanyProfileIds().PPC].includes(companyProfileId) || isOArea ? ['MK'] : []}
                        onChange={(value, info) => {
                            if (isDefined(info.countryCode) && info.countryCode !== countryCode) {
                                setCountryCode(info.countryCode!);
                            }
                            (info.nationalNumber?.length ?? 0) <= maxPhoneLength && field.onChange(value);
                        }}
                        placeholder={intlMessages('question.placeholder.phoneNumber')}
                        error={error}
                        inputProps={{
                            ['data-testid']: `input-${field?.name?.replaceAll('_', '-') ?? 'NA'}`,
                        }}
                    />
                );
            }}
        />
    );
};

export default PhoneInput;
