/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description redux Reducers - Client
 *
 * @version 20192908
 * @since 20192908 Initial release
 *
 */

import clone from 'fast-copy';
import {
    INITIAL_CLIENT,
    SET_CLIENT,
    RESET_CLIENT,
    FETCH_CLIENT,
    FETCH_CLIENT_SUCCESS,
    SET_IS_OPEN_CLIENT_CARD,
    REQUEST_GET_CLIENT_SUCCESS,
    SET_CLIENT_CAE5,
    REQUEST_GET_CLIENT,
    REQUEST_GET_CLIENT_FAIL,
    REQUEST_POST_CREATE_CLIENT,
    REQUEST_POST_CREATE_CLIENT_SUCCESS,
    REQUEST_POST_CREATE_CLIENT_FAIL,
    REQUEST_RESET_SUCCESSFULLY_CREATED_CLIENT,
    REQUEST_PUT_CLIENT_INFO,
    REQUEST_PUT_CLIENT_INFO_SUCCESS,
    REQUEST_PUT_CLIENT_INFO_FAIL,
    SET_CLIENT_BY_TOKEN,
    SET_CLIENT_FACILITIES,
    SET_CLIENT_B2C,
    RESET_CLIENT_FACILITIES,
    SET_CLIENT_HISTORY,
    MAX_HISTORY_LIST_CLIENT,
} from 'constants/client';

import findIndex from 'lodash.findindex';
import { STATE_IDS } from 'constants/user';
import { showStateCurrent } from 'services/user';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { SET_CHANGE_ADDRESS_FACILITY } from 'constants/facility';

const initialState = clone(INITIAL_CLIENT);

const reducersClient = (state = initialState, action) => {
    switch (action.type) {
        case SET_CLIENT_FACILITIES: {
            const { payload } = action;
            return {
                ...state,
                facilities: payload,
            };
        }
        case REQUEST_GET_CLIENT:
            return {
                ...state,
                isRequesting: true,
            };
        case REQUEST_GET_CLIENT_SUCCESS:
            return {
                ...INITIAL_CLIENT,
                search: {
                    // preserve search history
                    ...state.search,
                    history: state?.search?.history ?? [],
                },
                list: [...state.list],
                ...(action?.payload?.data ?? {}),
                isRequesting: false,
            };
        case REQUEST_GET_CLIENT_FAIL:
            return {
                ...state,
                isRequesting: false,
            };
        case SET_IS_OPEN_CLIENT_CARD:
            return {
                ...state,
                isOpenClientCard: action.payload,
            };
        case FETCH_CLIENT:
            return {
                ...state,
                loader: true,
            };
        case FETCH_CLIENT_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case SET_CLIENT_CAE5:
            return {
                ...state,
                ...action.payload,
            };
        case SET_CLIENT_HISTORY: {
            return {
                ...clone(INITIAL_CLIENT),
                list: action.payload,
            };
        }
        case SET_CLIENT: {
            const {
                id,
                contacto_email,
                contacto_nome,
                contacto_numero,
                descricao,
                nipc,
                pais_id,
                counters,
                cae2,
                cae5,
                segmento_descricao,
                risco_energia_id,
                localidade,
                morada,
                codigo_postal,
                setClientList,
                tnw,
            } = action.payload;

            //history
            let historyList = clone(state.list?.length > 0 ? state.list : []);

            let client = {
                id,
                contacto_email,
                contacto_nome,
                contacto_numero,
                descricao,
                nipc,
                pais_id,
                counters,
                cae2,
                cae5,
                segmento_descricao,
                risco_energia_id,
                tnw,
                morada,
                localidade,
                codigo_postal,
            };

            // Checks if this client is already on the history. If not, adds it to first position
            const addedClient = (client) => {
                let index = findIndex(historyList, ['nipc', nipc]);
                if (index === -1) {
                    historyList.unshift(client);
                } else {
                    historyList.splice(index, 1);
                    historyList.unshift(client);
                }

                if (historyList.length > MAX_HISTORY_LIST_CLIENT) historyList.pop();
            };
            // if history is empty, add, else check if client exists
            historyList?.length === 0 ? historyList.push(client) : addedClient(client);

            const hasListToDisplay = action.payload.list > 0;
            if (hasListToDisplay) {
                setClientList(historyList); //handler do context.
            }

            return {
                ...state,
                list: historyList,
                id,
                contacto_email,
                contacto_nome,
                contacto_numero,
                descricao,
                nipc,
                pais_id,
                counters,
                cae2,
                cae5,
                segmento_descricao,
                risco_energia_id,
                tnw,
                morada,
                localidade,
                codigo_postal,
            };
        }
        case SET_CLIENT_B2C: {
            const {
                id,
                nome_proprio,
                apelido,
                email,
                empresa_id,
                numero_fiscal,
                numero_telefone,
                segmento_descricao,
                segmento_id,
                setClientList,
                ...rest
            } = action.payload;

            let morada = rest?.location?.morada ?? rest?.morada;
            let codigo_postal = rest?.location?.codigo_postal ?? rest?.codigo_postal;
            let cidade = rest?.location?.cidade ?? rest?.cidade;

            //history
            let historyList = clone(state.list?.length > 0 ? state.list : []);

            let client = {
                id,
                nome_proprio,
                apelido,
                email,
                numero_fiscal,
                numero_telefone,
                segmento_descricao,
                morada,
                codigo_postal,
                cidade,
            };
            // Checks if this client is already on the history. If not, adds it to first position
            const addedClient = (client) => {
                let index = findIndex(historyList, ['id', id]);
                if (index === -1) {
                    historyList.unshift(client);
                } else {
                    historyList.splice(index, 1);
                    historyList.unshift(client);
                }

                if (historyList.length > MAX_HISTORY_LIST_CLIENT) historyList.pop();
            };
            // if history is empty, add, else check if client exists
            historyList?.length === 0 ? historyList.push(client) : addedClient(client);

            const hasListToDisplay = action.payload.list > 0;
            if (hasListToDisplay) {
                setClientList(historyList); //handler do context.
            }

            return {
                ...state,
                list: historyList,
                id,
                nome_proprio,
                apelido,
                email,
                empresa_id,
                numero_fiscal,
                numero_telefone,
                segmento_descricao,
                segmento_id,
                morada,
                codigo_postal,
                cidade,
            };
        }
        //#region CREATE CLIENT
        case REQUEST_POST_CREATE_CLIENT:
            return {
                ...state,
                isRequestingCreateClient: true,
            };
        case REQUEST_POST_CREATE_CLIENT_SUCCESS:
            return {
                ...state,
                isRequestingCreateClient: false,
                hasSuccessfullyCreatedClient: true,
            };
        case REQUEST_POST_CREATE_CLIENT_FAIL:
            return {
                ...state,
                isRequestingCreateClient: false,
                hasSuccessfullyCreatedClient: false,
            };
        case REQUEST_RESET_SUCCESSFULLY_CREATED_CLIENT:
            return {
                ...state,
                hasSuccessfullyCreatedClient: false,
            };
        //#endregion CREATE CLIENT

        //#region UPDATE CLIENT
        case REQUEST_PUT_CLIENT_INFO:
            return {
                ...state,
                isRequesting: true,
            };
        case REQUEST_PUT_CLIENT_INFO_SUCCESS: {
            const isB2C = localStorage.getItem('salesSegment') === 'B2C';
            let payload = {};
            if (isB2C) {
                const { morada, codigo_postal, cidade } = action.payload.location;
                payload = {
                    ...action.payload,
                    morada,
                    codigo_postal,
                    cidade,
                };
            } else {
                payload = { ...action.payload[0] };
            }
            let cList = clone(state?.list);
            let indexToChange = cList.findIndex((el) => parseInt(el.id) === parseInt(state?.id));
            cList[indexToChange].nome_proprio = action.payload?.nome_proprio;
            cList[indexToChange].apelido = action.payload?.apelido;
            cList[indexToChange].nipc = payload?.nipc;

            return {
                ...state,
                isRequesting: false,
                ...payload,
                list: cList,
            };
        }
        case REQUEST_PUT_CLIENT_INFO_FAIL:
            return {
                ...state,
                isRequesting: false,
            };
        //#endregion UPDATE CLIENT

        case RESET_CLIENT:
            return {
                ...clone(INITIAL_CLIENT),
                list: isFieldDefined(action.payload) ? state?.list?.filter((item) => item.id !== action.payload) : state?.list ?? [],
            };
        case RESET_CLIENT_FACILITIES:
            return {
                ...state,
                facilities: [],
            };
        case SET_CLIENT_BY_TOKEN:
            return {
                ...INITIAL_CLIENT,
                ...(action?.payload?.data ?? {}),
                isRequesting: false,
            };
        case SET_CHANGE_ADDRESS_FACILITY:
            return {
                ...state,
                facilities: state?.facilities?.map((facility) => {
                    if (facility.id === action.payload.id) {
                        if (action.payload?.isB2C) {
                            return {
                                ...facility,
                                morada: action?.payload?.body?.location?.address ?? '',
                                location: {
                                    ...facility.location,
                                    ...action.payload.body.location,
                                },
                            };
                        } else {
                            return {
                                ...facility,
                                ...action.payload.body,
                            };
                        }
                    }
                    return facility;
                }),
            };
        default:
            return (
                showStateCurrent(STATE_IDS.PLATFORM) ?
                    !state ?
                        {
                            ...clone(INITIAL_CLIENT),
                        }
                    :   state
                :   null
            );
    }
};

export default reducersClient;
