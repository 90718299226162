import { create } from 'zustand';
import {
    TAreaItem,
    TCoordConvFactorData,
    TCoordConvFactorPayload,
    TCoordsData,
    TDetectBuildingPayload,
    TDetectRooftopPayload,
    TDetectRoofTypeData,
    TDetectRoofTypePayload,
    TEstimatedStructureAspectPayload,
    TExclusionAreaADetectedPayload,
    TExclusionsData,
    TInvertersComb,
    TInvertersCombData,
    TInvertersCombPayload,
    TOptimizerPayload,
    TOptimizerWithKitsPayload,
    TRoofSplitPayload,
    TRowRatioPayload,
    TSimulationPayload,
    TSolutionData,
    TStructuresForRoofTypeData,
    TUsefulAreaPayload,
    TUseSpvCacheRequestsActionsProps,
} from 'hooks/products/solarpv/simple/store/types';
import { TUsefulAreaData } from 'types/products/solarpv/spvSimple';

const initialUseCacheRequests = {
    cacheStructureForRoofType: {
        payload: 0 as number,
        data: [] as TStructuresForRoofTypeData,
    },
    cacheDetectRooftop: {
        payload: {} as TDetectRooftopPayload,
        data: {} as TCoordsData,
    },
    cacheDetectBuilding: {
        payload: {} as TDetectBuildingPayload,
        data: {} as TCoordsData,
    },
    cacheDetectRoofType: {
        payload: {} as TDetectRoofTypePayload,
        data: {} as TDetectRoofTypeData,
    },
    cacheConvFactors: {
        payload: {} as TCoordConvFactorPayload,
        data: {} as TCoordConvFactorData,
    },
    cacheEstimatedStructuresAspect: {
        payload: {} as TEstimatedStructureAspectPayload,
        data: {} as TAreaItem[],
    },
    cacheRoofSplit: {
        payload: {} as TRoofSplitPayload,
        data: {} as TAreaItem[],
    },
    cacheExclusions: {
        payload: {} as TExclusionAreaADetectedPayload[],
        data: {} as TExclusionsData[],
    },
    cacheRowRatio: {
        payload: {} as TRowRatioPayload,
        data: 0 as number,
    },
    cacheUsefulAreas: {
        payload: {} as TUsefulAreaPayload[],
        data: {} as TAreaItem[],
        responses: [] as TUsefulAreaData[],
    },
    cacheInvertersComb: {
        payload: {} as TInvertersCombPayload,
        data: {} as TInvertersCombData,
        responses: {} as TInvertersComb,
    },
    cacheSimulation: {
        payload: {} as TSimulationPayload,
        data: {} as TSolutionData,
    },
    cacheOptimizer: {
        payload: {} as TOptimizerPayload | TOptimizerWithKitsPayload,
        data: {} as TSolutionData,
    },
};
interface ICacheRequestsStore {
    cacheStructureForRoofType: {
        payload: number;
        data: TStructuresForRoofTypeData;
    };
    cacheDetectRooftop: {
        payload: TDetectRooftopPayload;
        data: TCoordsData;
    };
    cacheDetectBuilding: {
        payload: TDetectBuildingPayload;
        data: TCoordsData;
    };
    cacheDetectRoofType: {
        payload: TDetectRoofTypePayload;
        data: TDetectRoofTypeData;
    };
    cacheConvFactors: {
        payload: TCoordConvFactorPayload;
        data: TCoordConvFactorData;
    };
    cacheEstimatedStructuresAspect: {
        payload: TEstimatedStructureAspectPayload;
        data: TAreaItem[];
    };
    cacheRoofSplit: {
        payload: TRoofSplitPayload;
        data: TAreaItem[];
    };
    cacheExclusions: {
        payload: TExclusionAreaADetectedPayload[];
        data: TExclusionsData[];
    };
    cacheRowRatio: {
        payload: TRowRatioPayload;
        data: number;
    };
    cacheUsefulAreas: {
        payload: TUsefulAreaPayload[];
        data: TAreaItem[];
        responses: TUsefulAreaData[];
    };
    cacheInvertersComb: {
        payload: TInvertersCombPayload;
        data: TInvertersCombData;
        responses: TInvertersComb;
    };
    cacheSimulation: {
        payload: TSimulationPayload;
        data: TSolutionData;
    };
    cacheOptimizer: {
        payload: TOptimizerPayload | TOptimizerWithKitsPayload;
        data: TSolutionData;
    };
    actions: TUseSpvCacheRequestsActionsProps;
}

export const useCacheRequests = create<ICacheRequestsStore>()((set) => ({
    ...initialUseCacheRequests,
    actions: {
        resetCache: () => set(() => ({ ...initialUseCacheRequests })),
        setCacheStructureForRoofType: (options) => set((state) => ({ ...state, cacheStructureForRoofType: options })),
        setCacheDetectRoofTop: (options) => set((state) => ({ ...state, cacheDetectRooftop: options })),
        setCacheDetectBuilding: (options) => set((state) => ({ ...state, cacheDetectBuilding: options })),
        setCacheDetectRoofType: (options) => set((state) => ({ ...state, cacheDetectRoofType: options })),
        setCacheConvFactors: (options) => set((state) => ({ ...state, cacheConvFactors: options })),
        setCacheEstimatedStructuresAspect: (options) => set((state) => ({ ...state, cacheEstimatedStructuresAspect: options })),
        setCacheRoofSplit: (options) => set((state) => ({ ...state, cacheRoofSplit: options })),
        setCacheExclusions: (options) => set((state) => ({ ...state, cacheExclusions: options })),
        setCacheRowRatio: (options) => set((state) => ({ ...state, cacheRowRatio: options })),
        setCacheUsefulAreas: (options) => set((state) => ({ ...state, cacheUsefulAreas: options })),
        setCacheOptimizer: (options) => set((state) => ({ ...state, cacheOptimizer: options })),
        setCacheInvertersComb: (options) => set((state) => ({ ...state, cacheInvertersComb: options })),
        setCacheSimulation: (options) => set((state) => ({ ...state, cacheSimulation: options })),
    },
}));
