import { useState } from 'react';
import { StatusCodes } from 'http-status-codes';
import { getTheme } from 'api/theme';
import { isEnvDevFlag, isOpenArea } from 'services/settings';
import { getCompanyProfileIds } from 'services/user';
import { isSolarpvPPCOAreaB2C } from 'services/util/auxiliaryUtils';
import { COMPANY_IDS } from 'constants/settings';
import { useUserStore } from 'store/user';
import { getUserSettings } from 'api/user';
import { useFeatureFlags } from 'store/featureFlags';

let getThemeAbortController;
let getUserSettingsController;

const useSettings = (isB2C = false) => {
    const { setUser, locale, user } = useUserStore();
    const { featureFlags } = useFeatureFlags();

    const [isRequesting, setIsRequesting] = useState(true);

    const getThemeHandler = async () => {
        getThemeAbortController && getThemeAbortController.abort();
        getThemeAbortController = new AbortController();
        const rspTheme = await getTheme(getThemeAbortController, true);
        if (rspTheme?.status === StatusCodes.OK) return rspTheme?.data?.data;
        return null;
    };

    const getUserSettingsHandler = async () => {
        getUserSettingsController && getUserSettingsController.abort();
        getUserSettingsController = new AbortController();
        const rspSettings = await getUserSettings(isOpenArea(), getUserSettingsController);
        if (rspSettings?.status === StatusCodes.OK) return rspSettings?.data?.[0];
        return null;
    };

    const fetchInitialRequestsOnHandler = async () => {
        setIsRequesting(true);

        if (isOpenArea()) {
            localStorage.removeItem('userToken');
            localStorage.setItem('salesSegment', isSolarpvPPCOAreaB2C() || isB2C ? 'B2C' : 'B2B');

            // Theme
            const newTheme = await getThemeHandler();

            // Settings
            let userSettings = null;
            if (isEnvDevFlag(featureFlags['fe-2536'])) {
                userSettings = await getUserSettingsHandler();
            }

            setUser({
                theme: newTheme,
                // @ts-ignore
                user: { ...user, perfil_empresa_id: getCompanyProfileIds().PPC, company_code: 'ppc', empresa_id: COMPANY_IDS.PPC },
                locale: locale,
                settings: userSettings,
            });
        }
        setIsRequesting(false);
    };

    return {
        fetchInitialRequestsOnHandler,
        isRequesting,
    };
};

export default useSettings;
