// import Button from "@mui/material/Button";
import ProjectSummaryTitle from 'components/BusinessModels/ProjectSummary/Title';
import IntlMessages from 'components/util/IntlMessages';
import { OFFER_DETAILS_INPUT_NAMES } from 'constants/businessModelsPro';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront } from 'interfaces/businessModels/upfront';
import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ProjectSummaryTotalCost from '../../TotalCost';
import ProjectSummaryBaseCost from '../../BaseCost';
import UpFrontOfferDetailsInputs from './OfferDetailsInputs';
import UpfrontOMDuration from './OMDuration';
import { isDefined } from 'services/util/auxiliaryUtils';
import InverterWarantyUpfront from 'components/BusinessModels/ProjectSummary/ProjectSummaryUpFront/OfferDetails/InverterWarantyUpfront';

const OfferDetailsUpFront = () => {
    const {
        state,
        bmSelected: { elements },
        negotiationProfile: { acesso_valores_custo },
    } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    // useForm Here
    const methods = useForm({
        defaultValues: {
            [OFFER_DETAILS_INPUT_NAMES.MONITORING]: true,
            [OFFER_DETAILS_INPUT_NAMES.EQUIPMENT_WARRANTY_INVERTERS]: 3,
            [OFFER_DETAILS_INPUT_NAMES.COST_ADJUSTMENT]: 0,
            [OFFER_DETAILS_INPUT_NAMES.OM_DURATION]: null,
            [OFFER_DETAILS_INPUT_NAMES.INVERTERS_WARATY]: null,
        },
    });
    const baseCost: number = state.selected.values.costs.baseCost; /* SPVPro ?? SPVSimple */

    return (
        <>
            <div className="bm-project-summary-card bm-project-summary-offer-details">
                <div className="bm-project-summary-offer-details-header">
                    <h1>
                        <IntlMessages id="label.modelBusiness" />:
                    </h1>
                    <h2>{state.selected.options?.pitch.description ?? <IntlMessages id="product.typeBusinessModel.UP_FRONT" />}</h2>
                </div>
                <div className="bm-project-summary-offer-details-separator" />
                <ProjectSummaryTitle label={'label.SummaryOffer'} />
                <ProjectSummaryBaseCost cost={baseCost} />
                <div className="bm-project-summary-offer-details-body">
                    <FormProvider {...methods}>
                        {isDefined(elements[OFFER_DETAILS_INPUT_NAMES.OM_DURATION]) && <UpfrontOMDuration />}
                        {isDefined(elements[OFFER_DETAILS_INPUT_NAMES.INVERTERS_WARATY]) &&
                            isDefined(methods.watch(OFFER_DETAILS_INPUT_NAMES.INVERTERS_WARATY)) && <InverterWarantyUpfront />}
                        <UpFrontOfferDetailsInputs />
                    </FormProvider>
                </div>
                {acesso_valores_custo ?
                    <>
                        <div className="bm-project-summary-offer-details-separator" />
                        <ProjectSummaryTotalCost cost={state.selected.values.costs.offerDetailsCost} />
                    </>
                :   <></>}
            </div>
        </>
    );
};

export default OfferDetailsUpFront;
