import FormatNumber from 'components/util/FormatNumber';
import IntlMessages from 'components/util/IntlMessages';
import { BusinessModelsProContext } from 'contexts/businessModelsPro/businessModelsContext';
import { IBusinessModelsContext, TProductInputs } from 'interfaces/businessModels';
import { TUpFront } from 'interfaces/businessModels/upfront';
import { useContext } from 'react';

const ProjectSummaryBaseCost = ({ cost }: { cost: number }) => {
    const { negotiationProfile } = useContext(BusinessModelsProContext) as IBusinessModelsContext<TProductInputs, TUpFront>;

    return (
        <>
            <div className="bm-project-summary-base-cost">
                <span>
                    <IntlMessages id="label.basePackage" />
                </span>
                {negotiationProfile?.acesso_valores_custo && (
                    <span>
                        {/* @ts-ignore */}
                        <FormatNumber number={cost} unit={'currency'} numberOfDecimalPlaces={0} />
                    </span>
                )}
            </div>
            <div className="bm-project-summary-offer-details-separator mt-3" />
        </>
    );
};

export default ProjectSummaryBaseCost;
