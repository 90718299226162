import { lazy } from 'react';
import PropTypes from 'prop-types';
import lazyLoader from 'services/lazyLoader';
import { BackofficeProvider } from 'contexts/backoffice/backofficeContext';
import { BASENAME_URL_APP } from 'constants/settings';
import Error403 from 'components/errors/Error403';
import { isFieldDefined } from 'services/util/auxiliaryUtils';
import { useUserStore } from 'store/user';

const BackofficePage = lazy(() => lazyLoader(() => import('pages/Restricted/Backoffice')));

/**
 * @context Route to Backoffice
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Backoffice = () => {
    const { user } = useUserStore();
    const userID = user?.id;
    return isFieldDefined(userID) ?
            <BackofficeProvider>
                <BackofficePage />
            </BackofficeProvider>
        :   <Error403
                props={{
                    url: BASENAME_URL_APP + 'solutions',
                    buttonText: 'page.404.button.goSolutions',
                    message: 'page.error.403.BusinessModels',
                }}
            />;
};

Backoffice.propTypes = {
    userId: PropTypes.number,
};

export default Backoffice;
