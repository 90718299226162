import { initialSPVProReducer } from 'contexts/products/solarpv/spvProReducer';
import { initialSolarpvTimelineReducer } from 'contexts/products/solarpv/spvTimeLineReducer';

export const initialSpvContextValues = {
    localeUser: '',
    userLocale: '',
    isB2C: false,
    solarpvState: initialSPVProReducer,
    solarpvTimelineState: initialSolarpvTimelineReducer,
    solarpvTimelineDispatch: () => {},
    solarpvDispatch: () => {},
    solarpvDispatchHandler: () => {},
    localeCode: '',
    companyProfileId: 0,
    risco_energia_id: 0,
    userTypeID: 0,
    productID: 0,
    coordinates_avg: { lat: null, long: null },
    userChannelIDGroup: null,
    facilityID: null,
    headBrand: null,
    imageName: '',
    is_power_increase: false,
    isIncreasePower: false,
    facilityInfo: {},
    potencia_contratada: 0,
    potencia_requisitada: 0,
    nivel_tensao_descricao: '',
    proposalDetails: {},
    standaloneSims: null,
    bundleSims: null,
    simulation: null,
    handleSimulations: () => {},
    setInfoTagsHandler: () => {},
    kpisSelected: null,
    projectSummaryExpanded: false,
    setProjectSummaryExpanded: () => {},
    isOpenMap: false,
    setIsOpenMap: () => {},
    polygonGroups: [],
    exclusionZonesPolygons: [],
    canDrawPolygon: false,
    setCanDrawPolygon: () => {},
    zoom: { current: 0, max: 0 },
    setZoom: () => {},
    dispatchEvt: { evt: null, groupId: null, values: null },
    setDispatchEvt: () => {},
    hasGroups: false,
    remunerationModelOptions: [],
    setRemunerationModelOptions: () => {},
    rangeId: null,
    rangeInvestDescription: null,
    isBatteryCompatibleRangeID: false,
    rangePanelId: null,
    rangePanelTypes: [],
    hasChangesInRemote: false,
    setHasChangesInRemote: () => {},
    isDisabledRemote: false,
    isEditingExclusions: false,
    hasChangesInExclusions: false,
    setHasChangesInExclusions: () => {},
    hasChangedExcusionInputs: new Map(),
    setHasChangedExcusionInputs: () => {},
    hasChangesOnExcursionsInputs: false,
    isDrawingExclusions: false,
    setIsDrawingExclusions: () => {},
    exclusionsRefs: [],
    setExclusionsRefs: () => {},
    exclusionsAutoSplit: { current: [] },
    toggleExclusionsDrawingModeHandler: () => {},
    isDrawingBuildings: false,
    setIsDrawingBuildings: () => {},
    duplicateMode: false,
    setDuplicateMode: () => {},
    duplicatePolygonID: null,
    setDuplicatePolygonID: () => {},
    isOverlayOpen: false,
    setIsOverlayOpen: () => {},
    isMovingGroup: false,
    setIsMovingGroup: () => {},
    movingCounter: 0,
    setMovingCounter: () => {},
    isEditingOrientation: null,
    setisEditingOrientation: () => {},
    isOrientationOverlayOpen: false,
    setIsOrientationOverlayOpen: () => {},
    isChangeAddressOverlayOpen: false,
    setIsChangeAddressOverlayOpen: () => {},
    canDragMarker: false,
    setCanDragMarker: () => {},
    alignmentOverlay: null,
    setAlignmentOverlay: () => {},
    isSplitingShape: null,
    setIsSplitingShape: () => {},
    isDrawingPolyline: false,
    setIsDrawingPolyline: () => {},
    splitGroupPolylines: [],
    setSplitGroupPolylines: () => {},
    groupTabRef: {},
    productSelectionRef: {},
    isV3: false,
    isValidGPU: { current: true },
    isDisabledBattery: false,
    hasBatteryAdded: false,
    isHybridBattery: false,
    showBatteryVisibility: false,
    googleMapsOverlay: {},
    threejsOverlay: {},
    threeJSMenu: { type: null, visible: false, x: null, y: null, intersections: [], selected: false },
    setThreeJSMenu: () => {},
    hasModeSelected: false,
    marker: {},
    setMarker: () => {},
    saved: false,
    setSaved: () => {},
    blockerPath: {
        location: null,
        submit: () => {},
        type: null,
    },
    setBlockerPath: () => {},
    hasProjectFeature: false,
    toolbarValue: null,
    setToolbarValue: () => {},
    toolbarData: null,
    setToolbarData: () => {},
};
