import { z } from 'zod';
import { createProjectHandler, getProjectHandler } from 'api/projects';
import { Nullable } from 'types/utils';
import { create } from 'zustand';
import { TCreateProjectOAreaPayload, TCreateProjectPayload, TPutProjectPayload } from 'schemas/projects';
import { BASENAME_URL_SOLUTIONS } from 'constants/settings';
import { createJSONStorage, persist } from 'zustand/middleware';
import { PRODUCT_IDS } from 'constants/products';

let createProjectAController: AbortController;
const persistProjectSchema = z.object({
    id: z.string(),
    projecto_id: z.string(),
    instalacao_id: z.string(),
    data_gravacao: z.string(),
    utilizador_id: z.string(),
    ativo: z.boolean(),
    visivel: z.boolean(),
    versao_projeto_produto_inputs_id: z.number().nullable(),
    description: z.string(),
    comments: z.string().nullable(),
    data_criacao: z.string(),
    data_edicao: z.string(),
    tipo_produto_id: z.number(),
    empresa_id: z.number().optional(),
    user_name: z.string().optional(),
});
export const projectSchema = z.intersection(persistProjectSchema, z.object({ inputs: z.any() }));

export const projectsConfigsSchema = z.object({
    feature: z.boolean(),
});

export type TProjectsConfigs = z.infer<typeof projectsConfigsSchema>;
export type TProject = z.infer<typeof projectSchema>;
export type TProductProjectsConfigs = { [key in keyof typeof PRODUCT_IDS]: TProjectsConfigs };

export interface IProjectsStore {
    projects: TProject[];
    selected: Nullable<string>;
    shadowProject: Nullable<string>;
    configs: Nullable<TProductProjectsConfigs>;
    actions: {
        setProjects: (projects: TProject[]) => void;
        updateProject: (project: TProject) => void;
        getProject: (id: string, abortController: AbortController) => Promise<TProject>;
        setNewProject: (project: TProject) => void;
        setSelected: (id: Nullable<string>) => void;
        setShadowProject: (id: Nullable<string>) => void;
        returnToSoluttions: (history: any, pid: string) => void;
        editProjectDetails: (projectDetailsToUpdate: TPutProjectPayload, id: string) => void;
        setFeatures: (feature: Nullable<{ [key in keyof typeof PRODUCT_IDS]: boolean }>) => void;
        createShadowProject: (productID: number, facility_id: number, inputs: any) => Promise<void>;
        createProject: (options: {
            description: string;
            productID: number;
            facility_id: number;
            simulation_id: number;
            visible: boolean;
            inputs: any;
            abortController: AbortController;
        }) => void;
    };
}

const initialConfigs = {
    [PRODUCT_IDS.SPV]: { feature: false },
    [PRODUCT_IDS.SPVSB]: { feature: false },
};

export const useProjects = create<IProjectsStore>()(
    persist(
        (set) => ({
            projects: [],
            selected: null,
            configs: null,
            shadowProject: null,
            actions: {
                setProjects: (projects) => set({ projects }),
                updateProject: (project) => {
                    set((state) => {
                        const projects = state.projects.map((p) => (p.projecto_id === project.projecto_id ? project : p));
                        return { projects };
                    });
                },
                getProject: async (id, abortController) => {
                    try {
                        const project = await getProjectHandler(id, abortController);
                        return project.data;
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                editProjectDetails: (projectDetailsToUpdate, id) => {
                    set((state) => {
                        const project = state.projects.find((p) => p.projecto_id === id);
                        if (!project) {
                            return state;
                        }
                        const updatedProject = { ...project, ...projectDetailsToUpdate };
                        const projects = state.projects.map((p) => (p.projecto_id === id ? updatedProject : p));
                        return { projects };
                    });
                },
                setNewProject: (project) => {
                    set((state) => {
                        return { selected: project.projecto_id, projects: [...state.projects, project] };
                    });
                },
                setSelected: (id) => set({ selected: id }),
                setShadowProject: (id) => set({ shadowProject: id }),
                returnToSoluttions: (history, pid) => {
                    history.push(BASENAME_URL_SOLUTIONS + '?pid=' + pid);
                    // set({ selected: null });
                },
                setFeatures: (feature) => {
                    let configs: Nullable<TProductProjectsConfigs> = null;
                    if (feature) {
                        configs = {
                            ...initialConfigs,
                            ...(Object.entries(feature).reduce((acc, [key, value]) => {
                                return { ...acc, [key]: { feature: value } };
                            }, {}) as TProductProjectsConfigs),
                        };
                    }
                    set({ configs });
                },
                createShadowProject: async (productID, facility_id, inputs) => {
                    try {
                        const newProjectPayload: TCreateProjectPayload = {
                            description: `Shadow_FE_${productID}`,
                            inputs: {
                                ...inputs,
                                fe_version: inputs?.fe_version ?? 'v2',
                            },
                            visible: false,
                            // simulation_id: proposalID,
                        };
                        const rspProj = await createProjectHandler(
                            newProjectPayload,
                            { facilityId: Number(facility_id), productId: productID },
                            createProjectAController
                        );
                        set({ shadowProject: rspProj.data.id });
                    } catch (error) {
                        // notify(intlMessages('page.error.403.unexpected'), 'error');
                    }
                },
                createProject: ({ description, productID, simulation_id, facility_id, inputs, visible, abortController }) => {
                    try {
                        const newProjectPayload: TCreateProjectOAreaPayload = {
                            description,
                            inputs,
                            visible,
                            simulation_id,
                        };
                        createProjectHandler(newProjectPayload, { facilityId: Number(facility_id), productId: productID }, abortController);
                    } catch (error) {
                        // notify(intlMessages('page.error.403.unexpected'), 'error');
                    }
                },
            },
        }),
        {
            name: 'solutions',
            partialize: (state) => ({
                projects: state.projects.map((project) => persistProjectSchema.parse(project)),
                selected: state.selected,
                configs: state.configs,
                shadowProject: state.shadowProject,
            }),
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);
