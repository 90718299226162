import { useEffect, useRef, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.css';
import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CookieConsent from 'react-cookie-consent';
import CookieBanner from 'components/Cookies/CookieBanner';
import CookieBannerOArea from 'components/OArea/CookieBannerOArea';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';
import AppLocale from 'lngProvider';
import CookieConsentContainer from 'components/Cookies/CookieConsent';

import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/pt';
import 'moment/locale/es';
import 'moment/locale/pl';
import 'moment/locale/it';
import 'moment/locale/cs';
import 'moment/locale/pt-br';
import 'moment/locale/de-ch';
import 'moment/locale/el';
import 'moment/locale/en-sg';
import 'moment/locale/en-ie';
import 'moment/locale/fr-ch';
import 'moment/locale/de';
import 'assets/vendors/style';

import MainApp from 'routes/index';
import Login from 'pages/auth/Login';
import ResetPassword from 'pages/auth/ResetPassword';
import Error404 from 'components/errors/Error404';
import { BASENAME_URL, BASENAME_URL_OAREA_PPC_B2B, BASENAME_URL_OAREA_PPC_B2C, BASENAME_URL_SOLUTIONS } from 'constants/settings';
import Loading from 'components/core/Loading';
import IntlMessages from 'components/util/IntlMessages';
import { getIsDarkTheme, getLocalePickers, getTheme } from 'redux/selectors/settings';
import { getCompanyProfileIds, isTokenValid } from 'services/user';
import CssBaseline from '@mui/material/CssBaseline';
import { getAppTheme, getHeadTitle, handleFavicon, isOpenArea, isS2CPlatformVersion } from 'services/settings';
import CacheBuster from 'pages/CacheBuster';
import useRedirectToken from 'hooks/redirectToken';
import muiOverrides from 'constants/muiOverrides';
import CloseButton from 'components/@efz/Notify/CloseButton';
import useCookies from 'hooks/cookies';
import { useMediaQuery } from '@mui/material';
import CustomizeCookiesDialog from 'components/Cookies/CustomizeCookiesDialog';
import CustomizeCookiesOAreaDialog from 'components/OArea/CustomizeCookiesOAreaDialog';
import {
    isFieldDefined,
    isSolarpvPPCOAreaB2C,
    // moveStyledComponentsHeadTagToBottom
} from 'services/util/auxiliaryUtils';
import OARoutes from 'routes/OARoutes';
import useAppSettings from 'hooks/settings/useAppSettings';
import ErrorClock from 'components/errors/ErrorClock';
import Logout from 'pages/auth/Logout';
import { useAuthStore } from 'store/auth';
import { useUserStore } from 'store/user';
import { UserContext } from 'contexts/userContext';
import { Button, EfzThemeProvider } from '@save2compete/efz-design-system';
import { StyledCookieConsent } from 'components/OArea/StylesCookie';
import { StyledToaster } from 'styles/styled/common/StylesToaster';
import NewUpdateDialog from 'components/core/newUpdateDialog';

const CustomRoute = ({ component: Component, ...rest }) => {
    const { isUserAuthenticated, logout } = useAuthStore();
    const dispatch = useDispatch();
    useEffect(() => {
        // Must be in a use effect to avoid updating redux's state whilke rendering this component. Use effect only runs after renders.
        if (!isTokenValid() && isUserAuthenticated && !isOpenArea()) {
            logout(dispatch);
        }
    }, [isUserAuthenticated]); // eslint-disable-line

    if (isTokenValid()) {
        //if (window.gtag instanceof Function) window?.gtag('set', 'User_Type_Efz', userTypeID);
        return <Route {...rest} component={Component} />;
    } else if (isUserAuthenticated) {
        // If userToken is deleted while user is logged in
        localStorage.removeItem('userToken');
        return <Redirect to={{ pathname: BASENAME_URL + 'login' }} />;
    } else {
        // If userToken is deleted by logout click action
        return (
            <Redirect
                to={{
                    pathname: `${BASENAME_URL}${
                        isOpenArea() ?
                            isSolarpvPPCOAreaB2C() ? BASENAME_URL_OAREA_PPC_B2C
                            :   BASENAME_URL_OAREA_PPC_B2B
                        :   'login'
                    }`,
                }}
            />
        );
    }
};

const App = (props) => {
    const matches = useMediaQuery('(min-width:900px)', { noSsr: true });
    const { isMoutingApp, hasNewVersion } = useAppSettings();

    const { location, isDarkTheme, localePickers, isRequestingAuthSSO, headTitle } = props;
    const { theme, locale, user } = useUserStore();
    const userChangedPassword = user?.mudou_password;
    const userID = user?.id;
    const { companyProfileId } = useContext(UserContext);
    const { isRedirectingByToken } = useRedirectToken(location);
    const isS2CVersion = isS2CPlatformVersion();

    //get favicon
    handleFavicon(companyProfileId);

    const currentAppLocale = AppLocale[locale?.appLocale];

    //set localePickers locale (locale fro DatePickers, TimePickers etc)
    moment.locale(localePickers);

    // Gets company theme or get last company theme from LS or defaults to defaultTheme
    const appliedTheme = useRef({ ...createTheme(getAppTheme(companyProfileId, theme)), ...muiOverrides });

    // * COOKIES * \\

    const { setCookie, PLATFORM_CUSTOMIZATION_COOKIE, PERSONALIZED_ADVERTISING_COOKIE, getCookie } = useCookies();

    const cookieConsentRef = useRef();
    const [isCustomizeDialogOpen, setIsCustomizeDialogOpen] = useState(false);

    //Cookies handler to accept consent and other privacy cookies with default values (true)
    const cookiesHandler = () => {
        if (isOpenArea()) {
            setCookie('evergage-ppcsa', true, 365);
        }
        setCookie(PLATFORM_CUSTOMIZATION_COOKIE, true, 355);
        setCookie(PERSONALIZED_ADVERTISING_COOKIE, true, 730);
        cookieConsentRef.current.accept();
        window.location.reload();
    };

    const cookiesEssentialHandler = () => {
        setCookie(PLATFORM_CUSTOMIZATION_COOKIE, !isOpenArea(), 355);
        setCookie(PERSONALIZED_ADVERTISING_COOKIE, false, 730);
        cookieConsentRef.current.accept();
        window.location.reload();
    };

    //componentDidMountWithUseEffect
    useEffect(() => {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    }, []); //eslint-disable-line

    // * THEME SELECTION * \\

    // Can't be inside a useEffect because it only triggers after the component finishes rendering, and we need the theme when it's rendering
    if (isDarkTheme) {
        appliedTheme.current = createTheme({
            ...appliedTheme.current,
            palette: { ...appliedTheme.current.palette, mode: 'dark' },
        });
    } /* if (userID) */ else {
        // Material UI theme set
        appliedTheme.current = createTheme(getAppTheme(companyProfileId, theme));
        appliedTheme.current = {
            ...appliedTheme.current,
            ...muiOverrides,
        };
        appliedTheme.current.locale = locale.locale;
    }

    // Set CSS Theme colors //TODO:verificar com o themeAPI
    let bodyStyles = document.body.style;
    bodyStyles.setProperty('--primary-color', appliedTheme.current.palette.primary.main);
    bodyStyles.setProperty('--primary-color-light', appliedTheme.current.palette.primary.light);
    bodyStyles.setProperty('--primary-color-dark', appliedTheme.current.palette.primary.dark);
    bodyStyles.setProperty('--primary-color-bleached', appliedTheme.current.palette.primary.bleached);
    bodyStyles.setProperty('--secondary-color', appliedTheme.current.palette.secondary.main);
    bodyStyles.setProperty('--secondary-color-bleached', appliedTheme.current.palette.secondary.bleached);
    bodyStyles.setProperty('--secondary-color-light', appliedTheme.current.palette.secondary.light);
    bodyStyles.setProperty('--secondary-color-dark', appliedTheme.current.palette.secondary.dark);

    bodyStyles.setProperty(
        '--tertiary-color',
        appliedTheme?.current?.palette?.tertiary?.main ?? companyProfileId === getCompanyProfileIds().PPC ?
            'rgba(3, 141, 90, 1)'
        :   'rgba(255, 255, 255, 1)'
    );
    bodyStyles.setProperty(
        '--tertiary-color-bleached',
        appliedTheme?.current?.palette?.tertiary?.bleached ?? companyProfileId === getCompanyProfileIds().PPC ?
            'rgba(3, 141, 90, 0.05)'
        :   'rgba(255, 255, 255, 0.05)'
    );
    // bodyStyles.setProperty('--tertiary-color-dark', appliedTheme.current.palette.tertiary.dark);

    bodyStyles.setProperty('--text-color', appliedTheme.current.palette.text.primary);
    bodyStyles.setProperty('--text-color-secondary', appliedTheme.current.palette.text.secondary);
    bodyStyles.setProperty('--divider-color', appliedTheme.current.palette.divider);
    bodyStyles.setProperty('--disabled-color', appliedTheme.current.palette.action.disabled);
    bodyStyles.setProperty('--disabled-bg-color', appliedTheme.current.palette.action.disabledBackground);

    // Manage redirection rules if user is at home
    const redirectRules = () => {
        let pathname = location?.pathname;

        // PPC B2C || B2B OArea
        if (isOpenArea() && ['', '/'].includes(pathname)) {
            return isSolarpvPPCOAreaB2C() ? BASENAME_URL_OAREA_PPC_B2C : BASENAME_URL_OAREA_PPC_B2B;
        }

        if ((isFieldDefined(userID) && ['/login'].includes(pathname)) || !userChangedPassword) {
            return BASENAME_URL_SOLUTIONS;
        }

        return BASENAME_URL_SOLUTIONS;
    };
    redirectRules();
    // moveStyledComponentsHeadTagToBottom();

    return (
        <CacheBuster location={location}>
            <ThemeProvider theme={appliedTheme.current}>
                <EfzThemeProvider theme={appliedTheme.current}>
                    <LocalizationProvider dateAdapter={MomentUtils} adapterLocale={localePickers}>
                        <IntlProvider locale={currentAppLocale?.locale} messages={currentAppLocale?.messages}>
                            <CssBaseline />
                            <div className="app-main">
                                <Helmet>
                                    <title>{headTitle}</title>
                                </Helmet>
                                {
                                    !isRequestingAuthSSO && !isRedirectingByToken && !isMoutingApp ?
                                        <>
                                            <Switch>
                                                <RestrictedRoute path={`${BASENAME_URL}app`} component={MainApp} />
                                                <Route path={`${BASENAME_URL}login`} component={Login} />
                                                <Route
                                                    path={`${BASENAME_URL}${BASENAME_URL_OAREA_PPC_B2C}`}
                                                    render={(props) => <OARoutes {...props} isB2C={true} />}
                                                />
                                                <Route
                                                    path={`${BASENAME_URL}${BASENAME_URL_OAREA_PPC_B2C}/steps/:id`}
                                                    render={(props) => <OARoutes {...props} isB2C={true} />}
                                                />
                                                <Route
                                                    path={`${BASENAME_URL}${BASENAME_URL_OAREA_PPC_B2B}`}
                                                    render={(props) => <OARoutes {...props} isB2C={false} />}
                                                />
                                                <Route
                                                    path={`${BASENAME_URL}${BASENAME_URL_OAREA_PPC_B2B}/steps/:id`}
                                                    render={(props) => <OARoutes {...props} isB2C={false} />}
                                                />
                                                <Route path={`${BASENAME_URL}cookie-policies`} component={CookieConsentContainer} />
                                                <Route path={`${BASENAME_URL}reset-password`} component={ResetPassword} />
                                                <Route path={`${BASENAME_URL}app/404`} component={Error404} />
                                                <Route exact path={`${BASENAME_URL}app/error`} component={ErrorClock} />
                                                <Route exact path={`${BASENAME_URL}app/logout`} component={Logout} />
                                                <Redirect to={redirectRules()} />
                                            </Switch>
                                            <StyledToaster autoClose={2500} hideProgressBar newestOnTop={true} closeButton={CloseButton} />
                                            {!getCookie('CookieConsent') && (
                                                <StyledCookieConsent>
                                                    <CookieConsent
                                                        ref={cookieConsentRef}
                                                        buttonStyle={{ display: 'none' }}
                                                        containerClasses={'cookie-banner'}
                                                        disableStyles={true}
                                                        buttonClasses={`btn cookie-banner-button MuiButton-root MuiButton-contained ${isS2CVersion ? 'isS2C' : ''}`}
                                                        buttonText={<IntlMessages id="label.cookies.acceptCookies" />}
                                                        overlayClasses="cookie"
                                                        overlay
                                                    >
                                                        <div className={`${matches ? 'd-flex' : ' '}`}>
                                                            {isOpenArea() ?
                                                                <CookieBannerOArea />
                                                            :   <CookieBanner />}
                                                            <div
                                                                className={`d-flex justify-content-center gap-2 ${matches ? 'flex-column ' : 'pb-4 gap-3'}`}
                                                            >
                                                                <Button onClick={cookiesHandler} datatype>
                                                                    <IntlMessages id="label.cookies.acceptCookies" />
                                                                </Button>
                                                                {isOpenArea() && (
                                                                    <Button
                                                                        variant="secondary"
                                                                        onClick={cookiesEssentialHandler}
                                                                        dataTestId
                                                                    >
                                                                        <IntlMessages id="label.cookies.acceptCookiesEssential" />
                                                                    </Button>
                                                                )}
                                                                <Button
                                                                    variant="tertiary"
                                                                    onClick={() => setIsCustomizeDialogOpen(true)}
                                                                    dataTestId="customize-cookies"
                                                                >
                                                                    <IntlMessages id="label.cookies.privacySettings" />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </CookieConsent>
                                                </StyledCookieConsent>
                                            )}
                                            {!isOpenArea() ?
                                                isCustomizeDialogOpen && (
                                                    <CustomizeCookiesDialog
                                                        isOpen={isCustomizeDialogOpen}
                                                        setIsOpen={setIsCustomizeDialogOpen}
                                                        cookieBannerHandler={cookieConsentRef.current}
                                                    />
                                                )
                                            :   isCustomizeDialogOpen && (
                                                    <CustomizeCookiesOAreaDialog
                                                        isOpen={isCustomizeDialogOpen}
                                                        setIsOpen={setIsCustomizeDialogOpen}
                                                        cookieBannerHandler={cookieConsentRef.current}
                                                    />
                                                )
                                            }
                                        </>
                                        //AutoLoginWait...
                                    :   <div className="app-main-login d-flex justify-content-center align-items-center w-100">
                                            <Loading />
                                        </div>

                                }
                                {hasNewVersion && <NewUpdateDialog />}
                            </div>
                        </IntlProvider>
                    </LocalizationProvider>
                </EfzThemeProvider>
            </ThemeProvider>
        </CacheBuster>
    );
};

const mapStateToProps = ({ settings }) => {
    return {
        isDarkTheme: getIsDarkTheme(settings),
        theme: getTheme(settings),
        localePickers: getLocalePickers(settings), //re-render when change lang
        headTitle: getHeadTitle(settings),
    };
};

const RestrictedRoute = connect()(CustomRoute);

export default connect(mapStateToProps)(App);
