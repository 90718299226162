/**
 * @author Victor Andrade <victor.andrade@caixamagica.pt>,
 *
 * @description API requests to the context of Auth (LogIn, Registry)
 *
 * @version 20190715
 * @since 20190715 Initial release
 *
 */

import axios, { axiosSSOInstance } from 'services/@efz/axios';
import { encode } from 'js-base64';

import { getEndpoint, URL_AUTH, URL_LOGOUT_ADFS, URL_TOKEN, URL_USERS } from 'constants/endpoints';
import { getMessageNetworkError } from 'services/statusCodeHttp';
import { isDefined } from 'services/util/auxiliaryUtils';
import { apiRspGetTokenSchema } from 'schemas/auth';
import { TGetToken } from 'types/auth';
import { AxiosInstance } from 'axios';

/**
 * LogIn
 *
 * @param data
 * @type Object {username: string, password: string}
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const auth = (data) => {
    const { username, password } = data;
    return axios
        .get(getEndpoint(URL_AUTH), {
            headers: {
                Authorization: 'Basic ' + encode(username + ':' + password),
                'X-Platform': 's2c',
                timeout: 15000,
            },
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * Registry
 *
 * @param data type Object
 * @type Object { firstName: string, lastName: string, email: string, phone: string, nipc: string, cpe: string }
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const registry = (data) => {
    return axios
        .post(`${getEndpoint(URL_AUTH)}`, data, {
            headers: {
                Authorization: 'Basic ' + encode(process.env.REACT_APP_REGISTRY_BASE64_STR as string),
                'X-Platform': 's2c',
                timeout: 15000,
            },
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * Password Reset
 *
 * @param payload type Object
 * @type Object { email: string }
 * @returns {Promise<AxiosResponse<T> | never>}
 */
export const getResetPassword = (payload) => {
    return axios
        .get(getEndpoint(URL_USERS) + '/credentials-email/' + payload, {
            headers: {
                Authorization: 'Basic ' + encode(process.env.REACT_APP_REGISTRY_BASE64_STR as string),
                'X-Platform': 's2c',
                timeout: 15000,
            },
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

/**
 * Logout- invalidates token
 */
export const getLogout = (token) => {
    return axios
        .get(getEndpoint(URL_AUTH) + '/logout', {
            headers: {
                Authorization: 'JWT ' + token,
            },
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getLogoutADFS = (token, nameid, sessionindex, id_empresa) => {
    const qData = isDefined(nameid) ? `?nameid=${nameid}&sessionindex=${sessionindex}` : '';

    return axios
        .get(`${getEndpoint(URL_LOGOUT_ADFS)}${id_empresa}/logout${qData}`, {
            headers: {
                Authorization: `JWT ${token}`,
                'X-Platform': 's2c',
                timeout: 15000,
            },
        })
        .then((response) => response)
        .catch((error) => (error.response ? error.response : getMessageNetworkError()));
};

export const getToken = async (sessionId, abortController) => {
    const getTokenAxiosInstance: AxiosInstance = axiosSSOInstance;
    getTokenAxiosInstance.defaults.headers['Session-id'] = sessionId;
    try {
        const response = await getTokenAxiosInstance.request({
            url: getEndpoint(URL_TOKEN),
            method: 'GET',
            signal: abortController?.signal,
        });
        return apiRspGetTokenSchema.parse(response.data) as TGetToken;
    } catch (error) {
        return Promise.reject(error);
    }
};
