import { Nullable } from 'types/utils';
import { create } from 'zustand';
// import { devtools } from 'zustand/middleware';
export const FEATURE_FLAGS = [
    2005, 1894, 1961, 1992, 2168 /*in prd but OFF*/, 2337, 2336, 2339, 2376, 2359, 2358, 2396, 2317, 2397, 2362, 2434,
    1871 /* #1871 in prd but hidden  */, 2516, 2503, 2438, 2514, 2508, 2510, 2521, 2531, 2527, 2553, 2529, 2442, 2550, 2526, 2542, 2536,
    2523, 2565,
] as const;

type FeatureFlag = (typeof FEATURE_FLAGS)[number];

export const FEATURE_FLAGS_TST: FeatureFlag[] = [2168, 2337, 2336, 2359, 2358, 2396, 2317, 2397, 2362];

type FeatureFlagKeys = (typeof FEATURE_FLAGS)[number] | (typeof FEATURE_FLAGS_TST)[number];

export type TFeatureFlags = {
    [K in `fe-${FeatureFlagKeys}`]: boolean;
};
interface IFeatureFlags {
    featureFlags: TFeatureFlags;
    id: Nullable<number>;
    setFeatureFlags: (featureFlags: TFeatureFlags, id: Nullable<number>) => void;
}

export const initialFeatureFlags = [...FEATURE_FLAGS, ...FEATURE_FLAGS_TST].reduce((acc, flag) => {
    acc[`fe-${flag}`] = false;
    return acc;
}, {} as TFeatureFlags);

export const useFeatureFlags = create<IFeatureFlags>()(
    // devtools(
    (set) => ({
        featureFlags: initialFeatureFlags,
        id: null,
        setFeatureFlags: (featureFlags: TFeatureFlags, id) => set({ featureFlags, id }),
    })
    // )
);
