import { SolarpvTimelineActions } from 'constants/products/solarpv';
import { ISpvTimelineAction } from 'interfaces/products/solarpv/index';
import { TTimelineAction } from 'schemas/products/spv';
import { Nullable } from 'types/utils';

const LIMIT_TIMELINE_ACTIONS = 20;

export type TSolarpvTimelineState = {
    current: Nullable<TTimelineAction>;
    front: TTimelineAction[];
    back: TTimelineAction[];
};
export const initialSolarpvTimelineReducer: TSolarpvTimelineState = {
    current: null,
    front: [],
    back: [],
};

export const spvTimelineReducer = (state = initialSolarpvTimelineReducer, action: ISpvTimelineAction) => {
    switch (action.type) {
        case SolarpvTimelineActions.SET_ACTION: {
            const { isInitial = false, ...rest } = action.payload;
            const _isInitial = isInitial;
            return {
                ...state,
                current: rest,
                front: [],
                back: _isInitial ? [] : [{ ...state.current }, ...state.back].filter((action, index) => index < LIMIT_TIMELINE_ACTIONS),
            };
        }
        case SolarpvTimelineActions.UPDATE_ACTION: {
            return {
                ...state,
                current: {
                    ...state.current,
                    ...action.payload,
                },
            };
        }
        case SolarpvTimelineActions.UNDO: {
            return {
                ...state,
                current: state.back[0],
                front: [state.current, ...state.front],
                back: state.back.slice(1),
            };
        }
        case SolarpvTimelineActions.REDO: {
            return {
                ...state,
                current: state.front[0],
                back: [state.current, ...state.back],
                front: state.front.slice(1),
            };
        }
        default: {
            return state;
        }
    }
};
