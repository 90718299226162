import { TModesConfig } from 'interfaces/products/solarpv/index';
import validate from 'services/util/validate';
import { TIntl } from 'types/utils';

export enum SpvActions {
    SET_INIT = 'SET_INIT',
    SET_IS_MOUNTING = 'SET_IS_MOUNTING',
    SET_AUTO_UPDATE_KPIS = 'SET_AUTO_UPDATE_KPIS',
    SET_IS_COMPUTE_KPIS = 'SET_IS_COMPUTE_KPIS',
    SET_SIMULATIONS = 'SET_SIMULATIONS',
    RESET_BUNDLE_SIMS = 'RESET_BUNDLE_SIMS',
    SET_CHART_PRODUCTIONS = 'SET_CHART_PRODUCTIONS',
    SET_CHART_CONSUMPTIONS = 'SET_CHART_CONSUMPTIONS',
    SET_LOADINGS = 'SET_LOADINGS',
    RESET_LOADINGS = 'RESET_LOADINGS',
    SET_MODES = 'SET_MODES',
    SET_HAS_UPDATED_KPIS = 'SET_HAS_UPDATED_KPIS',
    SET_TAGS = 'SET_TAGS',
    SET_DELETE_ALL_GROUPS = 'SET_DELETE_ALL_GROUPS',
    RESET_INPUTS = 'RESET_INPUTS',
    RESET_TAGS = 'RESET_TAGS',
    SET_INPUTS = 'SET_INPUTS',
    SET_RANGE_ID = 'SET_RANGE_ID',
    SET_NETWORK_SALE = 'SET_NETWORK_SALE',
    SET_INJECTION_TARIFF = 'SET_INJECTION_TARIFF',
    SET_INJECTION_LIMITATION = 'SET_INJECTION_LIMITATION',
    SET_REMUNERATION_MODEL = 'SET_REMUNERATION_MODEL',
    SET_BATTERY = 'SET_BATTERY',
    DELETE_BATTERY = 'DELETE_BATTERY',
    SET_BATTERY_POWERS = 'SET_BATTERY_POWERS',
    SET_COMPLETE_BATTERY_POWERS = 'SET_COMPLETE_BATTERY_POWERS',
    SET_RANGES = 'SET_RANGES',
    SET_INVERTERS_LIST = 'SET_INVERTERS_LIST',
    SET_INVERTERS_LIST_WITH_COSTS = 'SET_INVERTERS_LIST_WITH_COSTS',
    SET_DIALOG = 'SET_DIALOG',
    SET_SELECT_AREA_v2 = 'SET_SELECT_AREA_v2',
    ADD_NEW_GROUP = 'ADD_NEW_GROUP',
    SET_SELECT_GROUP = 'SET_SELECT_GROUP',
    SET_CHARTS_TAG = 'SET_CHARTS_TAG',
    SET_SENSIBILITY_DATA = 'SET_SENSIBILITY_DATA',
    SET_SENSIBILITY_DATA_BATTERY = 'SET_SENSIBILITY_DATA_BATTERY',
    SET_VISIBLE_CHART = 'SET_VISIBLE_CHART',
    SET_SENSIBILITY_POINTS_TYPE = 'SET_SENSIBILITY_POINTS_TYPE',
    HIDE_PROJECT_SUMMARY = 'HIDE_PROJECT_SUMMARY',
    STAMP = 'STAMP',
    SET_DRAWN_PANELS = 'SET_DRAWN_PANELS',
    SET_GROUP_TAB = 'SET_GROUP_TAB',
    SET_PRODUCT_SELECTION = 'SET_PRODUCT_SELECTION',
    SET_PRODUCT_SELECTION_INTERSECTION = 'SET_PRODUCT_SELECTION_INTERSECTION',
    APPLY_EXCLUSIONS_CHANGES = 'APPLY_EXCLUSIONS_CHANGES',
    UPDATE_EXCLUSION_GROUPS = 'UPDATE_EXCLUSION_GROUPS',
    SET_SELECT_EXCLUSION = 'SET_SELECT_EXCLUSION',
    DISCARD_EXCLUSIONS_CHANGES = 'DISCARD_EXCLUSIONS_CHANGES',
    UPDATE_BUILDING_GROUPS = 'UPDATE_BUILDING_GROUPS',
    UPDATE_BUILDINGS = 'UPDATE_BUILDINGS',
    UPDATE_BUILDINGS_DS = 'UPDATE_BUILDINGS_DS',
    SET_SELECT_BUILDING = 'SET_SELECT_BUILDING',
    SET_SLOPES = 'SET_SLOPES',
    SET_ORIENTATION = 'SET_ORIENTATION',
    SET_ALIGNMENT = 'SET_ALIGNMENT',
    SET_MAX_SIZE = 'SET_MAX_SIZE',
    SET_OPTIMIZER = 'SET_OPTIMIZER',
    SET_MOUNTING_STRUCTURE_ID = 'SET_MOUNTING_STRUCTURE_ID',
    SET_USEFUL_AREA = 'SET_USEFUL_AREA',
    DELETE_GROUP = 'DELETE_GROUP',
    DELETE_ALL_GROUPS = 'DELETE_ALL_GROUPS',
    SET_INVERTERS = 'SET_INVERTERS',
    SET_PRODUCTION_LOSSES = 'SET_PRODUCTION_LOSSES',
    SET_ROW_SPACE = 'SET_ROW_SPACE',
    SET_PANEL_REPRESENTATION = 'SET_PANEL_REPRESENTATION',
    UPDATE_GROUP = 'UPDATE_GROUP',
    SET_MAP_PRINT_STATE = 'SET_MAP_PRINT_STATE',
    NEXT_BUSINESS_MODELS = 'NEXT_BUSINESS_MODELS',
    FETCH_GET_EXPORT_SPVDATA = 'FETCH_GET_EXPORT_SPVDATA',
    SET_RANGE_PANEL_ID = 'SET_RANGE_PANEL_ID',
    SET_INVERTERS_COMBINATION = 'SET_INVERTERS_COMBINATION',
    SET_IS_SIMPLE_MODE = 'SET_IS_SIMPLE_MODE',
    SET_SIMPLE_MODE_STEP = 'SET_SIMPLE_MODE_STEP',
    SET_SIMPLE_MODE_ROOF_IDENTIFICATION = 'SET_SIMPLE_MODE_ROOF_IDENTIFICATION',
    RESET_SIMPLE_MODE = 'RESET_SIMPLE_MODE',
    SET_SELECTED_ROOF_TYPE_ID = 'SET_SELECTED_ROOF_TYPE_ID',
    APPLY_AUTO_SPLIT_FINAL = 'APPLY_AUTO_SPLIT_FINAL',
    APPLY_AUTO_SPLIT = 'APPLY_AUTO_SPLIT',
    SET_SIMPLE_SIMULATIONS = 'SET_SIMPLE_SIMULATIONS',
    SET_SPV_SIMPLE_AUTODETECT_POLYGON = 'SET_SPV_SIMPLE_AUTODETECT_POLYGON',
    APPLY_SIMPLE_AUTO_SPLIT = 'APPLY_SIMPLE_AUTO_SPLIT',
    PANEL_EDITOR = 'PANEL_EDITOR',
    ADD_NEW_SIMPLE_GROUP = 'ADD_NEW_SIMPLE_GROUP',
    SET_SIMPLE_ROOF_POLYGON = 'SET_SIMPLE_ROOF_POLYGON',
    APPLY_SIMPLE_AUTO_SPLIT_FINAL = 'APPLY_SIMPLE_AUTO_SPLIT_FINAL',
    SET_SIMPLE_FACILITY_COORDINATES = 'SET_SIMPLE_FACILITY_COORDINATES',
    SET_SIMPLE_ROOF_STRUCTURES = 'SET_SIMPLE_ROOF_STRUCTURES',
    SET_PROJECT = 'SET_PROJECT',
    START_LOADING_PROJECT = 'START_LOADING_PROJECT',
    LOAD_NEW_PROJECT = 'LOAD_NEW_PROJECT',
    RESET_PROJECT = 'RESET_PROJECT',
    EDIT_PROJECT_DETAILS = 'EDIT_PROJECT_DETAILS',
    SAVE_CUSTOMIZED_PROJECT = 'SAVE_CUSTOMIZED_PROJECT',
    SET_VISIBILITY_ELEMENT = 'SET_VISIBILITY_ELEMENT',
    SET_IS_REQUEST_ROOF_FAIL = 'SET_IS_REQUEST_ROOF_FAIL',
    SET_SHORTCUT = 'SET_SHORTCUT',
    SET_SELECTED_LAYER_NAME = 'SET_SELECTED_LAYER_NAME',
    SET_ARE_LAYERS_DOWNLOADED = 'SET_ARE_LAYERS_DOWNLOADED',
    SET_MAP_LABELS = 'SET_MAP_LABELS',
    GET_GSOLAR_LAYERS = 'GET_GSOLAR_LAYERS',
    SET_MAP_SELECTED_LAYER = 'SET_MAP_SELECTED_LAYER',
    SET_POLYGONS_TO_PASTE = 'SET_POLYGONS_TO_PASTE',
    SET_COPY_PROPERTIES = 'SET_COPY_PROPERTIES',
    SET_PV_KITS_B2C = 'SET_PV_KITS_B2C',
}

export enum SolarpvTimelineActions {
    SET_ACTION = 'SET_ACTION',
    UPDATE_ACTION = 'UPDATE_ACTION',
    UNDO = 'UNDO',
    REDO = 'REDO',
}

export enum SpvLoadings {
    simulation = 'simulation',
    changeAddress = 'changeAddress',
    usefulArea = 'usefulArea',
    minRowDistance = 'minRowDistance',
    estimatedStructures = 'estimatedStructures',
    autoExclusions = 'autoExclusions',
    autoBuildings = 'autoBuildings',
    buildings = 'buildings',
    autoSplit = 'autoSplit',
    productions = 'productions',
    consumptions = 'consumptions',
    spvData = 'spvData',
    sensibilityAnalysis = 'sensibilityAnalysis',
    sensibilityAnalysisBattery = 'sensibilityAnalysisBattery',
    inverters = 'inverters',
    spvSimpleKpisRequests = 'spvSimpleKpisRequests',
    spvSimpleRoofIdentification = 'spvSimpleRoofIdentification',
    loadProject = 'loadProject',
    loadLayers = 'loadLayers',
}

export enum SpvTags {
    info = 'info',
    error = 'error',
}

export enum SPV_OVERLAY_MODES {
    MOVE = 'move',
    ORIENTATION = 'orientation',
    PANEL_EDITOR = 'panelEditor',
    STAMP = 'stamp',
    ALIGNMENT = 'alignmment',
    ADDRESS = 'address',
    MAP_OPTIONS = 'mapOptions',
    COPY_PROPERTIES = 'copyProperties',
    // TODO = add more modes here...
}

export const SPV_OVERLAY_MODES_CONFIG: TModesConfig = {
    [SPV_OVERLAY_MODES.MOVE]: {
        name: SPV_OVERLAY_MODES.MOVE,
        title: 'page.spvPro.mode.move.title',
        subtitle: ['page.spvPro.mode.move.subtitle'],
        button: 'label.exit',
    },
    [SPV_OVERLAY_MODES.ORIENTATION]: {
        name: SPV_OVERLAY_MODES.ORIENTATION,
        title: 'page.spvPro.mode.orientation.title',
        subtitle: ['page.spvPro.label.orientationLign.topInfo', 'page.spvPro.label.orientationLign.bottomInfo.1'],
        link: 'page.spvPro.label.orientationLign.bottomInfo.2',
        dialogName: 'editOrientation',
        button: 'label.exit',
    },
    [SPV_OVERLAY_MODES.PANEL_EDITOR]: {
        name: SPV_OVERLAY_MODES.PANEL_EDITOR,
        title: 'page.spvPro.mode.addRemovePanels.title',
        subtitle: 'page.spvPro.mode.addRemovePanels.subTitle',
        button: 'label.exit',
    },
    [SPV_OVERLAY_MODES.STAMP]: {
        name: SPV_OVERLAY_MODES.STAMP,
        title: 'page.spvPro.mode.stamp.title',
        subtitle: ['page.spvPro.mode.stamp.subtitle'],
        button: 'label.exit',
    },
    [SPV_OVERLAY_MODES.ALIGNMENT]: {
        name: SPV_OVERLAY_MODES.ALIGNMENT,
        title: 'page.spvPro.mode.alignment.title',
        subtitle: ['page.spvPro.mode.alignment.subtitle'],
        button: 'label.exit',
    },
    [SPV_OVERLAY_MODES.ADDRESS]: {
        name: SPV_OVERLAY_MODES.ADDRESS,
        title: 'label.editAddress',
        subtitle: 'label.selectedGroup',
        button: 'label.confirmAddress',
    },
    [SPV_OVERLAY_MODES.MAP_OPTIONS]: {
        name: SPV_OVERLAY_MODES.MAP_OPTIONS,
        title: 'label.mapProvider',
        subtitle: 'label.mapProvider',
        button: 'page.spvPro.label.applyChanges',
    },
    [SPV_OVERLAY_MODES.COPY_PROPERTIES]: {
        name: SPV_OVERLAY_MODES.COPY_PROPERTIES,
        title: 'page.spvPro.mode.copyProperties.label',
        subtitle: 'page.spvPro.mode.copyProperties.label',
        button: 'page.spvPro.label.applyChanges',
    },
};

export enum SYSTEM_SETTINGS_TABS {
    EQUIPMENT = 'equipment',
    ENVIRONMENT = 'environment',
}

export enum SYSTEM_SETTINGS_GROUPS {
    PANELS = 'panels',
    REMOTE = 'remote',
    BATTERY = 'battery',
    EXCLUSIONS = 'exclusions',
    BUILDINGS = 'buildings',
}

export enum SpvDialogs {
    deletePanels = 'deletePanels',
    deleteAllPanels = 'deleteAllPanels',
    editStructure = 'editStructure',
    editSlope = 'editSlope',
    editPanelRepresentation = 'editPanelRepresentation',
    editOrientation = 'editOrientation',
    inverters = 'inverters',
    detailedCosts = 'detailedCosts',
    productionLosses = 'productionLosses',
    optimization = 'optimization',
    graph = 'graph',
    autoExclusions = 'autoExclusions',
    autoBuildings = 'autoBuildings',
    autoSplit = 'autoSplit',
    exclusionsConfirmation = 'exclusionsConfirmation',
    remotesConfimation = 'remotesConfimation',
    warnDataBatteryDelete = 'warnDataBatteryDelete',
    imgContract = 'imgContract',
    switchToSimpleMode = 'switchToSimpleMode',
    deleteExclusions = 'deleteExclusions',
    deleteAllExclusions = 'deleteAllExclusions',
    editBuilding = 'editBuilding',
    googleSolarLayers = 'googleSolarLayers',
    deleteBuilding = 'deleteBuilding',
    deleteAllBuildings = 'deleteAllBuildings',
}

export enum CHARTS_IDS {
    CONSUMPTIONS_PRODUCTIONS = 1,
    SENSIBILITY_ANALYSIS = 2,
}

export enum SIMPLE_MODE_STEPS {
    ADDRESS = 1,
    DRAW_ROOF_MANUALLY = 2,
    ROOF_IDENTIFICATION = 3,
    ROOF_DETAILS = 4,
    KPIS_DISPLAY = 5,
    BUSINESS_MODELS = 6,
}

export enum SPV_SIMPLE_MODE_INPUTS {
    ROOF_TYPE_ID = 'roofTypeId',
}

export const SPV_SIMPLE_MODE_QUESTIONS = {
    [SPV_SIMPLE_MODE_INPUTS.ROOF_TYPE_ID]: {
        name: SPV_SIMPLE_MODE_INPUTS.ROOF_TYPE_ID,
        label: 'page.spvSimple.roofDetails.roofType.label',
        placeholder: 'page.spvSimple.roofDetails.roofType.placeholder',
        type: 'string',
        defaultValue: '',
        visible: true,
        validation: validate(['required']),
    },
};

export enum SPV_SIMPLE_ROOF_TYPES_IDS {
    PITCHED_ROOF = 1,
    FLAT_ROOF = 2,
}

export enum BUILDING_ROOF_TYPE_IDS {
    ROOF_TILE = 1,
    FLAT_ROOF = 2,
}

export enum SolarpvShortcuts {
    DELETE = 'delete',
    ESCAPE = 'escape',
}

export enum COPY_PROPERTIES_SVGS {
    SELECTED = 'selectedSvgCopyProperties',
    UNSELECTED = 'unselectedSvgCopyProperties',
}

export const SIMPLE_RADIO_BTN_OPTIONS: { label: TIntl; value: boolean }[] = [
    {
        label: 'label.solarAdvanced',
        value: false,
    },
    {
        label: 'label.solarSimple',
        value: true,
    },
];

export const SIMPLE_ROOF_STRUCTURES = [
    {
        roof_type_id: 1,
        roof_type: 'PITCHED_ROOF',
        img_name: 'tilted_roof_spv_simple.png',
    },
    {
        roof_type_id: 2,
        roof_type: 'FLAT_ROOF',
        img_name: 'flat_roof_spv_simple.png',
    },
];

export const useFullAreaErrors = ['UA_MAX_SIZE'];

export const optimizerErrors = ['NO_SOLUTIONS'];

export const PAP_BATTERY_LINK = 'https://kwiki.corp.edp.com/bateria-solar-edp-bronze/pricing';

export const SPSB_BATTERY_IMAGE = 'https://kwiki.corp.edp.com/sites/kwikipt/files/2024-01/Pricing_Storage_AaS_0.png';

export const GSOLAR_ANNUAL_FLUX_COLORS = ['00000A', '91009C', 'E64616', 'FEB400', 'FFFFF6'];

export const GOOGLE_SOLAR_LAYERS: { name: string; label: TIntl }[] = [
    { name: 'rgb', label: 'label.aerialImage' },
    { name: 'annualFlux', label: 'label.annualSunshine' },
];

export const dummyProjects = [
    {
        id: '445',
        projecto_id: '445',
        instalacao_id: '567883',
        inputs: {
            areas: [
                {
                    id: 1,
                    lat: 41.186144158001355,
                    area: 435.1431263231753,
                    long: -8.48851291740686,
                    edges: [
                        {
                            aspect: 49.480200361030604,
                            points: [
                                {
                                    lat: 41.1860521,
                                    lng: -8.4887187,
                                },
                                {
                                    lat: 41.186038599999996,
                                    lng: -8.4887035,
                                },
                            ],
                        },
                        {
                            aspect: -41.610839990283644,
                            points: [
                                {
                                    lat: 41.186038599999996,
                                    lng: -8.4887035,
                                },
                                {
                                    lat: 41.18601160000001,
                                    lng: -8.488673,
                                },
                            ],
                        },
                        {
                            aspect: -130.44565838115057,
                            points: [
                                {
                                    lat: 41.18601160000001,
                                    lng: -8.488673,
                                },
                                {
                                    lat: 41.1860049,
                                    lng: -8.4886653,
                                },
                            ],
                        },
                        {
                            aspect: 170.68635433153466,
                            points: [
                                {
                                    lat: 41.1860049,
                                    lng: -8.4886653,
                                },
                                {
                                    lat: 41.186082,
                                    lng: -8.4885456,
                                },
                            ],
                        },
                        {
                            aspect: 141.1543891066709,
                            points: [
                                {
                                    lat: 41.186082,
                                    lng: -8.4885456,
                                },
                                {
                                    lat: 41.1861688,
                                    lng: -8.4884109,
                                },
                            ],
                        },
                        {
                            aspect: 131.00873260257197,
                            points: [
                                {
                                    lat: 41.1861688,
                                    lng: -8.4884109,
                                },
                                {
                                    lat: 41.1862074,
                                    lng: -8.488351,
                                },
                            ],
                        },
                    ],
                    slope: 5,
                    aspect: -40.27116805574741,
                    selected: true,
                    panel_rows: [
                        {
                            positions: [
                                [
                                    [41.186012273946346, -8.48866562329768],
                                    [41.186024001893145, -8.488647297971392],
                                    [41.186030818748634, -8.488654942428427],
                                    [41.186019090801835, -8.488673267754715],
                                ],
                                [
                                    [41.18607382384085, -8.488569449438872],
                                    [41.186085551787656, -8.488551124112584],
                                    [41.18609236864314, -8.48855876856962],
                                    [41.18608064069634, -8.488577093895909],
                                ],
                                [
                                    [41.18608846194815, -8.488546576885216],
                                    [41.18610018989495, -8.488528251558929],
                                    [41.18610700675044, -8.488535896015966],
                                    [41.186095278803634, -8.488554221342254],
                                ],
                                [
                                    [41.18610018989495, -8.488528251558929],
                                    [41.18611191784176, -8.488509926232641],
                                    [41.18611873469724, -8.488517570689677],
                                    [41.18610700675044, -8.488535896015966],
                                ],
                                [
                                    [41.18611191784176, -8.488509926232641],
                                    [41.186123645788555, -8.488491600906352],
                                    [41.186130462644044, -8.48849924536339],
                                    [41.18611873469724, -8.488517570689677],
                                ],
                                [
                                    [41.18612655594905, -8.488487053678986],
                                    [41.18613828389586, -8.488468728352698],
                                    [41.18614510075134, -8.488476372809734],
                                    [41.18613337280454, -8.488494698136023],
                                ],
                                [
                                    [41.18613828389586, -8.488468728352698],
                                    [41.186150011842656, -8.488450403026409],
                                    [41.186156828698145, -8.488458047483446],
                                    [41.18614510075134, -8.488476372809734],
                                ],
                                [
                                    [41.186150011842656, -8.488450403026409],
                                    [41.18616173978946, -8.488432077700121],
                                    [41.186168556644944, -8.488439722157159],
                                    [41.186156828698145, -8.488458047483446],
                                ],
                                [
                                    [41.18616464994996, -8.488427530472755],
                                    [41.18617637789676, -8.488409205146466],
                                    [41.186183194752246, -8.488416849603503],
                                    [41.18617146680544, -8.48843517492979],
                                ],
                                [
                                    [41.18617637789676, -8.488409205146466],
                                    [41.18618810584356, -8.488390879820178],
                                    [41.186194922699045, -8.488398524277216],
                                    [41.186183194752246, -8.488416849603503],
                                ],
                                [
                                    [41.18618810584356, -8.488390879820178],
                                    [41.18619983379036, -8.48837255449389],
                                    [41.18620665064585, -8.488380198950926],
                                    [41.186194922699045, -8.488398524277216],
                                ],
                                [
                                    [41.18620274395086, -8.488368007266523],
                                    [41.186214471897664, -8.488349681940235],
                                    [41.186221288753146, -8.488357326397272],
                                    [41.18620956080635, -8.48837565172356],
                                ],
                                [
                                    [41.186214471897664, -8.488349681940235],
                                    [41.18622619984446, -8.488331356613948],
                                    [41.186233016699944, -8.488339001070983],
                                    [41.186221288753146, -8.488357326397272],
                                ],
                                [
                                    [41.18622619984446, -8.488331356613948],
                                    [41.18623792779126, -8.488313031287658],
                                    [41.18624474464675, -8.488320675744696],
                                    [41.186233016699944, -8.488339001070983],
                                ],
                                [
                                    [41.18624083795176, -8.488308484060292],
                                    [41.18625256589856, -8.488290158734005],
                                    [41.186259382754045, -8.48829780319104],
                                    [41.18624765480725, -8.48831612851733],
                                ],
                                [
                                    [41.18625256589856, -8.488290158734005],
                                    [41.18626429384536, -8.488271833407715],
                                    [41.18627111070085, -8.488279477864753],
                                    [41.186259382754045, -8.48829780319104],
                                ],
                                [
                                    [41.18626429384536, -8.488271833407715],
                                    [41.18627602179217, -8.488253508081428],
                                    [41.18628283864765, -8.488261152538465],
                                    [41.18627111070085, -8.488279477864753],
                                ],
                                [
                                    [41.186278931952664, -8.488248960854062],
                                    [41.18629065989946, -8.488230635527772],
                                    [41.18629747675495, -8.48823827998481],
                                    [41.186285748808146, -8.488256605311097],
                                ],
                                [
                                    [41.186019090801835, -8.488673267754715],
                                    [41.186030818748634, -8.488654942428427],
                                    [41.186037635604116, -8.488662586885464],
                                    [41.18602590765732, -8.488680912211752],
                                ],
                                [
                                    [41.186030818748634, -8.488654942428427],
                                    [41.18604254669543, -8.48863661710214],
                                    [41.18604936355092, -8.488644261559175],
                                    [41.186037635604116, -8.488662586885464],
                                ],
                                [
                                    [41.18604254669543, -8.48863661710214],
                                    [41.18605427464224, -8.48861829177585],
                                    [41.18606109149772, -8.488625936232888],
                                    [41.18604936355092, -8.488644261559175],
                                ],
                                [
                                    [41.186057184802735, -8.488613744548484],
                                    [41.18606891274953, -8.488595419222197],
                                    [41.18607572960502, -8.488603063679232],
                                    [41.18606400165822, -8.488621389005521],
                                ],
                                [
                                    [41.18606891274953, -8.488595419222197],
                                    [41.18608064069634, -8.488577093895909],
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18607572960502, -8.488603063679232],
                                ],
                                [
                                    [41.18608064069634, -8.488577093895909],
                                    [41.18609236864314, -8.48855876856962],
                                    [41.18609918549863, -8.488566413026657],
                                    [41.18608745755182, -8.488584738352944],
                                ],
                                [
                                    [41.186095278803634, -8.488554221342254],
                                    [41.18610700675044, -8.488535896015966],
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18610209565912, -8.488561865799289],
                                ],
                                [
                                    [41.18610700675044, -8.488535896015966],
                                    [41.18611873469724, -8.488517570689677],
                                    [41.18612555155273, -8.488525215146714],
                                    [41.18611382360592, -8.488543540473001],
                                ],
                                [
                                    [41.18611873469724, -8.488517570689677],
                                    [41.186130462644044, -8.48849924536339],
                                    [41.186137279499526, -8.488506889820425],
                                    [41.18612555155273, -8.488525215146714],
                                ],
                                [
                                    [41.18613337280454, -8.488494698136023],
                                    [41.18614510075134, -8.488476372809734],
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18614018966002, -8.488502342593058],
                                ],
                                [
                                    [41.18614510075134, -8.488476372809734],
                                    [41.186156828698145, -8.488458047483446],
                                    [41.18616364555363, -8.488465691940482],
                                    [41.18615191760683, -8.48848401726677],
                                ],
                                [
                                    [41.186156828698145, -8.488458047483446],
                                    [41.186168556644944, -8.488439722157159],
                                    [41.18617537350043, -8.488447366614194],
                                    [41.18616364555363, -8.488465691940482],
                                ],
                                [
                                    [41.18617146680544, -8.48843517492979],
                                    [41.186183194752246, -8.488416849603503],
                                    [41.18619001160773, -8.488424494060538],
                                    [41.18617828366093, -8.488442819386828],
                                ],
                                [
                                    [41.186183194752246, -8.488416849603503],
                                    [41.186194922699045, -8.488398524277216],
                                    [41.186201739554534, -8.488406168734251],
                                    [41.18619001160773, -8.488424494060538],
                                ],
                                [
                                    [41.186194922699045, -8.488398524277216],
                                    [41.18620665064585, -8.488380198950926],
                                    [41.18621346750133, -8.488387843407963],
                                    [41.186201739554534, -8.488406168734251],
                                ],
                                [
                                    [41.18620956080635, -8.48837565172356],
                                    [41.186221288753146, -8.488357326397272],
                                    [41.186228105608635, -8.488364970854308],
                                    [41.18621637766183, -8.488383296180595],
                                ],
                                [
                                    [41.186221288753146, -8.488357326397272],
                                    [41.186233016699944, -8.488339001070983],
                                    [41.18623983355543, -8.48834664552802],
                                    [41.186228105608635, -8.488364970854308],
                                ],
                                [
                                    [41.186233016699944, -8.488339001070983],
                                    [41.18624474464675, -8.488320675744696],
                                    [41.18625156150223, -8.488328320201731],
                                    [41.18623983355543, -8.48834664552802],
                                ],
                                [
                                    [41.18624765480725, -8.48831612851733],
                                    [41.186259382754045, -8.48829780319104],
                                    [41.186266199609534, -8.488305447648077],
                                    [41.18625447166273, -8.488323772974365],
                                ],
                                [
                                    [41.186259382754045, -8.48829780319104],
                                    [41.18627111070085, -8.488279477864753],
                                    [41.18627792755633, -8.488287122321788],
                                    [41.186266199609534, -8.488305447648077],
                                ],
                                [
                                    [41.18627111070085, -8.488279477864753],
                                    [41.18628283864765, -8.488261152538465],
                                    [41.18628965550314, -8.4882687969955],
                                    [41.18627792755633, -8.488287122321788],
                                ],
                                [
                                    [41.186285748808146, -8.488256605311097],
                                    [41.18629747675495, -8.48823827998481],
                                    [41.18630429361043, -8.488245924441845],
                                    [41.186292565663635, -8.488264249768134],
                                ],
                                [
                                    [41.18602590765732, -8.488680912211752],
                                    [41.186037635604116, -8.488662586885464],
                                    [41.186044452459605, -8.4886702313425],
                                    [41.186032724512806, -8.488688556668787],
                                ],
                                [
                                    [41.186037635604116, -8.488662586885464],
                                    [41.18604936355092, -8.488644261559175],
                                    [41.1860561804064, -8.488651906016212],
                                    [41.186044452459605, -8.4886702313425],
                                ],
                                [
                                    [41.18604936355092, -8.488644261559175],
                                    [41.18606109149772, -8.488625936232888],
                                    [41.18606790835321, -8.488633580689923],
                                    [41.1860561804064, -8.488651906016212],
                                ],
                                [
                                    [41.18606400165822, -8.488621389005521],
                                    [41.18607572960502, -8.488603063679232],
                                    [41.186082546460504, -8.48861070813627],
                                    [41.186070818513706, -8.488629033462557],
                                ],
                                [
                                    [41.18607572960502, -8.488603063679232],
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18609427440731, -8.48859238280998],
                                    [41.186082546460504, -8.48861070813627],
                                ],
                                [
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18609918549863, -8.488566413026657],
                                    [41.18610600235411, -8.488574057483692],
                                    [41.18609427440731, -8.48859238280998],
                                ],
                                [
                                    [41.18610209565912, -8.488561865799289],
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18612064046141, -8.488551184930039],
                                    [41.186108912514605, -8.488569510256326],
                                ],
                                [
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18612555155273, -8.488525215146714],
                                    [41.18613236840821, -8.48853285960375],
                                    [41.18612064046141, -8.488551184930039],
                                ],
                                [
                                    [41.18612555155273, -8.488525215146714],
                                    [41.186137279499526, -8.488506889820425],
                                    [41.186144096355015, -8.488514534277462],
                                    [41.18613236840821, -8.48853285960375],
                                ],
                                [
                                    [41.18614018966002, -8.488502342593058],
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18615873446231, -8.488491661723806],
                                    [41.18614700651551, -8.488509987050096],
                                ],
                                [
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18616364555363, -8.488465691940482],
                                    [41.186170462409116, -8.488473336397519],
                                    [41.18615873446231, -8.488491661723806],
                                ],
                                [
                                    [41.18616364555363, -8.488465691940482],
                                    [41.18617537350043, -8.488447366614194],
                                    [41.186182190355915, -8.488455011071231],
                                    [41.186170462409116, -8.488473336397519],
                                ],
                                [
                                    [41.18617828366093, -8.488442819386828],
                                    [41.18619001160773, -8.488424494060538],
                                    [41.18619682846322, -8.488432138517576],
                                    [41.18618510051641, -8.488450463843863],
                                ],
                                [
                                    [41.18619001160773, -8.488424494060538],
                                    [41.186201739554534, -8.488406168734251],
                                    [41.186208556410016, -8.488413813191288],
                                    [41.18619682846322, -8.488432138517576],
                                ],
                                [
                                    [41.186201739554534, -8.488406168734251],
                                    [41.18621346750133, -8.488387843407963],
                                    [41.18622028435682, -8.488395487864999],
                                    [41.186208556410016, -8.488413813191288],
                                ],
                                [
                                    [41.18621637766183, -8.488383296180595],
                                    [41.186228105608635, -8.488364970854308],
                                    [41.186234922464116, -8.488372615311345],
                                    [41.18622319451732, -8.488390940637633],
                                ],
                                [
                                    [41.186228105608635, -8.488364970854308],
                                    [41.18623983355543, -8.48834664552802],
                                    [41.18624665041092, -8.488354289985056],
                                    [41.186234922464116, -8.488372615311345],
                                ],
                                [
                                    [41.18623983355543, -8.48834664552802],
                                    [41.18625156150223, -8.488328320201731],
                                    [41.18625837835772, -8.488335964658768],
                                    [41.18624665041092, -8.488354289985056],
                                ],
                                [
                                    [41.18625447166273, -8.488323772974365],
                                    [41.186266199609534, -8.488305447648077],
                                    [41.186273016465016, -8.488313092105113],
                                    [41.18626128851822, -8.488331417431402],
                                ],
                                [
                                    [41.186266199609534, -8.488305447648077],
                                    [41.18627792755633, -8.488287122321788],
                                    [41.18628474441182, -8.488294766778825],
                                    [41.186273016465016, -8.488313092105113],
                                ],
                                [
                                    [41.18627792755633, -8.488287122321788],
                                    [41.18628965550314, -8.4882687969955],
                                    [41.18629647235862, -8.488276441452538],
                                    [41.18628474441182, -8.488294766778825],
                                ],
                                [
                                    [41.186292565663635, -8.488264249768134],
                                    [41.18630429361043, -8.488245924441845],
                                    [41.18631111046592, -8.488253568898882],
                                    [41.18629938251912, -8.48827189422517],
                                ],
                                [
                                    [41.186032724512806, -8.488688556668787],
                                    [41.186044452459605, -8.4886702313425],
                                    [41.186051269315094, -8.488677875799537],
                                    [41.18603954136829, -8.488696201125824],
                                ],
                                [
                                    [41.186044452459605, -8.4886702313425],
                                    [41.1860561804064, -8.488651906016212],
                                    [41.18606299726189, -8.488659550473248],
                                    [41.186051269315094, -8.488677875799537],
                                ],
                                [
                                    [41.1860561804064, -8.488651906016212],
                                    [41.18606790835321, -8.488633580689923],
                                    [41.18607472520869, -8.48864122514696],
                                    [41.18606299726189, -8.488659550473248],
                                ],
                                [
                                    [41.186070818513706, -8.488629033462557],
                                    [41.186082546460504, -8.48861070813627],
                                    [41.18608936331599, -8.488618352593305],
                                    [41.18607763536919, -8.488636677919594],
                                ],
                                [
                                    [41.186082546460504, -8.48861070813627],
                                    [41.18609427440731, -8.48859238280998],
                                    [41.18610109126279, -8.488600027267017],
                                    [41.18608936331599, -8.488618352593305],
                                ],
                                [
                                    [41.18609427440731, -8.48859238280998],
                                    [41.18610600235411, -8.488574057483692],
                                    [41.1861128192096, -8.48858170194073],
                                    [41.18610109126279, -8.488600027267017],
                                ],
                                [
                                    [41.186108912514605, -8.488569510256326],
                                    [41.18612064046141, -8.488551184930039],
                                    [41.18612745731689, -8.488558829387074],
                                    [41.186115729370094, -8.488577154713361],
                                ],
                                [
                                    [41.18612064046141, -8.488551184930039],
                                    [41.18613236840821, -8.48853285960375],
                                    [41.1861391852637, -8.488540504060786],
                                    [41.18612745731689, -8.488558829387074],
                                ],
                                [
                                    [41.18613236840821, -8.48853285960375],
                                    [41.186144096355015, -8.488514534277462],
                                    [41.1861509132105, -8.488522178734497],
                                    [41.1861391852637, -8.488540504060786],
                                ],
                                [
                                    [41.18614700651551, -8.488509987050096],
                                    [41.18615873446231, -8.488491661723806],
                                    [41.1861655513178, -8.488499306180843],
                                    [41.186153823370994, -8.488517631507131],
                                ],
                                [
                                    [41.18615873446231, -8.488491661723806],
                                    [41.186170462409116, -8.488473336397519],
                                    [41.1861772792646, -8.488480980854554],
                                    [41.1861655513178, -8.488499306180843],
                                ],
                                [
                                    [41.186170462409116, -8.488473336397519],
                                    [41.186182190355915, -8.488455011071231],
                                    [41.186189007211404, -8.488462655528267],
                                    [41.1861772792646, -8.488480980854554],
                                ],
                                [
                                    [41.18618510051641, -8.488450463843863],
                                    [41.18619682846322, -8.488432138517576],
                                    [41.1862036453187, -8.488439782974611],
                                    [41.1861919173719, -8.4884581083009],
                                ],
                                [
                                    [41.18619682846322, -8.488432138517576],
                                    [41.186208556410016, -8.488413813191288],
                                    [41.186215373265505, -8.488421457648323],
                                    [41.1862036453187, -8.488439782974611],
                                ],
                                [
                                    [41.186208556410016, -8.488413813191288],
                                    [41.18622028435682, -8.488395487864999],
                                    [41.1862271012123, -8.488403132322036],
                                    [41.186215373265505, -8.488421457648323],
                                ],
                                [
                                    [41.18622319451732, -8.488390940637633],
                                    [41.186234922464116, -8.488372615311345],
                                    [41.186241739319605, -8.48838025976838],
                                    [41.1862300113728, -8.488398585094668],
                                ],
                                [
                                    [41.186234922464116, -8.488372615311345],
                                    [41.18624665041092, -8.488354289985056],
                                    [41.186253467266404, -8.488361934442093],
                                    [41.186241739319605, -8.48838025976838],
                                ],
                                [
                                    [41.18624665041092, -8.488354289985056],
                                    [41.18625837835772, -8.488335964658768],
                                    [41.1862651952132, -8.488343609115804],
                                    [41.186253467266404, -8.488361934442093],
                                ],
                                [
                                    [41.18626128851822, -8.488331417431402],
                                    [41.186273016465016, -8.488313092105113],
                                    [41.186279833320505, -8.48832073656215],
                                    [41.186268105373706, -8.488339061888437],
                                ],
                                [
                                    [41.186273016465016, -8.488313092105113],
                                    [41.18628474441182, -8.488294766778825],
                                    [41.1862915612673, -8.48830241123586],
                                    [41.186279833320505, -8.48832073656215],
                                ],
                                [
                                    [41.18628474441182, -8.488294766778825],
                                    [41.18629647235862, -8.488276441452538],
                                    [41.18630328921411, -8.488284085909573],
                                    [41.1862915612673, -8.48830241123586],
                                ],
                                [
                                    [41.18629938251912, -8.48827189422517],
                                    [41.18631111046592, -8.488253568898882],
                                    [41.186317927321404, -8.488261213355917],
                                    [41.186306199374606, -8.488279538682207],
                                ],
                                [
                                    [41.18603954136829, -8.488696201125824],
                                    [41.186051269315094, -8.488677875799537],
                                    [41.186058086170576, -8.488685520256572],
                                    [41.18604635822378, -8.48870384558286],
                                ],
                                [
                                    [41.186051269315094, -8.488677875799537],
                                    [41.18606299726189, -8.488659550473248],
                                    [41.186069814117374, -8.488667194930285],
                                    [41.186058086170576, -8.488685520256572],
                                ],
                                [
                                    [41.18606299726189, -8.488659550473248],
                                    [41.18607472520869, -8.48864122514696],
                                    [41.18608154206418, -8.488648869603995],
                                    [41.186069814117374, -8.488667194930285],
                                ],
                                [
                                    [41.18607763536919, -8.488636677919594],
                                    [41.18608936331599, -8.488618352593305],
                                    [41.186096180171475, -8.488625997050342],
                                    [41.18608445222468, -8.48864432237663],
                                ],
                                [
                                    [41.18608936331599, -8.488618352593305],
                                    [41.18610109126279, -8.488600027267017],
                                    [41.18610790811828, -8.488607671724052],
                                    [41.186096180171475, -8.488625997050342],
                                ],
                                [
                                    [41.18610109126279, -8.488600027267017],
                                    [41.1861128192096, -8.48858170194073],
                                    [41.18611963606508, -8.488589346397765],
                                    [41.18610790811828, -8.488607671724052],
                                ],
                                [
                                    [41.186115729370094, -8.488577154713361],
                                    [41.18612745731689, -8.488558829387074],
                                    [41.18613427417238, -8.48856647384411],
                                    [41.186122546225576, -8.488584799170399],
                                ],
                                [
                                    [41.18612745731689, -8.488558829387074],
                                    [41.1861391852637, -8.488540504060786],
                                    [41.18614600211918, -8.488548148517822],
                                    [41.18613427417238, -8.48856647384411],
                                ],
                                [
                                    [41.1861391852637, -8.488540504060786],
                                    [41.1861509132105, -8.488522178734497],
                                    [41.186157730065986, -8.488529823191534],
                                    [41.18614600211918, -8.488548148517822],
                                ],
                                [
                                    [41.186153823370994, -8.488517631507131],
                                    [41.1861655513178, -8.488499306180843],
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18616064022648, -8.488525275964168],
                                ],
                                [
                                    [41.1861655513178, -8.488499306180843],
                                    [41.1861772792646, -8.488480980854554],
                                    [41.18618409612009, -8.488488625311591],
                                    [41.18617236817328, -8.488506950637879],
                                ],
                                [
                                    [41.1861772792646, -8.488480980854554],
                                    [41.186189007211404, -8.488462655528267],
                                    [41.186195824066886, -8.488470299985304],
                                    [41.18618409612009, -8.488488625311591],
                                ],
                                [
                                    [41.1861919173719, -8.4884581083009],
                                    [41.1862036453187, -8.488439782974611],
                                    [41.18621046217419, -8.488447427431648],
                                    [41.18619873422738, -8.488465752757936],
                                ],
                                [
                                    [41.1862036453187, -8.488439782974611],
                                    [41.186215373265505, -8.488421457648323],
                                    [41.186222190120986, -8.48842910210536],
                                    [41.18621046217419, -8.488447427431648],
                                ],
                                [
                                    [41.186215373265505, -8.488421457648323],
                                    [41.1862271012123, -8.488403132322036],
                                    [41.18623391806779, -8.488410776779071],
                                    [41.186222190120986, -8.48842910210536],
                                ],
                                [
                                    [41.1862300113728, -8.488398585094668],
                                    [41.186241739319605, -8.48838025976838],
                                    [41.18624855617509, -8.488387904225418],
                                    [41.18623682822829, -8.488406229551705],
                                ],
                                [
                                    [41.186241739319605, -8.48838025976838],
                                    [41.186253467266404, -8.488361934442093],
                                    [41.18626028412189, -8.488369578899128],
                                    [41.18624855617509, -8.488387904225418],
                                ],
                                [
                                    [41.186253467266404, -8.488361934442093],
                                    [41.1862651952132, -8.488343609115804],
                                    [41.18627201206869, -8.48835125357284],
                                    [41.18626028412189, -8.488369578899128],
                                ],
                                [
                                    [41.186268105373706, -8.488339061888437],
                                    [41.186279833320505, -8.48832073656215],
                                    [41.18628665017599, -8.488328381019185],
                                    [41.18627492222919, -8.488346706345475],
                                ],
                                [
                                    [41.186279833320505, -8.48832073656215],
                                    [41.1862915612673, -8.48830241123586],
                                    [41.18629837812279, -8.488310055692898],
                                    [41.18628665017599, -8.488328381019185],
                                ],
                                [
                                    [41.1862915612673, -8.48830241123586],
                                    [41.18630328921411, -8.488284085909573],
                                    [41.18631010606959, -8.48829173036661],
                                    [41.18629837812279, -8.488310055692898],
                                ],
                                [
                                    [41.186306199374606, -8.488279538682207],
                                    [41.186317927321404, -8.488261213355917],
                                    [41.18632474417689, -8.488268857812955],
                                    [41.18631301623009, -8.488287183139242],
                                ],
                                [
                                    [41.18604635822378, -8.48870384558286],
                                    [41.186058086170576, -8.488685520256572],
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18605317507926, -8.488711490039897],
                                ],
                                [
                                    [41.186058086170576, -8.488685520256572],
                                    [41.186069814117374, -8.488667194930285],
                                    [41.18607663097286, -8.48867483938732],
                                    [41.186064903026065, -8.48869316471361],
                                ],
                                [
                                    [41.186069814117374, -8.488667194930285],
                                    [41.18608154206418, -8.488648869603995],
                                    [41.18608835891966, -8.488656514061033],
                                    [41.18607663097286, -8.48867483938732],
                                ],
                                [
                                    [41.18608445222468, -8.48864432237663],
                                    [41.186096180171475, -8.488625997050342],
                                    [41.186102997026964, -8.488633641507377],
                                    [41.18609126908016, -8.488651966833666],
                                ],
                                [
                                    [41.186096180171475, -8.488625997050342],
                                    [41.18610790811828, -8.488607671724052],
                                    [41.18611472497376, -8.48861531618109],
                                    [41.186102997026964, -8.488633641507377],
                                ],
                                [
                                    [41.18610790811828, -8.488607671724052],
                                    [41.18611963606508, -8.488589346397765],
                                    [41.18612645292057, -8.488596990854802],
                                    [41.18611472497376, -8.48861531618109],
                                ],
                                [
                                    [41.186122546225576, -8.488584799170399],
                                    [41.18613427417238, -8.48856647384411],
                                    [41.186141091027864, -8.488574118301146],
                                    [41.186129363081065, -8.488592443627434],
                                ],
                                [
                                    [41.18613427417238, -8.48856647384411],
                                    [41.18614600211918, -8.488548148517822],
                                    [41.18615281897467, -8.488555792974859],
                                    [41.186141091027864, -8.488574118301146],
                                ],
                                [
                                    [41.18614600211918, -8.488548148517822],
                                    [41.186157730065986, -8.488529823191534],
                                    [41.18616454692147, -8.48853746764857],
                                    [41.18615281897467, -8.488555792974859],
                                ],
                                [
                                    [41.18616064022648, -8.488525275964168],
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18617918502877, -8.488514595094916],
                                    [41.186167457081964, -8.488532920421203],
                                ],
                                [
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18618409612009, -8.488488625311591],
                                    [41.18619091297557, -8.488496269768627],
                                    [41.18617918502877, -8.488514595094916],
                                ],
                                [
                                    [41.18618409612009, -8.488488625311591],
                                    [41.186195824066886, -8.488470299985304],
                                    [41.186202640922374, -8.488477944442339],
                                    [41.18619091297557, -8.488496269768627],
                                ],
                                [
                                    [41.18619873422738, -8.488465752757936],
                                    [41.18621046217419, -8.488447427431648],
                                    [41.18621727902967, -8.488455071888684],
                                    [41.18620555108287, -8.488473397214973],
                                ],
                                [
                                    [41.18621046217419, -8.488447427431648],
                                    [41.186222190120986, -8.48842910210536],
                                    [41.186229006976475, -8.488436746562396],
                                    [41.18621727902967, -8.488455071888684],
                                ],
                                [
                                    [41.186222190120986, -8.48842910210536],
                                    [41.18623391806779, -8.488410776779071],
                                    [41.186240734923274, -8.488418421236108],
                                    [41.186229006976475, -8.488436746562396],
                                ],
                                [
                                    [41.18623682822829, -8.488406229551705],
                                    [41.18624855617509, -8.488387904225418],
                                    [41.186255373030576, -8.488395548682453],
                                    [41.18624364508377, -8.48841387400874],
                                ],
                                [
                                    [41.18624855617509, -8.488387904225418],
                                    [41.18626028412189, -8.488369578899128],
                                    [41.186267100977375, -8.488377223356165],
                                    [41.186255373030576, -8.488395548682453],
                                ],
                                [
                                    [41.18626028412189, -8.488369578899128],
                                    [41.18627201206869, -8.48835125357284],
                                    [41.18627882892418, -8.488358898029876],
                                    [41.186267100977375, -8.488377223356165],
                                ],
                                [
                                    [41.18627492222919, -8.488346706345475],
                                    [41.18628665017599, -8.488328381019185],
                                    [41.186293467031476, -8.488336025476222],
                                    [41.18628173908468, -8.48835435080251],
                                ],
                                [
                                    [41.18628665017599, -8.488328381019185],
                                    [41.18629837812279, -8.488310055692898],
                                    [41.186305194978274, -8.488317700149933],
                                    [41.186293467031476, -8.488336025476222],
                                ],
                                [
                                    [41.18629837812279, -8.488310055692898],
                                    [41.18631010606959, -8.48829173036661],
                                    [41.18631692292508, -8.488299374823645],
                                    [41.186305194978274, -8.488317700149933],
                                ],
                                [
                                    [41.18631301623009, -8.488287183139242],
                                    [41.18632474417689, -8.488268857812955],
                                    [41.186331561032375, -8.48827650226999],
                                    [41.18631983308558, -8.48829482759628],
                                ],
                                [
                                    [41.18605317507926, -8.488711490039897],
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18607171988155, -8.488700809170645],
                                    [41.18605999193475, -8.488719134496932],
                                ],
                                [
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18607663097286, -8.48867483938732],
                                    [41.18608344782835, -8.488682483844357],
                                    [41.18607171988155, -8.488700809170645],
                                ],
                                [
                                    [41.18607663097286, -8.48867483938732],
                                    [41.18608835891966, -8.488656514061033],
                                    [41.18609517577515, -8.488664158518068],
                                    [41.18608344782835, -8.488682483844357],
                                ],
                                [
                                    [41.18609126908016, -8.488651966833666],
                                    [41.186102997026964, -8.488633641507377],
                                    [41.186109813882446, -8.488641285964414],
                                    [41.18609808593565, -8.488659611290702],
                                ],
                                [
                                    [41.186102997026964, -8.488633641507377],
                                    [41.18611472497376, -8.48861531618109],
                                    [41.18612154182925, -8.488622960638125],
                                    [41.186109813882446, -8.488641285964414],
                                ],
                                [
                                    [41.18611472497376, -8.48861531618109],
                                    [41.18612645292057, -8.488596990854802],
                                    [41.18613326977605, -8.488604635311837],
                                    [41.18612154182925, -8.488622960638125],
                                ],
                                [
                                    [41.186129363081065, -8.488592443627434],
                                    [41.186141091027864, -8.488574118301146],
                                    [41.18614790788335, -8.488581762758182],
                                    [41.18613617993655, -8.488600088084471],
                                ],
                                [
                                    [41.186141091027864, -8.488574118301146],
                                    [41.18615281897467, -8.488555792974859],
                                    [41.18615963583015, -8.488563437431894],
                                    [41.18614790788335, -8.488581762758182],
                                ],
                                [
                                    [41.18617918502877, -8.488514595094916],
                                    [41.18619091297557, -8.488496269768627],
                                    [41.18619772983106, -8.488503914225664],
                                    [41.18618600188425, -8.488522239551951],
                                ],
                                [
                                    [41.18619091297557, -8.488496269768627],
                                    [41.186202640922374, -8.488477944442339],
                                    [41.186209457777856, -8.488485588899374],
                                    [41.18619772983106, -8.488503914225664],
                                ],
                                [
                                    [41.18620555108287, -8.488473397214973],
                                    [41.18621727902967, -8.488455071888684],
                                    [41.18622409588516, -8.48846271634572],
                                    [41.18621236793835, -8.488481041672008],
                                ],
                                [
                                    [41.18621727902967, -8.488455071888684],
                                    [41.186229006976475, -8.488436746562396],
                                    [41.18623582383196, -8.488444391019433],
                                    [41.18622409588516, -8.48846271634572],
                                ],
                                [
                                    [41.186229006976475, -8.488436746562396],
                                    [41.186240734923274, -8.488418421236108],
                                    [41.18624755177876, -8.488426065693144],
                                    [41.18623582383196, -8.488444391019433],
                                ],
                                [
                                    [41.18624364508377, -8.48841387400874],
                                    [41.186255373030576, -8.488395548682453],
                                    [41.18626218988606, -8.48840319313949],
                                    [41.18625046193926, -8.488421518465778],
                                ],
                                [
                                    [41.18628173908468, -8.48835435080251],
                                    [41.186293467031476, -8.488336025476222],
                                    [41.186300283886965, -8.488343669933258],
                                    [41.18628855594016, -8.488361995259547],
                                ],
                                [
                                    [41.186293467031476, -8.488336025476222],
                                    [41.186305194978274, -8.488317700149933],
                                    [41.18631201183376, -8.48832534460697],
                                    [41.186300283886965, -8.488343669933258],
                                ],
                                [
                                    [41.186305194978274, -8.488317700149933],
                                    [41.18631692292508, -8.488299374823645],
                                    [41.18632373978056, -8.488307019280683],
                                    [41.18631201183376, -8.48832534460697],
                                ],
                                [
                                    [41.18631983308558, -8.48829482759628],
                                    [41.186331561032375, -8.48827650226999],
                                    [41.186338377887864, -8.488284146727027],
                                    [41.18632664994106, -8.488302472053315],
                                ],
                            ],
                            panels_idx: [
                                [0, 0, [0, 0]],
                                [0, 1, [0, 2]],
                                [0, 2, [0, 0]],
                                [0, 2, [0, 1]],
                                [0, 2, [0, 2]],
                                [0, 3, [0, 0]],
                                [0, 3, [0, 1]],
                                [0, 3, [0, 2]],
                                [0, 4, [0, 0]],
                                [0, 4, [0, 1]],
                                [0, 4, [0, 2]],
                                [0, 5, [0, 0]],
                                [0, 5, [0, 1]],
                                [0, 5, [0, 2]],
                                [0, 6, [0, 0]],
                                [0, 6, [0, 1]],
                                [0, 6, [0, 2]],
                                [0, 7, [0, 0]],
                                [1, 0, [0, 0]],
                                [1, 0, [0, 1]],
                                [1, 0, [0, 2]],
                                [1, 1, [0, 0]],
                                [1, 1, [0, 1]],
                                [1, 1, [0, 2]],
                                [1, 2, [0, 0]],
                                [1, 2, [0, 1]],
                                [1, 2, [0, 2]],
                                [1, 3, [0, 0]],
                                [1, 3, [0, 1]],
                                [1, 3, [0, 2]],
                                [1, 4, [0, 0]],
                                [1, 4, [0, 1]],
                                [1, 4, [0, 2]],
                                [1, 5, [0, 0]],
                                [1, 5, [0, 1]],
                                [1, 5, [0, 2]],
                                [1, 6, [0, 0]],
                                [1, 6, [0, 1]],
                                [1, 6, [0, 2]],
                                [1, 7, [0, 0]],
                                [2, 0, [0, 0]],
                                [2, 0, [0, 1]],
                                [2, 0, [0, 2]],
                                [2, 1, [0, 0]],
                                [2, 1, [0, 1]],
                                [2, 1, [0, 2]],
                                [2, 2, [0, 0]],
                                [2, 2, [0, 1]],
                                [2, 2, [0, 2]],
                                [2, 3, [0, 0]],
                                [2, 3, [0, 1]],
                                [2, 3, [0, 2]],
                                [2, 4, [0, 0]],
                                [2, 4, [0, 1]],
                                [2, 4, [0, 2]],
                                [2, 5, [0, 0]],
                                [2, 5, [0, 1]],
                                [2, 5, [0, 2]],
                                [2, 6, [0, 0]],
                                [2, 6, [0, 1]],
                                [2, 6, [0, 2]],
                                [2, 7, [0, 0]],
                                [3, 0, [0, 0]],
                                [3, 0, [0, 1]],
                                [3, 0, [0, 2]],
                                [3, 1, [0, 0]],
                                [3, 1, [0, 1]],
                                [3, 1, [0, 2]],
                                [3, 2, [0, 0]],
                                [3, 2, [0, 1]],
                                [3, 2, [0, 2]],
                                [3, 3, [0, 0]],
                                [3, 3, [0, 1]],
                                [3, 3, [0, 2]],
                                [3, 4, [0, 0]],
                                [3, 4, [0, 1]],
                                [3, 4, [0, 2]],
                                [3, 5, [0, 0]],
                                [3, 5, [0, 1]],
                                [3, 5, [0, 2]],
                                [3, 6, [0, 0]],
                                [3, 6, [0, 1]],
                                [3, 6, [0, 2]],
                                [3, 7, [0, 0]],
                                [4, 0, [0, 0]],
                                [4, 0, [0, 1]],
                                [4, 0, [0, 2]],
                                [4, 1, [0, 0]],
                                [4, 1, [0, 1]],
                                [4, 1, [0, 2]],
                                [4, 2, [0, 0]],
                                [4, 2, [0, 1]],
                                [4, 2, [0, 2]],
                                [4, 3, [0, 0]],
                                [4, 3, [0, 1]],
                                [4, 3, [0, 2]],
                                [4, 4, [0, 0]],
                                [4, 4, [0, 1]],
                                [4, 4, [0, 2]],
                                [4, 5, [0, 0]],
                                [4, 5, [0, 1]],
                                [4, 5, [0, 2]],
                                [4, 6, [0, 0]],
                                [4, 6, [0, 1]],
                                [4, 6, [0, 2]],
                                [4, 7, [0, 0]],
                                [5, 0, [0, 0]],
                                [5, 0, [0, 1]],
                                [5, 0, [0, 2]],
                                [5, 1, [0, 0]],
                                [5, 1, [0, 1]],
                                [5, 1, [0, 2]],
                                [5, 2, [0, 0]],
                                [5, 2, [0, 1]],
                                [5, 2, [0, 2]],
                                [5, 3, [0, 0]],
                                [5, 3, [0, 1]],
                                [5, 3, [0, 2]],
                                [5, 4, [0, 0]],
                                [5, 4, [0, 1]],
                                [5, 4, [0, 2]],
                                [5, 5, [0, 0]],
                                [5, 5, [0, 1]],
                                [5, 5, [0, 2]],
                                [5, 6, [0, 0]],
                                [5, 6, [0, 1]],
                                [5, 6, [0, 2]],
                                [5, 7, [0, 0]],
                                [6, 0, [0, 0]],
                                [6, 0, [0, 1]],
                                [6, 0, [0, 2]],
                                [6, 1, [0, 0]],
                                [6, 1, [0, 1]],
                                [6, 1, [0, 2]],
                                [6, 2, [0, 0]],
                                [6, 2, [0, 1]],
                                [6, 3, [0, 1]],
                                [6, 3, [0, 2]],
                                [6, 4, [0, 0]],
                                [6, 4, [0, 1]],
                                [6, 4, [0, 2]],
                                [6, 5, [0, 0]],
                                [6, 6, [0, 0]],
                                [6, 6, [0, 1]],
                                [6, 6, [0, 2]],
                                [6, 7, [0, 0]],
                            ],
                            perc_shadow: [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0,
                            ],
                            panels_number: 146,
                        },
                    ],
                    coordinates: [
                        {
                            lat: 41.18600528112348,
                            lng: -8.488665535099807,
                        },
                        {
                            lat: 41.18606583790162,
                            lng: -8.488733931429687,
                        },
                        {
                            lat: 41.18635247331818,
                            lng: -8.488283320315185,
                        },
                        {
                            lat: 41.18629191654003,
                            lng: -8.488216265089813,
                        },
                        {
                            lat: 41.18600528112348,
                            lng: -8.488665535099807,
                        },
                    ],
                    orientation: -40.27,
                    region_gd_id: 125,
                    panels_number: 146,
                    perimeter_area: 116.39458181558223,
                    coordinates_avg: {
                        lat: 41.186144158001355,
                        long: -8.48851291740686,
                        region_gd_id: 125,
                    },
                    autoSplitBuilding: 1,
                    panel_representation: {
                        dayRank: '2024-12-22T00:00:00.000Z',
                        setback: 0.5,
                        rowSpace: 0,
                        alignment: 'left',
                        panels_nr: 3,
                        panels_up: 1,
                        roofSlope: 5,
                        row_space: 0,
                        hourWindow: 4,
                        panels_wide: 3,
                        panelFraming: 1,
                        space_btw_sets: 0.5,
                        reference_point: [41.186012273946346, -8.48866562329768],
                        isDefaultRowRatio: false,
                        alignment_vertical: 'bottom',
                        alignment_horizontal: 'left',
                        is_row_spacing_insert: true,
                        coordinates_conversion_factor: {
                            one_meter_in_lat: 0.00000900434376143347,
                            one_meter_in_lon: 0.00001191923025934094,
                        },
                    },
                    mounting_structure_id: 7,
                    panels_number_excluded: 0,
                    panels_number_possible: 146,
                    mounting_structure_type_id: 18,
                },
                {
                    id: 2,
                    lat: 41.18626910682026,
                    area: 435.14275740630234,
                    long: -8.488506211884323,
                    edges: [
                        {
                            aspect: 49.772882975519515,
                            points: [
                                {
                                    lat: 41.1861207,
                                    lng: -8.4888041,
                                },
                                {
                                    lat: 41.186113899999995,
                                    lng: -8.4887965,
                                },
                            ],
                        },
                        {
                            aspect: -38.67722912542285,
                            points: [
                                {
                                    lat: 41.186113899999995,
                                    lng: -8.4887965,
                                },
                                {
                                    lat: 41.1860935,
                                    lng: -8.4887737,
                                },
                            ],
                        },
                        {
                            aspect: -48.73453529487949,
                            points: [
                                {
                                    lat: 41.1860935,
                                    lng: -8.4887737,
                                },
                                {
                                    lat: 41.1860664,
                                    lng: -8.4887433,
                                },
                            ],
                        },
                        {
                            aspect: -130.2409363116384,
                            points: [
                                {
                                    lat: 41.1860664,
                                    lng: -8.4887433,
                                },
                                {
                                    lat: 41.186174799999996,
                                    lng: -8.4885555,
                                },
                            ],
                        },
                        {
                            aspect: 139.78307560281286,
                            points: [
                                {
                                    lat: 41.186174799999996,
                                    lng: -8.4885555,
                                },
                                {
                                    lat: 41.186270799999996,
                                    lng: -8.4884053,
                                },
                            ],
                        },
                    ],
                    slope: 5,
                    aspect: 139.7287929522657,
                    selected: false,
                    panel_rows: [
                        {
                            positions: [
                                [
                                    [41.18630785861647, -8.488501182006347],
                                    [41.186296130669675, -8.488519507332635],
                                    [41.186289313814186, -8.4885118628756],
                                    [41.18630104176099, -8.48849353754931],
                                ],
                                [
                                    [41.186296130669675, -8.488519507332635],
                                    [41.18628440272287, -8.488537832658922],
                                    [41.18627758586739, -8.488530188201887],
                                    [41.186289313814186, -8.4885118628756],
                                ],
                                [
                                    [41.18628440272287, -8.488537832658922],
                                    [41.18627267477607, -8.488556157985212],
                                    [41.18626585792058, -8.488548513528174],
                                    [41.18627758586739, -8.488530188201887],
                                ],
                                [
                                    [41.186269764615574, -8.488560705212578],
                                    [41.18625803666877, -8.488579030538865],
                                    [41.186251219813286, -8.48857138608183],
                                    [41.186262947760085, -8.488553060755542],
                                ],
                                [
                                    [41.18625803666877, -8.488579030538865],
                                    [41.18624630872197, -8.488597355865155],
                                    [41.18623949186648, -8.488589711408117],
                                    [41.186251219813286, -8.48857138608183],
                                ],
                                [
                                    [41.18624630872197, -8.488597355865155],
                                    [41.186234580775164, -8.488615681191442],
                                    [41.18622776391968, -8.488608036734407],
                                    [41.18623949186648, -8.488589711408117],
                                ],
                                [
                                    [41.18623167061467, -8.488620228418808],
                                    [41.18621994266787, -8.488638553745098],
                                    [41.18621312581238, -8.48863090928806],
                                    [41.186224853759185, -8.488612583961773],
                                ],
                                [
                                    [41.18621994266787, -8.488638553745098],
                                    [41.18620821472106, -8.488656879071385],
                                    [41.18620139786558, -8.48864923461435],
                                    [41.18621312581238, -8.48863090928806],
                                ],
                                [
                                    [41.18620821472106, -8.488656879071385],
                                    [41.186196486774264, -8.488675204397673],
                                    [41.186189669918775, -8.488667559940637],
                                    [41.18620139786558, -8.48864923461435],
                                ],
                                [
                                    [41.18619357661377, -8.48867975162504],
                                    [41.18618184866696, -8.488698076951328],
                                    [41.18617503181148, -8.488690432494293],
                                    [41.18618675975828, -8.488672107168004],
                                ],
                                [
                                    [41.18617012072016, -8.488716402277616],
                                    [41.18615839277336, -8.488734727603905],
                                    [41.186151575917876, -8.488727083146868],
                                    [41.186163303864674, -8.48870875782058],
                                ],
                                [
                                    [41.18615548261286, -8.488739274831271],
                                    [41.18614375466606, -8.488757600157559],
                                    [41.18613693781057, -8.488749955700523],
                                    [41.18614866575738, -8.488731630374236],
                                ],
                                [
                                    [41.18614375466606, -8.488757600157559],
                                    [41.18613202671926, -8.488775925483848],
                                    [41.186125209863775, -8.488768281026811],
                                    [41.18613693781057, -8.488749955700523],
                                ],
                                [
                                    [41.18613202671926, -8.488775925483848],
                                    [41.18612029877246, -8.488794250810136],
                                    [41.18611348191697, -8.4887866063531],
                                    [41.186125209863775, -8.488768281026811],
                                ],
                                [
                                    [41.18639186787009, -8.488351618583193],
                                    [41.18638013992329, -8.48836994390948],
                                    [41.186373323067805, -8.488362299452445],
                                    [41.186385051014604, -8.488343974126156],
                                ],
                                [
                                    [41.18637722976279, -8.488374491136849],
                                    [41.18636550181599, -8.488392816463136],
                                    [41.1863586849605, -8.4883851720061],
                                    [41.18637041290731, -8.488366846679812],
                                ],
                                [
                                    [41.18636550181599, -8.488392816463136],
                                    [41.186353773869186, -8.488411141789424],
                                    [41.186346957013704, -8.488403497332389],
                                    [41.1863586849605, -8.4883851720061],
                                ],
                                [
                                    [41.186353773869186, -8.488411141789424],
                                    [41.18634204592239, -8.488429467115713],
                                    [41.1863352290669, -8.488421822658676],
                                    [41.186346957013704, -8.488403497332389],
                                ],
                                [
                                    [41.18633913576189, -8.48843401434308],
                                    [41.186327407815085, -8.488452339669367],
                                    [41.1863205909596, -8.488444695212332],
                                    [41.1863323189064, -8.488426369886042],
                                ],
                                [
                                    [41.186327407815085, -8.488452339669367],
                                    [41.18631567986829, -8.488470664995656],
                                    [41.1863088630128, -8.48846302053862],
                                    [41.1863205909596, -8.488444695212332],
                                ],
                                [
                                    [41.18631567986829, -8.488470664995656],
                                    [41.18630395192149, -8.488488990321944],
                                    [41.186297135066, -8.488481345864907],
                                    [41.1863088630128, -8.48846302053862],
                                ],
                                [
                                    [41.18630104176099, -8.48849353754931],
                                    [41.186289313814186, -8.4885118628756],
                                    [41.186282496958704, -8.488504218418562],
                                    [41.1862942249055, -8.488485893092275],
                                ],
                                [
                                    [41.186289313814186, -8.4885118628756],
                                    [41.18627758586739, -8.488530188201887],
                                    [41.1862707690119, -8.48852254374485],
                                    [41.186282496958704, -8.488504218418562],
                                ],
                                [
                                    [41.18627758586739, -8.488530188201887],
                                    [41.18626585792058, -8.488548513528174],
                                    [41.1862590410651, -8.488540869071139],
                                    [41.1862707690119, -8.48852254374485],
                                ],
                                [
                                    [41.186262947760085, -8.488553060755542],
                                    [41.186251219813286, -8.48857138608183],
                                    [41.1862444029578, -8.488563741624793],
                                    [41.1862561309046, -8.488545416298505],
                                ],
                                [
                                    [41.186251219813286, -8.48857138608183],
                                    [41.18623949186648, -8.488589711408117],
                                    [41.186232675011, -8.488582066951082],
                                    [41.1862444029578, -8.488563741624793],
                                ],
                                [
                                    [41.18623949186648, -8.488589711408117],
                                    [41.18622776391968, -8.488608036734407],
                                    [41.18622094706419, -8.48860039227737],
                                    [41.186232675011, -8.488582066951082],
                                ],
                                [
                                    [41.186224853759185, -8.488612583961773],
                                    [41.18621312581238, -8.48863090928806],
                                    [41.1862063089569, -8.488623264831025],
                                    [41.186218036903696, -8.488604939504736],
                                ],
                                [
                                    [41.18621312581238, -8.48863090928806],
                                    [41.18620139786558, -8.48864923461435],
                                    [41.18619458101009, -8.488641590157313],
                                    [41.1862063089569, -8.488623264831025],
                                ],
                                [
                                    [41.18620139786558, -8.48864923461435],
                                    [41.186189669918775, -8.488667559940637],
                                    [41.18618285306329, -8.4886599154836],
                                    [41.18619458101009, -8.488641590157313],
                                ],
                                [
                                    [41.18618675975828, -8.488672107168004],
                                    [41.18617503181148, -8.488690432494293],
                                    [41.18616821495599, -8.488682788037256],
                                    [41.1861799429028, -8.488664462710968],
                                ],
                                [
                                    [41.186163303864674, -8.48870875782058],
                                    [41.186151575917876, -8.488727083146868],
                                    [41.18614475906239, -8.488719438689833],
                                    [41.18615648700919, -8.488701113363543],
                                ],
                                [
                                    [41.18614866575738, -8.488731630374236],
                                    [41.18613693781057, -8.488749955700523],
                                    [41.18613012095509, -8.488742311243486],
                                    [41.18614184890189, -8.488723985917199],
                                ],
                                [
                                    [41.18613693781057, -8.488749955700523],
                                    [41.186125209863775, -8.488768281026811],
                                    [41.186118393008286, -8.488760636569776],
                                    [41.18613012095509, -8.488742311243486],
                                ],
                                [
                                    [41.186125209863775, -8.488768281026811],
                                    [41.18611348191697, -8.4887866063531],
                                    [41.18610666506149, -8.488778961896063],
                                    [41.186118393008286, -8.488760636569776],
                                ],
                                [
                                    [41.186385051014604, -8.488343974126156],
                                    [41.186373323067805, -8.488362299452445],
                                    [41.186366506212316, -8.488354654995408],
                                    [41.18637823415912, -8.48833632966912],
                                ],
                                [
                                    [41.18637041290731, -8.488366846679812],
                                    [41.1863586849605, -8.4883851720061],
                                    [41.18635186810502, -8.488377527549064],
                                    [41.18636359605182, -8.488359202222776],
                                ],
                                [
                                    [41.1863586849605, -8.4883851720061],
                                    [41.186346957013704, -8.488403497332389],
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18635186810502, -8.488377527549064],
                                ],
                                [
                                    [41.186346957013704, -8.488403497332389],
                                    [41.1863352290669, -8.488421822658676],
                                    [41.18632841221142, -8.48841417820164],
                                    [41.186340140158215, -8.488395852875351],
                                ],
                                [
                                    [41.1863323189064, -8.488426369886042],
                                    [41.1863205909596, -8.488444695212332],
                                    [41.186313774104114, -8.488437050755294],
                                    [41.18632550205092, -8.488418725429007],
                                ],
                                [
                                    [41.1863205909596, -8.488444695212332],
                                    [41.1863088630128, -8.48846302053862],
                                    [41.186302046157316, -8.488455376081584],
                                    [41.186313774104114, -8.488437050755294],
                                ],
                                [
                                    [41.1863088630128, -8.48846302053862],
                                    [41.186297135066, -8.488481345864907],
                                    [41.18629031821052, -8.488473701407871],
                                    [41.186302046157316, -8.488455376081584],
                                ],
                                [
                                    [41.1862942249055, -8.488485893092275],
                                    [41.186282496958704, -8.488504218418562],
                                    [41.186275680103215, -8.488496573961527],
                                    [41.18628740805001, -8.488478248635237],
                                ],
                                [
                                    [41.186282496958704, -8.488504218418562],
                                    [41.1862707690119, -8.48852254374485],
                                    [41.186263952156416, -8.488514899287814],
                                    [41.186275680103215, -8.488496573961527],
                                ],
                                [
                                    [41.1862707690119, -8.48852254374485],
                                    [41.1862590410651, -8.488540869071139],
                                    [41.18625222420961, -8.488533224614102],
                                    [41.186263952156416, -8.488514899287814],
                                ],
                                [
                                    [41.1862561309046, -8.488545416298505],
                                    [41.1862444029578, -8.488563741624793],
                                    [41.186237586102315, -8.488556097167757],
                                    [41.186249314049114, -8.48853777184147],
                                ],
                                [
                                    [41.1862444029578, -8.488563741624793],
                                    [41.186232675011, -8.488582066951082],
                                    [41.18622585815551, -8.488574422494045],
                                    [41.186237586102315, -8.488556097167757],
                                ],
                                [
                                    [41.186232675011, -8.488582066951082],
                                    [41.18622094706419, -8.48860039227737],
                                    [41.18621413020871, -8.488592747820334],
                                    [41.18622585815551, -8.488574422494045],
                                ],
                                [
                                    [41.186218036903696, -8.488604939504736],
                                    [41.1862063089569, -8.488623264831025],
                                    [41.18619949210141, -8.488615620373988],
                                    [41.186211220048214, -8.4885972950477],
                                ],
                                [
                                    [41.1862063089569, -8.488623264831025],
                                    [41.18619458101009, -8.488641590157313],
                                    [41.18618776415461, -8.488633945700277],
                                    [41.18619949210141, -8.488615620373988],
                                ],
                                [
                                    [41.18619458101009, -8.488641590157313],
                                    [41.18618285306329, -8.4886599154836],
                                    [41.186176036207804, -8.488652271026565],
                                    [41.18618776415461, -8.488633945700277],
                                ],
                                [
                                    [41.1861799429028, -8.488664462710968],
                                    [41.18616821495599, -8.488682788037256],
                                    [41.18616139810051, -8.48867514358022],
                                    [41.18617312604731, -8.488656818253931],
                                ],
                                [
                                    [41.18615648700919, -8.488701113363543],
                                    [41.18614475906239, -8.488719438689833],
                                    [41.186137942206905, -8.488711794232795],
                                    [41.1861496701537, -8.488693468906508],
                                ],
                                [
                                    [41.18614184890189, -8.488723985917199],
                                    [41.18613012095509, -8.488742311243486],
                                    [41.1861233040996, -8.488734666786451],
                                    [41.18613503204641, -8.488716341460163],
                                ],
                                [
                                    [41.18613012095509, -8.488742311243486],
                                    [41.186118393008286, -8.488760636569776],
                                    [41.186111576152804, -8.488752992112738],
                                    [41.1861233040996, -8.488734666786451],
                                ],
                                [
                                    [41.186118393008286, -8.488760636569776],
                                    [41.18610666506149, -8.488778961896063],
                                    [41.186099848206, -8.488771317439028],
                                    [41.186111576152804, -8.488752992112738],
                                ],
                                [
                                    [41.18637823415912, -8.48833632966912],
                                    [41.186366506212316, -8.488354654995408],
                                    [41.186359689356834, -8.488347010538373],
                                    [41.18637141730363, -8.488328685212084],
                                ],
                                [
                                    [41.18636359605182, -8.488359202222776],
                                    [41.18635186810502, -8.488377527549064],
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18635677919634, -8.48835155776574],
                                ],
                                [
                                    [41.18635186810502, -8.488377527549064],
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18633332330273, -8.488388208418316],
                                    [41.18634505124953, -8.488369883092027],
                                ],
                                [
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18632841221142, -8.48841417820164],
                                    [41.18632159535593, -8.488406533744604],
                                    [41.18633332330273, -8.488388208418316],
                                ],
                                [
                                    [41.18632550205092, -8.488418725429007],
                                    [41.186313774104114, -8.488437050755294],
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18631868519543, -8.48841108097197],
                                ],
                                [
                                    [41.186313774104114, -8.488437050755294],
                                    [41.186302046157316, -8.488455376081584],
                                    [41.18629522930183, -8.488447731624547],
                                    [41.18630695724863, -8.488429406298259],
                                ],
                                [
                                    [41.186302046157316, -8.488455376081584],
                                    [41.18629031821052, -8.488473701407871],
                                    [41.18628350135503, -8.488466056950834],
                                    [41.18629522930183, -8.488447731624547],
                                ],
                                [
                                    [41.18628740805001, -8.488478248635237],
                                    [41.186275680103215, -8.488496573961527],
                                    [41.18626886324773, -8.48848892950449],
                                    [41.18628059119453, -8.488470604178202],
                                ],
                                [
                                    [41.186275680103215, -8.488496573961527],
                                    [41.186263952156416, -8.488514899287814],
                                    [41.18625713530093, -8.488507254830777],
                                    [41.18626886324773, -8.48848892950449],
                                ],
                                [
                                    [41.186263952156416, -8.488514899287814],
                                    [41.18625222420961, -8.488533224614102],
                                    [41.18624540735413, -8.488525580157066],
                                    [41.18625713530093, -8.488507254830777],
                                ],
                                [
                                    [41.186249314049114, -8.48853777184147],
                                    [41.186237586102315, -8.488556097167757],
                                    [41.186230769246826, -8.48854845271072],
                                    [41.18624249719363, -8.488530127384433],
                                ],
                                [
                                    [41.186237586102315, -8.488556097167757],
                                    [41.18622585815551, -8.488574422494045],
                                    [41.18621904130003, -8.48856677803701],
                                    [41.186230769246826, -8.48854845271072],
                                ],
                                [
                                    [41.18622585815551, -8.488574422494045],
                                    [41.18621413020871, -8.488592747820334],
                                    [41.18620731335322, -8.488585103363297],
                                    [41.18621904130003, -8.48856677803701],
                                ],
                                [
                                    [41.186211220048214, -8.4885972950477],
                                    [41.18619949210141, -8.488615620373988],
                                    [41.18619267524593, -8.488607975916953],
                                    [41.186204403192725, -8.488589650590663],
                                ],
                                [
                                    [41.18619949210141, -8.488615620373988],
                                    [41.18618776415461, -8.488633945700277],
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18619267524593, -8.488607975916953],
                                ],
                                [
                                    [41.18618776415461, -8.488633945700277],
                                    [41.186176036207804, -8.488652271026565],
                                    [41.18616921935232, -8.488644626569528],
                                    [41.18618094729912, -8.48862630124324],
                                ],
                                [
                                    [41.18617312604731, -8.488656818253931],
                                    [41.18616139810051, -8.48867514358022],
                                    [41.18615458124502, -8.488667499123183],
                                    [41.186166309191826, -8.488649173796896],
                                ],
                                [
                                    [41.1861496701537, -8.488693468906508],
                                    [41.186137942206905, -8.488711794232795],
                                    [41.186131125351416, -8.48870414977576],
                                    [41.18614285329822, -8.48868582444947],
                                ],
                                [
                                    [41.18613503204641, -8.488716341460163],
                                    [41.1861233040996, -8.488734666786451],
                                    [41.18611648724412, -8.488727022329414],
                                    [41.18612821519092, -8.488708697003126],
                                ],
                                [
                                    [41.1861233040996, -8.488734666786451],
                                    [41.186111576152804, -8.488752992112738],
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18611648724412, -8.488727022329414],
                                ],
                                [
                                    [41.186111576152804, -8.488752992112738],
                                    [41.186099848206, -8.488771317439028],
                                    [41.18609303135052, -8.48876367298199],
                                    [41.186104759297315, -8.488745347655703],
                                ],
                                [
                                    [41.18637141730363, -8.488328685212084],
                                    [41.186359689356834, -8.488347010538373],
                                    [41.186352872501345, -8.488339366081336],
                                    [41.18636460044815, -8.488321040755048],
                                ],
                                [
                                    [41.18635677919634, -8.48835155776574],
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18633823439405, -8.488362238634991],
                                    [41.18634996234085, -8.488343913308704],
                                ],
                                [
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18633332330273, -8.488388208418316],
                                    [41.186326506447244, -8.488380563961279],
                                    [41.18633823439405, -8.488362238634991],
                                ],
                                [
                                    [41.18633332330273, -8.488388208418316],
                                    [41.18632159535593, -8.488406533744604],
                                    [41.186314778500446, -8.488398889287568],
                                    [41.186326506447244, -8.488380563961279],
                                ],
                                [
                                    [41.18631868519543, -8.48841108097197],
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18630014039314, -8.488421761841222],
                                    [41.18631186833995, -8.488403436514934],
                                ],
                                [
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18629522930183, -8.488447731624547],
                                    [41.186288412446345, -8.488440087167511],
                                    [41.18630014039314, -8.488421761841222],
                                ],
                                [
                                    [41.18629522930183, -8.488447731624547],
                                    [41.18628350135503, -8.488466056950834],
                                    [41.18627668449954, -8.488458412493799],
                                    [41.186288412446345, -8.488440087167511],
                                ],
                                [
                                    [41.18628059119453, -8.488470604178202],
                                    [41.18626886324773, -8.48848892950449],
                                    [41.186262046392244, -8.488481285047454],
                                    [41.18627377433904, -8.488462959721165],
                                ],
                                [
                                    [41.18626886324773, -8.48848892950449],
                                    [41.18625713530093, -8.488507254830777],
                                    [41.186250318445445, -8.488499610373742],
                                    [41.186262046392244, -8.488481285047454],
                                ],
                                [
                                    [41.18625713530093, -8.488507254830777],
                                    [41.18624540735413, -8.488525580157066],
                                    [41.18623859049864, -8.48851793570003],
                                    [41.186250318445445, -8.488499610373742],
                                ],
                                [
                                    [41.18624249719363, -8.488530127384433],
                                    [41.186230769246826, -8.48854845271072],
                                    [41.186223952391344, -8.488540808253685],
                                    [41.18623568033814, -8.488522482927397],
                                ],
                                [
                                    [41.186230769246826, -8.48854845271072],
                                    [41.18621904130003, -8.48856677803701],
                                    [41.18621222444454, -8.488559133579972],
                                    [41.186223952391344, -8.488540808253685],
                                ],
                                [
                                    [41.18621904130003, -8.48856677803701],
                                    [41.18620731335322, -8.488585103363297],
                                    [41.18620049649774, -8.488577458906262],
                                    [41.18621222444454, -8.488559133579972],
                                ],
                                [
                                    [41.186204403192725, -8.488589650590663],
                                    [41.18619267524593, -8.488607975916953],
                                    [41.18618585839044, -8.488600331459915],
                                    [41.186197586337244, -8.488582006133628],
                                ],
                                [
                                    [41.18619267524593, -8.488607975916953],
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18617413044364, -8.488618656786205],
                                    [41.18618585839044, -8.488600331459915],
                                ],
                                [
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18616921935232, -8.488644626569528],
                                    [41.186162402496834, -8.488636982112492],
                                    [41.18617413044364, -8.488618656786205],
                                ],
                                [
                                    [41.186166309191826, -8.488649173796896],
                                    [41.18615458124502, -8.488667499123183],
                                    [41.18614776438954, -8.488659854666148],
                                    [41.18615949233634, -8.488641529339858],
                                ],
                                [
                                    [41.18614285329822, -8.48868582444947],
                                    [41.186131125351416, -8.48870414977576],
                                    [41.186124308495934, -8.488696505318723],
                                    [41.18613603644273, -8.488678179992435],
                                ],
                                [
                                    [41.18612821519092, -8.488708697003126],
                                    [41.18611648724412, -8.488727022329414],
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18612139833544, -8.48870105254609],
                                ],
                                [
                                    [41.18611648724412, -8.488727022329414],
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18609794244183, -8.488737703198666],
                                    [41.18610967038863, -8.488719377872378],
                                ],
                                [
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18609303135052, -8.48876367298199],
                                    [41.18608621449503, -8.488756028524955],
                                    [41.18609794244183, -8.488737703198666],
                                ],
                                [
                                    [41.18636460044815, -8.488321040755048],
                                    [41.186352872501345, -8.488339366081336],
                                    [41.18634605564586, -8.4883317216243],
                                    [41.18635778359266, -8.488313396298011],
                                ],
                                [
                                    [41.18634996234085, -8.488343913308704],
                                    [41.18633823439405, -8.488362238634991],
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18634314548537, -8.488336268851667],
                                ],
                                [
                                    [41.18633823439405, -8.488362238634991],
                                    [41.186326506447244, -8.488380563961279],
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18633141753856, -8.488354594177954],
                                ],
                                [
                                    [41.186326506447244, -8.488380563961279],
                                    [41.186314778500446, -8.488398889287568],
                                    [41.18630796164496, -8.488391244830531],
                                    [41.18631968959176, -8.488372919504243],
                                ],
                                [
                                    [41.18631186833995, -8.488403436514934],
                                    [41.18630014039314, -8.488421761841222],
                                    [41.18629332353766, -8.488414117384186],
                                    [41.18630505148446, -8.488395792057897],
                                ],
                                [
                                    [41.18630014039314, -8.488421761841222],
                                    [41.186288412446345, -8.488440087167511],
                                    [41.186281595590856, -8.488432442710474],
                                    [41.18629332353766, -8.488414117384186],
                                ],
                                [
                                    [41.186288412446345, -8.488440087167511],
                                    [41.18627668449954, -8.488458412493799],
                                    [41.18626986764406, -8.488450768036762],
                                    [41.186281595590856, -8.488432442710474],
                                ],
                                [
                                    [41.186262046392244, -8.488481285047454],
                                    [41.186250318445445, -8.488499610373742],
                                    [41.186243501589956, -8.488491965916705],
                                    [41.186255229536755, -8.488473640590417],
                                ],
                                [
                                    [41.186250318445445, -8.488499610373742],
                                    [41.18623859049864, -8.48851793570003],
                                    [41.18623177364316, -8.488510291242994],
                                    [41.186243501589956, -8.488491965916705],
                                ],
                                [
                                    [41.18623568033814, -8.488522482927397],
                                    [41.186223952391344, -8.488540808253685],
                                    [41.186217135535856, -8.488533163796648],
                                    [41.18622886348266, -8.48851483847036],
                                ],
                                [
                                    [41.186223952391344, -8.488540808253685],
                                    [41.18621222444454, -8.488559133579972],
                                    [41.18620540758906, -8.488551489122937],
                                    [41.186217135535856, -8.488533163796648],
                                ],
                                [
                                    [41.18621222444454, -8.488559133579972],
                                    [41.18620049649774, -8.488577458906262],
                                    [41.18619367964225, -8.488569814449225],
                                    [41.18620540758906, -8.488551489122937],
                                ],
                                [
                                    [41.186197586337244, -8.488582006133628],
                                    [41.18618585839044, -8.488600331459915],
                                    [41.186179041534956, -8.48859268700288],
                                    [41.186190769481755, -8.48857436167659],
                                ],
                                [
                                    [41.18618585839044, -8.488600331459915],
                                    [41.18617413044364, -8.488618656786205],
                                    [41.18616731358815, -8.488611012329168],
                                    [41.186179041534956, -8.48859268700288],
                                ],
                                [
                                    [41.18613603644273, -8.488678179992435],
                                    [41.186124308495934, -8.488696505318723],
                                    [41.186117491640445, -8.488688860861688],
                                    [41.18612921958725, -8.488670535535398],
                                ],
                                [
                                    [41.18612139833544, -8.48870105254609],
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18610285353315, -8.488711733415341],
                                    [41.18611458147995, -8.488693408089054],
                                ],
                                [
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18609794244183, -8.488737703198666],
                                    [41.186091125586344, -8.48873005874163],
                                    [41.18610285353315, -8.488711733415341],
                                ],
                                [
                                    [41.18609794244183, -8.488737703198666],
                                    [41.18608621449503, -8.488756028524955],
                                    [41.186079397639546, -8.488748384067918],
                                    [41.186091125586344, -8.48873005874163],
                                ],
                                [
                                    [41.18635778359266, -8.488313396298011],
                                    [41.18634605564586, -8.4883317216243],
                                    [41.186339238790374, -8.488324077167263],
                                    [41.18635096673718, -8.488305751840976],
                                ],
                                [
                                    [41.18634314548537, -8.488336268851667],
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18632460068308, -8.488346949720919],
                                    [41.18633632862988, -8.488328624394631],
                                ],
                                [
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18631287273627, -8.488365275047206],
                                    [41.18632460068308, -8.488346949720919],
                                ],
                                [
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18630796164496, -8.488391244830531],
                                    [41.186301144789475, -8.488383600373496],
                                    [41.18631287273627, -8.488365275047206],
                                ],
                                [
                                    [41.18630505148446, -8.488395792057897],
                                    [41.18629332353766, -8.488414117384186],
                                    [41.18628650668217, -8.48840647292715],
                                    [41.18629823462898, -8.488388147600862],
                                ],
                                [
                                    [41.18629332353766, -8.488414117384186],
                                    [41.186281595590856, -8.488432442710474],
                                    [41.186274778735374, -8.488424798253439],
                                    [41.18628650668217, -8.48840647292715],
                                ],
                                [
                                    [41.186281595590856, -8.488432442710474],
                                    [41.18626986764406, -8.488450768036762],
                                    [41.18626305078857, -8.488443123579726],
                                    [41.186274778735374, -8.488424798253439],
                                ],
                                [
                                    [41.186255229536755, -8.488473640590417],
                                    [41.186243501589956, -8.488491965916705],
                                    [41.186236684734475, -8.48848432145967],
                                    [41.18624841268127, -8.488465996133382],
                                ],
                                [
                                    [41.186243501589956, -8.488491965916705],
                                    [41.18623177364316, -8.488510291242994],
                                    [41.18622495678767, -8.488502646785957],
                                    [41.186236684734475, -8.48848432145967],
                                ],
                                [
                                    [41.18622886348266, -8.48851483847036],
                                    [41.186217135535856, -8.488533163796648],
                                    [41.186210318680374, -8.488525519339612],
                                    [41.18622204662717, -8.488507194013325],
                                ],
                                [
                                    [41.186217135535856, -8.488533163796648],
                                    [41.18620540758906, -8.488551489122937],
                                    [41.18619859073357, -8.4885438446659],
                                    [41.186210318680374, -8.488525519339612],
                                ],
                                [
                                    [41.18620540758906, -8.488551489122937],
                                    [41.18619367964225, -8.488569814449225],
                                    [41.18618686278677, -8.48856216999219],
                                    [41.18619859073357, -8.4885438446659],
                                ],
                                [
                                    [41.186190769481755, -8.48857436167659],
                                    [41.186179041534956, -8.48859268700288],
                                    [41.18617222467947, -8.488585042545843],
                                    [41.18618395262627, -8.488566717219555],
                                ],
                                [
                                    [41.186179041534956, -8.48859268700288],
                                    [41.18616731358815, -8.488611012329168],
                                    [41.18616049673267, -8.488603367872132],
                                    [41.18617222467947, -8.488585042545843],
                                ],
                                [
                                    [41.18612921958725, -8.488670535535398],
                                    [41.186117491640445, -8.488688860861688],
                                    [41.18611067478496, -8.48868121640465],
                                    [41.18612240273176, -8.488662891078363],
                                ],
                                [
                                    [41.18611458147995, -8.488693408089054],
                                    [41.18610285353315, -8.488711733415341],
                                    [41.18609603667766, -8.488704088958306],
                                    [41.18610776462447, -8.488685763632018],
                                ],
                                [
                                    [41.18610285353315, -8.488711733415341],
                                    [41.186091125586344, -8.48873005874163],
                                    [41.18608430873086, -8.488722414284593],
                                    [41.18609603667766, -8.488704088958306],
                                ],
                                [
                                    [41.186091125586344, -8.48873005874163],
                                    [41.186079397639546, -8.488748384067918],
                                    [41.18607258078406, -8.488740739610883],
                                    [41.18608430873086, -8.488722414284593],
                                ],
                            ],
                            panels_idx: [
                                [0, -5, [0, 0]],
                                [0, -5, [0, 1]],
                                [0, -5, [0, 2]],
                                [0, -4, [0, 0]],
                                [0, -4, [0, 1]],
                                [0, -4, [0, 2]],
                                [0, -3, [0, 0]],
                                [0, -3, [0, 1]],
                                [0, -3, [0, 2]],
                                [0, -2, [0, 0]],
                                [0, -2, [0, 2]],
                                [0, -1, [0, 0]],
                                [0, -1, [0, 1]],
                                [0, -1, [0, 2]],
                                [1, -8, [0, 2]],
                                [1, -7, [0, 0]],
                                [1, -7, [0, 1]],
                                [1, -7, [0, 2]],
                                [1, -6, [0, 0]],
                                [1, -6, [0, 1]],
                                [1, -6, [0, 2]],
                                [1, -5, [0, 0]],
                                [1, -5, [0, 1]],
                                [1, -5, [0, 2]],
                                [1, -4, [0, 0]],
                                [1, -4, [0, 1]],
                                [1, -4, [0, 2]],
                                [1, -3, [0, 0]],
                                [1, -3, [0, 1]],
                                [1, -3, [0, 2]],
                                [1, -2, [0, 0]],
                                [1, -2, [0, 2]],
                                [1, -1, [0, 0]],
                                [1, -1, [0, 1]],
                                [1, -1, [0, 2]],
                                [2, -8, [0, 2]],
                                [2, -7, [0, 0]],
                                [2, -7, [0, 1]],
                                [2, -7, [0, 2]],
                                [2, -6, [0, 0]],
                                [2, -6, [0, 1]],
                                [2, -6, [0, 2]],
                                [2, -5, [0, 0]],
                                [2, -5, [0, 1]],
                                [2, -5, [0, 2]],
                                [2, -4, [0, 0]],
                                [2, -4, [0, 1]],
                                [2, -4, [0, 2]],
                                [2, -3, [0, 0]],
                                [2, -3, [0, 1]],
                                [2, -3, [0, 2]],
                                [2, -2, [0, 0]],
                                [2, -2, [0, 2]],
                                [2, -1, [0, 0]],
                                [2, -1, [0, 1]],
                                [2, -1, [0, 2]],
                                [3, -8, [0, 2]],
                                [3, -7, [0, 0]],
                                [3, -7, [0, 1]],
                                [3, -7, [0, 2]],
                                [3, -6, [0, 0]],
                                [3, -6, [0, 1]],
                                [3, -6, [0, 2]],
                                [3, -5, [0, 0]],
                                [3, -5, [0, 1]],
                                [3, -5, [0, 2]],
                                [3, -4, [0, 0]],
                                [3, -4, [0, 1]],
                                [3, -4, [0, 2]],
                                [3, -3, [0, 0]],
                                [3, -3, [0, 1]],
                                [3, -3, [0, 2]],
                                [3, -2, [0, 0]],
                                [3, -2, [0, 2]],
                                [3, -1, [0, 0]],
                                [3, -1, [0, 1]],
                                [3, -1, [0, 2]],
                                [4, -8, [0, 2]],
                                [4, -7, [0, 0]],
                                [4, -7, [0, 1]],
                                [4, -7, [0, 2]],
                                [4, -6, [0, 0]],
                                [4, -6, [0, 1]],
                                [4, -6, [0, 2]],
                                [4, -5, [0, 0]],
                                [4, -5, [0, 1]],
                                [4, -5, [0, 2]],
                                [4, -4, [0, 0]],
                                [4, -4, [0, 1]],
                                [4, -4, [0, 2]],
                                [4, -3, [0, 0]],
                                [4, -3, [0, 1]],
                                [4, -3, [0, 2]],
                                [4, -2, [0, 0]],
                                [4, -2, [0, 2]],
                                [4, -1, [0, 0]],
                                [4, -1, [0, 1]],
                                [4, -1, [0, 2]],
                                [5, -8, [0, 2]],
                                [5, -7, [0, 0]],
                                [5, -7, [0, 1]],
                                [5, -7, [0, 2]],
                                [5, -6, [0, 0]],
                                [5, -6, [0, 1]],
                                [5, -6, [0, 2]],
                                [5, -5, [0, 1]],
                                [5, -5, [0, 2]],
                                [5, -4, [0, 0]],
                                [5, -4, [0, 1]],
                                [5, -4, [0, 2]],
                                [5, -3, [0, 0]],
                                [5, -3, [0, 1]],
                                [5, -2, [0, 2]],
                                [5, -1, [0, 0]],
                                [5, -1, [0, 1]],
                                [5, -1, [0, 2]],
                                [6, -8, [0, 2]],
                                [6, -7, [0, 0]],
                                [6, -7, [0, 1]],
                                [6, -7, [0, 2]],
                                [6, -6, [0, 0]],
                                [6, -6, [0, 1]],
                                [6, -6, [0, 2]],
                                [6, -5, [0, 1]],
                                [6, -5, [0, 2]],
                                [6, -4, [0, 0]],
                                [6, -4, [0, 1]],
                                [6, -4, [0, 2]],
                                [6, -3, [0, 0]],
                                [6, -3, [0, 1]],
                                [6, -2, [0, 2]],
                                [6, -1, [0, 0]],
                                [6, -1, [0, 1]],
                                [6, -1, [0, 2]],
                            ],
                            perc_shadow: [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            ],
                            panels_number: 134,
                        },
                    ],
                    coordinates: [
                        {
                            lat: 41.186407983698146,
                            lng: -8.488353057749572,
                        },
                        {
                            lat: 41.18634742692,
                            lng: -8.4882860025242,
                        },
                        {
                            lat: 41.18606079150344,
                            lng: -8.488735272534194,
                        },
                        {
                            lat: 41.186121348281596,
                            lng: -8.488803668864074,
                        },
                        {
                            lat: 41.186407983698146,
                            lng: -8.488353057749572,
                        },
                    ],
                    orientation: 139.73,
                    region_gd_id: 125,
                    panels_number: 134,
                    perimeter_area: 116.39452684003277,
                    coordinates_avg: {
                        lat: 41.18626910682026,
                        long: -8.488506211884323,
                        region_gd_id: 125,
                    },
                    autoSplitBuilding: 1,
                    panel_representation: {
                        dayRank: '2024-12-22T00:00:00.000Z',
                        setback: 0.5,
                        rowSpace: 0,
                        alignment: 'left',
                        panels_nr: 3,
                        panels_up: 1,
                        roofSlope: 5,
                        row_space: 0,
                        hourWindow: 4,
                        panels_wide: 3,
                        panelFraming: 1,
                        space_btw_sets: 0.5,
                        reference_point: [41.18612029877246, -8.488794250810136],
                        isDefaultRowRatio: false,
                        alignment_vertical: 'bottom',
                        alignment_horizontal: 'left',
                        is_row_spacing_insert: true,
                        coordinates_conversion_factor: {
                            one_meter_in_lat: 0.00000900434376143347,
                            one_meter_in_lon: 0.00001191923025934094,
                        },
                    },
                    mounting_structure_id: 7,
                    panels_number_excluded: 0,
                    panels_number_possible: 134,
                    mounting_structure_type_id: 18,
                },
            ],
            panel_id: 8,
            range_id: 1,
            buildings: [],
            exclusions: [
                {
                    id: 1,
                    area: 2.3236966347219257,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18617316347283, -8.488563568358668],
                        [41.18618324631769, -8.488547754070915],
                        [41.18617407397729, -8.488537428467358],
                        [41.18616399113243, -8.488553242755112],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 2,
                    area: 2.3883073931472767,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186274582160976, -8.488404166121391],
                        [41.18628514290098, -8.488387602286126],
                        [41.18627614211645, -8.488377469808624],
                        [41.186265581376446, -8.488394033643889],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 3,
                    area: 11.675015378699932,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18603549503049, -8.488645928834034],
                        [41.186075928736386, -8.488582511190609],
                        [41.186064436702104, -8.488569574231784],
                        [41.186024002996206, -8.488632991875209],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 4,
                    area: 5.258506222422111,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18603166304474, -8.488638176882642],
                        [41.18606929017439, -8.48857916117191],
                        [41.18606372800404, -8.488572899654843],
                        [41.186026100874386, -8.488631915365575],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 5,
                    area: 3.2157922936748062,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.1862581688646, -8.4884462602208],
                        [41.18624740430836, -8.488463143719617],
                        [41.18625929414733, -8.488476528529995],
                        [41.18627005870356, -8.488459645031178],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 6,
                    area: 3.2750328350843887,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186153259099875, -8.488609235659645],
                        [41.18614248717701, -8.488626130712536],
                        [41.18615458774738, -8.488639752750684],
                        [41.18616535967025, -8.488622857697793],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 7,
                    area: 1.3555382376282392,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18628200384683, -8.488386855640433],
                        [41.1862722522419, -8.488402150391726],
                        [41.18627778472285, -8.488408378500344],
                        [41.18628753632778, -8.48839308374905],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 8,
                    area: 4.929091323309152,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186128021055545, -8.488652061540803],
                        [41.18612427355185, -8.488657939253995],
                        [41.18617662252929, -8.488716870341182],
                        [41.18618037003298, -8.48871099262799],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 9,
                    area: 1.258065297983659,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18618149003819, -8.488546753576067],
                        [41.1861705707031, -8.488563879835331],
                        [41.186175156244, -8.488569041940183],
                        [41.18618607557908, -8.488551915680917],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 10,
                    area: 0.9409541472002925,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18628546455482, -8.488389084310926],
                        [41.186274891939085, -8.488405666763681],
                        [41.18627843411626, -8.488409654317254],
                        [41.186289006732, -8.488393071864499],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 11,
                    area: 0.8428999954032902,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.1862658088651, -8.488458005587107],
                        [41.1862569567156, -8.488471889601671],
                        [41.1862607464734, -8.488476155865465],
                        [41.18626959862289, -8.488462271850901],
                    ],
                    autoSplitBuilding: 1,
                },
            ],
            prod_params: {
                param: 'realTimeV1',
                raddatabase: 'PVGIS-SARAH',
                pvtechchoice: 'crystSi',
                pvgis_ano_tipico: 2014,
                factor_perda_inicial: 0.0834,
                geogrid_factor_correcao: null,
                factor_perda_extra_coplanar: 0.0325,
            },
            total_areas: 870.2858837294776,
            network_sale: true,
            total_panels: 280,
            max_tec_panels: 3012,
            min_tec_panels: 10,
            coordinates_avg: {
                lat: 41.18620663241081,
                long: -8.488509564645591,
                region_gd_id: 125,
            },
            injection_tariff: 0.06,
            limit_network_sale: false,
            inverters_combination: [
                {
                    id: 3,
                    qt: 0,
                    cost: 839.17,
                    brand: 'Huawei',
                    model: 'SUN2000-4KTL-M1',
                    power: 4,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 4,
                    qt: 0,
                    cost: 886.11,
                    brand: 'Huawei',
                    model: 'SUN2000-5KTL-M1',
                    power: 5,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 5,
                    qt: 0,
                    cost: 990.28,
                    brand: 'Huawei',
                    model: 'SUN2000-6KTL-M1',
                    power: 6,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 6,
                    qt: 0,
                    cost: 1174.59,
                    brand: 'Huawei',
                    model: 'SUN2000-8KTL-M1',
                    power: 8,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 7,
                    qt: 0,
                    cost: 1319.43,
                    brand: 'Huawei',
                    model: 'SUN2000-10KTL-M2',
                    power: 10,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 8,
                    qt: 0,
                    cost: 1407.92,
                    brand: 'Huawei',
                    model: 'SUN2000-12KTL-M2',
                    power: 12,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 10,
                    qt: 0,
                    cost: 1623.85,
                    brand: 'Huawei',
                    model: 'SUN2000-15KTL-M2',
                    power: 15,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 11,
                    qt: 0,
                    cost: 1630.98,
                    brand: 'Huawei',
                    model: 'SUN2000-17KTL-M2',
                    power: 17,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 12,
                    qt: 1,
                    cost: 1723.17,
                    brand: 'Huawei',
                    model: 'SUN2000-20KTL-M2',
                    power: 20,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 13,
                    qt: 0,
                    cost: 1966.38,
                    brand: 'Huawei',
                    model: 'SUN2000-30KTL-M3',
                    power: 30,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 14,
                    qt: 1,
                    cost: 2207.08,
                    brand: 'Huawei',
                    model: 'SUN2000-36KTL-M3',
                    power: 36,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 15,
                    qt: 1,
                    cost: 2360.96,
                    brand: 'Huawei',
                    model: 'SUN2000-40KTL-M3',
                    power: 40,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 16,
                    qt: 0,
                    cost: 2233.39,
                    brand: 'Huawei',
                    model: 'SUN2000-50KTL-M3',
                    power: 50,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 18,
                    qt: 0,
                    cost: 4384.41,
                    brand: 'Huawei',
                    model: 'SUN2000-100KTL-M1',
                    power: 100,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 20,
                    qt: 0,
                    cost: 4640.33,
                    brand: 'Sungrow',
                    model: 'SG110CX',
                    power: 110,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 19,
                    qt: 0,
                    cost: 3230.05,
                    brand: 'Sungrow',
                    model: 'SG50CX',
                    power: 50,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
            ],
            total_perimeter_areas: 232.789108655615,
            coordinates_conversion_factor: {
                one_meter_in_lat: 0.00000900434376143347,
                one_meter_in_lon: 0.00001191923025934094,
            },
        },
        data_gravacao: '2024-03-26T08:03:12.284Z',
        utilizador_id: '608138',
        ativo: true,
        visivel: true,
        versao_projeto_produto_inputs_id: 1,
        description: 'Ceramica Telhado',
        comments: 'Projeto de 100kWp, não sei que. Agua a sul.',
        data_criacao: '2024-03-26T08:03:12.225Z',
        data_edicao: '2024-03-26T08:03:12.225Z',
        tipo_produto_id: 1,
        empresa_id: 1,
    },
    {
        id: '446',
        projecto_id: '446',
        instalacao_id: '567883',
        inputs: {
            areas: [
                {
                    id: 1,
                    lat: 41.186144158001355,
                    area: 435.1431263231753,
                    long: -8.48851291740686,
                    edges: [
                        {
                            aspect: 49.480200361030604,
                            points: [
                                {
                                    lat: 41.1860521,
                                    lng: -8.4887187,
                                },
                                {
                                    lat: 41.186038599999996,
                                    lng: -8.4887035,
                                },
                            ],
                        },
                        {
                            aspect: -41.610839990283644,
                            points: [
                                {
                                    lat: 41.186038599999996,
                                    lng: -8.4887035,
                                },
                                {
                                    lat: 41.18601160000001,
                                    lng: -8.488673,
                                },
                            ],
                        },
                        {
                            aspect: -130.44565838115057,
                            points: [
                                {
                                    lat: 41.18601160000001,
                                    lng: -8.488673,
                                },
                                {
                                    lat: 41.1860049,
                                    lng: -8.4886653,
                                },
                            ],
                        },
                        {
                            aspect: 170.68635433153466,
                            points: [
                                {
                                    lat: 41.1860049,
                                    lng: -8.4886653,
                                },
                                {
                                    lat: 41.186082,
                                    lng: -8.4885456,
                                },
                            ],
                        },
                        {
                            aspect: 141.1543891066709,
                            points: [
                                {
                                    lat: 41.186082,
                                    lng: -8.4885456,
                                },
                                {
                                    lat: 41.1861688,
                                    lng: -8.4884109,
                                },
                            ],
                        },
                        {
                            aspect: 131.00873260257197,
                            points: [
                                {
                                    lat: 41.1861688,
                                    lng: -8.4884109,
                                },
                                {
                                    lat: 41.1862074,
                                    lng: -8.488351,
                                },
                            ],
                        },
                    ],
                    slope: 5,
                    aspect: -40.27116805574741,
                    selected: true,
                    panel_rows: [
                        {
                            positions: [
                                [
                                    [41.186012273946346, -8.48866562329768],
                                    [41.186024001893145, -8.488647297971392],
                                    [41.186030818748634, -8.488654942428427],
                                    [41.186019090801835, -8.488673267754715],
                                ],
                                [
                                    [41.18607382384085, -8.488569449438872],
                                    [41.186085551787656, -8.488551124112584],
                                    [41.18609236864314, -8.48855876856962],
                                    [41.18608064069634, -8.488577093895909],
                                ],
                                [
                                    [41.18608846194815, -8.488546576885216],
                                    [41.18610018989495, -8.488528251558929],
                                    [41.18610700675044, -8.488535896015966],
                                    [41.186095278803634, -8.488554221342254],
                                ],
                                [
                                    [41.18610018989495, -8.488528251558929],
                                    [41.18611191784176, -8.488509926232641],
                                    [41.18611873469724, -8.488517570689677],
                                    [41.18610700675044, -8.488535896015966],
                                ],
                                [
                                    [41.18611191784176, -8.488509926232641],
                                    [41.186123645788555, -8.488491600906352],
                                    [41.186130462644044, -8.48849924536339],
                                    [41.18611873469724, -8.488517570689677],
                                ],
                                [
                                    [41.18612655594905, -8.488487053678986],
                                    [41.18613828389586, -8.488468728352698],
                                    [41.18614510075134, -8.488476372809734],
                                    [41.18613337280454, -8.488494698136023],
                                ],
                                [
                                    [41.18613828389586, -8.488468728352698],
                                    [41.186150011842656, -8.488450403026409],
                                    [41.186156828698145, -8.488458047483446],
                                    [41.18614510075134, -8.488476372809734],
                                ],
                                [
                                    [41.186150011842656, -8.488450403026409],
                                    [41.18616173978946, -8.488432077700121],
                                    [41.186168556644944, -8.488439722157159],
                                    [41.186156828698145, -8.488458047483446],
                                ],
                                [
                                    [41.18616464994996, -8.488427530472755],
                                    [41.18617637789676, -8.488409205146466],
                                    [41.186183194752246, -8.488416849603503],
                                    [41.18617146680544, -8.48843517492979],
                                ],
                                [
                                    [41.18617637789676, -8.488409205146466],
                                    [41.18618810584356, -8.488390879820178],
                                    [41.186194922699045, -8.488398524277216],
                                    [41.186183194752246, -8.488416849603503],
                                ],
                                [
                                    [41.18618810584356, -8.488390879820178],
                                    [41.18619983379036, -8.48837255449389],
                                    [41.18620665064585, -8.488380198950926],
                                    [41.186194922699045, -8.488398524277216],
                                ],
                                [
                                    [41.18620274395086, -8.488368007266523],
                                    [41.186214471897664, -8.488349681940235],
                                    [41.186221288753146, -8.488357326397272],
                                    [41.18620956080635, -8.48837565172356],
                                ],
                                [
                                    [41.186214471897664, -8.488349681940235],
                                    [41.18622619984446, -8.488331356613948],
                                    [41.186233016699944, -8.488339001070983],
                                    [41.186221288753146, -8.488357326397272],
                                ],
                                [
                                    [41.18622619984446, -8.488331356613948],
                                    [41.18623792779126, -8.488313031287658],
                                    [41.18624474464675, -8.488320675744696],
                                    [41.186233016699944, -8.488339001070983],
                                ],
                                [
                                    [41.18624083795176, -8.488308484060292],
                                    [41.18625256589856, -8.488290158734005],
                                    [41.186259382754045, -8.48829780319104],
                                    [41.18624765480725, -8.48831612851733],
                                ],
                                [
                                    [41.18625256589856, -8.488290158734005],
                                    [41.18626429384536, -8.488271833407715],
                                    [41.18627111070085, -8.488279477864753],
                                    [41.186259382754045, -8.48829780319104],
                                ],
                                [
                                    [41.18626429384536, -8.488271833407715],
                                    [41.18627602179217, -8.488253508081428],
                                    [41.18628283864765, -8.488261152538465],
                                    [41.18627111070085, -8.488279477864753],
                                ],
                                [
                                    [41.186278931952664, -8.488248960854062],
                                    [41.18629065989946, -8.488230635527772],
                                    [41.18629747675495, -8.48823827998481],
                                    [41.186285748808146, -8.488256605311097],
                                ],
                                [
                                    [41.186019090801835, -8.488673267754715],
                                    [41.186030818748634, -8.488654942428427],
                                    [41.186037635604116, -8.488662586885464],
                                    [41.18602590765732, -8.488680912211752],
                                ],
                                [
                                    [41.186030818748634, -8.488654942428427],
                                    [41.18604254669543, -8.48863661710214],
                                    [41.18604936355092, -8.488644261559175],
                                    [41.186037635604116, -8.488662586885464],
                                ],
                                [
                                    [41.18604254669543, -8.48863661710214],
                                    [41.18605427464224, -8.48861829177585],
                                    [41.18606109149772, -8.488625936232888],
                                    [41.18604936355092, -8.488644261559175],
                                ],
                                [
                                    [41.186057184802735, -8.488613744548484],
                                    [41.18606891274953, -8.488595419222197],
                                    [41.18607572960502, -8.488603063679232],
                                    [41.18606400165822, -8.488621389005521],
                                ],
                                [
                                    [41.18606891274953, -8.488595419222197],
                                    [41.18608064069634, -8.488577093895909],
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18607572960502, -8.488603063679232],
                                ],
                                [
                                    [41.18608064069634, -8.488577093895909],
                                    [41.18609236864314, -8.48855876856962],
                                    [41.18609918549863, -8.488566413026657],
                                    [41.18608745755182, -8.488584738352944],
                                ],
                                [
                                    [41.186095278803634, -8.488554221342254],
                                    [41.18610700675044, -8.488535896015966],
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18610209565912, -8.488561865799289],
                                ],
                                [
                                    [41.18610700675044, -8.488535896015966],
                                    [41.18611873469724, -8.488517570689677],
                                    [41.18612555155273, -8.488525215146714],
                                    [41.18611382360592, -8.488543540473001],
                                ],
                                [
                                    [41.18611873469724, -8.488517570689677],
                                    [41.186130462644044, -8.48849924536339],
                                    [41.186137279499526, -8.488506889820425],
                                    [41.18612555155273, -8.488525215146714],
                                ],
                                [
                                    [41.18613337280454, -8.488494698136023],
                                    [41.18614510075134, -8.488476372809734],
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18614018966002, -8.488502342593058],
                                ],
                                [
                                    [41.18614510075134, -8.488476372809734],
                                    [41.186156828698145, -8.488458047483446],
                                    [41.18616364555363, -8.488465691940482],
                                    [41.18615191760683, -8.48848401726677],
                                ],
                                [
                                    [41.186156828698145, -8.488458047483446],
                                    [41.186168556644944, -8.488439722157159],
                                    [41.18617537350043, -8.488447366614194],
                                    [41.18616364555363, -8.488465691940482],
                                ],
                                [
                                    [41.18617146680544, -8.48843517492979],
                                    [41.186183194752246, -8.488416849603503],
                                    [41.18619001160773, -8.488424494060538],
                                    [41.18617828366093, -8.488442819386828],
                                ],
                                [
                                    [41.186183194752246, -8.488416849603503],
                                    [41.186194922699045, -8.488398524277216],
                                    [41.186201739554534, -8.488406168734251],
                                    [41.18619001160773, -8.488424494060538],
                                ],
                                [
                                    [41.186194922699045, -8.488398524277216],
                                    [41.18620665064585, -8.488380198950926],
                                    [41.18621346750133, -8.488387843407963],
                                    [41.186201739554534, -8.488406168734251],
                                ],
                                [
                                    [41.18620956080635, -8.48837565172356],
                                    [41.186221288753146, -8.488357326397272],
                                    [41.186228105608635, -8.488364970854308],
                                    [41.18621637766183, -8.488383296180595],
                                ],
                                [
                                    [41.186221288753146, -8.488357326397272],
                                    [41.186233016699944, -8.488339001070983],
                                    [41.18623983355543, -8.48834664552802],
                                    [41.186228105608635, -8.488364970854308],
                                ],
                                [
                                    [41.186233016699944, -8.488339001070983],
                                    [41.18624474464675, -8.488320675744696],
                                    [41.18625156150223, -8.488328320201731],
                                    [41.18623983355543, -8.48834664552802],
                                ],
                                [
                                    [41.18624765480725, -8.48831612851733],
                                    [41.186259382754045, -8.48829780319104],
                                    [41.186266199609534, -8.488305447648077],
                                    [41.18625447166273, -8.488323772974365],
                                ],
                                [
                                    [41.186259382754045, -8.48829780319104],
                                    [41.18627111070085, -8.488279477864753],
                                    [41.18627792755633, -8.488287122321788],
                                    [41.186266199609534, -8.488305447648077],
                                ],
                                [
                                    [41.18627111070085, -8.488279477864753],
                                    [41.18628283864765, -8.488261152538465],
                                    [41.18628965550314, -8.4882687969955],
                                    [41.18627792755633, -8.488287122321788],
                                ],
                                [
                                    [41.186285748808146, -8.488256605311097],
                                    [41.18629747675495, -8.48823827998481],
                                    [41.18630429361043, -8.488245924441845],
                                    [41.186292565663635, -8.488264249768134],
                                ],
                                [
                                    [41.18602590765732, -8.488680912211752],
                                    [41.186037635604116, -8.488662586885464],
                                    [41.186044452459605, -8.4886702313425],
                                    [41.186032724512806, -8.488688556668787],
                                ],
                                [
                                    [41.186037635604116, -8.488662586885464],
                                    [41.18604936355092, -8.488644261559175],
                                    [41.1860561804064, -8.488651906016212],
                                    [41.186044452459605, -8.4886702313425],
                                ],
                                [
                                    [41.18604936355092, -8.488644261559175],
                                    [41.18606109149772, -8.488625936232888],
                                    [41.18606790835321, -8.488633580689923],
                                    [41.1860561804064, -8.488651906016212],
                                ],
                                [
                                    [41.18606400165822, -8.488621389005521],
                                    [41.18607572960502, -8.488603063679232],
                                    [41.186082546460504, -8.48861070813627],
                                    [41.186070818513706, -8.488629033462557],
                                ],
                                [
                                    [41.18607572960502, -8.488603063679232],
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18609427440731, -8.48859238280998],
                                    [41.186082546460504, -8.48861070813627],
                                ],
                                [
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18609918549863, -8.488566413026657],
                                    [41.18610600235411, -8.488574057483692],
                                    [41.18609427440731, -8.48859238280998],
                                ],
                                [
                                    [41.18610209565912, -8.488561865799289],
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18612064046141, -8.488551184930039],
                                    [41.186108912514605, -8.488569510256326],
                                ],
                                [
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18612555155273, -8.488525215146714],
                                    [41.18613236840821, -8.48853285960375],
                                    [41.18612064046141, -8.488551184930039],
                                ],
                                [
                                    [41.18612555155273, -8.488525215146714],
                                    [41.186137279499526, -8.488506889820425],
                                    [41.186144096355015, -8.488514534277462],
                                    [41.18613236840821, -8.48853285960375],
                                ],
                                [
                                    [41.18614018966002, -8.488502342593058],
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18615873446231, -8.488491661723806],
                                    [41.18614700651551, -8.488509987050096],
                                ],
                                [
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18616364555363, -8.488465691940482],
                                    [41.186170462409116, -8.488473336397519],
                                    [41.18615873446231, -8.488491661723806],
                                ],
                                [
                                    [41.18616364555363, -8.488465691940482],
                                    [41.18617537350043, -8.488447366614194],
                                    [41.186182190355915, -8.488455011071231],
                                    [41.186170462409116, -8.488473336397519],
                                ],
                                [
                                    [41.18617828366093, -8.488442819386828],
                                    [41.18619001160773, -8.488424494060538],
                                    [41.18619682846322, -8.488432138517576],
                                    [41.18618510051641, -8.488450463843863],
                                ],
                                [
                                    [41.18619001160773, -8.488424494060538],
                                    [41.186201739554534, -8.488406168734251],
                                    [41.186208556410016, -8.488413813191288],
                                    [41.18619682846322, -8.488432138517576],
                                ],
                                [
                                    [41.186201739554534, -8.488406168734251],
                                    [41.18621346750133, -8.488387843407963],
                                    [41.18622028435682, -8.488395487864999],
                                    [41.186208556410016, -8.488413813191288],
                                ],
                                [
                                    [41.18621637766183, -8.488383296180595],
                                    [41.186228105608635, -8.488364970854308],
                                    [41.186234922464116, -8.488372615311345],
                                    [41.18622319451732, -8.488390940637633],
                                ],
                                [
                                    [41.186228105608635, -8.488364970854308],
                                    [41.18623983355543, -8.48834664552802],
                                    [41.18624665041092, -8.488354289985056],
                                    [41.186234922464116, -8.488372615311345],
                                ],
                                [
                                    [41.18623983355543, -8.48834664552802],
                                    [41.18625156150223, -8.488328320201731],
                                    [41.18625837835772, -8.488335964658768],
                                    [41.18624665041092, -8.488354289985056],
                                ],
                                [
                                    [41.18625447166273, -8.488323772974365],
                                    [41.186266199609534, -8.488305447648077],
                                    [41.186273016465016, -8.488313092105113],
                                    [41.18626128851822, -8.488331417431402],
                                ],
                                [
                                    [41.186266199609534, -8.488305447648077],
                                    [41.18627792755633, -8.488287122321788],
                                    [41.18628474441182, -8.488294766778825],
                                    [41.186273016465016, -8.488313092105113],
                                ],
                                [
                                    [41.18627792755633, -8.488287122321788],
                                    [41.18628965550314, -8.4882687969955],
                                    [41.18629647235862, -8.488276441452538],
                                    [41.18628474441182, -8.488294766778825],
                                ],
                                [
                                    [41.186292565663635, -8.488264249768134],
                                    [41.18630429361043, -8.488245924441845],
                                    [41.18631111046592, -8.488253568898882],
                                    [41.18629938251912, -8.48827189422517],
                                ],
                                [
                                    [41.186032724512806, -8.488688556668787],
                                    [41.186044452459605, -8.4886702313425],
                                    [41.186051269315094, -8.488677875799537],
                                    [41.18603954136829, -8.488696201125824],
                                ],
                                [
                                    [41.186044452459605, -8.4886702313425],
                                    [41.1860561804064, -8.488651906016212],
                                    [41.18606299726189, -8.488659550473248],
                                    [41.186051269315094, -8.488677875799537],
                                ],
                                [
                                    [41.1860561804064, -8.488651906016212],
                                    [41.18606790835321, -8.488633580689923],
                                    [41.18607472520869, -8.48864122514696],
                                    [41.18606299726189, -8.488659550473248],
                                ],
                                [
                                    [41.186070818513706, -8.488629033462557],
                                    [41.186082546460504, -8.48861070813627],
                                    [41.18608936331599, -8.488618352593305],
                                    [41.18607763536919, -8.488636677919594],
                                ],
                                [
                                    [41.186082546460504, -8.48861070813627],
                                    [41.18609427440731, -8.48859238280998],
                                    [41.18610109126279, -8.488600027267017],
                                    [41.18608936331599, -8.488618352593305],
                                ],
                                [
                                    [41.18609427440731, -8.48859238280998],
                                    [41.18610600235411, -8.488574057483692],
                                    [41.1861128192096, -8.48858170194073],
                                    [41.18610109126279, -8.488600027267017],
                                ],
                                [
                                    [41.186108912514605, -8.488569510256326],
                                    [41.18612064046141, -8.488551184930039],
                                    [41.18612745731689, -8.488558829387074],
                                    [41.186115729370094, -8.488577154713361],
                                ],
                                [
                                    [41.18612064046141, -8.488551184930039],
                                    [41.18613236840821, -8.48853285960375],
                                    [41.1861391852637, -8.488540504060786],
                                    [41.18612745731689, -8.488558829387074],
                                ],
                                [
                                    [41.18613236840821, -8.48853285960375],
                                    [41.186144096355015, -8.488514534277462],
                                    [41.1861509132105, -8.488522178734497],
                                    [41.1861391852637, -8.488540504060786],
                                ],
                                [
                                    [41.18614700651551, -8.488509987050096],
                                    [41.18615873446231, -8.488491661723806],
                                    [41.1861655513178, -8.488499306180843],
                                    [41.186153823370994, -8.488517631507131],
                                ],
                                [
                                    [41.18615873446231, -8.488491661723806],
                                    [41.186170462409116, -8.488473336397519],
                                    [41.1861772792646, -8.488480980854554],
                                    [41.1861655513178, -8.488499306180843],
                                ],
                                [
                                    [41.186170462409116, -8.488473336397519],
                                    [41.186182190355915, -8.488455011071231],
                                    [41.186189007211404, -8.488462655528267],
                                    [41.1861772792646, -8.488480980854554],
                                ],
                                [
                                    [41.18618510051641, -8.488450463843863],
                                    [41.18619682846322, -8.488432138517576],
                                    [41.1862036453187, -8.488439782974611],
                                    [41.1861919173719, -8.4884581083009],
                                ],
                                [
                                    [41.18619682846322, -8.488432138517576],
                                    [41.186208556410016, -8.488413813191288],
                                    [41.186215373265505, -8.488421457648323],
                                    [41.1862036453187, -8.488439782974611],
                                ],
                                [
                                    [41.186208556410016, -8.488413813191288],
                                    [41.18622028435682, -8.488395487864999],
                                    [41.1862271012123, -8.488403132322036],
                                    [41.186215373265505, -8.488421457648323],
                                ],
                                [
                                    [41.18622319451732, -8.488390940637633],
                                    [41.186234922464116, -8.488372615311345],
                                    [41.186241739319605, -8.48838025976838],
                                    [41.1862300113728, -8.488398585094668],
                                ],
                                [
                                    [41.186234922464116, -8.488372615311345],
                                    [41.18624665041092, -8.488354289985056],
                                    [41.186253467266404, -8.488361934442093],
                                    [41.186241739319605, -8.48838025976838],
                                ],
                                [
                                    [41.18624665041092, -8.488354289985056],
                                    [41.18625837835772, -8.488335964658768],
                                    [41.1862651952132, -8.488343609115804],
                                    [41.186253467266404, -8.488361934442093],
                                ],
                                [
                                    [41.18626128851822, -8.488331417431402],
                                    [41.186273016465016, -8.488313092105113],
                                    [41.186279833320505, -8.48832073656215],
                                    [41.186268105373706, -8.488339061888437],
                                ],
                                [
                                    [41.186273016465016, -8.488313092105113],
                                    [41.18628474441182, -8.488294766778825],
                                    [41.1862915612673, -8.48830241123586],
                                    [41.186279833320505, -8.48832073656215],
                                ],
                                [
                                    [41.18628474441182, -8.488294766778825],
                                    [41.18629647235862, -8.488276441452538],
                                    [41.18630328921411, -8.488284085909573],
                                    [41.1862915612673, -8.48830241123586],
                                ],
                                [
                                    [41.18629938251912, -8.48827189422517],
                                    [41.18631111046592, -8.488253568898882],
                                    [41.186317927321404, -8.488261213355917],
                                    [41.186306199374606, -8.488279538682207],
                                ],
                                [
                                    [41.18603954136829, -8.488696201125824],
                                    [41.186051269315094, -8.488677875799537],
                                    [41.186058086170576, -8.488685520256572],
                                    [41.18604635822378, -8.48870384558286],
                                ],
                                [
                                    [41.186051269315094, -8.488677875799537],
                                    [41.18606299726189, -8.488659550473248],
                                    [41.186069814117374, -8.488667194930285],
                                    [41.186058086170576, -8.488685520256572],
                                ],
                                [
                                    [41.18606299726189, -8.488659550473248],
                                    [41.18607472520869, -8.48864122514696],
                                    [41.18608154206418, -8.488648869603995],
                                    [41.186069814117374, -8.488667194930285],
                                ],
                                [
                                    [41.18607763536919, -8.488636677919594],
                                    [41.18608936331599, -8.488618352593305],
                                    [41.186096180171475, -8.488625997050342],
                                    [41.18608445222468, -8.48864432237663],
                                ],
                                [
                                    [41.18608936331599, -8.488618352593305],
                                    [41.18610109126279, -8.488600027267017],
                                    [41.18610790811828, -8.488607671724052],
                                    [41.186096180171475, -8.488625997050342],
                                ],
                                [
                                    [41.18610109126279, -8.488600027267017],
                                    [41.1861128192096, -8.48858170194073],
                                    [41.18611963606508, -8.488589346397765],
                                    [41.18610790811828, -8.488607671724052],
                                ],
                                [
                                    [41.186115729370094, -8.488577154713361],
                                    [41.18612745731689, -8.488558829387074],
                                    [41.18613427417238, -8.48856647384411],
                                    [41.186122546225576, -8.488584799170399],
                                ],
                                [
                                    [41.18612745731689, -8.488558829387074],
                                    [41.1861391852637, -8.488540504060786],
                                    [41.18614600211918, -8.488548148517822],
                                    [41.18613427417238, -8.48856647384411],
                                ],
                                [
                                    [41.1861391852637, -8.488540504060786],
                                    [41.1861509132105, -8.488522178734497],
                                    [41.186157730065986, -8.488529823191534],
                                    [41.18614600211918, -8.488548148517822],
                                ],
                                [
                                    [41.186153823370994, -8.488517631507131],
                                    [41.1861655513178, -8.488499306180843],
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18616064022648, -8.488525275964168],
                                ],
                                [
                                    [41.1861655513178, -8.488499306180843],
                                    [41.1861772792646, -8.488480980854554],
                                    [41.18618409612009, -8.488488625311591],
                                    [41.18617236817328, -8.488506950637879],
                                ],
                                [
                                    [41.1861772792646, -8.488480980854554],
                                    [41.186189007211404, -8.488462655528267],
                                    [41.186195824066886, -8.488470299985304],
                                    [41.18618409612009, -8.488488625311591],
                                ],
                                [
                                    [41.1861919173719, -8.4884581083009],
                                    [41.1862036453187, -8.488439782974611],
                                    [41.18621046217419, -8.488447427431648],
                                    [41.18619873422738, -8.488465752757936],
                                ],
                                [
                                    [41.1862036453187, -8.488439782974611],
                                    [41.186215373265505, -8.488421457648323],
                                    [41.186222190120986, -8.48842910210536],
                                    [41.18621046217419, -8.488447427431648],
                                ],
                                [
                                    [41.186215373265505, -8.488421457648323],
                                    [41.1862271012123, -8.488403132322036],
                                    [41.18623391806779, -8.488410776779071],
                                    [41.186222190120986, -8.48842910210536],
                                ],
                                [
                                    [41.1862300113728, -8.488398585094668],
                                    [41.186241739319605, -8.48838025976838],
                                    [41.18624855617509, -8.488387904225418],
                                    [41.18623682822829, -8.488406229551705],
                                ],
                                [
                                    [41.186241739319605, -8.48838025976838],
                                    [41.186253467266404, -8.488361934442093],
                                    [41.18626028412189, -8.488369578899128],
                                    [41.18624855617509, -8.488387904225418],
                                ],
                                [
                                    [41.186253467266404, -8.488361934442093],
                                    [41.1862651952132, -8.488343609115804],
                                    [41.18627201206869, -8.48835125357284],
                                    [41.18626028412189, -8.488369578899128],
                                ],
                                [
                                    [41.186268105373706, -8.488339061888437],
                                    [41.186279833320505, -8.48832073656215],
                                    [41.18628665017599, -8.488328381019185],
                                    [41.18627492222919, -8.488346706345475],
                                ],
                                [
                                    [41.186279833320505, -8.48832073656215],
                                    [41.1862915612673, -8.48830241123586],
                                    [41.18629837812279, -8.488310055692898],
                                    [41.18628665017599, -8.488328381019185],
                                ],
                                [
                                    [41.1862915612673, -8.48830241123586],
                                    [41.18630328921411, -8.488284085909573],
                                    [41.18631010606959, -8.48829173036661],
                                    [41.18629837812279, -8.488310055692898],
                                ],
                                [
                                    [41.186306199374606, -8.488279538682207],
                                    [41.186317927321404, -8.488261213355917],
                                    [41.18632474417689, -8.488268857812955],
                                    [41.18631301623009, -8.488287183139242],
                                ],
                                [
                                    [41.18604635822378, -8.48870384558286],
                                    [41.186058086170576, -8.488685520256572],
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18605317507926, -8.488711490039897],
                                ],
                                [
                                    [41.186058086170576, -8.488685520256572],
                                    [41.186069814117374, -8.488667194930285],
                                    [41.18607663097286, -8.48867483938732],
                                    [41.186064903026065, -8.48869316471361],
                                ],
                                [
                                    [41.186069814117374, -8.488667194930285],
                                    [41.18608154206418, -8.488648869603995],
                                    [41.18608835891966, -8.488656514061033],
                                    [41.18607663097286, -8.48867483938732],
                                ],
                                [
                                    [41.18608445222468, -8.48864432237663],
                                    [41.186096180171475, -8.488625997050342],
                                    [41.186102997026964, -8.488633641507377],
                                    [41.18609126908016, -8.488651966833666],
                                ],
                                [
                                    [41.186096180171475, -8.488625997050342],
                                    [41.18610790811828, -8.488607671724052],
                                    [41.18611472497376, -8.48861531618109],
                                    [41.186102997026964, -8.488633641507377],
                                ],
                                [
                                    [41.18610790811828, -8.488607671724052],
                                    [41.18611963606508, -8.488589346397765],
                                    [41.18612645292057, -8.488596990854802],
                                    [41.18611472497376, -8.48861531618109],
                                ],
                                [
                                    [41.186122546225576, -8.488584799170399],
                                    [41.18613427417238, -8.48856647384411],
                                    [41.186141091027864, -8.488574118301146],
                                    [41.186129363081065, -8.488592443627434],
                                ],
                                [
                                    [41.18613427417238, -8.48856647384411],
                                    [41.18614600211918, -8.488548148517822],
                                    [41.18615281897467, -8.488555792974859],
                                    [41.186141091027864, -8.488574118301146],
                                ],
                                [
                                    [41.18614600211918, -8.488548148517822],
                                    [41.186157730065986, -8.488529823191534],
                                    [41.18616454692147, -8.48853746764857],
                                    [41.18615281897467, -8.488555792974859],
                                ],
                                [
                                    [41.18616064022648, -8.488525275964168],
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18617918502877, -8.488514595094916],
                                    [41.186167457081964, -8.488532920421203],
                                ],
                                [
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18618409612009, -8.488488625311591],
                                    [41.18619091297557, -8.488496269768627],
                                    [41.18617918502877, -8.488514595094916],
                                ],
                                [
                                    [41.18618409612009, -8.488488625311591],
                                    [41.186195824066886, -8.488470299985304],
                                    [41.186202640922374, -8.488477944442339],
                                    [41.18619091297557, -8.488496269768627],
                                ],
                                [
                                    [41.18619873422738, -8.488465752757936],
                                    [41.18621046217419, -8.488447427431648],
                                    [41.18621727902967, -8.488455071888684],
                                    [41.18620555108287, -8.488473397214973],
                                ],
                                [
                                    [41.18621046217419, -8.488447427431648],
                                    [41.186222190120986, -8.48842910210536],
                                    [41.186229006976475, -8.488436746562396],
                                    [41.18621727902967, -8.488455071888684],
                                ],
                                [
                                    [41.186222190120986, -8.48842910210536],
                                    [41.18623391806779, -8.488410776779071],
                                    [41.186240734923274, -8.488418421236108],
                                    [41.186229006976475, -8.488436746562396],
                                ],
                                [
                                    [41.18623682822829, -8.488406229551705],
                                    [41.18624855617509, -8.488387904225418],
                                    [41.186255373030576, -8.488395548682453],
                                    [41.18624364508377, -8.48841387400874],
                                ],
                                [
                                    [41.18624855617509, -8.488387904225418],
                                    [41.18626028412189, -8.488369578899128],
                                    [41.186267100977375, -8.488377223356165],
                                    [41.186255373030576, -8.488395548682453],
                                ],
                                [
                                    [41.18626028412189, -8.488369578899128],
                                    [41.18627201206869, -8.48835125357284],
                                    [41.18627882892418, -8.488358898029876],
                                    [41.186267100977375, -8.488377223356165],
                                ],
                                [
                                    [41.18627492222919, -8.488346706345475],
                                    [41.18628665017599, -8.488328381019185],
                                    [41.186293467031476, -8.488336025476222],
                                    [41.18628173908468, -8.48835435080251],
                                ],
                                [
                                    [41.18628665017599, -8.488328381019185],
                                    [41.18629837812279, -8.488310055692898],
                                    [41.186305194978274, -8.488317700149933],
                                    [41.186293467031476, -8.488336025476222],
                                ],
                                [
                                    [41.18629837812279, -8.488310055692898],
                                    [41.18631010606959, -8.48829173036661],
                                    [41.18631692292508, -8.488299374823645],
                                    [41.186305194978274, -8.488317700149933],
                                ],
                                [
                                    [41.18631301623009, -8.488287183139242],
                                    [41.18632474417689, -8.488268857812955],
                                    [41.186331561032375, -8.48827650226999],
                                    [41.18631983308558, -8.48829482759628],
                                ],
                                [
                                    [41.18605317507926, -8.488711490039897],
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18607171988155, -8.488700809170645],
                                    [41.18605999193475, -8.488719134496932],
                                ],
                                [
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18607663097286, -8.48867483938732],
                                    [41.18608344782835, -8.488682483844357],
                                    [41.18607171988155, -8.488700809170645],
                                ],
                                [
                                    [41.18607663097286, -8.48867483938732],
                                    [41.18608835891966, -8.488656514061033],
                                    [41.18609517577515, -8.488664158518068],
                                    [41.18608344782835, -8.488682483844357],
                                ],
                                [
                                    [41.18609126908016, -8.488651966833666],
                                    [41.186102997026964, -8.488633641507377],
                                    [41.186109813882446, -8.488641285964414],
                                    [41.18609808593565, -8.488659611290702],
                                ],
                                [
                                    [41.186102997026964, -8.488633641507377],
                                    [41.18611472497376, -8.48861531618109],
                                    [41.18612154182925, -8.488622960638125],
                                    [41.186109813882446, -8.488641285964414],
                                ],
                                [
                                    [41.18611472497376, -8.48861531618109],
                                    [41.18612645292057, -8.488596990854802],
                                    [41.18613326977605, -8.488604635311837],
                                    [41.18612154182925, -8.488622960638125],
                                ],
                                [
                                    [41.186129363081065, -8.488592443627434],
                                    [41.186141091027864, -8.488574118301146],
                                    [41.18614790788335, -8.488581762758182],
                                    [41.18613617993655, -8.488600088084471],
                                ],
                                [
                                    [41.186141091027864, -8.488574118301146],
                                    [41.18615281897467, -8.488555792974859],
                                    [41.18615963583015, -8.488563437431894],
                                    [41.18614790788335, -8.488581762758182],
                                ],
                                [
                                    [41.18617918502877, -8.488514595094916],
                                    [41.18619091297557, -8.488496269768627],
                                    [41.18619772983106, -8.488503914225664],
                                    [41.18618600188425, -8.488522239551951],
                                ],
                                [
                                    [41.18619091297557, -8.488496269768627],
                                    [41.186202640922374, -8.488477944442339],
                                    [41.186209457777856, -8.488485588899374],
                                    [41.18619772983106, -8.488503914225664],
                                ],
                                [
                                    [41.18620555108287, -8.488473397214973],
                                    [41.18621727902967, -8.488455071888684],
                                    [41.18622409588516, -8.48846271634572],
                                    [41.18621236793835, -8.488481041672008],
                                ],
                                [
                                    [41.18621727902967, -8.488455071888684],
                                    [41.186229006976475, -8.488436746562396],
                                    [41.18623582383196, -8.488444391019433],
                                    [41.18622409588516, -8.48846271634572],
                                ],
                                [
                                    [41.186229006976475, -8.488436746562396],
                                    [41.186240734923274, -8.488418421236108],
                                    [41.18624755177876, -8.488426065693144],
                                    [41.18623582383196, -8.488444391019433],
                                ],
                                [
                                    [41.18624364508377, -8.48841387400874],
                                    [41.186255373030576, -8.488395548682453],
                                    [41.18626218988606, -8.48840319313949],
                                    [41.18625046193926, -8.488421518465778],
                                ],
                                [
                                    [41.18628173908468, -8.48835435080251],
                                    [41.186293467031476, -8.488336025476222],
                                    [41.186300283886965, -8.488343669933258],
                                    [41.18628855594016, -8.488361995259547],
                                ],
                                [
                                    [41.186293467031476, -8.488336025476222],
                                    [41.186305194978274, -8.488317700149933],
                                    [41.18631201183376, -8.48832534460697],
                                    [41.186300283886965, -8.488343669933258],
                                ],
                                [
                                    [41.186305194978274, -8.488317700149933],
                                    [41.18631692292508, -8.488299374823645],
                                    [41.18632373978056, -8.488307019280683],
                                    [41.18631201183376, -8.48832534460697],
                                ],
                                [
                                    [41.18631983308558, -8.48829482759628],
                                    [41.186331561032375, -8.48827650226999],
                                    [41.186338377887864, -8.488284146727027],
                                    [41.18632664994106, -8.488302472053315],
                                ],
                            ],
                            panels_idx: [
                                [0, 0, [0, 0]],
                                [0, 1, [0, 2]],
                                [0, 2, [0, 0]],
                                [0, 2, [0, 1]],
                                [0, 2, [0, 2]],
                                [0, 3, [0, 0]],
                                [0, 3, [0, 1]],
                                [0, 3, [0, 2]],
                                [0, 4, [0, 0]],
                                [0, 4, [0, 1]],
                                [0, 4, [0, 2]],
                                [0, 5, [0, 0]],
                                [0, 5, [0, 1]],
                                [0, 5, [0, 2]],
                                [0, 6, [0, 0]],
                                [0, 6, [0, 1]],
                                [0, 6, [0, 2]],
                                [0, 7, [0, 0]],
                                [1, 0, [0, 0]],
                                [1, 0, [0, 1]],
                                [1, 0, [0, 2]],
                                [1, 1, [0, 0]],
                                [1, 1, [0, 1]],
                                [1, 1, [0, 2]],
                                [1, 2, [0, 0]],
                                [1, 2, [0, 1]],
                                [1, 2, [0, 2]],
                                [1, 3, [0, 0]],
                                [1, 3, [0, 1]],
                                [1, 3, [0, 2]],
                                [1, 4, [0, 0]],
                                [1, 4, [0, 1]],
                                [1, 4, [0, 2]],
                                [1, 5, [0, 0]],
                                [1, 5, [0, 1]],
                                [1, 5, [0, 2]],
                                [1, 6, [0, 0]],
                                [1, 6, [0, 1]],
                                [1, 6, [0, 2]],
                                [1, 7, [0, 0]],
                                [2, 0, [0, 0]],
                                [2, 0, [0, 1]],
                                [2, 0, [0, 2]],
                                [2, 1, [0, 0]],
                                [2, 1, [0, 1]],
                                [2, 1, [0, 2]],
                                [2, 2, [0, 0]],
                                [2, 2, [0, 1]],
                                [2, 2, [0, 2]],
                                [2, 3, [0, 0]],
                                [2, 3, [0, 1]],
                                [2, 3, [0, 2]],
                                [2, 4, [0, 0]],
                                [2, 4, [0, 1]],
                                [2, 4, [0, 2]],
                                [2, 5, [0, 0]],
                                [2, 5, [0, 1]],
                                [2, 5, [0, 2]],
                                [2, 6, [0, 0]],
                                [2, 6, [0, 1]],
                                [2, 6, [0, 2]],
                                [2, 7, [0, 0]],
                                [3, 0, [0, 0]],
                                [3, 0, [0, 1]],
                                [3, 0, [0, 2]],
                                [3, 1, [0, 0]],
                                [3, 1, [0, 1]],
                                [3, 1, [0, 2]],
                                [3, 2, [0, 0]],
                                [3, 2, [0, 1]],
                                [3, 2, [0, 2]],
                                [3, 3, [0, 0]],
                                [3, 3, [0, 1]],
                                [3, 3, [0, 2]],
                                [3, 4, [0, 0]],
                                [3, 4, [0, 1]],
                                [3, 4, [0, 2]],
                                [3, 5, [0, 0]],
                                [3, 5, [0, 1]],
                                [3, 5, [0, 2]],
                                [3, 6, [0, 0]],
                                [3, 6, [0, 1]],
                                [3, 6, [0, 2]],
                                [3, 7, [0, 0]],
                                [4, 0, [0, 0]],
                                [4, 0, [0, 1]],
                                [4, 0, [0, 2]],
                                [4, 1, [0, 0]],
                                [4, 1, [0, 1]],
                                [4, 1, [0, 2]],
                                [4, 2, [0, 0]],
                                [4, 2, [0, 1]],
                                [4, 2, [0, 2]],
                                [4, 3, [0, 0]],
                                [4, 3, [0, 1]],
                                [4, 3, [0, 2]],
                                [4, 4, [0, 0]],
                                [4, 4, [0, 1]],
                                [4, 4, [0, 2]],
                                [4, 5, [0, 0]],
                                [4, 5, [0, 1]],
                                [4, 5, [0, 2]],
                                [4, 6, [0, 0]],
                                [4, 6, [0, 1]],
                                [4, 6, [0, 2]],
                                [4, 7, [0, 0]],
                                [5, 0, [0, 0]],
                                [5, 0, [0, 1]],
                                [5, 0, [0, 2]],
                                [5, 1, [0, 0]],
                                [5, 1, [0, 1]],
                                [5, 1, [0, 2]],
                                [5, 2, [0, 0]],
                                [5, 2, [0, 1]],
                                [5, 2, [0, 2]],
                                [5, 3, [0, 0]],
                                [5, 3, [0, 1]],
                                [5, 3, [0, 2]],
                                [5, 4, [0, 0]],
                                [5, 4, [0, 1]],
                                [5, 4, [0, 2]],
                                [5, 5, [0, 0]],
                                [5, 5, [0, 1]],
                                [5, 5, [0, 2]],
                                [5, 6, [0, 0]],
                                [5, 6, [0, 1]],
                                [5, 6, [0, 2]],
                                [5, 7, [0, 0]],
                                [6, 0, [0, 0]],
                                [6, 0, [0, 1]],
                                [6, 0, [0, 2]],
                                [6, 1, [0, 0]],
                                [6, 1, [0, 1]],
                                [6, 1, [0, 2]],
                                [6, 2, [0, 0]],
                                [6, 2, [0, 1]],
                                [6, 3, [0, 1]],
                                [6, 3, [0, 2]],
                                [6, 4, [0, 0]],
                                [6, 4, [0, 1]],
                                [6, 4, [0, 2]],
                                [6, 5, [0, 0]],
                                [6, 6, [0, 0]],
                                [6, 6, [0, 1]],
                                [6, 6, [0, 2]],
                                [6, 7, [0, 0]],
                            ],
                            perc_shadow: [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0,
                            ],
                            panels_number: 146,
                        },
                    ],
                    coordinates: [
                        {
                            lat: 41.18600528112348,
                            lng: -8.488665535099807,
                        },
                        {
                            lat: 41.18606583790162,
                            lng: -8.488733931429687,
                        },
                        {
                            lat: 41.18635247331818,
                            lng: -8.488283320315185,
                        },
                        {
                            lat: 41.18629191654003,
                            lng: -8.488216265089813,
                        },
                        {
                            lat: 41.18600528112348,
                            lng: -8.488665535099807,
                        },
                    ],
                    orientation: -40.27,
                    region_gd_id: 125,
                    panels_number: 146,
                    perimeter_area: 116.39458181558223,
                    coordinates_avg: {
                        lat: 41.186144158001355,
                        long: -8.48851291740686,
                        region_gd_id: 125,
                    },
                    autoSplitBuilding: 1,
                    panel_representation: {
                        dayRank: '2024-12-22T00:00:00.000Z',
                        setback: 0.5,
                        rowSpace: 0,
                        alignment: 'left',
                        panels_nr: 3,
                        panels_up: 1,
                        roofSlope: 5,
                        row_space: 0,
                        hourWindow: 4,
                        panels_wide: 3,
                        panelFraming: 1,
                        space_btw_sets: 0.5,
                        reference_point: [41.186012273946346, -8.48866562329768],
                        isDefaultRowRatio: false,
                        alignment_vertical: 'bottom',
                        alignment_horizontal: 'left',
                        is_row_spacing_insert: true,
                        coordinates_conversion_factor: {
                            one_meter_in_lat: 0.00000900434376143347,
                            one_meter_in_lon: 0.00001191923025934094,
                        },
                    },
                    mounting_structure_id: 7,
                    panels_number_excluded: 0,
                    panels_number_possible: 146,
                    mounting_structure_type_id: 18,
                },
                {
                    id: 2,
                    lat: 41.18626910682026,
                    area: 435.14275740630234,
                    long: -8.488506211884323,
                    edges: [
                        {
                            aspect: 49.772882975519515,
                            points: [
                                {
                                    lat: 41.1861207,
                                    lng: -8.4888041,
                                },
                                {
                                    lat: 41.186113899999995,
                                    lng: -8.4887965,
                                },
                            ],
                        },
                        {
                            aspect: -38.67722912542285,
                            points: [
                                {
                                    lat: 41.186113899999995,
                                    lng: -8.4887965,
                                },
                                {
                                    lat: 41.1860935,
                                    lng: -8.4887737,
                                },
                            ],
                        },
                        {
                            aspect: -48.73453529487949,
                            points: [
                                {
                                    lat: 41.1860935,
                                    lng: -8.4887737,
                                },
                                {
                                    lat: 41.1860664,
                                    lng: -8.4887433,
                                },
                            ],
                        },
                        {
                            aspect: -130.2409363116384,
                            points: [
                                {
                                    lat: 41.1860664,
                                    lng: -8.4887433,
                                },
                                {
                                    lat: 41.186174799999996,
                                    lng: -8.4885555,
                                },
                            ],
                        },
                        {
                            aspect: 139.78307560281286,
                            points: [
                                {
                                    lat: 41.186174799999996,
                                    lng: -8.4885555,
                                },
                                {
                                    lat: 41.186270799999996,
                                    lng: -8.4884053,
                                },
                            ],
                        },
                    ],
                    slope: 5,
                    aspect: 139.7287929522657,
                    selected: false,
                    panel_rows: [
                        {
                            positions: [
                                [
                                    [41.18630785861647, -8.488501182006347],
                                    [41.186296130669675, -8.488519507332635],
                                    [41.186289313814186, -8.4885118628756],
                                    [41.18630104176099, -8.48849353754931],
                                ],
                                [
                                    [41.186296130669675, -8.488519507332635],
                                    [41.18628440272287, -8.488537832658922],
                                    [41.18627758586739, -8.488530188201887],
                                    [41.186289313814186, -8.4885118628756],
                                ],
                                [
                                    [41.18628440272287, -8.488537832658922],
                                    [41.18627267477607, -8.488556157985212],
                                    [41.18626585792058, -8.488548513528174],
                                    [41.18627758586739, -8.488530188201887],
                                ],
                                [
                                    [41.186269764615574, -8.488560705212578],
                                    [41.18625803666877, -8.488579030538865],
                                    [41.186251219813286, -8.48857138608183],
                                    [41.186262947760085, -8.488553060755542],
                                ],
                                [
                                    [41.18625803666877, -8.488579030538865],
                                    [41.18624630872197, -8.488597355865155],
                                    [41.18623949186648, -8.488589711408117],
                                    [41.186251219813286, -8.48857138608183],
                                ],
                                [
                                    [41.18624630872197, -8.488597355865155],
                                    [41.186234580775164, -8.488615681191442],
                                    [41.18622776391968, -8.488608036734407],
                                    [41.18623949186648, -8.488589711408117],
                                ],
                                [
                                    [41.18623167061467, -8.488620228418808],
                                    [41.18621994266787, -8.488638553745098],
                                    [41.18621312581238, -8.48863090928806],
                                    [41.186224853759185, -8.488612583961773],
                                ],
                                [
                                    [41.18621994266787, -8.488638553745098],
                                    [41.18620821472106, -8.488656879071385],
                                    [41.18620139786558, -8.48864923461435],
                                    [41.18621312581238, -8.48863090928806],
                                ],
                                [
                                    [41.18620821472106, -8.488656879071385],
                                    [41.186196486774264, -8.488675204397673],
                                    [41.186189669918775, -8.488667559940637],
                                    [41.18620139786558, -8.48864923461435],
                                ],
                                [
                                    [41.18619357661377, -8.48867975162504],
                                    [41.18618184866696, -8.488698076951328],
                                    [41.18617503181148, -8.488690432494293],
                                    [41.18618675975828, -8.488672107168004],
                                ],
                                [
                                    [41.18617012072016, -8.488716402277616],
                                    [41.18615839277336, -8.488734727603905],
                                    [41.186151575917876, -8.488727083146868],
                                    [41.186163303864674, -8.48870875782058],
                                ],
                                [
                                    [41.18615548261286, -8.488739274831271],
                                    [41.18614375466606, -8.488757600157559],
                                    [41.18613693781057, -8.488749955700523],
                                    [41.18614866575738, -8.488731630374236],
                                ],
                                [
                                    [41.18614375466606, -8.488757600157559],
                                    [41.18613202671926, -8.488775925483848],
                                    [41.186125209863775, -8.488768281026811],
                                    [41.18613693781057, -8.488749955700523],
                                ],
                                [
                                    [41.18613202671926, -8.488775925483848],
                                    [41.18612029877246, -8.488794250810136],
                                    [41.18611348191697, -8.4887866063531],
                                    [41.186125209863775, -8.488768281026811],
                                ],
                                [
                                    [41.18639186787009, -8.488351618583193],
                                    [41.18638013992329, -8.48836994390948],
                                    [41.186373323067805, -8.488362299452445],
                                    [41.186385051014604, -8.488343974126156],
                                ],
                                [
                                    [41.18637722976279, -8.488374491136849],
                                    [41.18636550181599, -8.488392816463136],
                                    [41.1863586849605, -8.4883851720061],
                                    [41.18637041290731, -8.488366846679812],
                                ],
                                [
                                    [41.18636550181599, -8.488392816463136],
                                    [41.186353773869186, -8.488411141789424],
                                    [41.186346957013704, -8.488403497332389],
                                    [41.1863586849605, -8.4883851720061],
                                ],
                                [
                                    [41.186353773869186, -8.488411141789424],
                                    [41.18634204592239, -8.488429467115713],
                                    [41.1863352290669, -8.488421822658676],
                                    [41.186346957013704, -8.488403497332389],
                                ],
                                [
                                    [41.18633913576189, -8.48843401434308],
                                    [41.186327407815085, -8.488452339669367],
                                    [41.1863205909596, -8.488444695212332],
                                    [41.1863323189064, -8.488426369886042],
                                ],
                                [
                                    [41.186327407815085, -8.488452339669367],
                                    [41.18631567986829, -8.488470664995656],
                                    [41.1863088630128, -8.48846302053862],
                                    [41.1863205909596, -8.488444695212332],
                                ],
                                [
                                    [41.18631567986829, -8.488470664995656],
                                    [41.18630395192149, -8.488488990321944],
                                    [41.186297135066, -8.488481345864907],
                                    [41.1863088630128, -8.48846302053862],
                                ],
                                [
                                    [41.18630104176099, -8.48849353754931],
                                    [41.186289313814186, -8.4885118628756],
                                    [41.186282496958704, -8.488504218418562],
                                    [41.1862942249055, -8.488485893092275],
                                ],
                                [
                                    [41.186289313814186, -8.4885118628756],
                                    [41.18627758586739, -8.488530188201887],
                                    [41.1862707690119, -8.48852254374485],
                                    [41.186282496958704, -8.488504218418562],
                                ],
                                [
                                    [41.18627758586739, -8.488530188201887],
                                    [41.18626585792058, -8.488548513528174],
                                    [41.1862590410651, -8.488540869071139],
                                    [41.1862707690119, -8.48852254374485],
                                ],
                                [
                                    [41.186262947760085, -8.488553060755542],
                                    [41.186251219813286, -8.48857138608183],
                                    [41.1862444029578, -8.488563741624793],
                                    [41.1862561309046, -8.488545416298505],
                                ],
                                [
                                    [41.186251219813286, -8.48857138608183],
                                    [41.18623949186648, -8.488589711408117],
                                    [41.186232675011, -8.488582066951082],
                                    [41.1862444029578, -8.488563741624793],
                                ],
                                [
                                    [41.18623949186648, -8.488589711408117],
                                    [41.18622776391968, -8.488608036734407],
                                    [41.18622094706419, -8.48860039227737],
                                    [41.186232675011, -8.488582066951082],
                                ],
                                [
                                    [41.186224853759185, -8.488612583961773],
                                    [41.18621312581238, -8.48863090928806],
                                    [41.1862063089569, -8.488623264831025],
                                    [41.186218036903696, -8.488604939504736],
                                ],
                                [
                                    [41.18621312581238, -8.48863090928806],
                                    [41.18620139786558, -8.48864923461435],
                                    [41.18619458101009, -8.488641590157313],
                                    [41.1862063089569, -8.488623264831025],
                                ],
                                [
                                    [41.18620139786558, -8.48864923461435],
                                    [41.186189669918775, -8.488667559940637],
                                    [41.18618285306329, -8.4886599154836],
                                    [41.18619458101009, -8.488641590157313],
                                ],
                                [
                                    [41.18618675975828, -8.488672107168004],
                                    [41.18617503181148, -8.488690432494293],
                                    [41.18616821495599, -8.488682788037256],
                                    [41.1861799429028, -8.488664462710968],
                                ],
                                [
                                    [41.186163303864674, -8.48870875782058],
                                    [41.186151575917876, -8.488727083146868],
                                    [41.18614475906239, -8.488719438689833],
                                    [41.18615648700919, -8.488701113363543],
                                ],
                                [
                                    [41.18614866575738, -8.488731630374236],
                                    [41.18613693781057, -8.488749955700523],
                                    [41.18613012095509, -8.488742311243486],
                                    [41.18614184890189, -8.488723985917199],
                                ],
                                [
                                    [41.18613693781057, -8.488749955700523],
                                    [41.186125209863775, -8.488768281026811],
                                    [41.186118393008286, -8.488760636569776],
                                    [41.18613012095509, -8.488742311243486],
                                ],
                                [
                                    [41.186125209863775, -8.488768281026811],
                                    [41.18611348191697, -8.4887866063531],
                                    [41.18610666506149, -8.488778961896063],
                                    [41.186118393008286, -8.488760636569776],
                                ],
                                [
                                    [41.186385051014604, -8.488343974126156],
                                    [41.186373323067805, -8.488362299452445],
                                    [41.186366506212316, -8.488354654995408],
                                    [41.18637823415912, -8.48833632966912],
                                ],
                                [
                                    [41.18637041290731, -8.488366846679812],
                                    [41.1863586849605, -8.4883851720061],
                                    [41.18635186810502, -8.488377527549064],
                                    [41.18636359605182, -8.488359202222776],
                                ],
                                [
                                    [41.1863586849605, -8.4883851720061],
                                    [41.186346957013704, -8.488403497332389],
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18635186810502, -8.488377527549064],
                                ],
                                [
                                    [41.186346957013704, -8.488403497332389],
                                    [41.1863352290669, -8.488421822658676],
                                    [41.18632841221142, -8.48841417820164],
                                    [41.186340140158215, -8.488395852875351],
                                ],
                                [
                                    [41.1863323189064, -8.488426369886042],
                                    [41.1863205909596, -8.488444695212332],
                                    [41.186313774104114, -8.488437050755294],
                                    [41.18632550205092, -8.488418725429007],
                                ],
                                [
                                    [41.1863205909596, -8.488444695212332],
                                    [41.1863088630128, -8.48846302053862],
                                    [41.186302046157316, -8.488455376081584],
                                    [41.186313774104114, -8.488437050755294],
                                ],
                                [
                                    [41.1863088630128, -8.48846302053862],
                                    [41.186297135066, -8.488481345864907],
                                    [41.18629031821052, -8.488473701407871],
                                    [41.186302046157316, -8.488455376081584],
                                ],
                                [
                                    [41.1862942249055, -8.488485893092275],
                                    [41.186282496958704, -8.488504218418562],
                                    [41.186275680103215, -8.488496573961527],
                                    [41.18628740805001, -8.488478248635237],
                                ],
                                [
                                    [41.186282496958704, -8.488504218418562],
                                    [41.1862707690119, -8.48852254374485],
                                    [41.186263952156416, -8.488514899287814],
                                    [41.186275680103215, -8.488496573961527],
                                ],
                                [
                                    [41.1862707690119, -8.48852254374485],
                                    [41.1862590410651, -8.488540869071139],
                                    [41.18625222420961, -8.488533224614102],
                                    [41.186263952156416, -8.488514899287814],
                                ],
                                [
                                    [41.1862561309046, -8.488545416298505],
                                    [41.1862444029578, -8.488563741624793],
                                    [41.186237586102315, -8.488556097167757],
                                    [41.186249314049114, -8.48853777184147],
                                ],
                                [
                                    [41.1862444029578, -8.488563741624793],
                                    [41.186232675011, -8.488582066951082],
                                    [41.18622585815551, -8.488574422494045],
                                    [41.186237586102315, -8.488556097167757],
                                ],
                                [
                                    [41.186232675011, -8.488582066951082],
                                    [41.18622094706419, -8.48860039227737],
                                    [41.18621413020871, -8.488592747820334],
                                    [41.18622585815551, -8.488574422494045],
                                ],
                                [
                                    [41.186218036903696, -8.488604939504736],
                                    [41.1862063089569, -8.488623264831025],
                                    [41.18619949210141, -8.488615620373988],
                                    [41.186211220048214, -8.4885972950477],
                                ],
                                [
                                    [41.1862063089569, -8.488623264831025],
                                    [41.18619458101009, -8.488641590157313],
                                    [41.18618776415461, -8.488633945700277],
                                    [41.18619949210141, -8.488615620373988],
                                ],
                                [
                                    [41.18619458101009, -8.488641590157313],
                                    [41.18618285306329, -8.4886599154836],
                                    [41.186176036207804, -8.488652271026565],
                                    [41.18618776415461, -8.488633945700277],
                                ],
                                [
                                    [41.1861799429028, -8.488664462710968],
                                    [41.18616821495599, -8.488682788037256],
                                    [41.18616139810051, -8.48867514358022],
                                    [41.18617312604731, -8.488656818253931],
                                ],
                                [
                                    [41.18615648700919, -8.488701113363543],
                                    [41.18614475906239, -8.488719438689833],
                                    [41.186137942206905, -8.488711794232795],
                                    [41.1861496701537, -8.488693468906508],
                                ],
                                [
                                    [41.18614184890189, -8.488723985917199],
                                    [41.18613012095509, -8.488742311243486],
                                    [41.1861233040996, -8.488734666786451],
                                    [41.18613503204641, -8.488716341460163],
                                ],
                                [
                                    [41.18613012095509, -8.488742311243486],
                                    [41.186118393008286, -8.488760636569776],
                                    [41.186111576152804, -8.488752992112738],
                                    [41.1861233040996, -8.488734666786451],
                                ],
                                [
                                    [41.186118393008286, -8.488760636569776],
                                    [41.18610666506149, -8.488778961896063],
                                    [41.186099848206, -8.488771317439028],
                                    [41.186111576152804, -8.488752992112738],
                                ],
                                [
                                    [41.18637823415912, -8.48833632966912],
                                    [41.186366506212316, -8.488354654995408],
                                    [41.186359689356834, -8.488347010538373],
                                    [41.18637141730363, -8.488328685212084],
                                ],
                                [
                                    [41.18636359605182, -8.488359202222776],
                                    [41.18635186810502, -8.488377527549064],
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18635677919634, -8.48835155776574],
                                ],
                                [
                                    [41.18635186810502, -8.488377527549064],
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18633332330273, -8.488388208418316],
                                    [41.18634505124953, -8.488369883092027],
                                ],
                                [
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18632841221142, -8.48841417820164],
                                    [41.18632159535593, -8.488406533744604],
                                    [41.18633332330273, -8.488388208418316],
                                ],
                                [
                                    [41.18632550205092, -8.488418725429007],
                                    [41.186313774104114, -8.488437050755294],
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18631868519543, -8.48841108097197],
                                ],
                                [
                                    [41.186313774104114, -8.488437050755294],
                                    [41.186302046157316, -8.488455376081584],
                                    [41.18629522930183, -8.488447731624547],
                                    [41.18630695724863, -8.488429406298259],
                                ],
                                [
                                    [41.186302046157316, -8.488455376081584],
                                    [41.18629031821052, -8.488473701407871],
                                    [41.18628350135503, -8.488466056950834],
                                    [41.18629522930183, -8.488447731624547],
                                ],
                                [
                                    [41.18628740805001, -8.488478248635237],
                                    [41.186275680103215, -8.488496573961527],
                                    [41.18626886324773, -8.48848892950449],
                                    [41.18628059119453, -8.488470604178202],
                                ],
                                [
                                    [41.186275680103215, -8.488496573961527],
                                    [41.186263952156416, -8.488514899287814],
                                    [41.18625713530093, -8.488507254830777],
                                    [41.18626886324773, -8.48848892950449],
                                ],
                                [
                                    [41.186263952156416, -8.488514899287814],
                                    [41.18625222420961, -8.488533224614102],
                                    [41.18624540735413, -8.488525580157066],
                                    [41.18625713530093, -8.488507254830777],
                                ],
                                [
                                    [41.186249314049114, -8.48853777184147],
                                    [41.186237586102315, -8.488556097167757],
                                    [41.186230769246826, -8.48854845271072],
                                    [41.18624249719363, -8.488530127384433],
                                ],
                                [
                                    [41.186237586102315, -8.488556097167757],
                                    [41.18622585815551, -8.488574422494045],
                                    [41.18621904130003, -8.48856677803701],
                                    [41.186230769246826, -8.48854845271072],
                                ],
                                [
                                    [41.18622585815551, -8.488574422494045],
                                    [41.18621413020871, -8.488592747820334],
                                    [41.18620731335322, -8.488585103363297],
                                    [41.18621904130003, -8.48856677803701],
                                ],
                                [
                                    [41.186211220048214, -8.4885972950477],
                                    [41.18619949210141, -8.488615620373988],
                                    [41.18619267524593, -8.488607975916953],
                                    [41.186204403192725, -8.488589650590663],
                                ],
                                [
                                    [41.18619949210141, -8.488615620373988],
                                    [41.18618776415461, -8.488633945700277],
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18619267524593, -8.488607975916953],
                                ],
                                [
                                    [41.18618776415461, -8.488633945700277],
                                    [41.186176036207804, -8.488652271026565],
                                    [41.18616921935232, -8.488644626569528],
                                    [41.18618094729912, -8.48862630124324],
                                ],
                                [
                                    [41.18617312604731, -8.488656818253931],
                                    [41.18616139810051, -8.48867514358022],
                                    [41.18615458124502, -8.488667499123183],
                                    [41.186166309191826, -8.488649173796896],
                                ],
                                [
                                    [41.1861496701537, -8.488693468906508],
                                    [41.186137942206905, -8.488711794232795],
                                    [41.186131125351416, -8.48870414977576],
                                    [41.18614285329822, -8.48868582444947],
                                ],
                                [
                                    [41.18613503204641, -8.488716341460163],
                                    [41.1861233040996, -8.488734666786451],
                                    [41.18611648724412, -8.488727022329414],
                                    [41.18612821519092, -8.488708697003126],
                                ],
                                [
                                    [41.1861233040996, -8.488734666786451],
                                    [41.186111576152804, -8.488752992112738],
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18611648724412, -8.488727022329414],
                                ],
                                [
                                    [41.186111576152804, -8.488752992112738],
                                    [41.186099848206, -8.488771317439028],
                                    [41.18609303135052, -8.48876367298199],
                                    [41.186104759297315, -8.488745347655703],
                                ],
                                [
                                    [41.18637141730363, -8.488328685212084],
                                    [41.186359689356834, -8.488347010538373],
                                    [41.186352872501345, -8.488339366081336],
                                    [41.18636460044815, -8.488321040755048],
                                ],
                                [
                                    [41.18635677919634, -8.48835155776574],
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18633823439405, -8.488362238634991],
                                    [41.18634996234085, -8.488343913308704],
                                ],
                                [
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18633332330273, -8.488388208418316],
                                    [41.186326506447244, -8.488380563961279],
                                    [41.18633823439405, -8.488362238634991],
                                ],
                                [
                                    [41.18633332330273, -8.488388208418316],
                                    [41.18632159535593, -8.488406533744604],
                                    [41.186314778500446, -8.488398889287568],
                                    [41.186326506447244, -8.488380563961279],
                                ],
                                [
                                    [41.18631868519543, -8.48841108097197],
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18630014039314, -8.488421761841222],
                                    [41.18631186833995, -8.488403436514934],
                                ],
                                [
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18629522930183, -8.488447731624547],
                                    [41.186288412446345, -8.488440087167511],
                                    [41.18630014039314, -8.488421761841222],
                                ],
                                [
                                    [41.18629522930183, -8.488447731624547],
                                    [41.18628350135503, -8.488466056950834],
                                    [41.18627668449954, -8.488458412493799],
                                    [41.186288412446345, -8.488440087167511],
                                ],
                                [
                                    [41.18628059119453, -8.488470604178202],
                                    [41.18626886324773, -8.48848892950449],
                                    [41.186262046392244, -8.488481285047454],
                                    [41.18627377433904, -8.488462959721165],
                                ],
                                [
                                    [41.18626886324773, -8.48848892950449],
                                    [41.18625713530093, -8.488507254830777],
                                    [41.186250318445445, -8.488499610373742],
                                    [41.186262046392244, -8.488481285047454],
                                ],
                                [
                                    [41.18625713530093, -8.488507254830777],
                                    [41.18624540735413, -8.488525580157066],
                                    [41.18623859049864, -8.48851793570003],
                                    [41.186250318445445, -8.488499610373742],
                                ],
                                [
                                    [41.18624249719363, -8.488530127384433],
                                    [41.186230769246826, -8.48854845271072],
                                    [41.186223952391344, -8.488540808253685],
                                    [41.18623568033814, -8.488522482927397],
                                ],
                                [
                                    [41.186230769246826, -8.48854845271072],
                                    [41.18621904130003, -8.48856677803701],
                                    [41.18621222444454, -8.488559133579972],
                                    [41.186223952391344, -8.488540808253685],
                                ],
                                [
                                    [41.18621904130003, -8.48856677803701],
                                    [41.18620731335322, -8.488585103363297],
                                    [41.18620049649774, -8.488577458906262],
                                    [41.18621222444454, -8.488559133579972],
                                ],
                                [
                                    [41.186204403192725, -8.488589650590663],
                                    [41.18619267524593, -8.488607975916953],
                                    [41.18618585839044, -8.488600331459915],
                                    [41.186197586337244, -8.488582006133628],
                                ],
                                [
                                    [41.18619267524593, -8.488607975916953],
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18617413044364, -8.488618656786205],
                                    [41.18618585839044, -8.488600331459915],
                                ],
                                [
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18616921935232, -8.488644626569528],
                                    [41.186162402496834, -8.488636982112492],
                                    [41.18617413044364, -8.488618656786205],
                                ],
                                [
                                    [41.186166309191826, -8.488649173796896],
                                    [41.18615458124502, -8.488667499123183],
                                    [41.18614776438954, -8.488659854666148],
                                    [41.18615949233634, -8.488641529339858],
                                ],
                                [
                                    [41.18614285329822, -8.48868582444947],
                                    [41.186131125351416, -8.48870414977576],
                                    [41.186124308495934, -8.488696505318723],
                                    [41.18613603644273, -8.488678179992435],
                                ],
                                [
                                    [41.18612821519092, -8.488708697003126],
                                    [41.18611648724412, -8.488727022329414],
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18612139833544, -8.48870105254609],
                                ],
                                [
                                    [41.18611648724412, -8.488727022329414],
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18609794244183, -8.488737703198666],
                                    [41.18610967038863, -8.488719377872378],
                                ],
                                [
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18609303135052, -8.48876367298199],
                                    [41.18608621449503, -8.488756028524955],
                                    [41.18609794244183, -8.488737703198666],
                                ],
                                [
                                    [41.18636460044815, -8.488321040755048],
                                    [41.186352872501345, -8.488339366081336],
                                    [41.18634605564586, -8.4883317216243],
                                    [41.18635778359266, -8.488313396298011],
                                ],
                                [
                                    [41.18634996234085, -8.488343913308704],
                                    [41.18633823439405, -8.488362238634991],
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18634314548537, -8.488336268851667],
                                ],
                                [
                                    [41.18633823439405, -8.488362238634991],
                                    [41.186326506447244, -8.488380563961279],
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18633141753856, -8.488354594177954],
                                ],
                                [
                                    [41.186326506447244, -8.488380563961279],
                                    [41.186314778500446, -8.488398889287568],
                                    [41.18630796164496, -8.488391244830531],
                                    [41.18631968959176, -8.488372919504243],
                                ],
                                [
                                    [41.18631186833995, -8.488403436514934],
                                    [41.18630014039314, -8.488421761841222],
                                    [41.18629332353766, -8.488414117384186],
                                    [41.18630505148446, -8.488395792057897],
                                ],
                                [
                                    [41.18630014039314, -8.488421761841222],
                                    [41.186288412446345, -8.488440087167511],
                                    [41.186281595590856, -8.488432442710474],
                                    [41.18629332353766, -8.488414117384186],
                                ],
                                [
                                    [41.186288412446345, -8.488440087167511],
                                    [41.18627668449954, -8.488458412493799],
                                    [41.18626986764406, -8.488450768036762],
                                    [41.186281595590856, -8.488432442710474],
                                ],
                                [
                                    [41.186262046392244, -8.488481285047454],
                                    [41.186250318445445, -8.488499610373742],
                                    [41.186243501589956, -8.488491965916705],
                                    [41.186255229536755, -8.488473640590417],
                                ],
                                [
                                    [41.186250318445445, -8.488499610373742],
                                    [41.18623859049864, -8.48851793570003],
                                    [41.18623177364316, -8.488510291242994],
                                    [41.186243501589956, -8.488491965916705],
                                ],
                                [
                                    [41.18623568033814, -8.488522482927397],
                                    [41.186223952391344, -8.488540808253685],
                                    [41.186217135535856, -8.488533163796648],
                                    [41.18622886348266, -8.48851483847036],
                                ],
                                [
                                    [41.186223952391344, -8.488540808253685],
                                    [41.18621222444454, -8.488559133579972],
                                    [41.18620540758906, -8.488551489122937],
                                    [41.186217135535856, -8.488533163796648],
                                ],
                                [
                                    [41.18621222444454, -8.488559133579972],
                                    [41.18620049649774, -8.488577458906262],
                                    [41.18619367964225, -8.488569814449225],
                                    [41.18620540758906, -8.488551489122937],
                                ],
                                [
                                    [41.186197586337244, -8.488582006133628],
                                    [41.18618585839044, -8.488600331459915],
                                    [41.186179041534956, -8.48859268700288],
                                    [41.186190769481755, -8.48857436167659],
                                ],
                                [
                                    [41.18618585839044, -8.488600331459915],
                                    [41.18617413044364, -8.488618656786205],
                                    [41.18616731358815, -8.488611012329168],
                                    [41.186179041534956, -8.48859268700288],
                                ],
                                [
                                    [41.18613603644273, -8.488678179992435],
                                    [41.186124308495934, -8.488696505318723],
                                    [41.186117491640445, -8.488688860861688],
                                    [41.18612921958725, -8.488670535535398],
                                ],
                                [
                                    [41.18612139833544, -8.48870105254609],
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18610285353315, -8.488711733415341],
                                    [41.18611458147995, -8.488693408089054],
                                ],
                                [
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18609794244183, -8.488737703198666],
                                    [41.186091125586344, -8.48873005874163],
                                    [41.18610285353315, -8.488711733415341],
                                ],
                                [
                                    [41.18609794244183, -8.488737703198666],
                                    [41.18608621449503, -8.488756028524955],
                                    [41.186079397639546, -8.488748384067918],
                                    [41.186091125586344, -8.48873005874163],
                                ],
                                [
                                    [41.18635778359266, -8.488313396298011],
                                    [41.18634605564586, -8.4883317216243],
                                    [41.186339238790374, -8.488324077167263],
                                    [41.18635096673718, -8.488305751840976],
                                ],
                                [
                                    [41.18634314548537, -8.488336268851667],
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18632460068308, -8.488346949720919],
                                    [41.18633632862988, -8.488328624394631],
                                ],
                                [
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18631287273627, -8.488365275047206],
                                    [41.18632460068308, -8.488346949720919],
                                ],
                                [
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18630796164496, -8.488391244830531],
                                    [41.186301144789475, -8.488383600373496],
                                    [41.18631287273627, -8.488365275047206],
                                ],
                                [
                                    [41.18630505148446, -8.488395792057897],
                                    [41.18629332353766, -8.488414117384186],
                                    [41.18628650668217, -8.48840647292715],
                                    [41.18629823462898, -8.488388147600862],
                                ],
                                [
                                    [41.18629332353766, -8.488414117384186],
                                    [41.186281595590856, -8.488432442710474],
                                    [41.186274778735374, -8.488424798253439],
                                    [41.18628650668217, -8.48840647292715],
                                ],
                                [
                                    [41.186281595590856, -8.488432442710474],
                                    [41.18626986764406, -8.488450768036762],
                                    [41.18626305078857, -8.488443123579726],
                                    [41.186274778735374, -8.488424798253439],
                                ],
                                [
                                    [41.186255229536755, -8.488473640590417],
                                    [41.186243501589956, -8.488491965916705],
                                    [41.186236684734475, -8.48848432145967],
                                    [41.18624841268127, -8.488465996133382],
                                ],
                                [
                                    [41.186243501589956, -8.488491965916705],
                                    [41.18623177364316, -8.488510291242994],
                                    [41.18622495678767, -8.488502646785957],
                                    [41.186236684734475, -8.48848432145967],
                                ],
                                [
                                    [41.18622886348266, -8.48851483847036],
                                    [41.186217135535856, -8.488533163796648],
                                    [41.186210318680374, -8.488525519339612],
                                    [41.18622204662717, -8.488507194013325],
                                ],
                                [
                                    [41.186217135535856, -8.488533163796648],
                                    [41.18620540758906, -8.488551489122937],
                                    [41.18619859073357, -8.4885438446659],
                                    [41.186210318680374, -8.488525519339612],
                                ],
                                [
                                    [41.18620540758906, -8.488551489122937],
                                    [41.18619367964225, -8.488569814449225],
                                    [41.18618686278677, -8.48856216999219],
                                    [41.18619859073357, -8.4885438446659],
                                ],
                                [
                                    [41.186190769481755, -8.48857436167659],
                                    [41.186179041534956, -8.48859268700288],
                                    [41.18617222467947, -8.488585042545843],
                                    [41.18618395262627, -8.488566717219555],
                                ],
                                [
                                    [41.186179041534956, -8.48859268700288],
                                    [41.18616731358815, -8.488611012329168],
                                    [41.18616049673267, -8.488603367872132],
                                    [41.18617222467947, -8.488585042545843],
                                ],
                                [
                                    [41.18612921958725, -8.488670535535398],
                                    [41.186117491640445, -8.488688860861688],
                                    [41.18611067478496, -8.48868121640465],
                                    [41.18612240273176, -8.488662891078363],
                                ],
                                [
                                    [41.18611458147995, -8.488693408089054],
                                    [41.18610285353315, -8.488711733415341],
                                    [41.18609603667766, -8.488704088958306],
                                    [41.18610776462447, -8.488685763632018],
                                ],
                                [
                                    [41.18610285353315, -8.488711733415341],
                                    [41.186091125586344, -8.48873005874163],
                                    [41.18608430873086, -8.488722414284593],
                                    [41.18609603667766, -8.488704088958306],
                                ],
                                [
                                    [41.186091125586344, -8.48873005874163],
                                    [41.186079397639546, -8.488748384067918],
                                    [41.18607258078406, -8.488740739610883],
                                    [41.18608430873086, -8.488722414284593],
                                ],
                            ],
                            panels_idx: [
                                [0, -5, [0, 0]],
                                [0, -5, [0, 1]],
                                [0, -5, [0, 2]],
                                [0, -4, [0, 0]],
                                [0, -4, [0, 1]],
                                [0, -4, [0, 2]],
                                [0, -3, [0, 0]],
                                [0, -3, [0, 1]],
                                [0, -3, [0, 2]],
                                [0, -2, [0, 0]],
                                [0, -2, [0, 2]],
                                [0, -1, [0, 0]],
                                [0, -1, [0, 1]],
                                [0, -1, [0, 2]],
                                [1, -8, [0, 2]],
                                [1, -7, [0, 0]],
                                [1, -7, [0, 1]],
                                [1, -7, [0, 2]],
                                [1, -6, [0, 0]],
                                [1, -6, [0, 1]],
                                [1, -6, [0, 2]],
                                [1, -5, [0, 0]],
                                [1, -5, [0, 1]],
                                [1, -5, [0, 2]],
                                [1, -4, [0, 0]],
                                [1, -4, [0, 1]],
                                [1, -4, [0, 2]],
                                [1, -3, [0, 0]],
                                [1, -3, [0, 1]],
                                [1, -3, [0, 2]],
                                [1, -2, [0, 0]],
                                [1, -2, [0, 2]],
                                [1, -1, [0, 0]],
                                [1, -1, [0, 1]],
                                [1, -1, [0, 2]],
                                [2, -8, [0, 2]],
                                [2, -7, [0, 0]],
                                [2, -7, [0, 1]],
                                [2, -7, [0, 2]],
                                [2, -6, [0, 0]],
                                [2, -6, [0, 1]],
                                [2, -6, [0, 2]],
                                [2, -5, [0, 0]],
                                [2, -5, [0, 1]],
                                [2, -5, [0, 2]],
                                [2, -4, [0, 0]],
                                [2, -4, [0, 1]],
                                [2, -4, [0, 2]],
                                [2, -3, [0, 0]],
                                [2, -3, [0, 1]],
                                [2, -3, [0, 2]],
                                [2, -2, [0, 0]],
                                [2, -2, [0, 2]],
                                [2, -1, [0, 0]],
                                [2, -1, [0, 1]],
                                [2, -1, [0, 2]],
                                [3, -8, [0, 2]],
                                [3, -7, [0, 0]],
                                [3, -7, [0, 1]],
                                [3, -7, [0, 2]],
                                [3, -6, [0, 0]],
                                [3, -6, [0, 1]],
                                [3, -6, [0, 2]],
                                [3, -5, [0, 0]],
                                [3, -5, [0, 1]],
                                [3, -5, [0, 2]],
                                [3, -4, [0, 0]],
                                [3, -4, [0, 1]],
                                [3, -4, [0, 2]],
                                [3, -3, [0, 0]],
                                [3, -3, [0, 1]],
                                [3, -3, [0, 2]],
                                [3, -2, [0, 0]],
                                [3, -2, [0, 2]],
                                [3, -1, [0, 0]],
                                [3, -1, [0, 1]],
                                [3, -1, [0, 2]],
                                [4, -8, [0, 2]],
                                [4, -7, [0, 0]],
                                [4, -7, [0, 1]],
                                [4, -7, [0, 2]],
                                [4, -6, [0, 0]],
                                [4, -6, [0, 1]],
                                [4, -6, [0, 2]],
                                [4, -5, [0, 0]],
                                [4, -5, [0, 1]],
                                [4, -5, [0, 2]],
                                [4, -4, [0, 0]],
                                [4, -4, [0, 1]],
                                [4, -4, [0, 2]],
                                [4, -3, [0, 0]],
                                [4, -3, [0, 1]],
                                [4, -3, [0, 2]],
                                [4, -2, [0, 0]],
                                [4, -2, [0, 2]],
                                [4, -1, [0, 0]],
                                [4, -1, [0, 1]],
                                [4, -1, [0, 2]],
                                [5, -8, [0, 2]],
                                [5, -7, [0, 0]],
                                [5, -7, [0, 1]],
                                [5, -7, [0, 2]],
                                [5, -6, [0, 0]],
                                [5, -6, [0, 1]],
                                [5, -6, [0, 2]],
                                [5, -5, [0, 1]],
                                [5, -5, [0, 2]],
                                [5, -4, [0, 0]],
                                [5, -4, [0, 1]],
                                [5, -4, [0, 2]],
                                [5, -3, [0, 0]],
                                [5, -3, [0, 1]],
                                [5, -2, [0, 2]],
                                [5, -1, [0, 0]],
                                [5, -1, [0, 1]],
                                [5, -1, [0, 2]],
                                [6, -8, [0, 2]],
                                [6, -7, [0, 0]],
                                [6, -7, [0, 1]],
                                [6, -7, [0, 2]],
                                [6, -6, [0, 0]],
                                [6, -6, [0, 1]],
                                [6, -6, [0, 2]],
                                [6, -5, [0, 1]],
                                [6, -5, [0, 2]],
                                [6, -4, [0, 0]],
                                [6, -4, [0, 1]],
                                [6, -4, [0, 2]],
                                [6, -3, [0, 0]],
                                [6, -3, [0, 1]],
                                [6, -2, [0, 2]],
                                [6, -1, [0, 0]],
                                [6, -1, [0, 1]],
                                [6, -1, [0, 2]],
                            ],
                            perc_shadow: [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            ],
                            panels_number: 134,
                        },
                    ],
                    coordinates: [
                        {
                            lat: 41.186407983698146,
                            lng: -8.488353057749572,
                        },
                        {
                            lat: 41.18634742692,
                            lng: -8.4882860025242,
                        },
                        {
                            lat: 41.18606079150344,
                            lng: -8.488735272534194,
                        },
                        {
                            lat: 41.186121348281596,
                            lng: -8.488803668864074,
                        },
                        {
                            lat: 41.186407983698146,
                            lng: -8.488353057749572,
                        },
                    ],
                    orientation: 139.73,
                    region_gd_id: 125,
                    panels_number: 134,
                    perimeter_area: 116.39452684003277,
                    coordinates_avg: {
                        lat: 41.18626910682026,
                        long: -8.488506211884323,
                        region_gd_id: 125,
                    },
                    autoSplitBuilding: 1,
                    panel_representation: {
                        dayRank: '2024-12-22T00:00:00.000Z',
                        setback: 0.5,
                        rowSpace: 0,
                        alignment: 'left',
                        panels_nr: 3,
                        panels_up: 1,
                        roofSlope: 5,
                        row_space: 0,
                        hourWindow: 4,
                        panels_wide: 3,
                        panelFraming: 1,
                        space_btw_sets: 0.5,
                        reference_point: [41.18612029877246, -8.488794250810136],
                        isDefaultRowRatio: false,
                        alignment_vertical: 'bottom',
                        alignment_horizontal: 'left',
                        is_row_spacing_insert: true,
                        coordinates_conversion_factor: {
                            one_meter_in_lat: 0.00000900434376143347,
                            one_meter_in_lon: 0.00001191923025934094,
                        },
                    },
                    mounting_structure_id: 7,
                    panels_number_excluded: 0,
                    panels_number_possible: 134,
                    mounting_structure_type_id: 18,
                },
            ],
            panel_id: 8,
            range_id: 1,
            buildings: [],
            exclusions: [
                {
                    id: 1,
                    area: 2.3236966347219257,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18617316347283, -8.488563568358668],
                        [41.18618324631769, -8.488547754070915],
                        [41.18617407397729, -8.488537428467358],
                        [41.18616399113243, -8.488553242755112],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 2,
                    area: 2.3883073931472767,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186274582160976, -8.488404166121391],
                        [41.18628514290098, -8.488387602286126],
                        [41.18627614211645, -8.488377469808624],
                        [41.186265581376446, -8.488394033643889],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 3,
                    area: 11.675015378699932,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18603549503049, -8.488645928834034],
                        [41.186075928736386, -8.488582511190609],
                        [41.186064436702104, -8.488569574231784],
                        [41.186024002996206, -8.488632991875209],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 4,
                    area: 5.258506222422111,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18603166304474, -8.488638176882642],
                        [41.18606929017439, -8.48857916117191],
                        [41.18606372800404, -8.488572899654843],
                        [41.186026100874386, -8.488631915365575],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 5,
                    area: 3.2157922936748062,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.1862581688646, -8.4884462602208],
                        [41.18624740430836, -8.488463143719617],
                        [41.18625929414733, -8.488476528529995],
                        [41.18627005870356, -8.488459645031178],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 6,
                    area: 3.2750328350843887,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186153259099875, -8.488609235659645],
                        [41.18614248717701, -8.488626130712536],
                        [41.18615458774738, -8.488639752750684],
                        [41.18616535967025, -8.488622857697793],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 7,
                    area: 1.3555382376282392,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18628200384683, -8.488386855640433],
                        [41.1862722522419, -8.488402150391726],
                        [41.18627778472285, -8.488408378500344],
                        [41.18628753632778, -8.48839308374905],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 8,
                    area: 4.929091323309152,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186128021055545, -8.488652061540803],
                        [41.18612427355185, -8.488657939253995],
                        [41.18617662252929, -8.488716870341182],
                        [41.18618037003298, -8.48871099262799],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 9,
                    area: 1.258065297983659,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18618149003819, -8.488546753576067],
                        [41.1861705707031, -8.488563879835331],
                        [41.186175156244, -8.488569041940183],
                        [41.18618607557908, -8.488551915680917],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 10,
                    area: 0.9409541472002925,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18628546455482, -8.488389084310926],
                        [41.186274891939085, -8.488405666763681],
                        [41.18627843411626, -8.488409654317254],
                        [41.186289006732, -8.488393071864499],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 11,
                    area: 0.8428999954032902,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.1862658088651, -8.488458005587107],
                        [41.1862569567156, -8.488471889601671],
                        [41.1862607464734, -8.488476155865465],
                        [41.18626959862289, -8.488462271850901],
                    ],
                    autoSplitBuilding: 1,
                },
            ],
            prod_params: {
                param: 'realTimeV1',
                raddatabase: 'PVGIS-SARAH',
                pvtechchoice: 'crystSi',
                pvgis_ano_tipico: 2014,
                factor_perda_inicial: 0.0834,
                geogrid_factor_correcao: null,
                factor_perda_extra_coplanar: 0.0325,
            },
            total_areas: 870.2858837294776,
            network_sale: true,
            total_panels: 280,
            max_tec_panels: 3012,
            min_tec_panels: 10,
            coordinates_avg: {
                lat: 41.18620663241081,
                long: -8.488509564645591,
                region_gd_id: 125,
            },
            injection_tariff: 0.06,
            limit_network_sale: false,
            inverters_combination: [
                {
                    id: 3,
                    qt: 0,
                    cost: 839.17,
                    brand: 'Huawei',
                    model: 'SUN2000-4KTL-M1',
                    power: 4,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 4,
                    qt: 0,
                    cost: 886.11,
                    brand: 'Huawei',
                    model: 'SUN2000-5KTL-M1',
                    power: 5,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 5,
                    qt: 0,
                    cost: 990.28,
                    brand: 'Huawei',
                    model: 'SUN2000-6KTL-M1',
                    power: 6,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 6,
                    qt: 0,
                    cost: 1174.59,
                    brand: 'Huawei',
                    model: 'SUN2000-8KTL-M1',
                    power: 8,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 7,
                    qt: 0,
                    cost: 1319.43,
                    brand: 'Huawei',
                    model: 'SUN2000-10KTL-M2',
                    power: 10,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 8,
                    qt: 0,
                    cost: 1407.92,
                    brand: 'Huawei',
                    model: 'SUN2000-12KTL-M2',
                    power: 12,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 10,
                    qt: 0,
                    cost: 1623.85,
                    brand: 'Huawei',
                    model: 'SUN2000-15KTL-M2',
                    power: 15,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 11,
                    qt: 0,
                    cost: 1630.98,
                    brand: 'Huawei',
                    model: 'SUN2000-17KTL-M2',
                    power: 17,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 12,
                    qt: 1,
                    cost: 1723.17,
                    brand: 'Huawei',
                    model: 'SUN2000-20KTL-M2',
                    power: 20,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 13,
                    qt: 0,
                    cost: 1966.38,
                    brand: 'Huawei',
                    model: 'SUN2000-30KTL-M3',
                    power: 30,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 14,
                    qt: 1,
                    cost: 2207.08,
                    brand: 'Huawei',
                    model: 'SUN2000-36KTL-M3',
                    power: 36,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 15,
                    qt: 1,
                    cost: 2360.96,
                    brand: 'Huawei',
                    model: 'SUN2000-40KTL-M3',
                    power: 40,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 16,
                    qt: 0,
                    cost: 2233.39,
                    brand: 'Huawei',
                    model: 'SUN2000-50KTL-M3',
                    power: 50,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 18,
                    qt: 0,
                    cost: 4384.41,
                    brand: 'Huawei',
                    model: 'SUN2000-100KTL-M1',
                    power: 100,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 20,
                    qt: 0,
                    cost: 4640.33,
                    brand: 'Sungrow',
                    model: 'SG110CX',
                    power: 110,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 19,
                    qt: 0,
                    cost: 3230.05,
                    brand: 'Sungrow',
                    model: 'SG50CX',
                    power: 50,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
            ],
            total_perimeter_areas: 232.789108655615,
            coordinates_conversion_factor: {
                one_meter_in_lat: 0.00000900434376143347,
                one_meter_in_lon: 0.00001191923025934094,
            },
        },
        data_gravacao: '2024-03-26T08:03:14.805Z',
        utilizador_id: '608138',
        ativo: true,
        visivel: true,
        versao_projeto_produto_inputs_id: 1,
        description: 'Ceramica Telhado',
        comments: 'Projeto de 100kWp, não sei que. Agua a sul.',
        data_criacao: '2024-03-26T08:03:14.752Z',
        data_edicao: '2024-03-26T08:03:14.752Z',
        tipo_produto_id: 1,
        empresa_id: 1,
    },
    {
        id: '444',
        projecto_id: '444',
        instalacao_id: '567883',
        inputs: {
            areas: [
                {
                    id: 1,
                    lat: 41.186144158001355,
                    area: 435.1431263231753,
                    long: -8.48851291740686,
                    edges: [
                        {
                            aspect: 49.480200361030604,
                            points: [
                                {
                                    lat: 41.1860521,
                                    lng: -8.4887187,
                                },
                                {
                                    lat: 41.186038599999996,
                                    lng: -8.4887035,
                                },
                            ],
                        },
                        {
                            aspect: -41.610839990283644,
                            points: [
                                {
                                    lat: 41.186038599999996,
                                    lng: -8.4887035,
                                },
                                {
                                    lat: 41.18601160000001,
                                    lng: -8.488673,
                                },
                            ],
                        },
                        {
                            aspect: -130.44565838115057,
                            points: [
                                {
                                    lat: 41.18601160000001,
                                    lng: -8.488673,
                                },
                                {
                                    lat: 41.1860049,
                                    lng: -8.4886653,
                                },
                            ],
                        },
                        {
                            aspect: 170.68635433153466,
                            points: [
                                {
                                    lat: 41.1860049,
                                    lng: -8.4886653,
                                },
                                {
                                    lat: 41.186082,
                                    lng: -8.4885456,
                                },
                            ],
                        },
                        {
                            aspect: 141.1543891066709,
                            points: [
                                {
                                    lat: 41.186082,
                                    lng: -8.4885456,
                                },
                                {
                                    lat: 41.1861688,
                                    lng: -8.4884109,
                                },
                            ],
                        },
                        {
                            aspect: 131.00873260257197,
                            points: [
                                {
                                    lat: 41.1861688,
                                    lng: -8.4884109,
                                },
                                {
                                    lat: 41.1862074,
                                    lng: -8.488351,
                                },
                            ],
                        },
                    ],
                    slope: 5,
                    aspect: -40.27116805574741,
                    selected: true,
                    panel_rows: [
                        {
                            positions: [
                                [
                                    [41.186012273946346, -8.48866562329768],
                                    [41.186024001893145, -8.488647297971392],
                                    [41.186030818748634, -8.488654942428427],
                                    [41.186019090801835, -8.488673267754715],
                                ],
                                [
                                    [41.18607382384085, -8.488569449438872],
                                    [41.186085551787656, -8.488551124112584],
                                    [41.18609236864314, -8.48855876856962],
                                    [41.18608064069634, -8.488577093895909],
                                ],
                                [
                                    [41.18608846194815, -8.488546576885216],
                                    [41.18610018989495, -8.488528251558929],
                                    [41.18610700675044, -8.488535896015966],
                                    [41.186095278803634, -8.488554221342254],
                                ],
                                [
                                    [41.18610018989495, -8.488528251558929],
                                    [41.18611191784176, -8.488509926232641],
                                    [41.18611873469724, -8.488517570689677],
                                    [41.18610700675044, -8.488535896015966],
                                ],
                                [
                                    [41.18611191784176, -8.488509926232641],
                                    [41.186123645788555, -8.488491600906352],
                                    [41.186130462644044, -8.48849924536339],
                                    [41.18611873469724, -8.488517570689677],
                                ],
                                [
                                    [41.18612655594905, -8.488487053678986],
                                    [41.18613828389586, -8.488468728352698],
                                    [41.18614510075134, -8.488476372809734],
                                    [41.18613337280454, -8.488494698136023],
                                ],
                                [
                                    [41.18613828389586, -8.488468728352698],
                                    [41.186150011842656, -8.488450403026409],
                                    [41.186156828698145, -8.488458047483446],
                                    [41.18614510075134, -8.488476372809734],
                                ],
                                [
                                    [41.186150011842656, -8.488450403026409],
                                    [41.18616173978946, -8.488432077700121],
                                    [41.186168556644944, -8.488439722157159],
                                    [41.186156828698145, -8.488458047483446],
                                ],
                                [
                                    [41.18616464994996, -8.488427530472755],
                                    [41.18617637789676, -8.488409205146466],
                                    [41.186183194752246, -8.488416849603503],
                                    [41.18617146680544, -8.48843517492979],
                                ],
                                [
                                    [41.18617637789676, -8.488409205146466],
                                    [41.18618810584356, -8.488390879820178],
                                    [41.186194922699045, -8.488398524277216],
                                    [41.186183194752246, -8.488416849603503],
                                ],
                                [
                                    [41.18618810584356, -8.488390879820178],
                                    [41.18619983379036, -8.48837255449389],
                                    [41.18620665064585, -8.488380198950926],
                                    [41.186194922699045, -8.488398524277216],
                                ],
                                [
                                    [41.18620274395086, -8.488368007266523],
                                    [41.186214471897664, -8.488349681940235],
                                    [41.186221288753146, -8.488357326397272],
                                    [41.18620956080635, -8.48837565172356],
                                ],
                                [
                                    [41.186214471897664, -8.488349681940235],
                                    [41.18622619984446, -8.488331356613948],
                                    [41.186233016699944, -8.488339001070983],
                                    [41.186221288753146, -8.488357326397272],
                                ],
                                [
                                    [41.18622619984446, -8.488331356613948],
                                    [41.18623792779126, -8.488313031287658],
                                    [41.18624474464675, -8.488320675744696],
                                    [41.186233016699944, -8.488339001070983],
                                ],
                                [
                                    [41.18624083795176, -8.488308484060292],
                                    [41.18625256589856, -8.488290158734005],
                                    [41.186259382754045, -8.48829780319104],
                                    [41.18624765480725, -8.48831612851733],
                                ],
                                [
                                    [41.18625256589856, -8.488290158734005],
                                    [41.18626429384536, -8.488271833407715],
                                    [41.18627111070085, -8.488279477864753],
                                    [41.186259382754045, -8.48829780319104],
                                ],
                                [
                                    [41.18626429384536, -8.488271833407715],
                                    [41.18627602179217, -8.488253508081428],
                                    [41.18628283864765, -8.488261152538465],
                                    [41.18627111070085, -8.488279477864753],
                                ],
                                [
                                    [41.186278931952664, -8.488248960854062],
                                    [41.18629065989946, -8.488230635527772],
                                    [41.18629747675495, -8.48823827998481],
                                    [41.186285748808146, -8.488256605311097],
                                ],
                                [
                                    [41.186019090801835, -8.488673267754715],
                                    [41.186030818748634, -8.488654942428427],
                                    [41.186037635604116, -8.488662586885464],
                                    [41.18602590765732, -8.488680912211752],
                                ],
                                [
                                    [41.186030818748634, -8.488654942428427],
                                    [41.18604254669543, -8.48863661710214],
                                    [41.18604936355092, -8.488644261559175],
                                    [41.186037635604116, -8.488662586885464],
                                ],
                                [
                                    [41.18604254669543, -8.48863661710214],
                                    [41.18605427464224, -8.48861829177585],
                                    [41.18606109149772, -8.488625936232888],
                                    [41.18604936355092, -8.488644261559175],
                                ],
                                [
                                    [41.186057184802735, -8.488613744548484],
                                    [41.18606891274953, -8.488595419222197],
                                    [41.18607572960502, -8.488603063679232],
                                    [41.18606400165822, -8.488621389005521],
                                ],
                                [
                                    [41.18606891274953, -8.488595419222197],
                                    [41.18608064069634, -8.488577093895909],
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18607572960502, -8.488603063679232],
                                ],
                                [
                                    [41.18608064069634, -8.488577093895909],
                                    [41.18609236864314, -8.48855876856962],
                                    [41.18609918549863, -8.488566413026657],
                                    [41.18608745755182, -8.488584738352944],
                                ],
                                [
                                    [41.186095278803634, -8.488554221342254],
                                    [41.18610700675044, -8.488535896015966],
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18610209565912, -8.488561865799289],
                                ],
                                [
                                    [41.18610700675044, -8.488535896015966],
                                    [41.18611873469724, -8.488517570689677],
                                    [41.18612555155273, -8.488525215146714],
                                    [41.18611382360592, -8.488543540473001],
                                ],
                                [
                                    [41.18611873469724, -8.488517570689677],
                                    [41.186130462644044, -8.48849924536339],
                                    [41.186137279499526, -8.488506889820425],
                                    [41.18612555155273, -8.488525215146714],
                                ],
                                [
                                    [41.18613337280454, -8.488494698136023],
                                    [41.18614510075134, -8.488476372809734],
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18614018966002, -8.488502342593058],
                                ],
                                [
                                    [41.18614510075134, -8.488476372809734],
                                    [41.186156828698145, -8.488458047483446],
                                    [41.18616364555363, -8.488465691940482],
                                    [41.18615191760683, -8.48848401726677],
                                ],
                                [
                                    [41.186156828698145, -8.488458047483446],
                                    [41.186168556644944, -8.488439722157159],
                                    [41.18617537350043, -8.488447366614194],
                                    [41.18616364555363, -8.488465691940482],
                                ],
                                [
                                    [41.18617146680544, -8.48843517492979],
                                    [41.186183194752246, -8.488416849603503],
                                    [41.18619001160773, -8.488424494060538],
                                    [41.18617828366093, -8.488442819386828],
                                ],
                                [
                                    [41.186183194752246, -8.488416849603503],
                                    [41.186194922699045, -8.488398524277216],
                                    [41.186201739554534, -8.488406168734251],
                                    [41.18619001160773, -8.488424494060538],
                                ],
                                [
                                    [41.186194922699045, -8.488398524277216],
                                    [41.18620665064585, -8.488380198950926],
                                    [41.18621346750133, -8.488387843407963],
                                    [41.186201739554534, -8.488406168734251],
                                ],
                                [
                                    [41.18620956080635, -8.48837565172356],
                                    [41.186221288753146, -8.488357326397272],
                                    [41.186228105608635, -8.488364970854308],
                                    [41.18621637766183, -8.488383296180595],
                                ],
                                [
                                    [41.186221288753146, -8.488357326397272],
                                    [41.186233016699944, -8.488339001070983],
                                    [41.18623983355543, -8.48834664552802],
                                    [41.186228105608635, -8.488364970854308],
                                ],
                                [
                                    [41.186233016699944, -8.488339001070983],
                                    [41.18624474464675, -8.488320675744696],
                                    [41.18625156150223, -8.488328320201731],
                                    [41.18623983355543, -8.48834664552802],
                                ],
                                [
                                    [41.18624765480725, -8.48831612851733],
                                    [41.186259382754045, -8.48829780319104],
                                    [41.186266199609534, -8.488305447648077],
                                    [41.18625447166273, -8.488323772974365],
                                ],
                                [
                                    [41.186259382754045, -8.48829780319104],
                                    [41.18627111070085, -8.488279477864753],
                                    [41.18627792755633, -8.488287122321788],
                                    [41.186266199609534, -8.488305447648077],
                                ],
                                [
                                    [41.18627111070085, -8.488279477864753],
                                    [41.18628283864765, -8.488261152538465],
                                    [41.18628965550314, -8.4882687969955],
                                    [41.18627792755633, -8.488287122321788],
                                ],
                                [
                                    [41.186285748808146, -8.488256605311097],
                                    [41.18629747675495, -8.48823827998481],
                                    [41.18630429361043, -8.488245924441845],
                                    [41.186292565663635, -8.488264249768134],
                                ],
                                [
                                    [41.18602590765732, -8.488680912211752],
                                    [41.186037635604116, -8.488662586885464],
                                    [41.186044452459605, -8.4886702313425],
                                    [41.186032724512806, -8.488688556668787],
                                ],
                                [
                                    [41.186037635604116, -8.488662586885464],
                                    [41.18604936355092, -8.488644261559175],
                                    [41.1860561804064, -8.488651906016212],
                                    [41.186044452459605, -8.4886702313425],
                                ],
                                [
                                    [41.18604936355092, -8.488644261559175],
                                    [41.18606109149772, -8.488625936232888],
                                    [41.18606790835321, -8.488633580689923],
                                    [41.1860561804064, -8.488651906016212],
                                ],
                                [
                                    [41.18606400165822, -8.488621389005521],
                                    [41.18607572960502, -8.488603063679232],
                                    [41.186082546460504, -8.48861070813627],
                                    [41.186070818513706, -8.488629033462557],
                                ],
                                [
                                    [41.18607572960502, -8.488603063679232],
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18609427440731, -8.48859238280998],
                                    [41.186082546460504, -8.48861070813627],
                                ],
                                [
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18609918549863, -8.488566413026657],
                                    [41.18610600235411, -8.488574057483692],
                                    [41.18609427440731, -8.48859238280998],
                                ],
                                [
                                    [41.18610209565912, -8.488561865799289],
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18612064046141, -8.488551184930039],
                                    [41.186108912514605, -8.488569510256326],
                                ],
                                [
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18612555155273, -8.488525215146714],
                                    [41.18613236840821, -8.48853285960375],
                                    [41.18612064046141, -8.488551184930039],
                                ],
                                [
                                    [41.18612555155273, -8.488525215146714],
                                    [41.186137279499526, -8.488506889820425],
                                    [41.186144096355015, -8.488514534277462],
                                    [41.18613236840821, -8.48853285960375],
                                ],
                                [
                                    [41.18614018966002, -8.488502342593058],
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18615873446231, -8.488491661723806],
                                    [41.18614700651551, -8.488509987050096],
                                ],
                                [
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18616364555363, -8.488465691940482],
                                    [41.186170462409116, -8.488473336397519],
                                    [41.18615873446231, -8.488491661723806],
                                ],
                                [
                                    [41.18616364555363, -8.488465691940482],
                                    [41.18617537350043, -8.488447366614194],
                                    [41.186182190355915, -8.488455011071231],
                                    [41.186170462409116, -8.488473336397519],
                                ],
                                [
                                    [41.18617828366093, -8.488442819386828],
                                    [41.18619001160773, -8.488424494060538],
                                    [41.18619682846322, -8.488432138517576],
                                    [41.18618510051641, -8.488450463843863],
                                ],
                                [
                                    [41.18619001160773, -8.488424494060538],
                                    [41.186201739554534, -8.488406168734251],
                                    [41.186208556410016, -8.488413813191288],
                                    [41.18619682846322, -8.488432138517576],
                                ],
                                [
                                    [41.186201739554534, -8.488406168734251],
                                    [41.18621346750133, -8.488387843407963],
                                    [41.18622028435682, -8.488395487864999],
                                    [41.186208556410016, -8.488413813191288],
                                ],
                                [
                                    [41.18621637766183, -8.488383296180595],
                                    [41.186228105608635, -8.488364970854308],
                                    [41.186234922464116, -8.488372615311345],
                                    [41.18622319451732, -8.488390940637633],
                                ],
                                [
                                    [41.186228105608635, -8.488364970854308],
                                    [41.18623983355543, -8.48834664552802],
                                    [41.18624665041092, -8.488354289985056],
                                    [41.186234922464116, -8.488372615311345],
                                ],
                                [
                                    [41.18623983355543, -8.48834664552802],
                                    [41.18625156150223, -8.488328320201731],
                                    [41.18625837835772, -8.488335964658768],
                                    [41.18624665041092, -8.488354289985056],
                                ],
                                [
                                    [41.18625447166273, -8.488323772974365],
                                    [41.186266199609534, -8.488305447648077],
                                    [41.186273016465016, -8.488313092105113],
                                    [41.18626128851822, -8.488331417431402],
                                ],
                                [
                                    [41.186266199609534, -8.488305447648077],
                                    [41.18627792755633, -8.488287122321788],
                                    [41.18628474441182, -8.488294766778825],
                                    [41.186273016465016, -8.488313092105113],
                                ],
                                [
                                    [41.18627792755633, -8.488287122321788],
                                    [41.18628965550314, -8.4882687969955],
                                    [41.18629647235862, -8.488276441452538],
                                    [41.18628474441182, -8.488294766778825],
                                ],
                                [
                                    [41.186292565663635, -8.488264249768134],
                                    [41.18630429361043, -8.488245924441845],
                                    [41.18631111046592, -8.488253568898882],
                                    [41.18629938251912, -8.48827189422517],
                                ],
                                [
                                    [41.186032724512806, -8.488688556668787],
                                    [41.186044452459605, -8.4886702313425],
                                    [41.186051269315094, -8.488677875799537],
                                    [41.18603954136829, -8.488696201125824],
                                ],
                                [
                                    [41.186044452459605, -8.4886702313425],
                                    [41.1860561804064, -8.488651906016212],
                                    [41.18606299726189, -8.488659550473248],
                                    [41.186051269315094, -8.488677875799537],
                                ],
                                [
                                    [41.1860561804064, -8.488651906016212],
                                    [41.18606790835321, -8.488633580689923],
                                    [41.18607472520869, -8.48864122514696],
                                    [41.18606299726189, -8.488659550473248],
                                ],
                                [
                                    [41.186070818513706, -8.488629033462557],
                                    [41.186082546460504, -8.48861070813627],
                                    [41.18608936331599, -8.488618352593305],
                                    [41.18607763536919, -8.488636677919594],
                                ],
                                [
                                    [41.186082546460504, -8.48861070813627],
                                    [41.18609427440731, -8.48859238280998],
                                    [41.18610109126279, -8.488600027267017],
                                    [41.18608936331599, -8.488618352593305],
                                ],
                                [
                                    [41.18609427440731, -8.48859238280998],
                                    [41.18610600235411, -8.488574057483692],
                                    [41.1861128192096, -8.48858170194073],
                                    [41.18610109126279, -8.488600027267017],
                                ],
                                [
                                    [41.186108912514605, -8.488569510256326],
                                    [41.18612064046141, -8.488551184930039],
                                    [41.18612745731689, -8.488558829387074],
                                    [41.186115729370094, -8.488577154713361],
                                ],
                                [
                                    [41.18612064046141, -8.488551184930039],
                                    [41.18613236840821, -8.48853285960375],
                                    [41.1861391852637, -8.488540504060786],
                                    [41.18612745731689, -8.488558829387074],
                                ],
                                [
                                    [41.18613236840821, -8.48853285960375],
                                    [41.186144096355015, -8.488514534277462],
                                    [41.1861509132105, -8.488522178734497],
                                    [41.1861391852637, -8.488540504060786],
                                ],
                                [
                                    [41.18614700651551, -8.488509987050096],
                                    [41.18615873446231, -8.488491661723806],
                                    [41.1861655513178, -8.488499306180843],
                                    [41.186153823370994, -8.488517631507131],
                                ],
                                [
                                    [41.18615873446231, -8.488491661723806],
                                    [41.186170462409116, -8.488473336397519],
                                    [41.1861772792646, -8.488480980854554],
                                    [41.1861655513178, -8.488499306180843],
                                ],
                                [
                                    [41.186170462409116, -8.488473336397519],
                                    [41.186182190355915, -8.488455011071231],
                                    [41.186189007211404, -8.488462655528267],
                                    [41.1861772792646, -8.488480980854554],
                                ],
                                [
                                    [41.18618510051641, -8.488450463843863],
                                    [41.18619682846322, -8.488432138517576],
                                    [41.1862036453187, -8.488439782974611],
                                    [41.1861919173719, -8.4884581083009],
                                ],
                                [
                                    [41.18619682846322, -8.488432138517576],
                                    [41.186208556410016, -8.488413813191288],
                                    [41.186215373265505, -8.488421457648323],
                                    [41.1862036453187, -8.488439782974611],
                                ],
                                [
                                    [41.186208556410016, -8.488413813191288],
                                    [41.18622028435682, -8.488395487864999],
                                    [41.1862271012123, -8.488403132322036],
                                    [41.186215373265505, -8.488421457648323],
                                ],
                                [
                                    [41.18622319451732, -8.488390940637633],
                                    [41.186234922464116, -8.488372615311345],
                                    [41.186241739319605, -8.48838025976838],
                                    [41.1862300113728, -8.488398585094668],
                                ],
                                [
                                    [41.186234922464116, -8.488372615311345],
                                    [41.18624665041092, -8.488354289985056],
                                    [41.186253467266404, -8.488361934442093],
                                    [41.186241739319605, -8.48838025976838],
                                ],
                                [
                                    [41.18624665041092, -8.488354289985056],
                                    [41.18625837835772, -8.488335964658768],
                                    [41.1862651952132, -8.488343609115804],
                                    [41.186253467266404, -8.488361934442093],
                                ],
                                [
                                    [41.18626128851822, -8.488331417431402],
                                    [41.186273016465016, -8.488313092105113],
                                    [41.186279833320505, -8.48832073656215],
                                    [41.186268105373706, -8.488339061888437],
                                ],
                                [
                                    [41.186273016465016, -8.488313092105113],
                                    [41.18628474441182, -8.488294766778825],
                                    [41.1862915612673, -8.48830241123586],
                                    [41.186279833320505, -8.48832073656215],
                                ],
                                [
                                    [41.18628474441182, -8.488294766778825],
                                    [41.18629647235862, -8.488276441452538],
                                    [41.18630328921411, -8.488284085909573],
                                    [41.1862915612673, -8.48830241123586],
                                ],
                                [
                                    [41.18629938251912, -8.48827189422517],
                                    [41.18631111046592, -8.488253568898882],
                                    [41.186317927321404, -8.488261213355917],
                                    [41.186306199374606, -8.488279538682207],
                                ],
                                [
                                    [41.18603954136829, -8.488696201125824],
                                    [41.186051269315094, -8.488677875799537],
                                    [41.186058086170576, -8.488685520256572],
                                    [41.18604635822378, -8.48870384558286],
                                ],
                                [
                                    [41.186051269315094, -8.488677875799537],
                                    [41.18606299726189, -8.488659550473248],
                                    [41.186069814117374, -8.488667194930285],
                                    [41.186058086170576, -8.488685520256572],
                                ],
                                [
                                    [41.18606299726189, -8.488659550473248],
                                    [41.18607472520869, -8.48864122514696],
                                    [41.18608154206418, -8.488648869603995],
                                    [41.186069814117374, -8.488667194930285],
                                ],
                                [
                                    [41.18607763536919, -8.488636677919594],
                                    [41.18608936331599, -8.488618352593305],
                                    [41.186096180171475, -8.488625997050342],
                                    [41.18608445222468, -8.48864432237663],
                                ],
                                [
                                    [41.18608936331599, -8.488618352593305],
                                    [41.18610109126279, -8.488600027267017],
                                    [41.18610790811828, -8.488607671724052],
                                    [41.186096180171475, -8.488625997050342],
                                ],
                                [
                                    [41.18610109126279, -8.488600027267017],
                                    [41.1861128192096, -8.48858170194073],
                                    [41.18611963606508, -8.488589346397765],
                                    [41.18610790811828, -8.488607671724052],
                                ],
                                [
                                    [41.186115729370094, -8.488577154713361],
                                    [41.18612745731689, -8.488558829387074],
                                    [41.18613427417238, -8.48856647384411],
                                    [41.186122546225576, -8.488584799170399],
                                ],
                                [
                                    [41.18612745731689, -8.488558829387074],
                                    [41.1861391852637, -8.488540504060786],
                                    [41.18614600211918, -8.488548148517822],
                                    [41.18613427417238, -8.48856647384411],
                                ],
                                [
                                    [41.1861391852637, -8.488540504060786],
                                    [41.1861509132105, -8.488522178734497],
                                    [41.186157730065986, -8.488529823191534],
                                    [41.18614600211918, -8.488548148517822],
                                ],
                                [
                                    [41.186153823370994, -8.488517631507131],
                                    [41.1861655513178, -8.488499306180843],
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18616064022648, -8.488525275964168],
                                ],
                                [
                                    [41.1861655513178, -8.488499306180843],
                                    [41.1861772792646, -8.488480980854554],
                                    [41.18618409612009, -8.488488625311591],
                                    [41.18617236817328, -8.488506950637879],
                                ],
                                [
                                    [41.1861772792646, -8.488480980854554],
                                    [41.186189007211404, -8.488462655528267],
                                    [41.186195824066886, -8.488470299985304],
                                    [41.18618409612009, -8.488488625311591],
                                ],
                                [
                                    [41.1861919173719, -8.4884581083009],
                                    [41.1862036453187, -8.488439782974611],
                                    [41.18621046217419, -8.488447427431648],
                                    [41.18619873422738, -8.488465752757936],
                                ],
                                [
                                    [41.1862036453187, -8.488439782974611],
                                    [41.186215373265505, -8.488421457648323],
                                    [41.186222190120986, -8.48842910210536],
                                    [41.18621046217419, -8.488447427431648],
                                ],
                                [
                                    [41.186215373265505, -8.488421457648323],
                                    [41.1862271012123, -8.488403132322036],
                                    [41.18623391806779, -8.488410776779071],
                                    [41.186222190120986, -8.48842910210536],
                                ],
                                [
                                    [41.1862300113728, -8.488398585094668],
                                    [41.186241739319605, -8.48838025976838],
                                    [41.18624855617509, -8.488387904225418],
                                    [41.18623682822829, -8.488406229551705],
                                ],
                                [
                                    [41.186241739319605, -8.48838025976838],
                                    [41.186253467266404, -8.488361934442093],
                                    [41.18626028412189, -8.488369578899128],
                                    [41.18624855617509, -8.488387904225418],
                                ],
                                [
                                    [41.186253467266404, -8.488361934442093],
                                    [41.1862651952132, -8.488343609115804],
                                    [41.18627201206869, -8.48835125357284],
                                    [41.18626028412189, -8.488369578899128],
                                ],
                                [
                                    [41.186268105373706, -8.488339061888437],
                                    [41.186279833320505, -8.48832073656215],
                                    [41.18628665017599, -8.488328381019185],
                                    [41.18627492222919, -8.488346706345475],
                                ],
                                [
                                    [41.186279833320505, -8.48832073656215],
                                    [41.1862915612673, -8.48830241123586],
                                    [41.18629837812279, -8.488310055692898],
                                    [41.18628665017599, -8.488328381019185],
                                ],
                                [
                                    [41.1862915612673, -8.48830241123586],
                                    [41.18630328921411, -8.488284085909573],
                                    [41.18631010606959, -8.48829173036661],
                                    [41.18629837812279, -8.488310055692898],
                                ],
                                [
                                    [41.186306199374606, -8.488279538682207],
                                    [41.186317927321404, -8.488261213355917],
                                    [41.18632474417689, -8.488268857812955],
                                    [41.18631301623009, -8.488287183139242],
                                ],
                                [
                                    [41.18604635822378, -8.48870384558286],
                                    [41.186058086170576, -8.488685520256572],
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18605317507926, -8.488711490039897],
                                ],
                                [
                                    [41.186058086170576, -8.488685520256572],
                                    [41.186069814117374, -8.488667194930285],
                                    [41.18607663097286, -8.48867483938732],
                                    [41.186064903026065, -8.48869316471361],
                                ],
                                [
                                    [41.186069814117374, -8.488667194930285],
                                    [41.18608154206418, -8.488648869603995],
                                    [41.18608835891966, -8.488656514061033],
                                    [41.18607663097286, -8.48867483938732],
                                ],
                                [
                                    [41.18608445222468, -8.48864432237663],
                                    [41.186096180171475, -8.488625997050342],
                                    [41.186102997026964, -8.488633641507377],
                                    [41.18609126908016, -8.488651966833666],
                                ],
                                [
                                    [41.186096180171475, -8.488625997050342],
                                    [41.18610790811828, -8.488607671724052],
                                    [41.18611472497376, -8.48861531618109],
                                    [41.186102997026964, -8.488633641507377],
                                ],
                                [
                                    [41.18610790811828, -8.488607671724052],
                                    [41.18611963606508, -8.488589346397765],
                                    [41.18612645292057, -8.488596990854802],
                                    [41.18611472497376, -8.48861531618109],
                                ],
                                [
                                    [41.186122546225576, -8.488584799170399],
                                    [41.18613427417238, -8.48856647384411],
                                    [41.186141091027864, -8.488574118301146],
                                    [41.186129363081065, -8.488592443627434],
                                ],
                                [
                                    [41.18613427417238, -8.48856647384411],
                                    [41.18614600211918, -8.488548148517822],
                                    [41.18615281897467, -8.488555792974859],
                                    [41.186141091027864, -8.488574118301146],
                                ],
                                [
                                    [41.18614600211918, -8.488548148517822],
                                    [41.186157730065986, -8.488529823191534],
                                    [41.18616454692147, -8.48853746764857],
                                    [41.18615281897467, -8.488555792974859],
                                ],
                                [
                                    [41.18616064022648, -8.488525275964168],
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18617918502877, -8.488514595094916],
                                    [41.186167457081964, -8.488532920421203],
                                ],
                                [
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18618409612009, -8.488488625311591],
                                    [41.18619091297557, -8.488496269768627],
                                    [41.18617918502877, -8.488514595094916],
                                ],
                                [
                                    [41.18618409612009, -8.488488625311591],
                                    [41.186195824066886, -8.488470299985304],
                                    [41.186202640922374, -8.488477944442339],
                                    [41.18619091297557, -8.488496269768627],
                                ],
                                [
                                    [41.18619873422738, -8.488465752757936],
                                    [41.18621046217419, -8.488447427431648],
                                    [41.18621727902967, -8.488455071888684],
                                    [41.18620555108287, -8.488473397214973],
                                ],
                                [
                                    [41.18621046217419, -8.488447427431648],
                                    [41.186222190120986, -8.48842910210536],
                                    [41.186229006976475, -8.488436746562396],
                                    [41.18621727902967, -8.488455071888684],
                                ],
                                [
                                    [41.186222190120986, -8.48842910210536],
                                    [41.18623391806779, -8.488410776779071],
                                    [41.186240734923274, -8.488418421236108],
                                    [41.186229006976475, -8.488436746562396],
                                ],
                                [
                                    [41.18623682822829, -8.488406229551705],
                                    [41.18624855617509, -8.488387904225418],
                                    [41.186255373030576, -8.488395548682453],
                                    [41.18624364508377, -8.48841387400874],
                                ],
                                [
                                    [41.18624855617509, -8.488387904225418],
                                    [41.18626028412189, -8.488369578899128],
                                    [41.186267100977375, -8.488377223356165],
                                    [41.186255373030576, -8.488395548682453],
                                ],
                                [
                                    [41.18626028412189, -8.488369578899128],
                                    [41.18627201206869, -8.48835125357284],
                                    [41.18627882892418, -8.488358898029876],
                                    [41.186267100977375, -8.488377223356165],
                                ],
                                [
                                    [41.18627492222919, -8.488346706345475],
                                    [41.18628665017599, -8.488328381019185],
                                    [41.186293467031476, -8.488336025476222],
                                    [41.18628173908468, -8.48835435080251],
                                ],
                                [
                                    [41.18628665017599, -8.488328381019185],
                                    [41.18629837812279, -8.488310055692898],
                                    [41.186305194978274, -8.488317700149933],
                                    [41.186293467031476, -8.488336025476222],
                                ],
                                [
                                    [41.18629837812279, -8.488310055692898],
                                    [41.18631010606959, -8.48829173036661],
                                    [41.18631692292508, -8.488299374823645],
                                    [41.186305194978274, -8.488317700149933],
                                ],
                                [
                                    [41.18631301623009, -8.488287183139242],
                                    [41.18632474417689, -8.488268857812955],
                                    [41.186331561032375, -8.48827650226999],
                                    [41.18631983308558, -8.48829482759628],
                                ],
                                [
                                    [41.18605317507926, -8.488711490039897],
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18607171988155, -8.488700809170645],
                                    [41.18605999193475, -8.488719134496932],
                                ],
                                [
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18607663097286, -8.48867483938732],
                                    [41.18608344782835, -8.488682483844357],
                                    [41.18607171988155, -8.488700809170645],
                                ],
                                [
                                    [41.18607663097286, -8.48867483938732],
                                    [41.18608835891966, -8.488656514061033],
                                    [41.18609517577515, -8.488664158518068],
                                    [41.18608344782835, -8.488682483844357],
                                ],
                                [
                                    [41.18609126908016, -8.488651966833666],
                                    [41.186102997026964, -8.488633641507377],
                                    [41.186109813882446, -8.488641285964414],
                                    [41.18609808593565, -8.488659611290702],
                                ],
                                [
                                    [41.186102997026964, -8.488633641507377],
                                    [41.18611472497376, -8.48861531618109],
                                    [41.18612154182925, -8.488622960638125],
                                    [41.186109813882446, -8.488641285964414],
                                ],
                                [
                                    [41.18611472497376, -8.48861531618109],
                                    [41.18612645292057, -8.488596990854802],
                                    [41.18613326977605, -8.488604635311837],
                                    [41.18612154182925, -8.488622960638125],
                                ],
                                [
                                    [41.186129363081065, -8.488592443627434],
                                    [41.186141091027864, -8.488574118301146],
                                    [41.18614790788335, -8.488581762758182],
                                    [41.18613617993655, -8.488600088084471],
                                ],
                                [
                                    [41.186141091027864, -8.488574118301146],
                                    [41.18615281897467, -8.488555792974859],
                                    [41.18615963583015, -8.488563437431894],
                                    [41.18614790788335, -8.488581762758182],
                                ],
                                [
                                    [41.18617918502877, -8.488514595094916],
                                    [41.18619091297557, -8.488496269768627],
                                    [41.18619772983106, -8.488503914225664],
                                    [41.18618600188425, -8.488522239551951],
                                ],
                                [
                                    [41.18619091297557, -8.488496269768627],
                                    [41.186202640922374, -8.488477944442339],
                                    [41.186209457777856, -8.488485588899374],
                                    [41.18619772983106, -8.488503914225664],
                                ],
                                [
                                    [41.18620555108287, -8.488473397214973],
                                    [41.18621727902967, -8.488455071888684],
                                    [41.18622409588516, -8.48846271634572],
                                    [41.18621236793835, -8.488481041672008],
                                ],
                                [
                                    [41.18621727902967, -8.488455071888684],
                                    [41.186229006976475, -8.488436746562396],
                                    [41.18623582383196, -8.488444391019433],
                                    [41.18622409588516, -8.48846271634572],
                                ],
                                [
                                    [41.186229006976475, -8.488436746562396],
                                    [41.186240734923274, -8.488418421236108],
                                    [41.18624755177876, -8.488426065693144],
                                    [41.18623582383196, -8.488444391019433],
                                ],
                                [
                                    [41.18624364508377, -8.48841387400874],
                                    [41.186255373030576, -8.488395548682453],
                                    [41.18626218988606, -8.48840319313949],
                                    [41.18625046193926, -8.488421518465778],
                                ],
                                [
                                    [41.18628173908468, -8.48835435080251],
                                    [41.186293467031476, -8.488336025476222],
                                    [41.186300283886965, -8.488343669933258],
                                    [41.18628855594016, -8.488361995259547],
                                ],
                                [
                                    [41.186293467031476, -8.488336025476222],
                                    [41.186305194978274, -8.488317700149933],
                                    [41.18631201183376, -8.48832534460697],
                                    [41.186300283886965, -8.488343669933258],
                                ],
                                [
                                    [41.186305194978274, -8.488317700149933],
                                    [41.18631692292508, -8.488299374823645],
                                    [41.18632373978056, -8.488307019280683],
                                    [41.18631201183376, -8.48832534460697],
                                ],
                                [
                                    [41.18631983308558, -8.48829482759628],
                                    [41.186331561032375, -8.48827650226999],
                                    [41.186338377887864, -8.488284146727027],
                                    [41.18632664994106, -8.488302472053315],
                                ],
                            ],
                            panels_idx: [
                                [0, 0, [0, 0]],
                                [0, 1, [0, 2]],
                                [0, 2, [0, 0]],
                                [0, 2, [0, 1]],
                                [0, 2, [0, 2]],
                                [0, 3, [0, 0]],
                                [0, 3, [0, 1]],
                                [0, 3, [0, 2]],
                                [0, 4, [0, 0]],
                                [0, 4, [0, 1]],
                                [0, 4, [0, 2]],
                                [0, 5, [0, 0]],
                                [0, 5, [0, 1]],
                                [0, 5, [0, 2]],
                                [0, 6, [0, 0]],
                                [0, 6, [0, 1]],
                                [0, 6, [0, 2]],
                                [0, 7, [0, 0]],
                                [1, 0, [0, 0]],
                                [1, 0, [0, 1]],
                                [1, 0, [0, 2]],
                                [1, 1, [0, 0]],
                                [1, 1, [0, 1]],
                                [1, 1, [0, 2]],
                                [1, 2, [0, 0]],
                                [1, 2, [0, 1]],
                                [1, 2, [0, 2]],
                                [1, 3, [0, 0]],
                                [1, 3, [0, 1]],
                                [1, 3, [0, 2]],
                                [1, 4, [0, 0]],
                                [1, 4, [0, 1]],
                                [1, 4, [0, 2]],
                                [1, 5, [0, 0]],
                                [1, 5, [0, 1]],
                                [1, 5, [0, 2]],
                                [1, 6, [0, 0]],
                                [1, 6, [0, 1]],
                                [1, 6, [0, 2]],
                                [1, 7, [0, 0]],
                                [2, 0, [0, 0]],
                                [2, 0, [0, 1]],
                                [2, 0, [0, 2]],
                                [2, 1, [0, 0]],
                                [2, 1, [0, 1]],
                                [2, 1, [0, 2]],
                                [2, 2, [0, 0]],
                                [2, 2, [0, 1]],
                                [2, 2, [0, 2]],
                                [2, 3, [0, 0]],
                                [2, 3, [0, 1]],
                                [2, 3, [0, 2]],
                                [2, 4, [0, 0]],
                                [2, 4, [0, 1]],
                                [2, 4, [0, 2]],
                                [2, 5, [0, 0]],
                                [2, 5, [0, 1]],
                                [2, 5, [0, 2]],
                                [2, 6, [0, 0]],
                                [2, 6, [0, 1]],
                                [2, 6, [0, 2]],
                                [2, 7, [0, 0]],
                                [3, 0, [0, 0]],
                                [3, 0, [0, 1]],
                                [3, 0, [0, 2]],
                                [3, 1, [0, 0]],
                                [3, 1, [0, 1]],
                                [3, 1, [0, 2]],
                                [3, 2, [0, 0]],
                                [3, 2, [0, 1]],
                                [3, 2, [0, 2]],
                                [3, 3, [0, 0]],
                                [3, 3, [0, 1]],
                                [3, 3, [0, 2]],
                                [3, 4, [0, 0]],
                                [3, 4, [0, 1]],
                                [3, 4, [0, 2]],
                                [3, 5, [0, 0]],
                                [3, 5, [0, 1]],
                                [3, 5, [0, 2]],
                                [3, 6, [0, 0]],
                                [3, 6, [0, 1]],
                                [3, 6, [0, 2]],
                                [3, 7, [0, 0]],
                                [4, 0, [0, 0]],
                                [4, 0, [0, 1]],
                                [4, 0, [0, 2]],
                                [4, 1, [0, 0]],
                                [4, 1, [0, 1]],
                                [4, 1, [0, 2]],
                                [4, 2, [0, 0]],
                                [4, 2, [0, 1]],
                                [4, 2, [0, 2]],
                                [4, 3, [0, 0]],
                                [4, 3, [0, 1]],
                                [4, 3, [0, 2]],
                                [4, 4, [0, 0]],
                                [4, 4, [0, 1]],
                                [4, 4, [0, 2]],
                                [4, 5, [0, 0]],
                                [4, 5, [0, 1]],
                                [4, 5, [0, 2]],
                                [4, 6, [0, 0]],
                                [4, 6, [0, 1]],
                                [4, 6, [0, 2]],
                                [4, 7, [0, 0]],
                                [5, 0, [0, 0]],
                                [5, 0, [0, 1]],
                                [5, 0, [0, 2]],
                                [5, 1, [0, 0]],
                                [5, 1, [0, 1]],
                                [5, 1, [0, 2]],
                                [5, 2, [0, 0]],
                                [5, 2, [0, 1]],
                                [5, 2, [0, 2]],
                                [5, 3, [0, 0]],
                                [5, 3, [0, 1]],
                                [5, 3, [0, 2]],
                                [5, 4, [0, 0]],
                                [5, 4, [0, 1]],
                                [5, 4, [0, 2]],
                                [5, 5, [0, 0]],
                                [5, 5, [0, 1]],
                                [5, 5, [0, 2]],
                                [5, 6, [0, 0]],
                                [5, 6, [0, 1]],
                                [5, 6, [0, 2]],
                                [5, 7, [0, 0]],
                                [6, 0, [0, 0]],
                                [6, 0, [0, 1]],
                                [6, 0, [0, 2]],
                                [6, 1, [0, 0]],
                                [6, 1, [0, 1]],
                                [6, 1, [0, 2]],
                                [6, 2, [0, 0]],
                                [6, 2, [0, 1]],
                                [6, 3, [0, 1]],
                                [6, 3, [0, 2]],
                                [6, 4, [0, 0]],
                                [6, 4, [0, 1]],
                                [6, 4, [0, 2]],
                                [6, 5, [0, 0]],
                                [6, 6, [0, 0]],
                                [6, 6, [0, 1]],
                                [6, 6, [0, 2]],
                                [6, 7, [0, 0]],
                            ],
                            perc_shadow: [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0,
                            ],
                            panels_number: 146,
                        },
                    ],
                    coordinates: [
                        {
                            lat: 41.18600528112348,
                            lng: -8.488665535099807,
                        },
                        {
                            lat: 41.18606583790162,
                            lng: -8.488733931429687,
                        },
                        {
                            lat: 41.18635247331818,
                            lng: -8.488283320315185,
                        },
                        {
                            lat: 41.18629191654003,
                            lng: -8.488216265089813,
                        },
                        {
                            lat: 41.18600528112348,
                            lng: -8.488665535099807,
                        },
                    ],
                    orientation: -40.27,
                    region_gd_id: 125,
                    panels_number: 146,
                    perimeter_area: 116.39458181558223,
                    coordinates_avg: {
                        lat: 41.186144158001355,
                        long: -8.48851291740686,
                        region_gd_id: 125,
                    },
                    autoSplitBuilding: 1,
                    panel_representation: {
                        dayRank: '2024-12-22T00:00:00.000Z',
                        setback: 0.5,
                        rowSpace: 0,
                        alignment: 'left',
                        panels_nr: 3,
                        panels_up: 1,
                        roofSlope: 5,
                        row_space: 0,
                        hourWindow: 4,
                        panels_wide: 3,
                        panelFraming: 1,
                        space_btw_sets: 0.5,
                        reference_point: [41.186012273946346, -8.48866562329768],
                        isDefaultRowRatio: false,
                        alignment_vertical: 'bottom',
                        alignment_horizontal: 'left',
                        is_row_spacing_insert: true,
                        coordinates_conversion_factor: {
                            one_meter_in_lat: 0.00000900434376143347,
                            one_meter_in_lon: 0.00001191923025934094,
                        },
                    },
                    mounting_structure_id: 7,
                    panels_number_excluded: 0,
                    panels_number_possible: 146,
                    mounting_structure_type_id: 18,
                },
                {
                    id: 2,
                    lat: 41.18626910682026,
                    area: 435.14275740630234,
                    long: -8.488506211884323,
                    edges: [
                        {
                            aspect: 49.772882975519515,
                            points: [
                                {
                                    lat: 41.1861207,
                                    lng: -8.4888041,
                                },
                                {
                                    lat: 41.186113899999995,
                                    lng: -8.4887965,
                                },
                            ],
                        },
                        {
                            aspect: -38.67722912542285,
                            points: [
                                {
                                    lat: 41.186113899999995,
                                    lng: -8.4887965,
                                },
                                {
                                    lat: 41.1860935,
                                    lng: -8.4887737,
                                },
                            ],
                        },
                        {
                            aspect: -48.73453529487949,
                            points: [
                                {
                                    lat: 41.1860935,
                                    lng: -8.4887737,
                                },
                                {
                                    lat: 41.1860664,
                                    lng: -8.4887433,
                                },
                            ],
                        },
                        {
                            aspect: -130.2409363116384,
                            points: [
                                {
                                    lat: 41.1860664,
                                    lng: -8.4887433,
                                },
                                {
                                    lat: 41.186174799999996,
                                    lng: -8.4885555,
                                },
                            ],
                        },
                        {
                            aspect: 139.78307560281286,
                            points: [
                                {
                                    lat: 41.186174799999996,
                                    lng: -8.4885555,
                                },
                                {
                                    lat: 41.186270799999996,
                                    lng: -8.4884053,
                                },
                            ],
                        },
                    ],
                    slope: 5,
                    aspect: 139.7287929522657,
                    selected: false,
                    panel_rows: [
                        {
                            positions: [
                                [
                                    [41.18630785861647, -8.488501182006347],
                                    [41.186296130669675, -8.488519507332635],
                                    [41.186289313814186, -8.4885118628756],
                                    [41.18630104176099, -8.48849353754931],
                                ],
                                [
                                    [41.186296130669675, -8.488519507332635],
                                    [41.18628440272287, -8.488537832658922],
                                    [41.18627758586739, -8.488530188201887],
                                    [41.186289313814186, -8.4885118628756],
                                ],
                                [
                                    [41.18628440272287, -8.488537832658922],
                                    [41.18627267477607, -8.488556157985212],
                                    [41.18626585792058, -8.488548513528174],
                                    [41.18627758586739, -8.488530188201887],
                                ],
                                [
                                    [41.186269764615574, -8.488560705212578],
                                    [41.18625803666877, -8.488579030538865],
                                    [41.186251219813286, -8.48857138608183],
                                    [41.186262947760085, -8.488553060755542],
                                ],
                                [
                                    [41.18625803666877, -8.488579030538865],
                                    [41.18624630872197, -8.488597355865155],
                                    [41.18623949186648, -8.488589711408117],
                                    [41.186251219813286, -8.48857138608183],
                                ],
                                [
                                    [41.18624630872197, -8.488597355865155],
                                    [41.186234580775164, -8.488615681191442],
                                    [41.18622776391968, -8.488608036734407],
                                    [41.18623949186648, -8.488589711408117],
                                ],
                                [
                                    [41.18623167061467, -8.488620228418808],
                                    [41.18621994266787, -8.488638553745098],
                                    [41.18621312581238, -8.48863090928806],
                                    [41.186224853759185, -8.488612583961773],
                                ],
                                [
                                    [41.18621994266787, -8.488638553745098],
                                    [41.18620821472106, -8.488656879071385],
                                    [41.18620139786558, -8.48864923461435],
                                    [41.18621312581238, -8.48863090928806],
                                ],
                                [
                                    [41.18620821472106, -8.488656879071385],
                                    [41.186196486774264, -8.488675204397673],
                                    [41.186189669918775, -8.488667559940637],
                                    [41.18620139786558, -8.48864923461435],
                                ],
                                [
                                    [41.18619357661377, -8.48867975162504],
                                    [41.18618184866696, -8.488698076951328],
                                    [41.18617503181148, -8.488690432494293],
                                    [41.18618675975828, -8.488672107168004],
                                ],
                                [
                                    [41.18617012072016, -8.488716402277616],
                                    [41.18615839277336, -8.488734727603905],
                                    [41.186151575917876, -8.488727083146868],
                                    [41.186163303864674, -8.48870875782058],
                                ],
                                [
                                    [41.18615548261286, -8.488739274831271],
                                    [41.18614375466606, -8.488757600157559],
                                    [41.18613693781057, -8.488749955700523],
                                    [41.18614866575738, -8.488731630374236],
                                ],
                                [
                                    [41.18614375466606, -8.488757600157559],
                                    [41.18613202671926, -8.488775925483848],
                                    [41.186125209863775, -8.488768281026811],
                                    [41.18613693781057, -8.488749955700523],
                                ],
                                [
                                    [41.18613202671926, -8.488775925483848],
                                    [41.18612029877246, -8.488794250810136],
                                    [41.18611348191697, -8.4887866063531],
                                    [41.186125209863775, -8.488768281026811],
                                ],
                                [
                                    [41.18639186787009, -8.488351618583193],
                                    [41.18638013992329, -8.48836994390948],
                                    [41.186373323067805, -8.488362299452445],
                                    [41.186385051014604, -8.488343974126156],
                                ],
                                [
                                    [41.18637722976279, -8.488374491136849],
                                    [41.18636550181599, -8.488392816463136],
                                    [41.1863586849605, -8.4883851720061],
                                    [41.18637041290731, -8.488366846679812],
                                ],
                                [
                                    [41.18636550181599, -8.488392816463136],
                                    [41.186353773869186, -8.488411141789424],
                                    [41.186346957013704, -8.488403497332389],
                                    [41.1863586849605, -8.4883851720061],
                                ],
                                [
                                    [41.186353773869186, -8.488411141789424],
                                    [41.18634204592239, -8.488429467115713],
                                    [41.1863352290669, -8.488421822658676],
                                    [41.186346957013704, -8.488403497332389],
                                ],
                                [
                                    [41.18633913576189, -8.48843401434308],
                                    [41.186327407815085, -8.488452339669367],
                                    [41.1863205909596, -8.488444695212332],
                                    [41.1863323189064, -8.488426369886042],
                                ],
                                [
                                    [41.186327407815085, -8.488452339669367],
                                    [41.18631567986829, -8.488470664995656],
                                    [41.1863088630128, -8.48846302053862],
                                    [41.1863205909596, -8.488444695212332],
                                ],
                                [
                                    [41.18631567986829, -8.488470664995656],
                                    [41.18630395192149, -8.488488990321944],
                                    [41.186297135066, -8.488481345864907],
                                    [41.1863088630128, -8.48846302053862],
                                ],
                                [
                                    [41.18630104176099, -8.48849353754931],
                                    [41.186289313814186, -8.4885118628756],
                                    [41.186282496958704, -8.488504218418562],
                                    [41.1862942249055, -8.488485893092275],
                                ],
                                [
                                    [41.186289313814186, -8.4885118628756],
                                    [41.18627758586739, -8.488530188201887],
                                    [41.1862707690119, -8.48852254374485],
                                    [41.186282496958704, -8.488504218418562],
                                ],
                                [
                                    [41.18627758586739, -8.488530188201887],
                                    [41.18626585792058, -8.488548513528174],
                                    [41.1862590410651, -8.488540869071139],
                                    [41.1862707690119, -8.48852254374485],
                                ],
                                [
                                    [41.186262947760085, -8.488553060755542],
                                    [41.186251219813286, -8.48857138608183],
                                    [41.1862444029578, -8.488563741624793],
                                    [41.1862561309046, -8.488545416298505],
                                ],
                                [
                                    [41.186251219813286, -8.48857138608183],
                                    [41.18623949186648, -8.488589711408117],
                                    [41.186232675011, -8.488582066951082],
                                    [41.1862444029578, -8.488563741624793],
                                ],
                                [
                                    [41.18623949186648, -8.488589711408117],
                                    [41.18622776391968, -8.488608036734407],
                                    [41.18622094706419, -8.48860039227737],
                                    [41.186232675011, -8.488582066951082],
                                ],
                                [
                                    [41.186224853759185, -8.488612583961773],
                                    [41.18621312581238, -8.48863090928806],
                                    [41.1862063089569, -8.488623264831025],
                                    [41.186218036903696, -8.488604939504736],
                                ],
                                [
                                    [41.18621312581238, -8.48863090928806],
                                    [41.18620139786558, -8.48864923461435],
                                    [41.18619458101009, -8.488641590157313],
                                    [41.1862063089569, -8.488623264831025],
                                ],
                                [
                                    [41.18620139786558, -8.48864923461435],
                                    [41.186189669918775, -8.488667559940637],
                                    [41.18618285306329, -8.4886599154836],
                                    [41.18619458101009, -8.488641590157313],
                                ],
                                [
                                    [41.18618675975828, -8.488672107168004],
                                    [41.18617503181148, -8.488690432494293],
                                    [41.18616821495599, -8.488682788037256],
                                    [41.1861799429028, -8.488664462710968],
                                ],
                                [
                                    [41.186163303864674, -8.48870875782058],
                                    [41.186151575917876, -8.488727083146868],
                                    [41.18614475906239, -8.488719438689833],
                                    [41.18615648700919, -8.488701113363543],
                                ],
                                [
                                    [41.18614866575738, -8.488731630374236],
                                    [41.18613693781057, -8.488749955700523],
                                    [41.18613012095509, -8.488742311243486],
                                    [41.18614184890189, -8.488723985917199],
                                ],
                                [
                                    [41.18613693781057, -8.488749955700523],
                                    [41.186125209863775, -8.488768281026811],
                                    [41.186118393008286, -8.488760636569776],
                                    [41.18613012095509, -8.488742311243486],
                                ],
                                [
                                    [41.186125209863775, -8.488768281026811],
                                    [41.18611348191697, -8.4887866063531],
                                    [41.18610666506149, -8.488778961896063],
                                    [41.186118393008286, -8.488760636569776],
                                ],
                                [
                                    [41.186385051014604, -8.488343974126156],
                                    [41.186373323067805, -8.488362299452445],
                                    [41.186366506212316, -8.488354654995408],
                                    [41.18637823415912, -8.48833632966912],
                                ],
                                [
                                    [41.18637041290731, -8.488366846679812],
                                    [41.1863586849605, -8.4883851720061],
                                    [41.18635186810502, -8.488377527549064],
                                    [41.18636359605182, -8.488359202222776],
                                ],
                                [
                                    [41.1863586849605, -8.4883851720061],
                                    [41.186346957013704, -8.488403497332389],
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18635186810502, -8.488377527549064],
                                ],
                                [
                                    [41.186346957013704, -8.488403497332389],
                                    [41.1863352290669, -8.488421822658676],
                                    [41.18632841221142, -8.48841417820164],
                                    [41.186340140158215, -8.488395852875351],
                                ],
                                [
                                    [41.1863323189064, -8.488426369886042],
                                    [41.1863205909596, -8.488444695212332],
                                    [41.186313774104114, -8.488437050755294],
                                    [41.18632550205092, -8.488418725429007],
                                ],
                                [
                                    [41.1863205909596, -8.488444695212332],
                                    [41.1863088630128, -8.48846302053862],
                                    [41.186302046157316, -8.488455376081584],
                                    [41.186313774104114, -8.488437050755294],
                                ],
                                [
                                    [41.1863088630128, -8.48846302053862],
                                    [41.186297135066, -8.488481345864907],
                                    [41.18629031821052, -8.488473701407871],
                                    [41.186302046157316, -8.488455376081584],
                                ],
                                [
                                    [41.1862942249055, -8.488485893092275],
                                    [41.186282496958704, -8.488504218418562],
                                    [41.186275680103215, -8.488496573961527],
                                    [41.18628740805001, -8.488478248635237],
                                ],
                                [
                                    [41.186282496958704, -8.488504218418562],
                                    [41.1862707690119, -8.48852254374485],
                                    [41.186263952156416, -8.488514899287814],
                                    [41.186275680103215, -8.488496573961527],
                                ],
                                [
                                    [41.1862707690119, -8.48852254374485],
                                    [41.1862590410651, -8.488540869071139],
                                    [41.18625222420961, -8.488533224614102],
                                    [41.186263952156416, -8.488514899287814],
                                ],
                                [
                                    [41.1862561309046, -8.488545416298505],
                                    [41.1862444029578, -8.488563741624793],
                                    [41.186237586102315, -8.488556097167757],
                                    [41.186249314049114, -8.48853777184147],
                                ],
                                [
                                    [41.1862444029578, -8.488563741624793],
                                    [41.186232675011, -8.488582066951082],
                                    [41.18622585815551, -8.488574422494045],
                                    [41.186237586102315, -8.488556097167757],
                                ],
                                [
                                    [41.186232675011, -8.488582066951082],
                                    [41.18622094706419, -8.48860039227737],
                                    [41.18621413020871, -8.488592747820334],
                                    [41.18622585815551, -8.488574422494045],
                                ],
                                [
                                    [41.186218036903696, -8.488604939504736],
                                    [41.1862063089569, -8.488623264831025],
                                    [41.18619949210141, -8.488615620373988],
                                    [41.186211220048214, -8.4885972950477],
                                ],
                                [
                                    [41.1862063089569, -8.488623264831025],
                                    [41.18619458101009, -8.488641590157313],
                                    [41.18618776415461, -8.488633945700277],
                                    [41.18619949210141, -8.488615620373988],
                                ],
                                [
                                    [41.18619458101009, -8.488641590157313],
                                    [41.18618285306329, -8.4886599154836],
                                    [41.186176036207804, -8.488652271026565],
                                    [41.18618776415461, -8.488633945700277],
                                ],
                                [
                                    [41.1861799429028, -8.488664462710968],
                                    [41.18616821495599, -8.488682788037256],
                                    [41.18616139810051, -8.48867514358022],
                                    [41.18617312604731, -8.488656818253931],
                                ],
                                [
                                    [41.18615648700919, -8.488701113363543],
                                    [41.18614475906239, -8.488719438689833],
                                    [41.186137942206905, -8.488711794232795],
                                    [41.1861496701537, -8.488693468906508],
                                ],
                                [
                                    [41.18614184890189, -8.488723985917199],
                                    [41.18613012095509, -8.488742311243486],
                                    [41.1861233040996, -8.488734666786451],
                                    [41.18613503204641, -8.488716341460163],
                                ],
                                [
                                    [41.18613012095509, -8.488742311243486],
                                    [41.186118393008286, -8.488760636569776],
                                    [41.186111576152804, -8.488752992112738],
                                    [41.1861233040996, -8.488734666786451],
                                ],
                                [
                                    [41.186118393008286, -8.488760636569776],
                                    [41.18610666506149, -8.488778961896063],
                                    [41.186099848206, -8.488771317439028],
                                    [41.186111576152804, -8.488752992112738],
                                ],
                                [
                                    [41.18637823415912, -8.48833632966912],
                                    [41.186366506212316, -8.488354654995408],
                                    [41.186359689356834, -8.488347010538373],
                                    [41.18637141730363, -8.488328685212084],
                                ],
                                [
                                    [41.18636359605182, -8.488359202222776],
                                    [41.18635186810502, -8.488377527549064],
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18635677919634, -8.48835155776574],
                                ],
                                [
                                    [41.18635186810502, -8.488377527549064],
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18633332330273, -8.488388208418316],
                                    [41.18634505124953, -8.488369883092027],
                                ],
                                [
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18632841221142, -8.48841417820164],
                                    [41.18632159535593, -8.488406533744604],
                                    [41.18633332330273, -8.488388208418316],
                                ],
                                [
                                    [41.18632550205092, -8.488418725429007],
                                    [41.186313774104114, -8.488437050755294],
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18631868519543, -8.48841108097197],
                                ],
                                [
                                    [41.186313774104114, -8.488437050755294],
                                    [41.186302046157316, -8.488455376081584],
                                    [41.18629522930183, -8.488447731624547],
                                    [41.18630695724863, -8.488429406298259],
                                ],
                                [
                                    [41.186302046157316, -8.488455376081584],
                                    [41.18629031821052, -8.488473701407871],
                                    [41.18628350135503, -8.488466056950834],
                                    [41.18629522930183, -8.488447731624547],
                                ],
                                [
                                    [41.18628740805001, -8.488478248635237],
                                    [41.186275680103215, -8.488496573961527],
                                    [41.18626886324773, -8.48848892950449],
                                    [41.18628059119453, -8.488470604178202],
                                ],
                                [
                                    [41.186275680103215, -8.488496573961527],
                                    [41.186263952156416, -8.488514899287814],
                                    [41.18625713530093, -8.488507254830777],
                                    [41.18626886324773, -8.48848892950449],
                                ],
                                [
                                    [41.186263952156416, -8.488514899287814],
                                    [41.18625222420961, -8.488533224614102],
                                    [41.18624540735413, -8.488525580157066],
                                    [41.18625713530093, -8.488507254830777],
                                ],
                                [
                                    [41.186249314049114, -8.48853777184147],
                                    [41.186237586102315, -8.488556097167757],
                                    [41.186230769246826, -8.48854845271072],
                                    [41.18624249719363, -8.488530127384433],
                                ],
                                [
                                    [41.186237586102315, -8.488556097167757],
                                    [41.18622585815551, -8.488574422494045],
                                    [41.18621904130003, -8.48856677803701],
                                    [41.186230769246826, -8.48854845271072],
                                ],
                                [
                                    [41.18622585815551, -8.488574422494045],
                                    [41.18621413020871, -8.488592747820334],
                                    [41.18620731335322, -8.488585103363297],
                                    [41.18621904130003, -8.48856677803701],
                                ],
                                [
                                    [41.186211220048214, -8.4885972950477],
                                    [41.18619949210141, -8.488615620373988],
                                    [41.18619267524593, -8.488607975916953],
                                    [41.186204403192725, -8.488589650590663],
                                ],
                                [
                                    [41.18619949210141, -8.488615620373988],
                                    [41.18618776415461, -8.488633945700277],
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18619267524593, -8.488607975916953],
                                ],
                                [
                                    [41.18618776415461, -8.488633945700277],
                                    [41.186176036207804, -8.488652271026565],
                                    [41.18616921935232, -8.488644626569528],
                                    [41.18618094729912, -8.48862630124324],
                                ],
                                [
                                    [41.18617312604731, -8.488656818253931],
                                    [41.18616139810051, -8.48867514358022],
                                    [41.18615458124502, -8.488667499123183],
                                    [41.186166309191826, -8.488649173796896],
                                ],
                                [
                                    [41.1861496701537, -8.488693468906508],
                                    [41.186137942206905, -8.488711794232795],
                                    [41.186131125351416, -8.48870414977576],
                                    [41.18614285329822, -8.48868582444947],
                                ],
                                [
                                    [41.18613503204641, -8.488716341460163],
                                    [41.1861233040996, -8.488734666786451],
                                    [41.18611648724412, -8.488727022329414],
                                    [41.18612821519092, -8.488708697003126],
                                ],
                                [
                                    [41.1861233040996, -8.488734666786451],
                                    [41.186111576152804, -8.488752992112738],
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18611648724412, -8.488727022329414],
                                ],
                                [
                                    [41.186111576152804, -8.488752992112738],
                                    [41.186099848206, -8.488771317439028],
                                    [41.18609303135052, -8.48876367298199],
                                    [41.186104759297315, -8.488745347655703],
                                ],
                                [
                                    [41.18637141730363, -8.488328685212084],
                                    [41.186359689356834, -8.488347010538373],
                                    [41.186352872501345, -8.488339366081336],
                                    [41.18636460044815, -8.488321040755048],
                                ],
                                [
                                    [41.18635677919634, -8.48835155776574],
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18633823439405, -8.488362238634991],
                                    [41.18634996234085, -8.488343913308704],
                                ],
                                [
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18633332330273, -8.488388208418316],
                                    [41.186326506447244, -8.488380563961279],
                                    [41.18633823439405, -8.488362238634991],
                                ],
                                [
                                    [41.18633332330273, -8.488388208418316],
                                    [41.18632159535593, -8.488406533744604],
                                    [41.186314778500446, -8.488398889287568],
                                    [41.186326506447244, -8.488380563961279],
                                ],
                                [
                                    [41.18631868519543, -8.48841108097197],
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18630014039314, -8.488421761841222],
                                    [41.18631186833995, -8.488403436514934],
                                ],
                                [
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18629522930183, -8.488447731624547],
                                    [41.186288412446345, -8.488440087167511],
                                    [41.18630014039314, -8.488421761841222],
                                ],
                                [
                                    [41.18629522930183, -8.488447731624547],
                                    [41.18628350135503, -8.488466056950834],
                                    [41.18627668449954, -8.488458412493799],
                                    [41.186288412446345, -8.488440087167511],
                                ],
                                [
                                    [41.18628059119453, -8.488470604178202],
                                    [41.18626886324773, -8.48848892950449],
                                    [41.186262046392244, -8.488481285047454],
                                    [41.18627377433904, -8.488462959721165],
                                ],
                                [
                                    [41.18626886324773, -8.48848892950449],
                                    [41.18625713530093, -8.488507254830777],
                                    [41.186250318445445, -8.488499610373742],
                                    [41.186262046392244, -8.488481285047454],
                                ],
                                [
                                    [41.18625713530093, -8.488507254830777],
                                    [41.18624540735413, -8.488525580157066],
                                    [41.18623859049864, -8.48851793570003],
                                    [41.186250318445445, -8.488499610373742],
                                ],
                                [
                                    [41.18624249719363, -8.488530127384433],
                                    [41.186230769246826, -8.48854845271072],
                                    [41.186223952391344, -8.488540808253685],
                                    [41.18623568033814, -8.488522482927397],
                                ],
                                [
                                    [41.186230769246826, -8.48854845271072],
                                    [41.18621904130003, -8.48856677803701],
                                    [41.18621222444454, -8.488559133579972],
                                    [41.186223952391344, -8.488540808253685],
                                ],
                                [
                                    [41.18621904130003, -8.48856677803701],
                                    [41.18620731335322, -8.488585103363297],
                                    [41.18620049649774, -8.488577458906262],
                                    [41.18621222444454, -8.488559133579972],
                                ],
                                [
                                    [41.186204403192725, -8.488589650590663],
                                    [41.18619267524593, -8.488607975916953],
                                    [41.18618585839044, -8.488600331459915],
                                    [41.186197586337244, -8.488582006133628],
                                ],
                                [
                                    [41.18619267524593, -8.488607975916953],
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18617413044364, -8.488618656786205],
                                    [41.18618585839044, -8.488600331459915],
                                ],
                                [
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18616921935232, -8.488644626569528],
                                    [41.186162402496834, -8.488636982112492],
                                    [41.18617413044364, -8.488618656786205],
                                ],
                                [
                                    [41.186166309191826, -8.488649173796896],
                                    [41.18615458124502, -8.488667499123183],
                                    [41.18614776438954, -8.488659854666148],
                                    [41.18615949233634, -8.488641529339858],
                                ],
                                [
                                    [41.18614285329822, -8.48868582444947],
                                    [41.186131125351416, -8.48870414977576],
                                    [41.186124308495934, -8.488696505318723],
                                    [41.18613603644273, -8.488678179992435],
                                ],
                                [
                                    [41.18612821519092, -8.488708697003126],
                                    [41.18611648724412, -8.488727022329414],
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18612139833544, -8.48870105254609],
                                ],
                                [
                                    [41.18611648724412, -8.488727022329414],
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18609794244183, -8.488737703198666],
                                    [41.18610967038863, -8.488719377872378],
                                ],
                                [
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18609303135052, -8.48876367298199],
                                    [41.18608621449503, -8.488756028524955],
                                    [41.18609794244183, -8.488737703198666],
                                ],
                                [
                                    [41.18636460044815, -8.488321040755048],
                                    [41.186352872501345, -8.488339366081336],
                                    [41.18634605564586, -8.4883317216243],
                                    [41.18635778359266, -8.488313396298011],
                                ],
                                [
                                    [41.18634996234085, -8.488343913308704],
                                    [41.18633823439405, -8.488362238634991],
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18634314548537, -8.488336268851667],
                                ],
                                [
                                    [41.18633823439405, -8.488362238634991],
                                    [41.186326506447244, -8.488380563961279],
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18633141753856, -8.488354594177954],
                                ],
                                [
                                    [41.186326506447244, -8.488380563961279],
                                    [41.186314778500446, -8.488398889287568],
                                    [41.18630796164496, -8.488391244830531],
                                    [41.18631968959176, -8.488372919504243],
                                ],
                                [
                                    [41.18631186833995, -8.488403436514934],
                                    [41.18630014039314, -8.488421761841222],
                                    [41.18629332353766, -8.488414117384186],
                                    [41.18630505148446, -8.488395792057897],
                                ],
                                [
                                    [41.18630014039314, -8.488421761841222],
                                    [41.186288412446345, -8.488440087167511],
                                    [41.186281595590856, -8.488432442710474],
                                    [41.18629332353766, -8.488414117384186],
                                ],
                                [
                                    [41.186288412446345, -8.488440087167511],
                                    [41.18627668449954, -8.488458412493799],
                                    [41.18626986764406, -8.488450768036762],
                                    [41.186281595590856, -8.488432442710474],
                                ],
                                [
                                    [41.186262046392244, -8.488481285047454],
                                    [41.186250318445445, -8.488499610373742],
                                    [41.186243501589956, -8.488491965916705],
                                    [41.186255229536755, -8.488473640590417],
                                ],
                                [
                                    [41.186250318445445, -8.488499610373742],
                                    [41.18623859049864, -8.48851793570003],
                                    [41.18623177364316, -8.488510291242994],
                                    [41.186243501589956, -8.488491965916705],
                                ],
                                [
                                    [41.18623568033814, -8.488522482927397],
                                    [41.186223952391344, -8.488540808253685],
                                    [41.186217135535856, -8.488533163796648],
                                    [41.18622886348266, -8.48851483847036],
                                ],
                                [
                                    [41.186223952391344, -8.488540808253685],
                                    [41.18621222444454, -8.488559133579972],
                                    [41.18620540758906, -8.488551489122937],
                                    [41.186217135535856, -8.488533163796648],
                                ],
                                [
                                    [41.18621222444454, -8.488559133579972],
                                    [41.18620049649774, -8.488577458906262],
                                    [41.18619367964225, -8.488569814449225],
                                    [41.18620540758906, -8.488551489122937],
                                ],
                                [
                                    [41.186197586337244, -8.488582006133628],
                                    [41.18618585839044, -8.488600331459915],
                                    [41.186179041534956, -8.48859268700288],
                                    [41.186190769481755, -8.48857436167659],
                                ],
                                [
                                    [41.18618585839044, -8.488600331459915],
                                    [41.18617413044364, -8.488618656786205],
                                    [41.18616731358815, -8.488611012329168],
                                    [41.186179041534956, -8.48859268700288],
                                ],
                                [
                                    [41.18613603644273, -8.488678179992435],
                                    [41.186124308495934, -8.488696505318723],
                                    [41.186117491640445, -8.488688860861688],
                                    [41.18612921958725, -8.488670535535398],
                                ],
                                [
                                    [41.18612139833544, -8.48870105254609],
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18610285353315, -8.488711733415341],
                                    [41.18611458147995, -8.488693408089054],
                                ],
                                [
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18609794244183, -8.488737703198666],
                                    [41.186091125586344, -8.48873005874163],
                                    [41.18610285353315, -8.488711733415341],
                                ],
                                [
                                    [41.18609794244183, -8.488737703198666],
                                    [41.18608621449503, -8.488756028524955],
                                    [41.186079397639546, -8.488748384067918],
                                    [41.186091125586344, -8.48873005874163],
                                ],
                                [
                                    [41.18635778359266, -8.488313396298011],
                                    [41.18634605564586, -8.4883317216243],
                                    [41.186339238790374, -8.488324077167263],
                                    [41.18635096673718, -8.488305751840976],
                                ],
                                [
                                    [41.18634314548537, -8.488336268851667],
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18632460068308, -8.488346949720919],
                                    [41.18633632862988, -8.488328624394631],
                                ],
                                [
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18631287273627, -8.488365275047206],
                                    [41.18632460068308, -8.488346949720919],
                                ],
                                [
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18630796164496, -8.488391244830531],
                                    [41.186301144789475, -8.488383600373496],
                                    [41.18631287273627, -8.488365275047206],
                                ],
                                [
                                    [41.18630505148446, -8.488395792057897],
                                    [41.18629332353766, -8.488414117384186],
                                    [41.18628650668217, -8.48840647292715],
                                    [41.18629823462898, -8.488388147600862],
                                ],
                                [
                                    [41.18629332353766, -8.488414117384186],
                                    [41.186281595590856, -8.488432442710474],
                                    [41.186274778735374, -8.488424798253439],
                                    [41.18628650668217, -8.48840647292715],
                                ],
                                [
                                    [41.186281595590856, -8.488432442710474],
                                    [41.18626986764406, -8.488450768036762],
                                    [41.18626305078857, -8.488443123579726],
                                    [41.186274778735374, -8.488424798253439],
                                ],
                                [
                                    [41.186255229536755, -8.488473640590417],
                                    [41.186243501589956, -8.488491965916705],
                                    [41.186236684734475, -8.48848432145967],
                                    [41.18624841268127, -8.488465996133382],
                                ],
                                [
                                    [41.186243501589956, -8.488491965916705],
                                    [41.18623177364316, -8.488510291242994],
                                    [41.18622495678767, -8.488502646785957],
                                    [41.186236684734475, -8.48848432145967],
                                ],
                                [
                                    [41.18622886348266, -8.48851483847036],
                                    [41.186217135535856, -8.488533163796648],
                                    [41.186210318680374, -8.488525519339612],
                                    [41.18622204662717, -8.488507194013325],
                                ],
                                [
                                    [41.186217135535856, -8.488533163796648],
                                    [41.18620540758906, -8.488551489122937],
                                    [41.18619859073357, -8.4885438446659],
                                    [41.186210318680374, -8.488525519339612],
                                ],
                                [
                                    [41.18620540758906, -8.488551489122937],
                                    [41.18619367964225, -8.488569814449225],
                                    [41.18618686278677, -8.48856216999219],
                                    [41.18619859073357, -8.4885438446659],
                                ],
                                [
                                    [41.186190769481755, -8.48857436167659],
                                    [41.186179041534956, -8.48859268700288],
                                    [41.18617222467947, -8.488585042545843],
                                    [41.18618395262627, -8.488566717219555],
                                ],
                                [
                                    [41.186179041534956, -8.48859268700288],
                                    [41.18616731358815, -8.488611012329168],
                                    [41.18616049673267, -8.488603367872132],
                                    [41.18617222467947, -8.488585042545843],
                                ],
                                [
                                    [41.18612921958725, -8.488670535535398],
                                    [41.186117491640445, -8.488688860861688],
                                    [41.18611067478496, -8.48868121640465],
                                    [41.18612240273176, -8.488662891078363],
                                ],
                                [
                                    [41.18611458147995, -8.488693408089054],
                                    [41.18610285353315, -8.488711733415341],
                                    [41.18609603667766, -8.488704088958306],
                                    [41.18610776462447, -8.488685763632018],
                                ],
                                [
                                    [41.18610285353315, -8.488711733415341],
                                    [41.186091125586344, -8.48873005874163],
                                    [41.18608430873086, -8.488722414284593],
                                    [41.18609603667766, -8.488704088958306],
                                ],
                                [
                                    [41.186091125586344, -8.48873005874163],
                                    [41.186079397639546, -8.488748384067918],
                                    [41.18607258078406, -8.488740739610883],
                                    [41.18608430873086, -8.488722414284593],
                                ],
                            ],
                            panels_idx: [
                                [0, -5, [0, 0]],
                                [0, -5, [0, 1]],
                                [0, -5, [0, 2]],
                                [0, -4, [0, 0]],
                                [0, -4, [0, 1]],
                                [0, -4, [0, 2]],
                                [0, -3, [0, 0]],
                                [0, -3, [0, 1]],
                                [0, -3, [0, 2]],
                                [0, -2, [0, 0]],
                                [0, -2, [0, 2]],
                                [0, -1, [0, 0]],
                                [0, -1, [0, 1]],
                                [0, -1, [0, 2]],
                                [1, -8, [0, 2]],
                                [1, -7, [0, 0]],
                                [1, -7, [0, 1]],
                                [1, -7, [0, 2]],
                                [1, -6, [0, 0]],
                                [1, -6, [0, 1]],
                                [1, -6, [0, 2]],
                                [1, -5, [0, 0]],
                                [1, -5, [0, 1]],
                                [1, -5, [0, 2]],
                                [1, -4, [0, 0]],
                                [1, -4, [0, 1]],
                                [1, -4, [0, 2]],
                                [1, -3, [0, 0]],
                                [1, -3, [0, 1]],
                                [1, -3, [0, 2]],
                                [1, -2, [0, 0]],
                                [1, -2, [0, 2]],
                                [1, -1, [0, 0]],
                                [1, -1, [0, 1]],
                                [1, -1, [0, 2]],
                                [2, -8, [0, 2]],
                                [2, -7, [0, 0]],
                                [2, -7, [0, 1]],
                                [2, -7, [0, 2]],
                                [2, -6, [0, 0]],
                                [2, -6, [0, 1]],
                                [2, -6, [0, 2]],
                                [2, -5, [0, 0]],
                                [2, -5, [0, 1]],
                                [2, -5, [0, 2]],
                                [2, -4, [0, 0]],
                                [2, -4, [0, 1]],
                                [2, -4, [0, 2]],
                                [2, -3, [0, 0]],
                                [2, -3, [0, 1]],
                                [2, -3, [0, 2]],
                                [2, -2, [0, 0]],
                                [2, -2, [0, 2]],
                                [2, -1, [0, 0]],
                                [2, -1, [0, 1]],
                                [2, -1, [0, 2]],
                                [3, -8, [0, 2]],
                                [3, -7, [0, 0]],
                                [3, -7, [0, 1]],
                                [3, -7, [0, 2]],
                                [3, -6, [0, 0]],
                                [3, -6, [0, 1]],
                                [3, -6, [0, 2]],
                                [3, -5, [0, 0]],
                                [3, -5, [0, 1]],
                                [3, -5, [0, 2]],
                                [3, -4, [0, 0]],
                                [3, -4, [0, 1]],
                                [3, -4, [0, 2]],
                                [3, -3, [0, 0]],
                                [3, -3, [0, 1]],
                                [3, -3, [0, 2]],
                                [3, -2, [0, 0]],
                                [3, -2, [0, 2]],
                                [3, -1, [0, 0]],
                                [3, -1, [0, 1]],
                                [3, -1, [0, 2]],
                                [4, -8, [0, 2]],
                                [4, -7, [0, 0]],
                                [4, -7, [0, 1]],
                                [4, -7, [0, 2]],
                                [4, -6, [0, 0]],
                                [4, -6, [0, 1]],
                                [4, -6, [0, 2]],
                                [4, -5, [0, 0]],
                                [4, -5, [0, 1]],
                                [4, -5, [0, 2]],
                                [4, -4, [0, 0]],
                                [4, -4, [0, 1]],
                                [4, -4, [0, 2]],
                                [4, -3, [0, 0]],
                                [4, -3, [0, 1]],
                                [4, -3, [0, 2]],
                                [4, -2, [0, 0]],
                                [4, -2, [0, 2]],
                                [4, -1, [0, 0]],
                                [4, -1, [0, 1]],
                                [4, -1, [0, 2]],
                                [5, -8, [0, 2]],
                                [5, -7, [0, 0]],
                                [5, -7, [0, 1]],
                                [5, -7, [0, 2]],
                                [5, -6, [0, 0]],
                                [5, -6, [0, 1]],
                                [5, -6, [0, 2]],
                                [5, -5, [0, 1]],
                                [5, -5, [0, 2]],
                                [5, -4, [0, 0]],
                                [5, -4, [0, 1]],
                                [5, -4, [0, 2]],
                                [5, -3, [0, 0]],
                                [5, -3, [0, 1]],
                                [5, -2, [0, 2]],
                                [5, -1, [0, 0]],
                                [5, -1, [0, 1]],
                                [5, -1, [0, 2]],
                                [6, -8, [0, 2]],
                                [6, -7, [0, 0]],
                                [6, -7, [0, 1]],
                                [6, -7, [0, 2]],
                                [6, -6, [0, 0]],
                                [6, -6, [0, 1]],
                                [6, -6, [0, 2]],
                                [6, -5, [0, 1]],
                                [6, -5, [0, 2]],
                                [6, -4, [0, 0]],
                                [6, -4, [0, 1]],
                                [6, -4, [0, 2]],
                                [6, -3, [0, 0]],
                                [6, -3, [0, 1]],
                                [6, -2, [0, 2]],
                                [6, -1, [0, 0]],
                                [6, -1, [0, 1]],
                                [6, -1, [0, 2]],
                            ],
                            perc_shadow: [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            ],
                            panels_number: 134,
                        },
                    ],
                    coordinates: [
                        {
                            lat: 41.186407983698146,
                            lng: -8.488353057749572,
                        },
                        {
                            lat: 41.18634742692,
                            lng: -8.4882860025242,
                        },
                        {
                            lat: 41.18606079150344,
                            lng: -8.488735272534194,
                        },
                        {
                            lat: 41.186121348281596,
                            lng: -8.488803668864074,
                        },
                        {
                            lat: 41.186407983698146,
                            lng: -8.488353057749572,
                        },
                    ],
                    orientation: 139.73,
                    region_gd_id: 125,
                    panels_number: 134,
                    perimeter_area: 116.39452684003277,
                    coordinates_avg: {
                        lat: 41.18626910682026,
                        long: -8.488506211884323,
                        region_gd_id: 125,
                    },
                    autoSplitBuilding: 1,
                    panel_representation: {
                        dayRank: '2024-12-22T00:00:00.000Z',
                        setback: 0.5,
                        rowSpace: 0,
                        alignment: 'left',
                        panels_nr: 3,
                        panels_up: 1,
                        roofSlope: 5,
                        row_space: 0,
                        hourWindow: 4,
                        panels_wide: 3,
                        panelFraming: 1,
                        space_btw_sets: 0.5,
                        reference_point: [41.18612029877246, -8.488794250810136],
                        isDefaultRowRatio: false,
                        alignment_vertical: 'bottom',
                        alignment_horizontal: 'left',
                        is_row_spacing_insert: true,
                        coordinates_conversion_factor: {
                            one_meter_in_lat: 0.00000900434376143347,
                            one_meter_in_lon: 0.00001191923025934094,
                        },
                    },
                    mounting_structure_id: 7,
                    panels_number_excluded: 0,
                    panels_number_possible: 134,
                    mounting_structure_type_id: 18,
                },
            ],
            panel_id: 8,
            range_id: 3,
            buildings: [],
            exclusions: [
                {
                    id: 1,
                    area: 2.3236966347219257,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18617316347283, -8.488563568358668],
                        [41.18618324631769, -8.488547754070915],
                        [41.18617407397729, -8.488537428467358],
                        [41.18616399113243, -8.488553242755112],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 2,
                    area: 2.3883073931472767,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186274582160976, -8.488404166121391],
                        [41.18628514290098, -8.488387602286126],
                        [41.18627614211645, -8.488377469808624],
                        [41.186265581376446, -8.488394033643889],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 3,
                    area: 11.675015378699932,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18603549503049, -8.488645928834034],
                        [41.186075928736386, -8.488582511190609],
                        [41.186064436702104, -8.488569574231784],
                        [41.186024002996206, -8.488632991875209],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 4,
                    area: 5.258506222422111,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18603166304474, -8.488638176882642],
                        [41.18606929017439, -8.48857916117191],
                        [41.18606372800404, -8.488572899654843],
                        [41.186026100874386, -8.488631915365575],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 5,
                    area: 3.2157922936748062,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.1862581688646, -8.4884462602208],
                        [41.18624740430836, -8.488463143719617],
                        [41.18625929414733, -8.488476528529995],
                        [41.18627005870356, -8.488459645031178],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 6,
                    area: 3.2750328350843887,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186153259099875, -8.488609235659645],
                        [41.18614248717701, -8.488626130712536],
                        [41.18615458774738, -8.488639752750684],
                        [41.18616535967025, -8.488622857697793],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 7,
                    area: 1.3555382376282392,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18628200384683, -8.488386855640433],
                        [41.1862722522419, -8.488402150391726],
                        [41.18627778472285, -8.488408378500344],
                        [41.18628753632778, -8.48839308374905],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 8,
                    area: 4.929091323309152,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186128021055545, -8.488652061540803],
                        [41.18612427355185, -8.488657939253995],
                        [41.18617662252929, -8.488716870341182],
                        [41.18618037003298, -8.48871099262799],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 9,
                    area: 1.258065297983659,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18618149003819, -8.488546753576067],
                        [41.1861705707031, -8.488563879835331],
                        [41.186175156244, -8.488569041940183],
                        [41.18618607557908, -8.488551915680917],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 10,
                    area: 0.9409541472002925,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18628546455482, -8.488389084310926],
                        [41.186274891939085, -8.488405666763681],
                        [41.18627843411626, -8.488409654317254],
                        [41.186289006732, -8.488393071864499],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 11,
                    area: 0.8428999954032902,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.1862658088651, -8.488458005587107],
                        [41.1862569567156, -8.488471889601671],
                        [41.1862607464734, -8.488476155865465],
                        [41.18626959862289, -8.488462271850901],
                    ],
                    autoSplitBuilding: 1,
                },
            ],
            prod_params: {
                param: 'realTimeV1',
                raddatabase: 'PVGIS-SARAH',
                pvtechchoice: 'crystSi',
                pvgis_ano_tipico: 2014,
                factor_perda_inicial: 0.0834,
                geogrid_factor_correcao: null,
                factor_perda_extra_coplanar: 0.0325,
            },
            total_areas: 870.2858837294776,
            network_sale: true,
            total_panels: 280,
            max_tec_panels: 3012,
            min_tec_panels: 10,
            coordinates_avg: {
                lat: 41.18620663241081,
                long: -8.488509564645591,
                region_gd_id: 125,
            },
            injection_tariff: 0.06,
            limit_network_sale: false,
            inverters_combination: [
                {
                    id: 3,
                    qt: 0,
                    cost: 839.17,
                    brand: 'Huawei',
                    model: 'SUN2000-4KTL-M1',
                    power: 4,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 4,
                    qt: 0,
                    cost: 886.11,
                    brand: 'Huawei',
                    model: 'SUN2000-5KTL-M1',
                    power: 5,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 5,
                    qt: 0,
                    cost: 990.28,
                    brand: 'Huawei',
                    model: 'SUN2000-6KTL-M1',
                    power: 6,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 6,
                    qt: 0,
                    cost: 1174.59,
                    brand: 'Huawei',
                    model: 'SUN2000-8KTL-M1',
                    power: 8,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 7,
                    qt: 0,
                    cost: 1319.43,
                    brand: 'Huawei',
                    model: 'SUN2000-10KTL-M2',
                    power: 10,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 8,
                    qt: 0,
                    cost: 1407.92,
                    brand: 'Huawei',
                    model: 'SUN2000-12KTL-M2',
                    power: 12,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 10,
                    qt: 0,
                    cost: 1623.85,
                    brand: 'Huawei',
                    model: 'SUN2000-15KTL-M2',
                    power: 15,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 11,
                    qt: 0,
                    cost: 1630.98,
                    brand: 'Huawei',
                    model: 'SUN2000-17KTL-M2',
                    power: 17,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 12,
                    qt: 1,
                    cost: 1723.17,
                    brand: 'Huawei',
                    model: 'SUN2000-20KTL-M2',
                    power: 20,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 13,
                    qt: 0,
                    cost: 1966.38,
                    brand: 'Huawei',
                    model: 'SUN2000-30KTL-M3',
                    power: 30,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 14,
                    qt: 1,
                    cost: 2207.08,
                    brand: 'Huawei',
                    model: 'SUN2000-36KTL-M3',
                    power: 36,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 15,
                    qt: 1,
                    cost: 2360.96,
                    brand: 'Huawei',
                    model: 'SUN2000-40KTL-M3',
                    power: 40,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 16,
                    qt: 0,
                    cost: 2233.39,
                    brand: 'Huawei',
                    model: 'SUN2000-50KTL-M3',
                    power: 50,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 18,
                    qt: 0,
                    cost: 4384.41,
                    brand: 'Huawei',
                    model: 'SUN2000-100KTL-M1',
                    power: 100,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 20,
                    qt: 0,
                    cost: 4640.33,
                    brand: 'Sungrow',
                    model: 'SG110CX',
                    power: 110,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 19,
                    qt: 0,
                    cost: 3230.05,
                    brand: 'Sungrow',
                    model: 'SG50CX',
                    power: 50,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
            ],
            total_perimeter_areas: 232.789108655615,
            coordinates_conversion_factor: {
                one_meter_in_lat: 0.00000900434376143347,
                one_meter_in_lon: 0.00001191923025934094,
            },
        },
        data_gravacao: '2024-03-26T08:25:58.506Z',
        utilizador_id: '608138',
        ativo: true,
        visivel: true,
        versao_projeto_produto_inputs_id: null,
        description: 'Ceramica Telhado (Editada)',
        comments: 'Projeto de 101kWp, não sei que. Agua a sul.',
        data_criacao: '2024-03-26T08:00:26.399Z',
        data_edicao: '2024-03-26T08:25:57.859Z',
        tipo_produto_id: 1,
        empresa_id: 1,
    },
    {
        id: '470',
        projecto_id: '470',
        instalacao_id: '567883',
        inputs: {
            areas: [
                {
                    id: 1,
                    lat: 41.186144158001355,
                    area: 435.1431263231753,
                    long: -8.48851291740686,
                    edges: [
                        {
                            aspect: 49.480200361030604,
                            points: [
                                {
                                    lat: 41.1860521,
                                    lng: -8.4887187,
                                },
                                {
                                    lat: 41.186038599999996,
                                    lng: -8.4887035,
                                },
                            ],
                        },
                        {
                            aspect: -41.610839990283644,
                            points: [
                                {
                                    lat: 41.186038599999996,
                                    lng: -8.4887035,
                                },
                                {
                                    lat: 41.18601160000001,
                                    lng: -8.488673,
                                },
                            ],
                        },
                        {
                            aspect: -130.44565838115057,
                            points: [
                                {
                                    lat: 41.18601160000001,
                                    lng: -8.488673,
                                },
                                {
                                    lat: 41.1860049,
                                    lng: -8.4886653,
                                },
                            ],
                        },
                        {
                            aspect: 170.68635433153466,
                            points: [
                                {
                                    lat: 41.1860049,
                                    lng: -8.4886653,
                                },
                                {
                                    lat: 41.186082,
                                    lng: -8.4885456,
                                },
                            ],
                        },
                        {
                            aspect: 141.1543891066709,
                            points: [
                                {
                                    lat: 41.186082,
                                    lng: -8.4885456,
                                },
                                {
                                    lat: 41.1861688,
                                    lng: -8.4884109,
                                },
                            ],
                        },
                        {
                            aspect: 131.00873260257197,
                            points: [
                                {
                                    lat: 41.1861688,
                                    lng: -8.4884109,
                                },
                                {
                                    lat: 41.1862074,
                                    lng: -8.488351,
                                },
                            ],
                        },
                    ],
                    slope: 5,
                    aspect: -40.27116805574741,
                    selected: true,
                    panel_rows: [
                        {
                            positions: [
                                [
                                    [41.186012273946346, -8.48866562329768],
                                    [41.186024001893145, -8.488647297971392],
                                    [41.186030818748634, -8.488654942428427],
                                    [41.186019090801835, -8.488673267754715],
                                ],
                                [
                                    [41.18607382384085, -8.488569449438872],
                                    [41.186085551787656, -8.488551124112584],
                                    [41.18609236864314, -8.48855876856962],
                                    [41.18608064069634, -8.488577093895909],
                                ],
                                [
                                    [41.18608846194815, -8.488546576885216],
                                    [41.18610018989495, -8.488528251558929],
                                    [41.18610700675044, -8.488535896015966],
                                    [41.186095278803634, -8.488554221342254],
                                ],
                                [
                                    [41.18610018989495, -8.488528251558929],
                                    [41.18611191784176, -8.488509926232641],
                                    [41.18611873469724, -8.488517570689677],
                                    [41.18610700675044, -8.488535896015966],
                                ],
                                [
                                    [41.18611191784176, -8.488509926232641],
                                    [41.186123645788555, -8.488491600906352],
                                    [41.186130462644044, -8.48849924536339],
                                    [41.18611873469724, -8.488517570689677],
                                ],
                                [
                                    [41.18612655594905, -8.488487053678986],
                                    [41.18613828389586, -8.488468728352698],
                                    [41.18614510075134, -8.488476372809734],
                                    [41.18613337280454, -8.488494698136023],
                                ],
                                [
                                    [41.18613828389586, -8.488468728352698],
                                    [41.186150011842656, -8.488450403026409],
                                    [41.186156828698145, -8.488458047483446],
                                    [41.18614510075134, -8.488476372809734],
                                ],
                                [
                                    [41.186150011842656, -8.488450403026409],
                                    [41.18616173978946, -8.488432077700121],
                                    [41.186168556644944, -8.488439722157159],
                                    [41.186156828698145, -8.488458047483446],
                                ],
                                [
                                    [41.18616464994996, -8.488427530472755],
                                    [41.18617637789676, -8.488409205146466],
                                    [41.186183194752246, -8.488416849603503],
                                    [41.18617146680544, -8.48843517492979],
                                ],
                                [
                                    [41.18617637789676, -8.488409205146466],
                                    [41.18618810584356, -8.488390879820178],
                                    [41.186194922699045, -8.488398524277216],
                                    [41.186183194752246, -8.488416849603503],
                                ],
                                [
                                    [41.18618810584356, -8.488390879820178],
                                    [41.18619983379036, -8.48837255449389],
                                    [41.18620665064585, -8.488380198950926],
                                    [41.186194922699045, -8.488398524277216],
                                ],
                                [
                                    [41.18620274395086, -8.488368007266523],
                                    [41.186214471897664, -8.488349681940235],
                                    [41.186221288753146, -8.488357326397272],
                                    [41.18620956080635, -8.48837565172356],
                                ],
                                [
                                    [41.186214471897664, -8.488349681940235],
                                    [41.18622619984446, -8.488331356613948],
                                    [41.186233016699944, -8.488339001070983],
                                    [41.186221288753146, -8.488357326397272],
                                ],
                                [
                                    [41.18622619984446, -8.488331356613948],
                                    [41.18623792779126, -8.488313031287658],
                                    [41.18624474464675, -8.488320675744696],
                                    [41.186233016699944, -8.488339001070983],
                                ],
                                [
                                    [41.18624083795176, -8.488308484060292],
                                    [41.18625256589856, -8.488290158734005],
                                    [41.186259382754045, -8.48829780319104],
                                    [41.18624765480725, -8.48831612851733],
                                ],
                                [
                                    [41.18625256589856, -8.488290158734005],
                                    [41.18626429384536, -8.488271833407715],
                                    [41.18627111070085, -8.488279477864753],
                                    [41.186259382754045, -8.48829780319104],
                                ],
                                [
                                    [41.18626429384536, -8.488271833407715],
                                    [41.18627602179217, -8.488253508081428],
                                    [41.18628283864765, -8.488261152538465],
                                    [41.18627111070085, -8.488279477864753],
                                ],
                                [
                                    [41.186278931952664, -8.488248960854062],
                                    [41.18629065989946, -8.488230635527772],
                                    [41.18629747675495, -8.48823827998481],
                                    [41.186285748808146, -8.488256605311097],
                                ],
                                [
                                    [41.186019090801835, -8.488673267754715],
                                    [41.186030818748634, -8.488654942428427],
                                    [41.186037635604116, -8.488662586885464],
                                    [41.18602590765732, -8.488680912211752],
                                ],
                                [
                                    [41.186030818748634, -8.488654942428427],
                                    [41.18604254669543, -8.48863661710214],
                                    [41.18604936355092, -8.488644261559175],
                                    [41.186037635604116, -8.488662586885464],
                                ],
                                [
                                    [41.18604254669543, -8.48863661710214],
                                    [41.18605427464224, -8.48861829177585],
                                    [41.18606109149772, -8.488625936232888],
                                    [41.18604936355092, -8.488644261559175],
                                ],
                                [
                                    [41.186057184802735, -8.488613744548484],
                                    [41.18606891274953, -8.488595419222197],
                                    [41.18607572960502, -8.488603063679232],
                                    [41.18606400165822, -8.488621389005521],
                                ],
                                [
                                    [41.18606891274953, -8.488595419222197],
                                    [41.18608064069634, -8.488577093895909],
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18607572960502, -8.488603063679232],
                                ],
                                [
                                    [41.18608064069634, -8.488577093895909],
                                    [41.18609236864314, -8.48855876856962],
                                    [41.18609918549863, -8.488566413026657],
                                    [41.18608745755182, -8.488584738352944],
                                ],
                                [
                                    [41.186095278803634, -8.488554221342254],
                                    [41.18610700675044, -8.488535896015966],
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18610209565912, -8.488561865799289],
                                ],
                                [
                                    [41.18610700675044, -8.488535896015966],
                                    [41.18611873469724, -8.488517570689677],
                                    [41.18612555155273, -8.488525215146714],
                                    [41.18611382360592, -8.488543540473001],
                                ],
                                [
                                    [41.18611873469724, -8.488517570689677],
                                    [41.186130462644044, -8.48849924536339],
                                    [41.186137279499526, -8.488506889820425],
                                    [41.18612555155273, -8.488525215146714],
                                ],
                                [
                                    [41.18613337280454, -8.488494698136023],
                                    [41.18614510075134, -8.488476372809734],
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18614018966002, -8.488502342593058],
                                ],
                                [
                                    [41.18614510075134, -8.488476372809734],
                                    [41.186156828698145, -8.488458047483446],
                                    [41.18616364555363, -8.488465691940482],
                                    [41.18615191760683, -8.48848401726677],
                                ],
                                [
                                    [41.186156828698145, -8.488458047483446],
                                    [41.186168556644944, -8.488439722157159],
                                    [41.18617537350043, -8.488447366614194],
                                    [41.18616364555363, -8.488465691940482],
                                ],
                                [
                                    [41.18617146680544, -8.48843517492979],
                                    [41.186183194752246, -8.488416849603503],
                                    [41.18619001160773, -8.488424494060538],
                                    [41.18617828366093, -8.488442819386828],
                                ],
                                [
                                    [41.186183194752246, -8.488416849603503],
                                    [41.186194922699045, -8.488398524277216],
                                    [41.186201739554534, -8.488406168734251],
                                    [41.18619001160773, -8.488424494060538],
                                ],
                                [
                                    [41.186194922699045, -8.488398524277216],
                                    [41.18620665064585, -8.488380198950926],
                                    [41.18621346750133, -8.488387843407963],
                                    [41.186201739554534, -8.488406168734251],
                                ],
                                [
                                    [41.18620956080635, -8.48837565172356],
                                    [41.186221288753146, -8.488357326397272],
                                    [41.186228105608635, -8.488364970854308],
                                    [41.18621637766183, -8.488383296180595],
                                ],
                                [
                                    [41.186221288753146, -8.488357326397272],
                                    [41.186233016699944, -8.488339001070983],
                                    [41.18623983355543, -8.48834664552802],
                                    [41.186228105608635, -8.488364970854308],
                                ],
                                [
                                    [41.186233016699944, -8.488339001070983],
                                    [41.18624474464675, -8.488320675744696],
                                    [41.18625156150223, -8.488328320201731],
                                    [41.18623983355543, -8.48834664552802],
                                ],
                                [
                                    [41.18624765480725, -8.48831612851733],
                                    [41.186259382754045, -8.48829780319104],
                                    [41.186266199609534, -8.488305447648077],
                                    [41.18625447166273, -8.488323772974365],
                                ],
                                [
                                    [41.186259382754045, -8.48829780319104],
                                    [41.18627111070085, -8.488279477864753],
                                    [41.18627792755633, -8.488287122321788],
                                    [41.186266199609534, -8.488305447648077],
                                ],
                                [
                                    [41.18627111070085, -8.488279477864753],
                                    [41.18628283864765, -8.488261152538465],
                                    [41.18628965550314, -8.4882687969955],
                                    [41.18627792755633, -8.488287122321788],
                                ],
                                [
                                    [41.186285748808146, -8.488256605311097],
                                    [41.18629747675495, -8.48823827998481],
                                    [41.18630429361043, -8.488245924441845],
                                    [41.186292565663635, -8.488264249768134],
                                ],
                                [
                                    [41.18602590765732, -8.488680912211752],
                                    [41.186037635604116, -8.488662586885464],
                                    [41.186044452459605, -8.4886702313425],
                                    [41.186032724512806, -8.488688556668787],
                                ],
                                [
                                    [41.186037635604116, -8.488662586885464],
                                    [41.18604936355092, -8.488644261559175],
                                    [41.1860561804064, -8.488651906016212],
                                    [41.186044452459605, -8.4886702313425],
                                ],
                                [
                                    [41.18604936355092, -8.488644261559175],
                                    [41.18606109149772, -8.488625936232888],
                                    [41.18606790835321, -8.488633580689923],
                                    [41.1860561804064, -8.488651906016212],
                                ],
                                [
                                    [41.18606400165822, -8.488621389005521],
                                    [41.18607572960502, -8.488603063679232],
                                    [41.186082546460504, -8.48861070813627],
                                    [41.186070818513706, -8.488629033462557],
                                ],
                                [
                                    [41.18607572960502, -8.488603063679232],
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18609427440731, -8.48859238280998],
                                    [41.186082546460504, -8.48861070813627],
                                ],
                                [
                                    [41.18608745755182, -8.488584738352944],
                                    [41.18609918549863, -8.488566413026657],
                                    [41.18610600235411, -8.488574057483692],
                                    [41.18609427440731, -8.48859238280998],
                                ],
                                [
                                    [41.18610209565912, -8.488561865799289],
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18612064046141, -8.488551184930039],
                                    [41.186108912514605, -8.488569510256326],
                                ],
                                [
                                    [41.18611382360592, -8.488543540473001],
                                    [41.18612555155273, -8.488525215146714],
                                    [41.18613236840821, -8.48853285960375],
                                    [41.18612064046141, -8.488551184930039],
                                ],
                                [
                                    [41.18612555155273, -8.488525215146714],
                                    [41.186137279499526, -8.488506889820425],
                                    [41.186144096355015, -8.488514534277462],
                                    [41.18613236840821, -8.48853285960375],
                                ],
                                [
                                    [41.18614018966002, -8.488502342593058],
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18615873446231, -8.488491661723806],
                                    [41.18614700651551, -8.488509987050096],
                                ],
                                [
                                    [41.18615191760683, -8.48848401726677],
                                    [41.18616364555363, -8.488465691940482],
                                    [41.186170462409116, -8.488473336397519],
                                    [41.18615873446231, -8.488491661723806],
                                ],
                                [
                                    [41.18616364555363, -8.488465691940482],
                                    [41.18617537350043, -8.488447366614194],
                                    [41.186182190355915, -8.488455011071231],
                                    [41.186170462409116, -8.488473336397519],
                                ],
                                [
                                    [41.18617828366093, -8.488442819386828],
                                    [41.18619001160773, -8.488424494060538],
                                    [41.18619682846322, -8.488432138517576],
                                    [41.18618510051641, -8.488450463843863],
                                ],
                                [
                                    [41.18619001160773, -8.488424494060538],
                                    [41.186201739554534, -8.488406168734251],
                                    [41.186208556410016, -8.488413813191288],
                                    [41.18619682846322, -8.488432138517576],
                                ],
                                [
                                    [41.186201739554534, -8.488406168734251],
                                    [41.18621346750133, -8.488387843407963],
                                    [41.18622028435682, -8.488395487864999],
                                    [41.186208556410016, -8.488413813191288],
                                ],
                                [
                                    [41.18621637766183, -8.488383296180595],
                                    [41.186228105608635, -8.488364970854308],
                                    [41.186234922464116, -8.488372615311345],
                                    [41.18622319451732, -8.488390940637633],
                                ],
                                [
                                    [41.186228105608635, -8.488364970854308],
                                    [41.18623983355543, -8.48834664552802],
                                    [41.18624665041092, -8.488354289985056],
                                    [41.186234922464116, -8.488372615311345],
                                ],
                                [
                                    [41.18623983355543, -8.48834664552802],
                                    [41.18625156150223, -8.488328320201731],
                                    [41.18625837835772, -8.488335964658768],
                                    [41.18624665041092, -8.488354289985056],
                                ],
                                [
                                    [41.18625447166273, -8.488323772974365],
                                    [41.186266199609534, -8.488305447648077],
                                    [41.186273016465016, -8.488313092105113],
                                    [41.18626128851822, -8.488331417431402],
                                ],
                                [
                                    [41.186266199609534, -8.488305447648077],
                                    [41.18627792755633, -8.488287122321788],
                                    [41.18628474441182, -8.488294766778825],
                                    [41.186273016465016, -8.488313092105113],
                                ],
                                [
                                    [41.18627792755633, -8.488287122321788],
                                    [41.18628965550314, -8.4882687969955],
                                    [41.18629647235862, -8.488276441452538],
                                    [41.18628474441182, -8.488294766778825],
                                ],
                                [
                                    [41.186292565663635, -8.488264249768134],
                                    [41.18630429361043, -8.488245924441845],
                                    [41.18631111046592, -8.488253568898882],
                                    [41.18629938251912, -8.48827189422517],
                                ],
                                [
                                    [41.186032724512806, -8.488688556668787],
                                    [41.186044452459605, -8.4886702313425],
                                    [41.186051269315094, -8.488677875799537],
                                    [41.18603954136829, -8.488696201125824],
                                ],
                                [
                                    [41.186044452459605, -8.4886702313425],
                                    [41.1860561804064, -8.488651906016212],
                                    [41.18606299726189, -8.488659550473248],
                                    [41.186051269315094, -8.488677875799537],
                                ],
                                [
                                    [41.1860561804064, -8.488651906016212],
                                    [41.18606790835321, -8.488633580689923],
                                    [41.18607472520869, -8.48864122514696],
                                    [41.18606299726189, -8.488659550473248],
                                ],
                                [
                                    [41.186070818513706, -8.488629033462557],
                                    [41.186082546460504, -8.48861070813627],
                                    [41.18608936331599, -8.488618352593305],
                                    [41.18607763536919, -8.488636677919594],
                                ],
                                [
                                    [41.186082546460504, -8.48861070813627],
                                    [41.18609427440731, -8.48859238280998],
                                    [41.18610109126279, -8.488600027267017],
                                    [41.18608936331599, -8.488618352593305],
                                ],
                                [
                                    [41.18609427440731, -8.48859238280998],
                                    [41.18610600235411, -8.488574057483692],
                                    [41.1861128192096, -8.48858170194073],
                                    [41.18610109126279, -8.488600027267017],
                                ],
                                [
                                    [41.186108912514605, -8.488569510256326],
                                    [41.18612064046141, -8.488551184930039],
                                    [41.18612745731689, -8.488558829387074],
                                    [41.186115729370094, -8.488577154713361],
                                ],
                                [
                                    [41.18612064046141, -8.488551184930039],
                                    [41.18613236840821, -8.48853285960375],
                                    [41.1861391852637, -8.488540504060786],
                                    [41.18612745731689, -8.488558829387074],
                                ],
                                [
                                    [41.18613236840821, -8.48853285960375],
                                    [41.186144096355015, -8.488514534277462],
                                    [41.1861509132105, -8.488522178734497],
                                    [41.1861391852637, -8.488540504060786],
                                ],
                                [
                                    [41.18614700651551, -8.488509987050096],
                                    [41.18615873446231, -8.488491661723806],
                                    [41.1861655513178, -8.488499306180843],
                                    [41.186153823370994, -8.488517631507131],
                                ],
                                [
                                    [41.18615873446231, -8.488491661723806],
                                    [41.186170462409116, -8.488473336397519],
                                    [41.1861772792646, -8.488480980854554],
                                    [41.1861655513178, -8.488499306180843],
                                ],
                                [
                                    [41.186170462409116, -8.488473336397519],
                                    [41.186182190355915, -8.488455011071231],
                                    [41.186189007211404, -8.488462655528267],
                                    [41.1861772792646, -8.488480980854554],
                                ],
                                [
                                    [41.18618510051641, -8.488450463843863],
                                    [41.18619682846322, -8.488432138517576],
                                    [41.1862036453187, -8.488439782974611],
                                    [41.1861919173719, -8.4884581083009],
                                ],
                                [
                                    [41.18619682846322, -8.488432138517576],
                                    [41.186208556410016, -8.488413813191288],
                                    [41.186215373265505, -8.488421457648323],
                                    [41.1862036453187, -8.488439782974611],
                                ],
                                [
                                    [41.186208556410016, -8.488413813191288],
                                    [41.18622028435682, -8.488395487864999],
                                    [41.1862271012123, -8.488403132322036],
                                    [41.186215373265505, -8.488421457648323],
                                ],
                                [
                                    [41.18622319451732, -8.488390940637633],
                                    [41.186234922464116, -8.488372615311345],
                                    [41.186241739319605, -8.48838025976838],
                                    [41.1862300113728, -8.488398585094668],
                                ],
                                [
                                    [41.186234922464116, -8.488372615311345],
                                    [41.18624665041092, -8.488354289985056],
                                    [41.186253467266404, -8.488361934442093],
                                    [41.186241739319605, -8.48838025976838],
                                ],
                                [
                                    [41.18624665041092, -8.488354289985056],
                                    [41.18625837835772, -8.488335964658768],
                                    [41.1862651952132, -8.488343609115804],
                                    [41.186253467266404, -8.488361934442093],
                                ],
                                [
                                    [41.18626128851822, -8.488331417431402],
                                    [41.186273016465016, -8.488313092105113],
                                    [41.186279833320505, -8.48832073656215],
                                    [41.186268105373706, -8.488339061888437],
                                ],
                                [
                                    [41.186273016465016, -8.488313092105113],
                                    [41.18628474441182, -8.488294766778825],
                                    [41.1862915612673, -8.48830241123586],
                                    [41.186279833320505, -8.48832073656215],
                                ],
                                [
                                    [41.18628474441182, -8.488294766778825],
                                    [41.18629647235862, -8.488276441452538],
                                    [41.18630328921411, -8.488284085909573],
                                    [41.1862915612673, -8.48830241123586],
                                ],
                                [
                                    [41.18629938251912, -8.48827189422517],
                                    [41.18631111046592, -8.488253568898882],
                                    [41.186317927321404, -8.488261213355917],
                                    [41.186306199374606, -8.488279538682207],
                                ],
                                [
                                    [41.18603954136829, -8.488696201125824],
                                    [41.186051269315094, -8.488677875799537],
                                    [41.186058086170576, -8.488685520256572],
                                    [41.18604635822378, -8.48870384558286],
                                ],
                                [
                                    [41.186051269315094, -8.488677875799537],
                                    [41.18606299726189, -8.488659550473248],
                                    [41.186069814117374, -8.488667194930285],
                                    [41.186058086170576, -8.488685520256572],
                                ],
                                [
                                    [41.18606299726189, -8.488659550473248],
                                    [41.18607472520869, -8.48864122514696],
                                    [41.18608154206418, -8.488648869603995],
                                    [41.186069814117374, -8.488667194930285],
                                ],
                                [
                                    [41.18607763536919, -8.488636677919594],
                                    [41.18608936331599, -8.488618352593305],
                                    [41.186096180171475, -8.488625997050342],
                                    [41.18608445222468, -8.48864432237663],
                                ],
                                [
                                    [41.18608936331599, -8.488618352593305],
                                    [41.18610109126279, -8.488600027267017],
                                    [41.18610790811828, -8.488607671724052],
                                    [41.186096180171475, -8.488625997050342],
                                ],
                                [
                                    [41.18610109126279, -8.488600027267017],
                                    [41.1861128192096, -8.48858170194073],
                                    [41.18611963606508, -8.488589346397765],
                                    [41.18610790811828, -8.488607671724052],
                                ],
                                [
                                    [41.186115729370094, -8.488577154713361],
                                    [41.18612745731689, -8.488558829387074],
                                    [41.18613427417238, -8.48856647384411],
                                    [41.186122546225576, -8.488584799170399],
                                ],
                                [
                                    [41.18612745731689, -8.488558829387074],
                                    [41.1861391852637, -8.488540504060786],
                                    [41.18614600211918, -8.488548148517822],
                                    [41.18613427417238, -8.48856647384411],
                                ],
                                [
                                    [41.1861391852637, -8.488540504060786],
                                    [41.1861509132105, -8.488522178734497],
                                    [41.186157730065986, -8.488529823191534],
                                    [41.18614600211918, -8.488548148517822],
                                ],
                                [
                                    [41.186153823370994, -8.488517631507131],
                                    [41.1861655513178, -8.488499306180843],
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18616064022648, -8.488525275964168],
                                ],
                                [
                                    [41.1861655513178, -8.488499306180843],
                                    [41.1861772792646, -8.488480980854554],
                                    [41.18618409612009, -8.488488625311591],
                                    [41.18617236817328, -8.488506950637879],
                                ],
                                [
                                    [41.1861772792646, -8.488480980854554],
                                    [41.186189007211404, -8.488462655528267],
                                    [41.186195824066886, -8.488470299985304],
                                    [41.18618409612009, -8.488488625311591],
                                ],
                                [
                                    [41.1861919173719, -8.4884581083009],
                                    [41.1862036453187, -8.488439782974611],
                                    [41.18621046217419, -8.488447427431648],
                                    [41.18619873422738, -8.488465752757936],
                                ],
                                [
                                    [41.1862036453187, -8.488439782974611],
                                    [41.186215373265505, -8.488421457648323],
                                    [41.186222190120986, -8.48842910210536],
                                    [41.18621046217419, -8.488447427431648],
                                ],
                                [
                                    [41.186215373265505, -8.488421457648323],
                                    [41.1862271012123, -8.488403132322036],
                                    [41.18623391806779, -8.488410776779071],
                                    [41.186222190120986, -8.48842910210536],
                                ],
                                [
                                    [41.1862300113728, -8.488398585094668],
                                    [41.186241739319605, -8.48838025976838],
                                    [41.18624855617509, -8.488387904225418],
                                    [41.18623682822829, -8.488406229551705],
                                ],
                                [
                                    [41.186241739319605, -8.48838025976838],
                                    [41.186253467266404, -8.488361934442093],
                                    [41.18626028412189, -8.488369578899128],
                                    [41.18624855617509, -8.488387904225418],
                                ],
                                [
                                    [41.186253467266404, -8.488361934442093],
                                    [41.1862651952132, -8.488343609115804],
                                    [41.18627201206869, -8.48835125357284],
                                    [41.18626028412189, -8.488369578899128],
                                ],
                                [
                                    [41.186268105373706, -8.488339061888437],
                                    [41.186279833320505, -8.48832073656215],
                                    [41.18628665017599, -8.488328381019185],
                                    [41.18627492222919, -8.488346706345475],
                                ],
                                [
                                    [41.186279833320505, -8.48832073656215],
                                    [41.1862915612673, -8.48830241123586],
                                    [41.18629837812279, -8.488310055692898],
                                    [41.18628665017599, -8.488328381019185],
                                ],
                                [
                                    [41.1862915612673, -8.48830241123586],
                                    [41.18630328921411, -8.488284085909573],
                                    [41.18631010606959, -8.48829173036661],
                                    [41.18629837812279, -8.488310055692898],
                                ],
                                [
                                    [41.186306199374606, -8.488279538682207],
                                    [41.186317927321404, -8.488261213355917],
                                    [41.18632474417689, -8.488268857812955],
                                    [41.18631301623009, -8.488287183139242],
                                ],
                                [
                                    [41.18604635822378, -8.48870384558286],
                                    [41.186058086170576, -8.488685520256572],
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18605317507926, -8.488711490039897],
                                ],
                                [
                                    [41.186058086170576, -8.488685520256572],
                                    [41.186069814117374, -8.488667194930285],
                                    [41.18607663097286, -8.48867483938732],
                                    [41.186064903026065, -8.48869316471361],
                                ],
                                [
                                    [41.186069814117374, -8.488667194930285],
                                    [41.18608154206418, -8.488648869603995],
                                    [41.18608835891966, -8.488656514061033],
                                    [41.18607663097286, -8.48867483938732],
                                ],
                                [
                                    [41.18608445222468, -8.48864432237663],
                                    [41.186096180171475, -8.488625997050342],
                                    [41.186102997026964, -8.488633641507377],
                                    [41.18609126908016, -8.488651966833666],
                                ],
                                [
                                    [41.186096180171475, -8.488625997050342],
                                    [41.18610790811828, -8.488607671724052],
                                    [41.18611472497376, -8.48861531618109],
                                    [41.186102997026964, -8.488633641507377],
                                ],
                                [
                                    [41.18610790811828, -8.488607671724052],
                                    [41.18611963606508, -8.488589346397765],
                                    [41.18612645292057, -8.488596990854802],
                                    [41.18611472497376, -8.48861531618109],
                                ],
                                [
                                    [41.186122546225576, -8.488584799170399],
                                    [41.18613427417238, -8.48856647384411],
                                    [41.186141091027864, -8.488574118301146],
                                    [41.186129363081065, -8.488592443627434],
                                ],
                                [
                                    [41.18613427417238, -8.48856647384411],
                                    [41.18614600211918, -8.488548148517822],
                                    [41.18615281897467, -8.488555792974859],
                                    [41.186141091027864, -8.488574118301146],
                                ],
                                [
                                    [41.18614600211918, -8.488548148517822],
                                    [41.186157730065986, -8.488529823191534],
                                    [41.18616454692147, -8.48853746764857],
                                    [41.18615281897467, -8.488555792974859],
                                ],
                                [
                                    [41.18616064022648, -8.488525275964168],
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18617918502877, -8.488514595094916],
                                    [41.186167457081964, -8.488532920421203],
                                ],
                                [
                                    [41.18617236817328, -8.488506950637879],
                                    [41.18618409612009, -8.488488625311591],
                                    [41.18619091297557, -8.488496269768627],
                                    [41.18617918502877, -8.488514595094916],
                                ],
                                [
                                    [41.18618409612009, -8.488488625311591],
                                    [41.186195824066886, -8.488470299985304],
                                    [41.186202640922374, -8.488477944442339],
                                    [41.18619091297557, -8.488496269768627],
                                ],
                                [
                                    [41.18619873422738, -8.488465752757936],
                                    [41.18621046217419, -8.488447427431648],
                                    [41.18621727902967, -8.488455071888684],
                                    [41.18620555108287, -8.488473397214973],
                                ],
                                [
                                    [41.18621046217419, -8.488447427431648],
                                    [41.186222190120986, -8.48842910210536],
                                    [41.186229006976475, -8.488436746562396],
                                    [41.18621727902967, -8.488455071888684],
                                ],
                                [
                                    [41.186222190120986, -8.48842910210536],
                                    [41.18623391806779, -8.488410776779071],
                                    [41.186240734923274, -8.488418421236108],
                                    [41.186229006976475, -8.488436746562396],
                                ],
                                [
                                    [41.18623682822829, -8.488406229551705],
                                    [41.18624855617509, -8.488387904225418],
                                    [41.186255373030576, -8.488395548682453],
                                    [41.18624364508377, -8.48841387400874],
                                ],
                                [
                                    [41.18624855617509, -8.488387904225418],
                                    [41.18626028412189, -8.488369578899128],
                                    [41.186267100977375, -8.488377223356165],
                                    [41.186255373030576, -8.488395548682453],
                                ],
                                [
                                    [41.18626028412189, -8.488369578899128],
                                    [41.18627201206869, -8.48835125357284],
                                    [41.18627882892418, -8.488358898029876],
                                    [41.186267100977375, -8.488377223356165],
                                ],
                                [
                                    [41.18627492222919, -8.488346706345475],
                                    [41.18628665017599, -8.488328381019185],
                                    [41.186293467031476, -8.488336025476222],
                                    [41.18628173908468, -8.48835435080251],
                                ],
                                [
                                    [41.18628665017599, -8.488328381019185],
                                    [41.18629837812279, -8.488310055692898],
                                    [41.186305194978274, -8.488317700149933],
                                    [41.186293467031476, -8.488336025476222],
                                ],
                                [
                                    [41.18629837812279, -8.488310055692898],
                                    [41.18631010606959, -8.48829173036661],
                                    [41.18631692292508, -8.488299374823645],
                                    [41.186305194978274, -8.488317700149933],
                                ],
                                [
                                    [41.18631301623009, -8.488287183139242],
                                    [41.18632474417689, -8.488268857812955],
                                    [41.186331561032375, -8.48827650226999],
                                    [41.18631983308558, -8.48829482759628],
                                ],
                                [
                                    [41.18605317507926, -8.488711490039897],
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18607171988155, -8.488700809170645],
                                    [41.18605999193475, -8.488719134496932],
                                ],
                                [
                                    [41.186064903026065, -8.48869316471361],
                                    [41.18607663097286, -8.48867483938732],
                                    [41.18608344782835, -8.488682483844357],
                                    [41.18607171988155, -8.488700809170645],
                                ],
                                [
                                    [41.18607663097286, -8.48867483938732],
                                    [41.18608835891966, -8.488656514061033],
                                    [41.18609517577515, -8.488664158518068],
                                    [41.18608344782835, -8.488682483844357],
                                ],
                                [
                                    [41.18609126908016, -8.488651966833666],
                                    [41.186102997026964, -8.488633641507377],
                                    [41.186109813882446, -8.488641285964414],
                                    [41.18609808593565, -8.488659611290702],
                                ],
                                [
                                    [41.186102997026964, -8.488633641507377],
                                    [41.18611472497376, -8.48861531618109],
                                    [41.18612154182925, -8.488622960638125],
                                    [41.186109813882446, -8.488641285964414],
                                ],
                                [
                                    [41.18611472497376, -8.48861531618109],
                                    [41.18612645292057, -8.488596990854802],
                                    [41.18613326977605, -8.488604635311837],
                                    [41.18612154182925, -8.488622960638125],
                                ],
                                [
                                    [41.186129363081065, -8.488592443627434],
                                    [41.186141091027864, -8.488574118301146],
                                    [41.18614790788335, -8.488581762758182],
                                    [41.18613617993655, -8.488600088084471],
                                ],
                                [
                                    [41.186141091027864, -8.488574118301146],
                                    [41.18615281897467, -8.488555792974859],
                                    [41.18615963583015, -8.488563437431894],
                                    [41.18614790788335, -8.488581762758182],
                                ],
                                [
                                    [41.18617918502877, -8.488514595094916],
                                    [41.18619091297557, -8.488496269768627],
                                    [41.18619772983106, -8.488503914225664],
                                    [41.18618600188425, -8.488522239551951],
                                ],
                                [
                                    [41.18619091297557, -8.488496269768627],
                                    [41.186202640922374, -8.488477944442339],
                                    [41.186209457777856, -8.488485588899374],
                                    [41.18619772983106, -8.488503914225664],
                                ],
                                [
                                    [41.18620555108287, -8.488473397214973],
                                    [41.18621727902967, -8.488455071888684],
                                    [41.18622409588516, -8.48846271634572],
                                    [41.18621236793835, -8.488481041672008],
                                ],
                                [
                                    [41.18621727902967, -8.488455071888684],
                                    [41.186229006976475, -8.488436746562396],
                                    [41.18623582383196, -8.488444391019433],
                                    [41.18622409588516, -8.48846271634572],
                                ],
                                [
                                    [41.186229006976475, -8.488436746562396],
                                    [41.186240734923274, -8.488418421236108],
                                    [41.18624755177876, -8.488426065693144],
                                    [41.18623582383196, -8.488444391019433],
                                ],
                                [
                                    [41.18624364508377, -8.48841387400874],
                                    [41.186255373030576, -8.488395548682453],
                                    [41.18626218988606, -8.48840319313949],
                                    [41.18625046193926, -8.488421518465778],
                                ],
                                [
                                    [41.18628173908468, -8.48835435080251],
                                    [41.186293467031476, -8.488336025476222],
                                    [41.186300283886965, -8.488343669933258],
                                    [41.18628855594016, -8.488361995259547],
                                ],
                                [
                                    [41.186293467031476, -8.488336025476222],
                                    [41.186305194978274, -8.488317700149933],
                                    [41.18631201183376, -8.48832534460697],
                                    [41.186300283886965, -8.488343669933258],
                                ],
                                [
                                    [41.186305194978274, -8.488317700149933],
                                    [41.18631692292508, -8.488299374823645],
                                    [41.18632373978056, -8.488307019280683],
                                    [41.18631201183376, -8.48832534460697],
                                ],
                                [
                                    [41.18631983308558, -8.48829482759628],
                                    [41.186331561032375, -8.48827650226999],
                                    [41.186338377887864, -8.488284146727027],
                                    [41.18632664994106, -8.488302472053315],
                                ],
                            ],
                            panels_idx: [
                                [0, 0, [0, 0]],
                                [0, 1, [0, 2]],
                                [0, 2, [0, 0]],
                                [0, 2, [0, 1]],
                                [0, 2, [0, 2]],
                                [0, 3, [0, 0]],
                                [0, 3, [0, 1]],
                                [0, 3, [0, 2]],
                                [0, 4, [0, 0]],
                                [0, 4, [0, 1]],
                                [0, 4, [0, 2]],
                                [0, 5, [0, 0]],
                                [0, 5, [0, 1]],
                                [0, 5, [0, 2]],
                                [0, 6, [0, 0]],
                                [0, 6, [0, 1]],
                                [0, 6, [0, 2]],
                                [0, 7, [0, 0]],
                                [1, 0, [0, 0]],
                                [1, 0, [0, 1]],
                                [1, 0, [0, 2]],
                                [1, 1, [0, 0]],
                                [1, 1, [0, 1]],
                                [1, 1, [0, 2]],
                                [1, 2, [0, 0]],
                                [1, 2, [0, 1]],
                                [1, 2, [0, 2]],
                                [1, 3, [0, 0]],
                                [1, 3, [0, 1]],
                                [1, 3, [0, 2]],
                                [1, 4, [0, 0]],
                                [1, 4, [0, 1]],
                                [1, 4, [0, 2]],
                                [1, 5, [0, 0]],
                                [1, 5, [0, 1]],
                                [1, 5, [0, 2]],
                                [1, 6, [0, 0]],
                                [1, 6, [0, 1]],
                                [1, 6, [0, 2]],
                                [1, 7, [0, 0]],
                                [2, 0, [0, 0]],
                                [2, 0, [0, 1]],
                                [2, 0, [0, 2]],
                                [2, 1, [0, 0]],
                                [2, 1, [0, 1]],
                                [2, 1, [0, 2]],
                                [2, 2, [0, 0]],
                                [2, 2, [0, 1]],
                                [2, 2, [0, 2]],
                                [2, 3, [0, 0]],
                                [2, 3, [0, 1]],
                                [2, 3, [0, 2]],
                                [2, 4, [0, 0]],
                                [2, 4, [0, 1]],
                                [2, 4, [0, 2]],
                                [2, 5, [0, 0]],
                                [2, 5, [0, 1]],
                                [2, 5, [0, 2]],
                                [2, 6, [0, 0]],
                                [2, 6, [0, 1]],
                                [2, 6, [0, 2]],
                                [2, 7, [0, 0]],
                                [3, 0, [0, 0]],
                                [3, 0, [0, 1]],
                                [3, 0, [0, 2]],
                                [3, 1, [0, 0]],
                                [3, 1, [0, 1]],
                                [3, 1, [0, 2]],
                                [3, 2, [0, 0]],
                                [3, 2, [0, 1]],
                                [3, 2, [0, 2]],
                                [3, 3, [0, 0]],
                                [3, 3, [0, 1]],
                                [3, 3, [0, 2]],
                                [3, 4, [0, 0]],
                                [3, 4, [0, 1]],
                                [3, 4, [0, 2]],
                                [3, 5, [0, 0]],
                                [3, 5, [0, 1]],
                                [3, 5, [0, 2]],
                                [3, 6, [0, 0]],
                                [3, 6, [0, 1]],
                                [3, 6, [0, 2]],
                                [3, 7, [0, 0]],
                                [4, 0, [0, 0]],
                                [4, 0, [0, 1]],
                                [4, 0, [0, 2]],
                                [4, 1, [0, 0]],
                                [4, 1, [0, 1]],
                                [4, 1, [0, 2]],
                                [4, 2, [0, 0]],
                                [4, 2, [0, 1]],
                                [4, 2, [0, 2]],
                                [4, 3, [0, 0]],
                                [4, 3, [0, 1]],
                                [4, 3, [0, 2]],
                                [4, 4, [0, 0]],
                                [4, 4, [0, 1]],
                                [4, 4, [0, 2]],
                                [4, 5, [0, 0]],
                                [4, 5, [0, 1]],
                                [4, 5, [0, 2]],
                                [4, 6, [0, 0]],
                                [4, 6, [0, 1]],
                                [4, 6, [0, 2]],
                                [4, 7, [0, 0]],
                                [5, 0, [0, 0]],
                                [5, 0, [0, 1]],
                                [5, 0, [0, 2]],
                                [5, 1, [0, 0]],
                                [5, 1, [0, 1]],
                                [5, 1, [0, 2]],
                                [5, 2, [0, 0]],
                                [5, 2, [0, 1]],
                                [5, 2, [0, 2]],
                                [5, 3, [0, 0]],
                                [5, 3, [0, 1]],
                                [5, 3, [0, 2]],
                                [5, 4, [0, 0]],
                                [5, 4, [0, 1]],
                                [5, 4, [0, 2]],
                                [5, 5, [0, 0]],
                                [5, 5, [0, 1]],
                                [5, 5, [0, 2]],
                                [5, 6, [0, 0]],
                                [5, 6, [0, 1]],
                                [5, 6, [0, 2]],
                                [5, 7, [0, 0]],
                                [6, 0, [0, 0]],
                                [6, 0, [0, 1]],
                                [6, 0, [0, 2]],
                                [6, 1, [0, 0]],
                                [6, 1, [0, 1]],
                                [6, 1, [0, 2]],
                                [6, 2, [0, 0]],
                                [6, 2, [0, 1]],
                                [6, 3, [0, 1]],
                                [6, 3, [0, 2]],
                                [6, 4, [0, 0]],
                                [6, 4, [0, 1]],
                                [6, 4, [0, 2]],
                                [6, 5, [0, 0]],
                                [6, 6, [0, 0]],
                                [6, 6, [0, 1]],
                                [6, 6, [0, 2]],
                                [6, 7, [0, 0]],
                            ],
                            perc_shadow: [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0,
                            ],
                            panels_number: 146,
                        },
                    ],
                    coordinates: [
                        {
                            lat: 41.18600528112348,
                            lng: -8.488665535099807,
                        },
                        {
                            lat: 41.18606583790162,
                            lng: -8.488733931429687,
                        },
                        {
                            lat: 41.18635247331818,
                            lng: -8.488283320315185,
                        },
                        {
                            lat: 41.18629191654003,
                            lng: -8.488216265089813,
                        },
                        {
                            lat: 41.18600528112348,
                            lng: -8.488665535099807,
                        },
                    ],
                    orientation: -40.27,
                    region_gd_id: 125,
                    panels_number: 146,
                    perimeter_area: 116.39458181558223,
                    coordinates_avg: {
                        lat: 41.186144158001355,
                        long: -8.48851291740686,
                        region_gd_id: 125,
                    },
                    autoSplitBuilding: 1,
                    panel_representation: {
                        dayRank: '2024-12-22T00:00:00.000Z',
                        setback: 0.5,
                        rowSpace: 0,
                        alignment: 'left',
                        panels_nr: 3,
                        panels_up: 1,
                        roofSlope: 5,
                        row_space: 0,
                        hourWindow: 4,
                        panels_wide: 3,
                        panelFraming: 1,
                        space_btw_sets: 0.5,
                        reference_point: [41.186012273946346, -8.48866562329768],
                        isDefaultRowRatio: false,
                        alignment_vertical: 'bottom',
                        alignment_horizontal: 'left',
                        is_row_spacing_insert: true,
                        coordinates_conversion_factor: {
                            one_meter_in_lat: 0.00000900434376143347,
                            one_meter_in_lon: 0.00001191923025934094,
                        },
                    },
                    mounting_structure_id: 7,
                    panels_number_excluded: 0,
                    panels_number_possible: 146,
                    mounting_structure_type_id: 18,
                },
                {
                    id: 2,
                    lat: 41.18626910682026,
                    area: 435.14275740630234,
                    long: -8.488506211884323,
                    edges: [
                        {
                            aspect: 49.772882975519515,
                            points: [
                                {
                                    lat: 41.1861207,
                                    lng: -8.4888041,
                                },
                                {
                                    lat: 41.186113899999995,
                                    lng: -8.4887965,
                                },
                            ],
                        },
                        {
                            aspect: -38.67722912542285,
                            points: [
                                {
                                    lat: 41.186113899999995,
                                    lng: -8.4887965,
                                },
                                {
                                    lat: 41.1860935,
                                    lng: -8.4887737,
                                },
                            ],
                        },
                        {
                            aspect: -48.73453529487949,
                            points: [
                                {
                                    lat: 41.1860935,
                                    lng: -8.4887737,
                                },
                                {
                                    lat: 41.1860664,
                                    lng: -8.4887433,
                                },
                            ],
                        },
                        {
                            aspect: -130.2409363116384,
                            points: [
                                {
                                    lat: 41.1860664,
                                    lng: -8.4887433,
                                },
                                {
                                    lat: 41.186174799999996,
                                    lng: -8.4885555,
                                },
                            ],
                        },
                        {
                            aspect: 139.78307560281286,
                            points: [
                                {
                                    lat: 41.186174799999996,
                                    lng: -8.4885555,
                                },
                                {
                                    lat: 41.186270799999996,
                                    lng: -8.4884053,
                                },
                            ],
                        },
                    ],
                    slope: 5,
                    aspect: 139.7287929522657,
                    selected: false,
                    panel_rows: [
                        {
                            positions: [
                                [
                                    [41.18630785861647, -8.488501182006347],
                                    [41.186296130669675, -8.488519507332635],
                                    [41.186289313814186, -8.4885118628756],
                                    [41.18630104176099, -8.48849353754931],
                                ],
                                [
                                    [41.186296130669675, -8.488519507332635],
                                    [41.18628440272287, -8.488537832658922],
                                    [41.18627758586739, -8.488530188201887],
                                    [41.186289313814186, -8.4885118628756],
                                ],
                                [
                                    [41.18628440272287, -8.488537832658922],
                                    [41.18627267477607, -8.488556157985212],
                                    [41.18626585792058, -8.488548513528174],
                                    [41.18627758586739, -8.488530188201887],
                                ],
                                [
                                    [41.186269764615574, -8.488560705212578],
                                    [41.18625803666877, -8.488579030538865],
                                    [41.186251219813286, -8.48857138608183],
                                    [41.186262947760085, -8.488553060755542],
                                ],
                                [
                                    [41.18625803666877, -8.488579030538865],
                                    [41.18624630872197, -8.488597355865155],
                                    [41.18623949186648, -8.488589711408117],
                                    [41.186251219813286, -8.48857138608183],
                                ],
                                [
                                    [41.18624630872197, -8.488597355865155],
                                    [41.186234580775164, -8.488615681191442],
                                    [41.18622776391968, -8.488608036734407],
                                    [41.18623949186648, -8.488589711408117],
                                ],
                                [
                                    [41.18623167061467, -8.488620228418808],
                                    [41.18621994266787, -8.488638553745098],
                                    [41.18621312581238, -8.48863090928806],
                                    [41.186224853759185, -8.488612583961773],
                                ],
                                [
                                    [41.18621994266787, -8.488638553745098],
                                    [41.18620821472106, -8.488656879071385],
                                    [41.18620139786558, -8.48864923461435],
                                    [41.18621312581238, -8.48863090928806],
                                ],
                                [
                                    [41.18620821472106, -8.488656879071385],
                                    [41.186196486774264, -8.488675204397673],
                                    [41.186189669918775, -8.488667559940637],
                                    [41.18620139786558, -8.48864923461435],
                                ],
                                [
                                    [41.18619357661377, -8.48867975162504],
                                    [41.18618184866696, -8.488698076951328],
                                    [41.18617503181148, -8.488690432494293],
                                    [41.18618675975828, -8.488672107168004],
                                ],
                                [
                                    [41.18617012072016, -8.488716402277616],
                                    [41.18615839277336, -8.488734727603905],
                                    [41.186151575917876, -8.488727083146868],
                                    [41.186163303864674, -8.48870875782058],
                                ],
                                [
                                    [41.18615548261286, -8.488739274831271],
                                    [41.18614375466606, -8.488757600157559],
                                    [41.18613693781057, -8.488749955700523],
                                    [41.18614866575738, -8.488731630374236],
                                ],
                                [
                                    [41.18614375466606, -8.488757600157559],
                                    [41.18613202671926, -8.488775925483848],
                                    [41.186125209863775, -8.488768281026811],
                                    [41.18613693781057, -8.488749955700523],
                                ],
                                [
                                    [41.18613202671926, -8.488775925483848],
                                    [41.18612029877246, -8.488794250810136],
                                    [41.18611348191697, -8.4887866063531],
                                    [41.186125209863775, -8.488768281026811],
                                ],
                                [
                                    [41.18639186787009, -8.488351618583193],
                                    [41.18638013992329, -8.48836994390948],
                                    [41.186373323067805, -8.488362299452445],
                                    [41.186385051014604, -8.488343974126156],
                                ],
                                [
                                    [41.18637722976279, -8.488374491136849],
                                    [41.18636550181599, -8.488392816463136],
                                    [41.1863586849605, -8.4883851720061],
                                    [41.18637041290731, -8.488366846679812],
                                ],
                                [
                                    [41.18636550181599, -8.488392816463136],
                                    [41.186353773869186, -8.488411141789424],
                                    [41.186346957013704, -8.488403497332389],
                                    [41.1863586849605, -8.4883851720061],
                                ],
                                [
                                    [41.186353773869186, -8.488411141789424],
                                    [41.18634204592239, -8.488429467115713],
                                    [41.1863352290669, -8.488421822658676],
                                    [41.186346957013704, -8.488403497332389],
                                ],
                                [
                                    [41.18633913576189, -8.48843401434308],
                                    [41.186327407815085, -8.488452339669367],
                                    [41.1863205909596, -8.488444695212332],
                                    [41.1863323189064, -8.488426369886042],
                                ],
                                [
                                    [41.186327407815085, -8.488452339669367],
                                    [41.18631567986829, -8.488470664995656],
                                    [41.1863088630128, -8.48846302053862],
                                    [41.1863205909596, -8.488444695212332],
                                ],
                                [
                                    [41.18631567986829, -8.488470664995656],
                                    [41.18630395192149, -8.488488990321944],
                                    [41.186297135066, -8.488481345864907],
                                    [41.1863088630128, -8.48846302053862],
                                ],
                                [
                                    [41.18630104176099, -8.48849353754931],
                                    [41.186289313814186, -8.4885118628756],
                                    [41.186282496958704, -8.488504218418562],
                                    [41.1862942249055, -8.488485893092275],
                                ],
                                [
                                    [41.186289313814186, -8.4885118628756],
                                    [41.18627758586739, -8.488530188201887],
                                    [41.1862707690119, -8.48852254374485],
                                    [41.186282496958704, -8.488504218418562],
                                ],
                                [
                                    [41.18627758586739, -8.488530188201887],
                                    [41.18626585792058, -8.488548513528174],
                                    [41.1862590410651, -8.488540869071139],
                                    [41.1862707690119, -8.48852254374485],
                                ],
                                [
                                    [41.186262947760085, -8.488553060755542],
                                    [41.186251219813286, -8.48857138608183],
                                    [41.1862444029578, -8.488563741624793],
                                    [41.1862561309046, -8.488545416298505],
                                ],
                                [
                                    [41.186251219813286, -8.48857138608183],
                                    [41.18623949186648, -8.488589711408117],
                                    [41.186232675011, -8.488582066951082],
                                    [41.1862444029578, -8.488563741624793],
                                ],
                                [
                                    [41.18623949186648, -8.488589711408117],
                                    [41.18622776391968, -8.488608036734407],
                                    [41.18622094706419, -8.48860039227737],
                                    [41.186232675011, -8.488582066951082],
                                ],
                                [
                                    [41.186224853759185, -8.488612583961773],
                                    [41.18621312581238, -8.48863090928806],
                                    [41.1862063089569, -8.488623264831025],
                                    [41.186218036903696, -8.488604939504736],
                                ],
                                [
                                    [41.18621312581238, -8.48863090928806],
                                    [41.18620139786558, -8.48864923461435],
                                    [41.18619458101009, -8.488641590157313],
                                    [41.1862063089569, -8.488623264831025],
                                ],
                                [
                                    [41.18620139786558, -8.48864923461435],
                                    [41.186189669918775, -8.488667559940637],
                                    [41.18618285306329, -8.4886599154836],
                                    [41.18619458101009, -8.488641590157313],
                                ],
                                [
                                    [41.18618675975828, -8.488672107168004],
                                    [41.18617503181148, -8.488690432494293],
                                    [41.18616821495599, -8.488682788037256],
                                    [41.1861799429028, -8.488664462710968],
                                ],
                                [
                                    [41.186163303864674, -8.48870875782058],
                                    [41.186151575917876, -8.488727083146868],
                                    [41.18614475906239, -8.488719438689833],
                                    [41.18615648700919, -8.488701113363543],
                                ],
                                [
                                    [41.18614866575738, -8.488731630374236],
                                    [41.18613693781057, -8.488749955700523],
                                    [41.18613012095509, -8.488742311243486],
                                    [41.18614184890189, -8.488723985917199],
                                ],
                                [
                                    [41.18613693781057, -8.488749955700523],
                                    [41.186125209863775, -8.488768281026811],
                                    [41.186118393008286, -8.488760636569776],
                                    [41.18613012095509, -8.488742311243486],
                                ],
                                [
                                    [41.186125209863775, -8.488768281026811],
                                    [41.18611348191697, -8.4887866063531],
                                    [41.18610666506149, -8.488778961896063],
                                    [41.186118393008286, -8.488760636569776],
                                ],
                                [
                                    [41.186385051014604, -8.488343974126156],
                                    [41.186373323067805, -8.488362299452445],
                                    [41.186366506212316, -8.488354654995408],
                                    [41.18637823415912, -8.48833632966912],
                                ],
                                [
                                    [41.18637041290731, -8.488366846679812],
                                    [41.1863586849605, -8.4883851720061],
                                    [41.18635186810502, -8.488377527549064],
                                    [41.18636359605182, -8.488359202222776],
                                ],
                                [
                                    [41.1863586849605, -8.4883851720061],
                                    [41.186346957013704, -8.488403497332389],
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18635186810502, -8.488377527549064],
                                ],
                                [
                                    [41.186346957013704, -8.488403497332389],
                                    [41.1863352290669, -8.488421822658676],
                                    [41.18632841221142, -8.48841417820164],
                                    [41.186340140158215, -8.488395852875351],
                                ],
                                [
                                    [41.1863323189064, -8.488426369886042],
                                    [41.1863205909596, -8.488444695212332],
                                    [41.186313774104114, -8.488437050755294],
                                    [41.18632550205092, -8.488418725429007],
                                ],
                                [
                                    [41.1863205909596, -8.488444695212332],
                                    [41.1863088630128, -8.48846302053862],
                                    [41.186302046157316, -8.488455376081584],
                                    [41.186313774104114, -8.488437050755294],
                                ],
                                [
                                    [41.1863088630128, -8.48846302053862],
                                    [41.186297135066, -8.488481345864907],
                                    [41.18629031821052, -8.488473701407871],
                                    [41.186302046157316, -8.488455376081584],
                                ],
                                [
                                    [41.1862942249055, -8.488485893092275],
                                    [41.186282496958704, -8.488504218418562],
                                    [41.186275680103215, -8.488496573961527],
                                    [41.18628740805001, -8.488478248635237],
                                ],
                                [
                                    [41.186282496958704, -8.488504218418562],
                                    [41.1862707690119, -8.48852254374485],
                                    [41.186263952156416, -8.488514899287814],
                                    [41.186275680103215, -8.488496573961527],
                                ],
                                [
                                    [41.1862707690119, -8.48852254374485],
                                    [41.1862590410651, -8.488540869071139],
                                    [41.18625222420961, -8.488533224614102],
                                    [41.186263952156416, -8.488514899287814],
                                ],
                                [
                                    [41.1862561309046, -8.488545416298505],
                                    [41.1862444029578, -8.488563741624793],
                                    [41.186237586102315, -8.488556097167757],
                                    [41.186249314049114, -8.48853777184147],
                                ],
                                [
                                    [41.1862444029578, -8.488563741624793],
                                    [41.186232675011, -8.488582066951082],
                                    [41.18622585815551, -8.488574422494045],
                                    [41.186237586102315, -8.488556097167757],
                                ],
                                [
                                    [41.186232675011, -8.488582066951082],
                                    [41.18622094706419, -8.48860039227737],
                                    [41.18621413020871, -8.488592747820334],
                                    [41.18622585815551, -8.488574422494045],
                                ],
                                [
                                    [41.186218036903696, -8.488604939504736],
                                    [41.1862063089569, -8.488623264831025],
                                    [41.18619949210141, -8.488615620373988],
                                    [41.186211220048214, -8.4885972950477],
                                ],
                                [
                                    [41.1862063089569, -8.488623264831025],
                                    [41.18619458101009, -8.488641590157313],
                                    [41.18618776415461, -8.488633945700277],
                                    [41.18619949210141, -8.488615620373988],
                                ],
                                [
                                    [41.18619458101009, -8.488641590157313],
                                    [41.18618285306329, -8.4886599154836],
                                    [41.186176036207804, -8.488652271026565],
                                    [41.18618776415461, -8.488633945700277],
                                ],
                                [
                                    [41.1861799429028, -8.488664462710968],
                                    [41.18616821495599, -8.488682788037256],
                                    [41.18616139810051, -8.48867514358022],
                                    [41.18617312604731, -8.488656818253931],
                                ],
                                [
                                    [41.18615648700919, -8.488701113363543],
                                    [41.18614475906239, -8.488719438689833],
                                    [41.186137942206905, -8.488711794232795],
                                    [41.1861496701537, -8.488693468906508],
                                ],
                                [
                                    [41.18614184890189, -8.488723985917199],
                                    [41.18613012095509, -8.488742311243486],
                                    [41.1861233040996, -8.488734666786451],
                                    [41.18613503204641, -8.488716341460163],
                                ],
                                [
                                    [41.18613012095509, -8.488742311243486],
                                    [41.186118393008286, -8.488760636569776],
                                    [41.186111576152804, -8.488752992112738],
                                    [41.1861233040996, -8.488734666786451],
                                ],
                                [
                                    [41.186118393008286, -8.488760636569776],
                                    [41.18610666506149, -8.488778961896063],
                                    [41.186099848206, -8.488771317439028],
                                    [41.186111576152804, -8.488752992112738],
                                ],
                                [
                                    [41.18637823415912, -8.48833632966912],
                                    [41.186366506212316, -8.488354654995408],
                                    [41.186359689356834, -8.488347010538373],
                                    [41.18637141730363, -8.488328685212084],
                                ],
                                [
                                    [41.18636359605182, -8.488359202222776],
                                    [41.18635186810502, -8.488377527549064],
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18635677919634, -8.48835155776574],
                                ],
                                [
                                    [41.18635186810502, -8.488377527549064],
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18633332330273, -8.488388208418316],
                                    [41.18634505124953, -8.488369883092027],
                                ],
                                [
                                    [41.186340140158215, -8.488395852875351],
                                    [41.18632841221142, -8.48841417820164],
                                    [41.18632159535593, -8.488406533744604],
                                    [41.18633332330273, -8.488388208418316],
                                ],
                                [
                                    [41.18632550205092, -8.488418725429007],
                                    [41.186313774104114, -8.488437050755294],
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18631868519543, -8.48841108097197],
                                ],
                                [
                                    [41.186313774104114, -8.488437050755294],
                                    [41.186302046157316, -8.488455376081584],
                                    [41.18629522930183, -8.488447731624547],
                                    [41.18630695724863, -8.488429406298259],
                                ],
                                [
                                    [41.186302046157316, -8.488455376081584],
                                    [41.18629031821052, -8.488473701407871],
                                    [41.18628350135503, -8.488466056950834],
                                    [41.18629522930183, -8.488447731624547],
                                ],
                                [
                                    [41.18628740805001, -8.488478248635237],
                                    [41.186275680103215, -8.488496573961527],
                                    [41.18626886324773, -8.48848892950449],
                                    [41.18628059119453, -8.488470604178202],
                                ],
                                [
                                    [41.186275680103215, -8.488496573961527],
                                    [41.186263952156416, -8.488514899287814],
                                    [41.18625713530093, -8.488507254830777],
                                    [41.18626886324773, -8.48848892950449],
                                ],
                                [
                                    [41.186263952156416, -8.488514899287814],
                                    [41.18625222420961, -8.488533224614102],
                                    [41.18624540735413, -8.488525580157066],
                                    [41.18625713530093, -8.488507254830777],
                                ],
                                [
                                    [41.186249314049114, -8.48853777184147],
                                    [41.186237586102315, -8.488556097167757],
                                    [41.186230769246826, -8.48854845271072],
                                    [41.18624249719363, -8.488530127384433],
                                ],
                                [
                                    [41.186237586102315, -8.488556097167757],
                                    [41.18622585815551, -8.488574422494045],
                                    [41.18621904130003, -8.48856677803701],
                                    [41.186230769246826, -8.48854845271072],
                                ],
                                [
                                    [41.18622585815551, -8.488574422494045],
                                    [41.18621413020871, -8.488592747820334],
                                    [41.18620731335322, -8.488585103363297],
                                    [41.18621904130003, -8.48856677803701],
                                ],
                                [
                                    [41.186211220048214, -8.4885972950477],
                                    [41.18619949210141, -8.488615620373988],
                                    [41.18619267524593, -8.488607975916953],
                                    [41.186204403192725, -8.488589650590663],
                                ],
                                [
                                    [41.18619949210141, -8.488615620373988],
                                    [41.18618776415461, -8.488633945700277],
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18619267524593, -8.488607975916953],
                                ],
                                [
                                    [41.18618776415461, -8.488633945700277],
                                    [41.186176036207804, -8.488652271026565],
                                    [41.18616921935232, -8.488644626569528],
                                    [41.18618094729912, -8.48862630124324],
                                ],
                                [
                                    [41.18617312604731, -8.488656818253931],
                                    [41.18616139810051, -8.48867514358022],
                                    [41.18615458124502, -8.488667499123183],
                                    [41.186166309191826, -8.488649173796896],
                                ],
                                [
                                    [41.1861496701537, -8.488693468906508],
                                    [41.186137942206905, -8.488711794232795],
                                    [41.186131125351416, -8.48870414977576],
                                    [41.18614285329822, -8.48868582444947],
                                ],
                                [
                                    [41.18613503204641, -8.488716341460163],
                                    [41.1861233040996, -8.488734666786451],
                                    [41.18611648724412, -8.488727022329414],
                                    [41.18612821519092, -8.488708697003126],
                                ],
                                [
                                    [41.1861233040996, -8.488734666786451],
                                    [41.186111576152804, -8.488752992112738],
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18611648724412, -8.488727022329414],
                                ],
                                [
                                    [41.186111576152804, -8.488752992112738],
                                    [41.186099848206, -8.488771317439028],
                                    [41.18609303135052, -8.48876367298199],
                                    [41.186104759297315, -8.488745347655703],
                                ],
                                [
                                    [41.18637141730363, -8.488328685212084],
                                    [41.186359689356834, -8.488347010538373],
                                    [41.186352872501345, -8.488339366081336],
                                    [41.18636460044815, -8.488321040755048],
                                ],
                                [
                                    [41.18635677919634, -8.48835155776574],
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18633823439405, -8.488362238634991],
                                    [41.18634996234085, -8.488343913308704],
                                ],
                                [
                                    [41.18634505124953, -8.488369883092027],
                                    [41.18633332330273, -8.488388208418316],
                                    [41.186326506447244, -8.488380563961279],
                                    [41.18633823439405, -8.488362238634991],
                                ],
                                [
                                    [41.18633332330273, -8.488388208418316],
                                    [41.18632159535593, -8.488406533744604],
                                    [41.186314778500446, -8.488398889287568],
                                    [41.186326506447244, -8.488380563961279],
                                ],
                                [
                                    [41.18631868519543, -8.48841108097197],
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18630014039314, -8.488421761841222],
                                    [41.18631186833995, -8.488403436514934],
                                ],
                                [
                                    [41.18630695724863, -8.488429406298259],
                                    [41.18629522930183, -8.488447731624547],
                                    [41.186288412446345, -8.488440087167511],
                                    [41.18630014039314, -8.488421761841222],
                                ],
                                [
                                    [41.18629522930183, -8.488447731624547],
                                    [41.18628350135503, -8.488466056950834],
                                    [41.18627668449954, -8.488458412493799],
                                    [41.186288412446345, -8.488440087167511],
                                ],
                                [
                                    [41.18628059119453, -8.488470604178202],
                                    [41.18626886324773, -8.48848892950449],
                                    [41.186262046392244, -8.488481285047454],
                                    [41.18627377433904, -8.488462959721165],
                                ],
                                [
                                    [41.18626886324773, -8.48848892950449],
                                    [41.18625713530093, -8.488507254830777],
                                    [41.186250318445445, -8.488499610373742],
                                    [41.186262046392244, -8.488481285047454],
                                ],
                                [
                                    [41.18625713530093, -8.488507254830777],
                                    [41.18624540735413, -8.488525580157066],
                                    [41.18623859049864, -8.48851793570003],
                                    [41.186250318445445, -8.488499610373742],
                                ],
                                [
                                    [41.18624249719363, -8.488530127384433],
                                    [41.186230769246826, -8.48854845271072],
                                    [41.186223952391344, -8.488540808253685],
                                    [41.18623568033814, -8.488522482927397],
                                ],
                                [
                                    [41.186230769246826, -8.48854845271072],
                                    [41.18621904130003, -8.48856677803701],
                                    [41.18621222444454, -8.488559133579972],
                                    [41.186223952391344, -8.488540808253685],
                                ],
                                [
                                    [41.18621904130003, -8.48856677803701],
                                    [41.18620731335322, -8.488585103363297],
                                    [41.18620049649774, -8.488577458906262],
                                    [41.18621222444454, -8.488559133579972],
                                ],
                                [
                                    [41.186204403192725, -8.488589650590663],
                                    [41.18619267524593, -8.488607975916953],
                                    [41.18618585839044, -8.488600331459915],
                                    [41.186197586337244, -8.488582006133628],
                                ],
                                [
                                    [41.18619267524593, -8.488607975916953],
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18617413044364, -8.488618656786205],
                                    [41.18618585839044, -8.488600331459915],
                                ],
                                [
                                    [41.18618094729912, -8.48862630124324],
                                    [41.18616921935232, -8.488644626569528],
                                    [41.186162402496834, -8.488636982112492],
                                    [41.18617413044364, -8.488618656786205],
                                ],
                                [
                                    [41.186166309191826, -8.488649173796896],
                                    [41.18615458124502, -8.488667499123183],
                                    [41.18614776438954, -8.488659854666148],
                                    [41.18615949233634, -8.488641529339858],
                                ],
                                [
                                    [41.18614285329822, -8.48868582444947],
                                    [41.186131125351416, -8.48870414977576],
                                    [41.186124308495934, -8.488696505318723],
                                    [41.18613603644273, -8.488678179992435],
                                ],
                                [
                                    [41.18612821519092, -8.488708697003126],
                                    [41.18611648724412, -8.488727022329414],
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18612139833544, -8.48870105254609],
                                ],
                                [
                                    [41.18611648724412, -8.488727022329414],
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18609794244183, -8.488737703198666],
                                    [41.18610967038863, -8.488719377872378],
                                ],
                                [
                                    [41.186104759297315, -8.488745347655703],
                                    [41.18609303135052, -8.48876367298199],
                                    [41.18608621449503, -8.488756028524955],
                                    [41.18609794244183, -8.488737703198666],
                                ],
                                [
                                    [41.18636460044815, -8.488321040755048],
                                    [41.186352872501345, -8.488339366081336],
                                    [41.18634605564586, -8.4883317216243],
                                    [41.18635778359266, -8.488313396298011],
                                ],
                                [
                                    [41.18634996234085, -8.488343913308704],
                                    [41.18633823439405, -8.488362238634991],
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18634314548537, -8.488336268851667],
                                ],
                                [
                                    [41.18633823439405, -8.488362238634991],
                                    [41.186326506447244, -8.488380563961279],
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18633141753856, -8.488354594177954],
                                ],
                                [
                                    [41.186326506447244, -8.488380563961279],
                                    [41.186314778500446, -8.488398889287568],
                                    [41.18630796164496, -8.488391244830531],
                                    [41.18631968959176, -8.488372919504243],
                                ],
                                [
                                    [41.18631186833995, -8.488403436514934],
                                    [41.18630014039314, -8.488421761841222],
                                    [41.18629332353766, -8.488414117384186],
                                    [41.18630505148446, -8.488395792057897],
                                ],
                                [
                                    [41.18630014039314, -8.488421761841222],
                                    [41.186288412446345, -8.488440087167511],
                                    [41.186281595590856, -8.488432442710474],
                                    [41.18629332353766, -8.488414117384186],
                                ],
                                [
                                    [41.186288412446345, -8.488440087167511],
                                    [41.18627668449954, -8.488458412493799],
                                    [41.18626986764406, -8.488450768036762],
                                    [41.186281595590856, -8.488432442710474],
                                ],
                                [
                                    [41.186262046392244, -8.488481285047454],
                                    [41.186250318445445, -8.488499610373742],
                                    [41.186243501589956, -8.488491965916705],
                                    [41.186255229536755, -8.488473640590417],
                                ],
                                [
                                    [41.186250318445445, -8.488499610373742],
                                    [41.18623859049864, -8.48851793570003],
                                    [41.18623177364316, -8.488510291242994],
                                    [41.186243501589956, -8.488491965916705],
                                ],
                                [
                                    [41.18623568033814, -8.488522482927397],
                                    [41.186223952391344, -8.488540808253685],
                                    [41.186217135535856, -8.488533163796648],
                                    [41.18622886348266, -8.48851483847036],
                                ],
                                [
                                    [41.186223952391344, -8.488540808253685],
                                    [41.18621222444454, -8.488559133579972],
                                    [41.18620540758906, -8.488551489122937],
                                    [41.186217135535856, -8.488533163796648],
                                ],
                                [
                                    [41.18621222444454, -8.488559133579972],
                                    [41.18620049649774, -8.488577458906262],
                                    [41.18619367964225, -8.488569814449225],
                                    [41.18620540758906, -8.488551489122937],
                                ],
                                [
                                    [41.186197586337244, -8.488582006133628],
                                    [41.18618585839044, -8.488600331459915],
                                    [41.186179041534956, -8.48859268700288],
                                    [41.186190769481755, -8.48857436167659],
                                ],
                                [
                                    [41.18618585839044, -8.488600331459915],
                                    [41.18617413044364, -8.488618656786205],
                                    [41.18616731358815, -8.488611012329168],
                                    [41.186179041534956, -8.48859268700288],
                                ],
                                [
                                    [41.18613603644273, -8.488678179992435],
                                    [41.186124308495934, -8.488696505318723],
                                    [41.186117491640445, -8.488688860861688],
                                    [41.18612921958725, -8.488670535535398],
                                ],
                                [
                                    [41.18612139833544, -8.48870105254609],
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18610285353315, -8.488711733415341],
                                    [41.18611458147995, -8.488693408089054],
                                ],
                                [
                                    [41.18610967038863, -8.488719377872378],
                                    [41.18609794244183, -8.488737703198666],
                                    [41.186091125586344, -8.48873005874163],
                                    [41.18610285353315, -8.488711733415341],
                                ],
                                [
                                    [41.18609794244183, -8.488737703198666],
                                    [41.18608621449503, -8.488756028524955],
                                    [41.186079397639546, -8.488748384067918],
                                    [41.186091125586344, -8.48873005874163],
                                ],
                                [
                                    [41.18635778359266, -8.488313396298011],
                                    [41.18634605564586, -8.4883317216243],
                                    [41.186339238790374, -8.488324077167263],
                                    [41.18635096673718, -8.488305751840976],
                                ],
                                [
                                    [41.18634314548537, -8.488336268851667],
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18632460068308, -8.488346949720919],
                                    [41.18633632862988, -8.488328624394631],
                                ],
                                [
                                    [41.18633141753856, -8.488354594177954],
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18631287273627, -8.488365275047206],
                                    [41.18632460068308, -8.488346949720919],
                                ],
                                [
                                    [41.18631968959176, -8.488372919504243],
                                    [41.18630796164496, -8.488391244830531],
                                    [41.186301144789475, -8.488383600373496],
                                    [41.18631287273627, -8.488365275047206],
                                ],
                                [
                                    [41.18630505148446, -8.488395792057897],
                                    [41.18629332353766, -8.488414117384186],
                                    [41.18628650668217, -8.48840647292715],
                                    [41.18629823462898, -8.488388147600862],
                                ],
                                [
                                    [41.18629332353766, -8.488414117384186],
                                    [41.186281595590856, -8.488432442710474],
                                    [41.186274778735374, -8.488424798253439],
                                    [41.18628650668217, -8.48840647292715],
                                ],
                                [
                                    [41.186281595590856, -8.488432442710474],
                                    [41.18626986764406, -8.488450768036762],
                                    [41.18626305078857, -8.488443123579726],
                                    [41.186274778735374, -8.488424798253439],
                                ],
                                [
                                    [41.186255229536755, -8.488473640590417],
                                    [41.186243501589956, -8.488491965916705],
                                    [41.186236684734475, -8.48848432145967],
                                    [41.18624841268127, -8.488465996133382],
                                ],
                                [
                                    [41.186243501589956, -8.488491965916705],
                                    [41.18623177364316, -8.488510291242994],
                                    [41.18622495678767, -8.488502646785957],
                                    [41.186236684734475, -8.48848432145967],
                                ],
                                [
                                    [41.18622886348266, -8.48851483847036],
                                    [41.186217135535856, -8.488533163796648],
                                    [41.186210318680374, -8.488525519339612],
                                    [41.18622204662717, -8.488507194013325],
                                ],
                                [
                                    [41.186217135535856, -8.488533163796648],
                                    [41.18620540758906, -8.488551489122937],
                                    [41.18619859073357, -8.4885438446659],
                                    [41.186210318680374, -8.488525519339612],
                                ],
                                [
                                    [41.18620540758906, -8.488551489122937],
                                    [41.18619367964225, -8.488569814449225],
                                    [41.18618686278677, -8.48856216999219],
                                    [41.18619859073357, -8.4885438446659],
                                ],
                                [
                                    [41.186190769481755, -8.48857436167659],
                                    [41.186179041534956, -8.48859268700288],
                                    [41.18617222467947, -8.488585042545843],
                                    [41.18618395262627, -8.488566717219555],
                                ],
                                [
                                    [41.186179041534956, -8.48859268700288],
                                    [41.18616731358815, -8.488611012329168],
                                    [41.18616049673267, -8.488603367872132],
                                    [41.18617222467947, -8.488585042545843],
                                ],
                                [
                                    [41.18612921958725, -8.488670535535398],
                                    [41.186117491640445, -8.488688860861688],
                                    [41.18611067478496, -8.48868121640465],
                                    [41.18612240273176, -8.488662891078363],
                                ],
                                [
                                    [41.18611458147995, -8.488693408089054],
                                    [41.18610285353315, -8.488711733415341],
                                    [41.18609603667766, -8.488704088958306],
                                    [41.18610776462447, -8.488685763632018],
                                ],
                                [
                                    [41.18610285353315, -8.488711733415341],
                                    [41.186091125586344, -8.48873005874163],
                                    [41.18608430873086, -8.488722414284593],
                                    [41.18609603667766, -8.488704088958306],
                                ],
                                [
                                    [41.186091125586344, -8.48873005874163],
                                    [41.186079397639546, -8.488748384067918],
                                    [41.18607258078406, -8.488740739610883],
                                    [41.18608430873086, -8.488722414284593],
                                ],
                            ],
                            panels_idx: [
                                [0, -5, [0, 0]],
                                [0, -5, [0, 1]],
                                [0, -5, [0, 2]],
                                [0, -4, [0, 0]],
                                [0, -4, [0, 1]],
                                [0, -4, [0, 2]],
                                [0, -3, [0, 0]],
                                [0, -3, [0, 1]],
                                [0, -3, [0, 2]],
                                [0, -2, [0, 0]],
                                [0, -2, [0, 2]],
                                [0, -1, [0, 0]],
                                [0, -1, [0, 1]],
                                [0, -1, [0, 2]],
                                [1, -8, [0, 2]],
                                [1, -7, [0, 0]],
                                [1, -7, [0, 1]],
                                [1, -7, [0, 2]],
                                [1, -6, [0, 0]],
                                [1, -6, [0, 1]],
                                [1, -6, [0, 2]],
                                [1, -5, [0, 0]],
                                [1, -5, [0, 1]],
                                [1, -5, [0, 2]],
                                [1, -4, [0, 0]],
                                [1, -4, [0, 1]],
                                [1, -4, [0, 2]],
                                [1, -3, [0, 0]],
                                [1, -3, [0, 1]],
                                [1, -3, [0, 2]],
                                [1, -2, [0, 0]],
                                [1, -2, [0, 2]],
                                [1, -1, [0, 0]],
                                [1, -1, [0, 1]],
                                [1, -1, [0, 2]],
                                [2, -8, [0, 2]],
                                [2, -7, [0, 0]],
                                [2, -7, [0, 1]],
                                [2, -7, [0, 2]],
                                [2, -6, [0, 0]],
                                [2, -6, [0, 1]],
                                [2, -6, [0, 2]],
                                [2, -5, [0, 0]],
                                [2, -5, [0, 1]],
                                [2, -5, [0, 2]],
                                [2, -4, [0, 0]],
                                [2, -4, [0, 1]],
                                [2, -4, [0, 2]],
                                [2, -3, [0, 0]],
                                [2, -3, [0, 1]],
                                [2, -3, [0, 2]],
                                [2, -2, [0, 0]],
                                [2, -2, [0, 2]],
                                [2, -1, [0, 0]],
                                [2, -1, [0, 1]],
                                [2, -1, [0, 2]],
                                [3, -8, [0, 2]],
                                [3, -7, [0, 0]],
                                [3, -7, [0, 1]],
                                [3, -7, [0, 2]],
                                [3, -6, [0, 0]],
                                [3, -6, [0, 1]],
                                [3, -6, [0, 2]],
                                [3, -5, [0, 0]],
                                [3, -5, [0, 1]],
                                [3, -5, [0, 2]],
                                [3, -4, [0, 0]],
                                [3, -4, [0, 1]],
                                [3, -4, [0, 2]],
                                [3, -3, [0, 0]],
                                [3, -3, [0, 1]],
                                [3, -3, [0, 2]],
                                [3, -2, [0, 0]],
                                [3, -2, [0, 2]],
                                [3, -1, [0, 0]],
                                [3, -1, [0, 1]],
                                [3, -1, [0, 2]],
                                [4, -8, [0, 2]],
                                [4, -7, [0, 0]],
                                [4, -7, [0, 1]],
                                [4, -7, [0, 2]],
                                [4, -6, [0, 0]],
                                [4, -6, [0, 1]],
                                [4, -6, [0, 2]],
                                [4, -5, [0, 0]],
                                [4, -5, [0, 1]],
                                [4, -5, [0, 2]],
                                [4, -4, [0, 0]],
                                [4, -4, [0, 1]],
                                [4, -4, [0, 2]],
                                [4, -3, [0, 0]],
                                [4, -3, [0, 1]],
                                [4, -3, [0, 2]],
                                [4, -2, [0, 0]],
                                [4, -2, [0, 2]],
                                [4, -1, [0, 0]],
                                [4, -1, [0, 1]],
                                [4, -1, [0, 2]],
                                [5, -8, [0, 2]],
                                [5, -7, [0, 0]],
                                [5, -7, [0, 1]],
                                [5, -7, [0, 2]],
                                [5, -6, [0, 0]],
                                [5, -6, [0, 1]],
                                [5, -6, [0, 2]],
                                [5, -5, [0, 1]],
                                [5, -5, [0, 2]],
                                [5, -4, [0, 0]],
                                [5, -4, [0, 1]],
                                [5, -4, [0, 2]],
                                [5, -3, [0, 0]],
                                [5, -3, [0, 1]],
                                [5, -2, [0, 2]],
                                [5, -1, [0, 0]],
                                [5, -1, [0, 1]],
                                [5, -1, [0, 2]],
                                [6, -8, [0, 2]],
                                [6, -7, [0, 0]],
                                [6, -7, [0, 1]],
                                [6, -7, [0, 2]],
                                [6, -6, [0, 0]],
                                [6, -6, [0, 1]],
                                [6, -6, [0, 2]],
                                [6, -5, [0, 1]],
                                [6, -5, [0, 2]],
                                [6, -4, [0, 0]],
                                [6, -4, [0, 1]],
                                [6, -4, [0, 2]],
                                [6, -3, [0, 0]],
                                [6, -3, [0, 1]],
                                [6, -2, [0, 2]],
                                [6, -1, [0, 0]],
                                [6, -1, [0, 1]],
                                [6, -1, [0, 2]],
                            ],
                            perc_shadow: [
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                            ],
                            panels_number: 134,
                        },
                    ],
                    coordinates: [
                        {
                            lat: 41.186407983698146,
                            lng: -8.488353057749572,
                        },
                        {
                            lat: 41.18634742692,
                            lng: -8.4882860025242,
                        },
                        {
                            lat: 41.18606079150344,
                            lng: -8.488735272534194,
                        },
                        {
                            lat: 41.186121348281596,
                            lng: -8.488803668864074,
                        },
                        {
                            lat: 41.186407983698146,
                            lng: -8.488353057749572,
                        },
                    ],
                    orientation: 139.73,
                    region_gd_id: 125,
                    panels_number: 134,
                    perimeter_area: 116.39452684003277,
                    coordinates_avg: {
                        lat: 41.18626910682026,
                        long: -8.488506211884323,
                        region_gd_id: 125,
                    },
                    autoSplitBuilding: 1,
                    panel_representation: {
                        dayRank: '2024-12-22T00:00:00.000Z',
                        setback: 0.5,
                        rowSpace: 0,
                        alignment: 'left',
                        panels_nr: 3,
                        panels_up: 1,
                        roofSlope: 5,
                        row_space: 0,
                        hourWindow: 4,
                        panels_wide: 3,
                        panelFraming: 1,
                        space_btw_sets: 0.5,
                        reference_point: [41.18612029877246, -8.488794250810136],
                        isDefaultRowRatio: false,
                        alignment_vertical: 'bottom',
                        alignment_horizontal: 'left',
                        is_row_spacing_insert: true,
                        coordinates_conversion_factor: {
                            one_meter_in_lat: 0.00000900434376143347,
                            one_meter_in_lon: 0.00001191923025934094,
                        },
                    },
                    mounting_structure_id: 7,
                    panels_number_excluded: 0,
                    panels_number_possible: 134,
                    mounting_structure_type_id: 18,
                },
            ],
            panel_id: 8,
            range_id: 1,
            buildings: [],
            exclusions: [
                {
                    id: 1,
                    area: 2.3236966347219257,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18617316347283, -8.488563568358668],
                        [41.18618324631769, -8.488547754070915],
                        [41.18617407397729, -8.488537428467358],
                        [41.18616399113243, -8.488553242755112],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 2,
                    area: 2.3883073931472767,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186274582160976, -8.488404166121391],
                        [41.18628514290098, -8.488387602286126],
                        [41.18627614211645, -8.488377469808624],
                        [41.186265581376446, -8.488394033643889],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 3,
                    area: 11.675015378699932,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18603549503049, -8.488645928834034],
                        [41.186075928736386, -8.488582511190609],
                        [41.186064436702104, -8.488569574231784],
                        [41.186024002996206, -8.488632991875209],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 4,
                    area: 5.258506222422111,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18603166304474, -8.488638176882642],
                        [41.18606929017439, -8.48857916117191],
                        [41.18606372800404, -8.488572899654843],
                        [41.186026100874386, -8.488631915365575],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 5,
                    area: 3.2157922936748062,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.1862581688646, -8.4884462602208],
                        [41.18624740430836, -8.488463143719617],
                        [41.18625929414733, -8.488476528529995],
                        [41.18627005870356, -8.488459645031178],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 6,
                    area: 3.2750328350843887,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186153259099875, -8.488609235659645],
                        [41.18614248717701, -8.488626130712536],
                        [41.18615458774738, -8.488639752750684],
                        [41.18616535967025, -8.488622857697793],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 7,
                    area: 1.3555382376282392,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18628200384683, -8.488386855640433],
                        [41.1862722522419, -8.488402150391726],
                        [41.18627778472285, -8.488408378500344],
                        [41.18628753632778, -8.48839308374905],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 8,
                    area: 4.929091323309152,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.186128021055545, -8.488652061540803],
                        [41.18612427355185, -8.488657939253995],
                        [41.18617662252929, -8.488716870341182],
                        [41.18618037003298, -8.48871099262799],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 9,
                    area: 1.258065297983659,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18618149003819, -8.488546753576067],
                        [41.1861705707031, -8.488563879835331],
                        [41.186175156244, -8.488569041940183],
                        [41.18618607557908, -8.488551915680917],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 10,
                    area: 0.9409541472002925,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.18628546455482, -8.488389084310926],
                        [41.186274891939085, -8.488405666763681],
                        [41.18627843411626, -8.488409654317254],
                        [41.186289006732, -8.488393071864499],
                    ],
                    autoSplitBuilding: 1,
                },
                {
                    id: 11,
                    area: 0.8428999954032902,
                    selected: false,
                    isPolygon: true,
                    coordinates: [
                        [41.1862658088651, -8.488458005587107],
                        [41.1862569567156, -8.488471889601671],
                        [41.1862607464734, -8.488476155865465],
                        [41.18626959862289, -8.488462271850901],
                    ],
                    autoSplitBuilding: 1,
                },
            ],
            prod_params: {
                param: 'realTimeV1',
                raddatabase: 'PVGIS-SARAH',
                pvtechchoice: 'crystSi',
                pvgis_ano_tipico: 2014,
                factor_perda_inicial: 0.0834,
                geogrid_factor_correcao: null,
                factor_perda_extra_coplanar: 0.0325,
            },
            total_areas: 870.2858837294776,
            network_sale: true,
            total_panels: 280,
            max_tec_panels: 3012,
            min_tec_panels: 10,
            coordinates_avg: {
                lat: 41.18620663241081,
                long: -8.488509564645591,
                region_gd_id: 125,
            },
            injection_tariff: 0.06,
            limit_network_sale: false,
            inverters_combination: [
                {
                    id: 3,
                    qt: 0,
                    cost: 839.17,
                    brand: 'Huawei',
                    model: 'SUN2000-4KTL-M1',
                    power: 4,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 4,
                    qt: 0,
                    cost: 886.11,
                    brand: 'Huawei',
                    model: 'SUN2000-5KTL-M1',
                    power: 5,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 5,
                    qt: 0,
                    cost: 990.28,
                    brand: 'Huawei',
                    model: 'SUN2000-6KTL-M1',
                    power: 6,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 6,
                    qt: 0,
                    cost: 1174.59,
                    brand: 'Huawei',
                    model: 'SUN2000-8KTL-M1',
                    power: 8,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 7,
                    qt: 0,
                    cost: 1319.43,
                    brand: 'Huawei',
                    model: 'SUN2000-10KTL-M2',
                    power: 10,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 8,
                    qt: 0,
                    cost: 1407.92,
                    brand: 'Huawei',
                    model: 'SUN2000-12KTL-M2',
                    power: 12,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 10,
                    qt: 0,
                    cost: 1623.85,
                    brand: 'Huawei',
                    model: 'SUN2000-15KTL-M2',
                    power: 15,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 11,
                    qt: 0,
                    cost: 1630.98,
                    brand: 'Huawei',
                    model: 'SUN2000-17KTL-M2',
                    power: 17,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 12,
                    qt: 1,
                    cost: 1723.17,
                    brand: 'Huawei',
                    model: 'SUN2000-20KTL-M2',
                    power: 20,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 13,
                    qt: 0,
                    cost: 1966.38,
                    brand: 'Huawei',
                    model: 'SUN2000-30KTL-M3',
                    power: 30,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 14,
                    qt: 1,
                    cost: 2207.08,
                    brand: 'Huawei',
                    model: 'SUN2000-36KTL-M3',
                    power: 36,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 15,
                    qt: 1,
                    cost: 2360.96,
                    brand: 'Huawei',
                    model: 'SUN2000-40KTL-M3',
                    power: 40,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 16,
                    qt: 0,
                    cost: 2233.39,
                    brand: 'Huawei',
                    model: 'SUN2000-50KTL-M3',
                    power: 50,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 18,
                    qt: 0,
                    cost: 4384.41,
                    brand: 'Huawei',
                    model: 'SUN2000-100KTL-M1',
                    power: 100,
                    hybrid: true,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 20,
                    qt: 0,
                    cost: 4640.33,
                    brand: 'Sungrow',
                    model: 'SG110CX',
                    power: 110,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
                {
                    id: 19,
                    qt: 0,
                    cost: 3230.05,
                    brand: 'Sungrow',
                    model: 'SG50CX',
                    power: 50,
                    hybrid: false,
                    pv_rubrica_custo_id: 38,
                    max_ac_apparent_power: 0,
                },
            ],
            total_perimeter_areas: 232.789108655615,
            coordinates_conversion_factor: {
                one_meter_in_lat: 0.00000900434376143347,
                one_meter_in_lon: 0.00001191923025934094,
            },
        },
        data_gravacao: '2024-03-26T15:41:11.945Z',
        utilizador_id: '608138',
        ativo: true,
        visivel: true,
        versao_projeto_produto_inputs_id: null,
        description: 'Ceramica Telhado',
        comments: 'Projeto de 100kWp, não sei que. Agua a sul.',
        data_criacao: '2024-03-26T15:41:11.929Z',
        data_edicao: '2024-03-26T15:41:11.929Z',
        tipo_produto_id: 1,
        empresa_id: 1,
    },
];

export enum SolarPvToolbarOptions {
    ADDRESS = 'address',
    ZOOM = 'ZOOM',
    VISIBILITY = 'VISIBILITY',
    PANEL_EDITOR = 'PANEL_EDITOR',
    MAP_OPTIONS = 'mapOptions',
    STAMP = 'stamp',
    MOVE = 'move',
    ORIENTATION = 'orientation',
    ALIGNMENT = 'alignmment',
    COPY_PROPERTIES = 'copyProperties',
}

export const DEFAULT_VISIBILITY_ELEMENTS = {
    panels: {
        areas: true,
        panels: true,
        exclusions: true,
        buildings: false,
    },
    exclusions: {
        areas: true,
        panels: true,
        exclusions: true,
        buildings: false,
    },
    buildings: {
        areas: false,
        panels: false,
        exclusions: false,
        buildings: true,
    },
};

export enum THREEJS_MENU_TYPES {
    PANELS = 'panels',
    BUILDINGS = 'buildings',
    EXCLUSIONS = 'exclusions',
}

export const BATTERY_INPUT_DEFAULTS = {
    battery_charge_efficiency_perc: 0.95,
    battery_discharge_efficiency_perc: 0.95,
    battery_dod_perc: 0.9,
    injection_decrease_selected_perc: 0.1,
};

export const DISTANCE_TO_RESET_MAP_METERS: number = 1000;
export const DISTANCE_TO_RESET_MAP_ANCHOR_METERS = 100000;
