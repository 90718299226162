import { memo } from 'react';
// Material UI
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
// Our Components
import LanguageSwitcherOld from '../../../core/LanguageSwitcher/old';
import LanguageSwitcher from 'components/core/LanguageSwitcher';
// Constants
// import { GA_LABEL_TOP_LEVEL_NAVIGATION, GA_TYPE_EVT } from 'constants/gAnalytics';
// Services
// import { gAnalyticsEvt } from 'services/gAnalytics';
import { isEnvDevFlag } from 'services/settings';
// Stores
import { useUserStore } from 'store/user';
import { useFeatureFlags } from 'store/featureFlags';

const LangSwitcherCard = ({ isOpen, handleToggle, uniqueItem = false }) => {
    const { locale } = useUserStore();

    const { featureFlags } = useFeatureFlags();

    return (
        <Dropdown
            isOpen={isOpen}
            toggle={handleToggle}
            // onMouseDown={() => gAnalyticsEvt(GA_LABEL_TOP_LEVEL_NAVIGATION.LANGUAGE_SELECTOR, GA_TYPE_EVT.TOP_LEVEL_NAVIGATION)}
        >
            <DropdownToggle className="header-dropdown-toggle" tag="span" data-toggle="dropdown">
                <div className={`menu-item-content ${uniqueItem ? 'only-one-card' : ''}`}>
                    <span className="language-icon">{locale.isoLocale}</span>
                </div>
            </DropdownToggle>

            <DropdownMenu end className="w-auto transition-none">
                {isEnvDevFlag(featureFlags['fe-2536']) ?
                    <LanguageSwitcher handleRequestClose={handleToggle} />
                :   <LanguageSwitcherOld handleRequestClose={handleToggle} />}
            </DropdownMenu>
        </Dropdown>
    );
};

export default memo(LangSwitcherCard);
