import { memo, ReactNode, useState } from 'react';

//Libraries
import { IconButton, TIconButtonIcon } from '@save2compete/efz-design-system';
import IntlMessages from 'components/util/IntlMessages';

// icons
import { ReactComponent as SuccessIcon } from 'assets/@efz/icons/success.svg';
import { ReactComponent as WarningIcon } from 'assets/@efz/icons/warning.svg';
import { ReactComponent as ErrorIcon } from 'assets/@efz/icons/error.svg';
import { StyledAlert } from './StylesAlert';
import { AlertColor } from '@mui/material';

type TAlert = {
    severity?: AlertColor;
    className?: string;
    children: ReactNode;
    icon?: ReactNode;
    showClose?: boolean;
};

const Alert = ({
    severity = 'warning',
    className = '',
    children = <IntlMessages id="efz.text.loremIpsum" />,
    icon = undefined,
    showClose = false,
}: TAlert) => {
    const [show, setShow] = useState(true);

    const handleCloseAlert = () => setShow(false);

    return show ?
            <StyledAlert
                severity={severity}
                className={`${severity} ${className}`}
                icon={icon}
                iconMapping={{
                    success: <SuccessIcon />,
                    warning: <WarningIcon />,
                    error: <ErrorIcon />,
                }}
                onClose={showClose ? handleCloseAlert : undefined}
                slots={{
                    closeButton: IconButton,
                }}
                slotProps={{
                    // @ts-ignore
                    closeButton: {
                        disableRipple: true,
                        size: 'md',
                        variant: 'tertiary',
                        icon: 'xMarkExit',
                        className: 'close-alert-btn',
                        dataTestId: 'close-alert-btn',
                    } as TIconButtonIcon,
                }}
            >
                {children}
            </StyledAlert>
        :   <></>;
};

export default memo(Alert);
