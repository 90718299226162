import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector } from 'react-redux';
import { getClientID } from 'redux/selectors/client';
import { getFacilityID } from 'redux/selectors/facility';
import { useLocation } from 'react-router-dom';
import {
    BASENAME_URL_CLIENT,
    BASENAME_URL_CREATE_CLIENT,
    BASENAME_URL_FACILITIES,
    BASENAME_URL_FACILITY,
    BASENAME_URL_SOLUTIONS,
} from 'constants/settings';
import { SIDEBAR_BUTTONS, SIDEBAR_BUTTONS_CONFIG, SIDEBAR_BUTTONS_SECTIONS } from 'constants/sidebar';
import { UserContext } from './userContext';
import { containString, isDefined } from 'services/util/auxiliaryUtils';
import { BACKOFFICE_CONFIGS } from 'constants/backoffice';
import { Nullable } from 'types/utils';

interface ISidebarContext {
    buttonSelected: Nullable<boolean>;
    setButtonSelected: (value: boolean) => void;
    isSidebarPermanent: boolean;
    sidebarConfig: any;
    hasSolutionsButton: boolean;
    sidebarOpen: boolean;
    setSidebarOpen: (value: boolean) => void;
    showBackoffice: boolean;
    showErrorDevice: any;
    setShowErrorDevice: (value: any) => void;
    // Secondary Sidebar
    hasSecondarySidebar: boolean;
    sethasSecondarySidebar: (value: boolean) => void;
    backofficeSidebarConfig: any;
    secondarySidebarSectionSelected: any;
    setSecondarySidebarSectionSelected: (value: any) => void;
    secondarySidebarPosSectionSelected: any;
    setSecondarySidebarPosSectionSelected: (value: any) => void;
    sectionSelected: Nullable<SIDEBAR_BUTTONS_SECTIONS>;
    setSectionSelected: (value: SIDEBAR_BUTTONS_SECTIONS) => void;
}
const SidebarContext = createContext<ISidebarContext>({
    buttonSelected: null,
    setButtonSelected: () => {},
    isSidebarPermanent: false,
    sidebarConfig: null,
    hasSolutionsButton: false,
    sidebarOpen: false,
    setSidebarOpen: () => {},
    showBackoffice: false,
    showErrorDevice: null,
    setShowErrorDevice: () => {},
    // Secondary Sidebar
    hasSecondarySidebar: false,
    sethasSecondarySidebar: () => {},
    backofficeSidebarConfig: null,
    secondarySidebarSectionSelected: null,
    setSecondarySidebarSectionSelected: () => {},
    secondarySidebarPosSectionSelected: null,
    setSecondarySidebarPosSectionSelected: () => {},
    sectionSelected: null,
    setSectionSelected: () => {},
});

const SidebarProvider = ({ children }) => {
    const location = useLocation();
    const matches = useMediaQuery('(min-width:1366px)', { noSsr: true });
    const isMobileScreen = useMediaQuery('(max-width:575px)', { noSsr: true });

    // Contexts
    const { companyProfileId, configBackoffice } = useContext(UserContext);

    // Selectors
    const clientID = useSelector((state: any) => getClientID(state?.client));
    const facilityID = useSelector((state: any) => getFacilityID(state?.facility));

    // States
    const [sidebarOpen, setSidebarOpen] = useState(matches ? true : false);
    const [sidebarState, setSidebarState] = useState<Nullable<boolean>>(null); // When changing from permanent and non and get previous state
    const [isSidebarPermanent, setIsSidebarPermanent] = useState(false);
    const [buttonSelected, setButtonSelected] = useState<Nullable<boolean>>(null);
    const [sectionSelected, setSectionSelected] = useState<Nullable<SIDEBAR_BUTTONS_SECTIONS>>(null);
    const [hasSecondarySidebar, sethasSecondarySidebar] = useState(true);
    const [showErrorDevice, setShowErrorDevice] = useState(null);

    // Secondary Sidebar States
    const [secondarySidebarSectionSelected, setSecondarySidebarSectionSelected] = useState(null);
    const [secondarySidebarPosSectionSelected, setSecondarySidebarPosSectionSelected] = useState(null);

    const hasSolutionsButton = useMemo(() => isDefined(clientID) && isDefined(facilityID), [clientID, facilityID]);

    const sidebarStateHandler = (isPermanent, buttonSelected, withSecondarySidebar = false) => {
        const _isPermanent = isMobileScreen ? false : isPermanent;
        setButtonSelected(buttonSelected);
        setIsSidebarPermanent(_isPermanent);
        sethasSecondarySidebar(withSecondarySidebar);
        if (!containString(['site-survey', 'proposals', 'om'], location.pathname)) setSectionSelected(null);

        if (_isPermanent) {
            setSidebarOpen(sidebarState ?? sidebarOpen);
            setSidebarState(null);
        } else {
            setSidebarState(sidebarOpen);
            setSidebarOpen(false);
        }
    };

    const sidebarConfig = useMemo(
        () => SIDEBAR_BUTTONS_CONFIG?.[companyProfileId] ?? SIDEBAR_BUTTONS_CONFIG.DEFAULT,
        [companyProfileId, hasSolutionsButton] // eslint-disable-line
    );
    const backofficeSidebarConfig = useMemo(() => BACKOFFICE_CONFIGS?.[companyProfileId] ?? BACKOFFICE_CONFIGS.DEFAULT, [companyProfileId]);

    useEffect(() => {
        switch (location.pathname) {
            case BASENAME_URL_CLIENT:
            case BASENAME_URL_CREATE_CLIENT:
            case BASENAME_URL_FACILITY:
            case BASENAME_URL_FACILITIES:
                sidebarStateHandler(true, null);
                break;
            case BASENAME_URL_SOLUTIONS:
                sidebarStateHandler(!isMobileScreen ? true : false, SIDEBAR_BUTTONS.SOLUTIONS);
                break;
            // case BASENAME_URL_COOKIES_POLICIES:
            //     sidebarStateHandler(false, null);
            //     break;
            default:
                if (containString(['processes'], location.pathname)) {
                    sidebarStateHandler(false, SIDEBAR_BUTTONS.PROCESSES);
                    if (containString(['proposals'], location.pathname)) setSectionSelected(SIDEBAR_BUTTONS_SECTIONS.PROPOSALS);
                    if (containString(['site-survey'], location.pathname)) setSectionSelected(SIDEBAR_BUTTONS_SECTIONS.SITE_SURVEY);
                    if (containString(['om'], location.pathname)) setSectionSelected(SIDEBAR_BUTTONS_SECTIONS.ASSET_MANAGEMENT);
                } else if (containString(['proposal'], location.pathname)) {
                    sidebarStateHandler(true, null);
                    break;
                } else if (containString(['backoffice'], location.pathname)) {
                    sidebarStateHandler(true, SIDEBAR_BUTTONS.BACKOFFICE, true);
                } else {
                    // Products, and rest of app
                    sidebarStateHandler(false, SIDEBAR_BUTTONS.SOLUTIONS);
                    break;
                }
        }
    }, [location.pathname]); // eslint-disable-line

    const hasAtLeastOneBOProduct = (obj) => {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key].show_backoffice === true) {
                return true;
            }
        }
        return false;
    };

    const hasOneBOProduct = useMemo(() => hasAtLeastOneBOProduct(configBackoffice), [configBackoffice]); // eslint-disable-line

    const sidebarValues: ISidebarContext = {
        buttonSelected,
        setButtonSelected,
        isSidebarPermanent,
        sidebarConfig,
        hasSolutionsButton: hasOneBOProduct,
        sidebarOpen,
        setSidebarOpen,
        showBackoffice: hasOneBOProduct,
        showErrorDevice,
        setShowErrorDevice,
        // Secondary Sidebar
        hasSecondarySidebar,
        sethasSecondarySidebar,
        backofficeSidebarConfig,
        secondarySidebarSectionSelected,
        setSecondarySidebarSectionSelected,
        secondarySidebarPosSectionSelected,
        setSecondarySidebarPosSectionSelected,
        sectionSelected,
        setSectionSelected,
    };
    return <SidebarContext.Provider value={sidebarValues}>{children}</SidebarContext.Provider>;
};

export { SidebarContext, SidebarProvider };
