import { memo } from 'react';
import classNames from 'classnames';
import IntlMessages from 'components/util/IntlMessages';
import { useUserStore } from 'store/user';

const LanguageItem = ({ language, handleRequestClose }) => {
    const { name, nameTranslated, isoLocale, locale } = language;
    const { switchLanguage, LOCALE_CODE } = useUserStore();

    return (
        <li
            className={classNames('pointer', { active: locale === LOCALE_CODE })}
            onClick={() => {
                handleRequestClose();
                switchLanguage(language);
            }}
        >
            <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                    <span className="language-icon">{isoLocale}</span>
                    <span className="ms-1">-</span>
                    <h5 className="mb-0 ms-1">
                        <IntlMessages id={nameTranslated} />
                    </h5>
                </div>
                <span className="language-italic">
                    <IntlMessages id={name} />
                </span>
            </div>
        </li>
    );
};

export default memo(LanguageItem);
