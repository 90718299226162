import useProposal from 'hooks/proposal';
import GenerateProposal from 'components/Proposal/GenerateProposal';
import GenerateDocuments from 'components/Proposal/GenerateDocuments';
import { useContext } from 'react';
import { UserContext } from 'contexts/userContext';
import { useSelector } from 'react-redux';
import Alert from 'components/@efz/Alert';
import IntlMessages from 'components/util/IntlMessages';
import { Button } from '@save2compete/efz-design-system';

const ProposalContainer = () => {
    const { companyProfileId } = useContext(UserContext);

    const product = useSelector((state: any) => state?.product);

    const {
        isHeadingSupportDocs,
        errorSupportDocs,
        proposalID,
        has_proposal_description_feature,
        contractAvailable,
        getSupportDocsHandler,
        isGettingSupportDocs,
        canSendToCRM,
        hasAccessToProposals,
        fetchAgainHandler,
        errorIdProposal,
        fetchProposalIdAgain,
    } = useProposal();

    if (errorIdProposal) {
        return (
            <div className="d-flex flex-column align-items-center w-100">
                <Alert severity="error" className="w-100">
                    <IntlMessages id="page.error.403.unexpected" />
                </Alert>
                <Button onClick={fetchProposalIdAgain} className="mt-3" color="primary" dataTestId="fetch-proposal-id">
                    <IntlMessages id="label.tryAgain" />
                </Button>
            </div>
        );
    }

    return (
        <div className={`proposal-card`}>
            {proposalID ?
                <GenerateDocuments
                    proposalID={proposalID}
                    isHeadingSupportDocs={isHeadingSupportDocs}
                    has_proposal_description_feature={has_proposal_description_feature}
                    errorSupportDocs={errorSupportDocs}
                    contractAvailable={contractAvailable}
                    getSupportDocsHandler={getSupportDocsHandler}
                    isGettingSupportDocs={isGettingSupportDocs}
                    companyProfileId={companyProfileId}
                    canSendToCRM={canSendToCRM}
                    productID={product.info.id}
                    hasAccessToProposals={hasAccessToProposals}
                    fetchAgainHandler={fetchAgainHandler}
                />
            :   <GenerateProposal />}
        </div>
    );
};

export default ProposalContainer;
