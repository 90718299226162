import { z } from 'zod';
import { PRODUCT_IDS } from 'constants/products';

const coordinatesAvgSchema = z.object({
    lat: z.number(),
    long: z.number(),
    region_gd_id: z.number().optional(),
});

const kitsSchema = z.object({
    id: z.number(),
    areas: z.array(z.number()),
});

const areaSchema = z.object({
    id: z.number(),
    area: z.number(),
    orientation: z.number(),
    coordinates_avg: coordinatesAvgSchema,
    panels_number: z.number(),
    region_gd_id: z.number().nullable().optional(),
    mounting_structure_id: z.number(),
    selected: z.boolean().optional(),
    perimeter_area: z.number(),
    panels_number_possible: z.number(),
    panels_number_kit: z.number().optional(),
    panels_number_excluded: z
        .number()
        .int()
        .transform((val) => parseInt(val.toString())),
    kit_id: z.number().optional(),
    slope: z.number(),
    panels_max_number: z.number().optional(),
    aspect: z.number().optional(),
    range_id: z.number().optional(),
    region_radiation: z.any().optional(),
    price_zone_name: z.string().optional(),
    npv: z.number().optional(),
    total_loss: z.number().optional(),
    max_tec_area_panels: z.number().optional(),
    max_kwp_area: z.number().nullable().optional(),
    mounting_structure_type_id: z.number(),
});

export const simulationPayloadSchema = z.object({
    is_scaling: z.boolean(),
    tipo_produto_id: z.union([z.literal(PRODUCT_IDS.SPV), z.literal(PRODUCT_IDS.SPVSB)]),
    facility: z.object({
        id: z.union([z.string().regex(/^\d+$/), z.number()]).nullable(),
    }),
    inputs: z.object({
        areas: z.array(areaSchema),
        battery: z.record(z.any(), z.any()).optional(),
        coordinates_avg: coordinatesAvgSchema,
        injection_tariff: z.number().nullable().optional(),
        injection_tariff_distributor: z.any().optional(),
        is_customize: z.boolean().optional(),
        inverters_combination: z.array(z.any()),
        isChangeBattery: z.boolean().optional(),
        is_kits: z.boolean().optional(),
        isManualDrawing: z.boolean().optional(),
        isSimpleMode: z.boolean().optional(),
        max_tec_panels: z.number(),
        min_tec_panels: z.number(),
        network_sale: z.boolean(),
        limit_network_sale: z.union([z.number(), z.boolean()]),
        range_id: z.number(),
        panel_id: z.number(),
        total_areas: z.number(),
        total_panels: z.number(),
        remuneration_type_id: z.number().nullable().optional(),
        kits: z.array(kitsSchema).optional(),
        total_perimeter_areas: z.number().nullable(),
        remote: z.record(z.any(), z.any()).nullable().optional(),
        offer_edition: z.record(z.any(), z.any()).optional(),
        locationAddress: z.record(z.any(), z.any()).optional(),
        prod_params: z.record(z.any(), z.any()).optional(),
        // power increase
        is_power_increase: z.boolean().optional(),
        old_proposal_id: z.number().optional(),
        previous_inverter_power_kw: z.number().optional(),
        previous_peak_power: z.number().optional(),
        // reformulation
        reformulation: z
            .object({
                id: z.union([z.string().regex(/^\d+$/), z.number()]),
            })
            .optional(),
        user_id: z.union([z.string().regex(/^\d+$/), z.number()]).optional(),
    }),
});

export const optimizerPayloadSchema = z.object({
    is_scaling: z.boolean(),
    tipo_produto_id: z.union([z.literal(PRODUCT_IDS.SPV), z.literal(PRODUCT_IDS.SPVSB)]),
    facility: z.object({
        id: z.union([z.string().regex(/^\d+$/), z.number()]).nullable(),
    }),
    inputs: z.object({
        areas: z.array(areaSchema),
        battery: z.record(z.any(), z.any()).optional(),
        coordinates_avg: coordinatesAvgSchema,
        injection_tariff: z.number().nullable().optional(),
        injection_tariff_distributor: z.any().optional(),
        is_customize: z.boolean().optional(),
        inverters_combination: z.array(z.any()).optional(),
        isChangeBattery: z.boolean().optional(),
        is_kits: z.boolean().optional(),
        isManualDrawing: z.boolean().optional(),
        isSimpleMode: z.boolean().optional(),
        max_tec_panels: z.number(),
        min_tec_panels: z.number(),
        network_sale: z.boolean(),
        limit_network_sale: z.union([z.number(), z.boolean()]),
        range_id: z.number(),
        panel_id: z.number(),
        total_areas: z.number(),
        total_panels: z.number(),
        remuneration_type_id: z.number().nullable().optional(),
        kits: z.array(kitsSchema).optional(),
        total_perimeter_areas: z.number().nullable(),
        remote: z.record(z.any(), z.any()).nullable().optional(),
        prod_params: z.record(z.any(), z.any()).optional(),
    }),
});

// Define the CoordinatesConversionFactor schema
const coordinatesConversionFactorSchema = z.object({
    one_meter_in_lon: z.number(),
    one_meter_in_lat: z.number(),
});

// Define the PanelRow schema (assuming its structure, you may need to adjust it)
const panelRowSchema = z.object({
    panels_number: z.number(),
    positions: z.array(z.array(z.array(z.number()))),
    perc_shadow: z.array(z.number()),
    panels_idx: z.array(z.array(z.union([z.array(z.number()), z.number()]))),
    excluded: z.boolean(),
});

// Define the IUsefulArea schema
export const usefulAreaSchema = z.object({
    id: z.number(),
    roof_area: z.number(),
    useful_area: z.number(),
    panels_number: z.number(),
    panel_rows: z.array(panelRowSchema),
    message: z.string(),
    roof_centroid: z.array(z.number()),
    reference_point: z.array(z.number()),
    coordinates_conversion_factor: coordinatesConversionFactorSchema,
    coordinates_setbacks: z.array(z.array(z.array(z.number()))),
    output_on_cache: z.boolean(),
    panel_representation: z.any(), // Adjust this based on the actual type
    uaErrorMessage: z.string(),
});

const SavingsPossibleSchema = z.object({
    ac_tariff: z.number(),
    prd_tariff: z.number(),
    annual_saving_self_consumption: z.number(),
});

const ProductionLossesSchema = z.object({
    theoretical_production_system: z.number(),
    final_energy: z.number(),
    output_energy_panels: z.number(),
    output_energy_panels_prc: z.number(),
    energy_after_clipping: z.number(),
    energy_after_clipping_prc: z.number(),
    final_energy_prc: z.number(),
    total_loss_prc: z.number(),
    default_total_loss: z.number(),
});

const BrandSchema = z.enum(['Huawei', 'Sungrow']);

const InvertersCombinationSchema = z.object({
    id: z.number(),
    qt: z.number(),
    cost: z.number(),
    brand: BrandSchema,
    model: z.string(),
    power: z.number(),
    hybrid: z.boolean(),
    pv_rubrica_custo_id: z.number(),
    max_ac_apparent_power: z.number(),
});

const InvertersFaultArrSchema = z.object({
    id: z.string(),
    ano: z.number(),
    valor: z.number(),
    empresa_id: z.number(),
});

const SystemSchema = z.object({
    // Define the properties of System here
});

const LecEnergySaleMonthlyAggArrSchema = z.object({
    mes: z.number(),
    taker_prev_cons_sum: z.number(),
    energy_to_sale_kwh_sum: z.number(),
});

const LocalEnergyCommunitySchema = z.object({
    lec_con_kwh: z.number(),
    lec_energy_sale_sum: z.number(),
    has_lec_business_model_conditions: z.boolean(),
    lec_con_kwh_db: z.number(),
    max_takers: z.number(),
    num_takers: z.number(),
    n_contracts: z.number(),
    is_greater_than_energy_min: z.boolean(),
    is_num_contracts_ok: z.boolean(),
    is_num_takers_ok: z.boolean(),
    lec_energy_sale_monthly_agg_arr: z.array(LecEnergySaleMonthlyAggArrSchema),
});

// Define the Years schema
const YearsSchema = z.object({
    default: z.number(),
    included: z.number(),
    values: z.array(z.number()),
});

// Define the Duration schema
const DurationSchema = z.object({
    gc_years: YearsSchema,
    maintenance_years: YearsSchema,
    inverters_warranty_years: YearsSchema,
    panels_warranty_years: YearsSchema,
});

// Define the WarrantyOption schema
const WarrantyOptionSchema = z.object({
    cost: z.number(),
    years_warranty: z.number(),
});

// Define the Annuity schema
const AnnuitySchema = z.object({
    gc: z.number(),
    maintenance: z.union([z.number(), z.null()]),
    panels_warranty: z.number(),
    inverters_warranty: z.null().optional(),
});

// Define the PurpleCost schema
const PurpleCostSchema = z.object({
    annuity: AnnuitySchema,
    base: z.number(),
    extra: z.number(),
    inverters_warranty_options: z.array(WarrantyOptionSchema),
    inverters_warranty: z.number(),
    panels_warranty: z.number(),
    gc: z.number(),
    base_service: z.number(),
});

// Define the KpisOfferEdition schema
const KpisOfferEditionSchema = z.object({
    descricao: z.string(),
    cost: PurpleCostSchema,
    duration: DurationSchema,
    calculus_mode_id: z.number(),
});

const Nullable = <T extends z.ZodTypeAny>(schema: T) => z.union([schema, z.null()]);

// Define the ISpvKpis schema
export const ISpvKpisSchema = z.object({
    savings_possible: SavingsPossibleSchema,
    range_id: z.number(),
    cost: z.number(),
    capex: z.number(),
    opex: z.number(),
    equipments_cost: z.number(),
    services_cost: z.number(),
    maintenance_cost: z.number(),
    investment: z.number(),
    annual_saving: z.number(),
    payback: z.number(),
    annual_prod: z.number(),
    annual_cons: z.number(),
    annual_surplus: z.number(),
    annual_injection: z.number(),
    annual_charged_energy_batt: z.number(),
    annual_discharged_energy_batt: z.number(),
    injection_perc: z.number(),
    selfconsumption_perc: z.number(),
    annual_ac: z.number(),
    cons_reduction_perc: z.number(),
    peak_power: z.number(),
    total_area: z.number(),
    ac_tariff: z.number(),
    carbon_footprint_reduction: z.number(),
    network_sale: z.boolean(),
    network_sale_editable: z.boolean(),
    limit_network_sale: z.boolean(),
    injection_tariff_editable: z.boolean(),
    injection_tariff: z.number(),
    injection_tariff_edited: z.boolean(),
    annual_consumption_tariff: z.number(),
    injection_tariff_default: z.number(),
    production_losses: ProductionLossesSchema,
    prod_before_inverter_cut: z.number(),
    inverters_combination: z.array(InvertersCombinationSchema),
    inverters_combination_recommendation: z.array(InvertersCombinationSchema),
    monthly_payments_number: z.number(),
    due_diligence_extra_cost: z.number(),
    distributor_extra_cost: z.number(),
    battery_cost: z.number(),
    battery_installation_cost: z.number(),
    battery_inverter_cost: z.number(),
    battery_maintenance_cost: z.number(),
    inverters_fault_arr: z.array(InvertersFaultArrSchema),
    inverters_opex_arr: z.array(z.any()),
    system: SystemSchema,
    inverter_power: z.number(),
    local_energy_community: LocalEnergyCommunitySchema,
    ground_panels_number: z.number(),
    roof_panels_number: z.number(),
    battery_savings: z.null(),
    battery_capacity_kwh: z.null(),
    battery_power_kw: z.null(),
    battery_recommendation_is_optimal: z.null(),
    battery_selfcons_graph: z.null(),
    offer_edition: KpisOfferEditionSchema,
    service_cost: z.number(),
    breaker_rating: Nullable(z.number()),
});

// Timeline
export const timelineActionSchema = z.object({
    usefulAreaData: z.any(),
    standaloneSims: z.any(),
    bundleSims: z.any(),
    panels: z.any(),
    polygonGroups: z.any(),
    selectedArea: z.any(),
    exclusionGroups: z.any(),
    selectedExclusion: z.any(),
    buildingGroups: z.any(),
    selectedBuilding: z.any(),
    rangeSelected: z.any(),
    inputs: z.any(),
    hasAutoUpdateKPIs: z.boolean(),
    hasUpdatedKPIS: z.boolean(),
    isComputeKPIs: z.boolean(),
    inverters: z.any(),
    tags: z.any(),
    productSelection: z.any(),
    groupTab: z.any(),
    visibility: z.any(),
    productions: z.any(),
});

export type TTimelineAction = z.infer<typeof timelineActionSchema>;
