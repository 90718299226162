import { BOActions, BOInitialState, BOLoadings, BOLoadingsType } from 'interfaces/backoffice';

export const initialBOLoadings: BOLoadingsType = {
    [BOLoadings.templatesProductID]: false,
    [BOLoadings.templatesBMs]: false,
    [BOLoadings.templatesDocTypes]: false,
    [BOLoadings.templatesDocs]: false,
    [BOLoadings.templatesDownloadDocs]: null,
    [BOLoadings.templatesUploadDocs]: false,
    [BOLoadings.templatesMarkEvent]: false,
    [BOLoadings.templatesProposalDetails]: false,
    [BOLoadings.templatesPostSimulation]: false,
    [BOLoadings.templatesSupportDocs]: false,
    [BOLoadings.templatesPutDoc]: false,
    [BOLoadings.templatesProposalList]: false,
    [BOLoadings.templatesProposalClients]: false,
    [BOLoadings.menusGeneral]: false,
    [BOLoadings.menusMounting]: true,
    [BOLoadings.menusUpdating]: false,
    [BOLoadings.menusRequesting]: false,
    [BOLoadings.menusSaving]: false,
    [BOLoadings.menusPreviewing]: false,
    [BOLoadings.menusPublishing]: false,
    [BOLoadings.menusDiscarding]: false,
};

export const initialBOReducer: BOInitialState = {
    product: {
        productID: null,
        tag: null,
    },
    templates: {
        businessModels: [],
        selectedBM: null,
        docTypes: [],
        selectedDocType: null,
        docs: [],
        selectedDoc: null,
        originalUploadedDocName: null,
        uploadedDocAzureName: null,
        proposal: null,
        simId: null,
        proposalList: {
            clientList: [],
            original: [],
            filtered: [],
        },
    },
    loadings: initialBOLoadings,
    menus: {
        availableMenus: [],
        availableStates: [],
        currentMenu: null,
        currentMenuState: null,
        currentMenuPublishedStateInfo: null,
        changeToSavedState: function (): Promise<void> {
            throw new Error('Function not implemented.');
        },
        changeToPreviewState: function (): Promise<void> {
            throw new Error('Function not implemented.');
        },
        changeToPublishState: function (): Promise<void> {
            throw new Error('Function not implemented.');
        },
        discardChanges: function (): Promise<void> {
            throw new Error('Function not implemented.');
        },
    },
};

export const boReducer = (state = initialBOReducer, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case BOActions.SET_LOADINGS: {
            // setup menusGeneral loading value
            const menusLoadings = [
                BOLoadings.menusMounting,
                BOLoadings.menusUpdating,
                BOLoadings.menusRequesting,
                BOLoadings.menusSaving,
                BOLoadings.menusPreviewing,
                BOLoadings.menusPublishing,
                BOLoadings.menusDiscarding,
            ];
            const menusLoadingsCurrentlyOn = Object.entries(state.loadings)
                .filter((loading) => menusLoadings.includes(loading[0] as BOLoadings) && loading[1] === true)
                .map((loading) => loading[0] as BOLoadings);

            let menusGeneralLoadingValue = state.loadings?.[BOLoadings.menusGeneral];
            if (menusLoadings.includes(payload.name)) {
                if (menusLoadingsCurrentlyOn?.length === 0) {
                    menusGeneralLoadingValue = payload.value === true;
                } else if (menusLoadingsCurrentlyOn.length === 1) {
                    menusGeneralLoadingValue =
                        menusLoadingsCurrentlyOn?.includes(payload.name) ? payload.value === true : menusGeneralLoadingValue;
                } else {
                    menusGeneralLoadingValue = true;
                }
            }

            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    [action.payload.name]: action.payload.value,
                    [BOLoadings.menusGeneral]: menusGeneralLoadingValue,
                },
            };
        }

        case BOActions.SET_PRODUCT_ID: {
            return {
                ...state,
                product: payload,
                templates: initialBOReducer.templates,
                loadings: {
                    ...state.loadings,
                    templatesProductID: false,
                },
            };
        }

        case BOActions.SET_BUSINESS_MODELS: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    businessModels: payload,
                    selectedBM: payload.length === 1 ? payload[0] : state.templates.selectedBM,
                },
                loadings: {
                    ...state.loadings,
                    templatesBMs: false,
                },
            };
        }

        case BOActions.SET_SELECTED_BM: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    selectedBM: payload,
                    proposalList: { ...initialBOReducer.templates.proposalList },
                },
            };
        }

        case BOActions.SET_DOC_TYPES: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    docTypes: payload,
                },
                loadings: {
                    ...state.loadings,
                    templatesDocTypes: false,
                },
            };
        }

        case BOActions.SET_SELECTED_DOC_TYPE: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    selectedDocType: payload,
                },
            };
        }

        case BOActions.SET_CLEAR_FILTERS: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    selectedBM: null,
                    selectedDocType: null,
                },
            };
        }

        case BOActions.SET_DOCUMENT_TEMPLATES: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    docs: payload,
                },
            };
        }

        case BOActions.SET_SELECTED_DOC: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    selectedDoc: payload,
                },
            };
        }

        case BOActions.SET_UPLOAD_DOC_AZURE_NAME: {
            const { originalName, azureName } = payload;
            return {
                ...state,
                templates: {
                    ...state.templates,
                    originalUploadedDocName: originalName,
                    uploadedDocAzureName: azureName,
                },
            };
        }

        case BOActions.SET_TEMPLATE_PROPOSAL_DETAILS: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    proposal: payload,
                },
            };
        }

        case BOActions.SET_SIMULATION_ID: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    simId: payload,
                },
            };
        }

        case BOActions.SET_PROPOSAL_LIST: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    proposalList: {
                        ...state.templates.proposalList,
                        original: payload,
                    },
                },
            };
        }

        case BOActions.SET_FILTERED_PROPOSAL_LIST: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    proposalList: {
                        ...state.templates.proposalList,
                        filtered: payload,
                    },
                },
            };
        }

        case BOActions.SET_PROPOSAL_CLIENT_LIST: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    proposalList: {
                        ...state.templates.proposalList,
                        clientList: payload,
                    },
                },
            };
        }

        case BOActions.SET_RESET_TEMPLATES: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    originalUploadedDocName: initialBOReducer.templates.originalUploadedDocName,
                    proposal: initialBOReducer.templates.proposal,
                    simId: initialBOReducer.templates.simId,
                    uploadedDocAzureName: initialBOReducer.templates.uploadedDocAzureName,
                },
            };
        }

        case BOActions.SET_RESET_ALL: {
            return initialBOReducer;
        }

        case BOActions.SET_MENUS: {
            const { availableMenus, availableStates, isStateChangeEnabled } = payload;

            return {
                ...state,
                loadings: {
                    ...state.loadings,
                    menusMounting: false,
                },
                menus: {
                    ...state.menus,
                    availableMenus,
                    availableStates,
                    isStateChangeEnabled,
                },
            };
        }

        case BOActions.UPDATE_MENUS: {
            const {
                currentMenu,
                currentMenuState,
                currentMenuPublishedStateInfo,
                changeToSavedState,
                changeToPreviewState,
                changeToPublishState,
                discardChanges,
            } = payload;

            return {
                ...state,
                menus: {
                    ...state.menus,
                    currentMenu,
                    currentMenuState,
                    currentMenuPublishedStateInfo,
                    changeToSavedState,
                    changeToPreviewState,
                    changeToPublishState,
                    discardChanges,
                },
            };
        }

        default: {
            throw new Error('Error on BOReducer, no valid action ' + action);
        }
    }
};
